import Component from '../../model/Component';
import HttpClient from "../../business/HttpClient";
import $ from "jquery";
import {Utils} from "./Utils";
import GigyaManager from "../../business/GigyaManager";
import Utility from "../../business/Utility";

class InstantWinStepFormComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);
        this.store = store;
        this.stateObject = this.store.getState().data;
        this.key = 'CH_888_MLB_WEB_MATANO23_NAV_NAV_20230919_20231130';
        this.checkbox = this.element.querySelector('input[type=checkbox]');
        this.button = this.element.querySelector('.topic-step-form__win');
        this.modal = document.getElementById('instant-win-modal');
        this.conditionGranted = false;
        this.init();
    }


    instantWin() {
        const data = {basedata: {channelid: 1}, customdata: { video_code: this.id }};
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'POST',
                url: API_ENDPOINT + `/api/mulinobianco/2.0/web/promo-engine/morso-felicita-2023/gioca`,
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(data)
            }).then((data) => resolve(data.body), (error) => {
                $('#overlayer').hide();
                reject(error);
            });
        });
    }


    getModalResult(winner, token, sharedData) {
        const isMobile = Utils.isMobileDevice();
        if (winner) {
            return `
                <h1 class="umdf-modal__title umdf-subtitle">Complimenti!<br>Hai vinto una Sandwich Bag Mulino Bianco.</h1>
                <img src="/fe-web/img/un-morso-di-felicita/prize.png" alt="sandwich bag"/>
                <a href="/promozioni/dedica-un-morso-di-felicita/completa-dati?pt=${token}" class="button">Richiedi premio</a>
            `;
        } else {
            return `
                <h1 class="umdf-modal__title umdf-subtitle">Purtroppo non hai vinto <br> :( </h1>
                <div class="umdf-text">
                    Questa volta purtroppo non sei riuscito a vincere la Sandwich Bag Mulino Bianco. 
                    Ma hai ancora la possibilità di farlo! Pensa a qualcuno a cui vuoi bene, dedicagli 
                    un nuovo morso di felicità e prova a vincere!
                    <br><br>
                    <b>INTANTO CONDIVIDI IL TUO VIDEO</b>
                </div>
                
                <div class="instant-win-modal__share">
                    ${isMobile ? '<button type="button" class="mobile"></button>' : `
                        <a href="${ Utility.getShareLink("whatsapp", sharedData.title, encodeURIComponent(sharedData.text + " " +sharedData.url)) }" 
                            class="whatsapp" target="_blank"></a>
                        <a href="${ Utility.getShareLink("email", sharedData.title, encodeURIComponent(sharedData.text + " " +sharedData.url)) }" 
                            class="email" target="_blank"></a>              
                    `}
                </div>
                <a href="/promozioni/dedica-un-morso-di-felicita/prepara-il-tuo-morso" class="button">Crea un nuovo video</a>
                
                <a href="/promozioni/dedica-un-morso-di-felicita/area-personale" class="instant-win-modal__back umdf-link">Vai alla tua area riservata</a>
                
                <div class="umdf-text">
                    Il video resterà attivo solo per 48 ore dalla sua creazione.
                </div>
            `;
        }
    }

    addShareListener(sharedData) {
        const shareButton = this.modal.querySelector('.instant-win-modal__share .mobile');
        shareButton && shareButton.addEventListener('click', () => {
            try {navigator.share(sharedData).then(() => console.log("Native share popup opened!"));}
            catch (e) { console.log(e); }
        });
    }

    getProfileInfo() {
        GigyaManager.getLoggedUser().then((result) => {
            $('#overlayer').hide();
            this.element.querySelector('.instant-win-step-form__label b').innerHTML = result.profile.email;
            this.conditionGranted = result.data[this.key];
            if (!this.conditionGranted) {
                this.element.querySelector('.data-step-form-checkbox').classList.remove('hidden');
                this.element.querySelector('.data-step-form__required-field').classList.remove('hidden');

            } else {
                this.button.removeAttribute('disabled');
            }
        });
    }

    setCH() {
        GigyaManager.addCH(this.key).then(() => {
            this.getInstantWin();
        }).catch((error) => {
            console.log(error);
            $('#overlayer').hide();
        });
    }

    getInstantWin() {
        this.instantWin().then((result) => {
            console.log(result);
            const winner = (result.response?.data?.play?.status === "WIN");
            const token = winner ? result.response.data.play.token : '';
            this.button.remove();

            const sharedData = {
                title: "Mulino Bianco - Un Morso di Felicità",
                text: "Una persona ti ha dedicato un morso di felicità, scopri chi è!",
                url: location.origin + "/promozioni/dedica-un-morso-di-felicita/condividi-il-tuo-morso?id=" + this.id
            };

            this.modal.querySelector('.instant-win-modal').innerHTML = this.getModalResult(winner, token, sharedData);
            Utils.openModal(this.modal);
            this.addShareListener(sharedData);
            $('#overlayer').hide();
        }, (error) => {
            console.log(error);
        });
    }


    addListeners() {
        this.checkbox.addEventListener('change', () => {
            (this.checkbox.checked) ? this.button.removeAttribute('disabled') : this.button.setAttribute('disabled', 'true');
        });

        this.button.addEventListener('click', () => {
            $('#overlayer').show();
            this.conditionGranted ? this.getInstantWin() : this.setCH();
        });
    }

    init() {
        this.id = this.stateObject.id;
        if (!this.id) {
            this.element.querySelector('.instant-win-step-form').innerHTML =
                '<div class="instant-win-step-form__error"><h2 class="umdf-subtitle">Ops..</h2><h3 class="umdf-text"> qualcosa è andato storto </h3></div>';
            return;
        }

        $('#overlayer').show();
        this.addListeners();
        this.getProfileInfo();
    }
}


export default InstantWinStepFormComponent;
