import NewsletterGoodMorningCtrl from "./NewsletterGoodMorningCtrl";
import UserManager from "../business/UserManager";
import GigyaManager from "../business/GigyaManager";
import $ from 'jquery';
import Pristine from "pristinejs/dist/pristine";
import GoogleAnalytics from "../business/GoogleAnalytics";

class NewsletterNmcvCtrl extends NewsletterGoodMorningCtrl {
    constructor(element) {
        super(element);
        this.pristine = '';
        this.$el = $(this.element);
        this.initForm();
        this.toggleInput();
    }

    initForm(){
        let form = document.getElementById("newsletter-good-morning");

        let formConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'newsletter-good-morning__radio-container',
            errorClass: 'error',
            successClass: 'has-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'newsletter-good-morning__radio-container',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'error-field'
        };

        // create the pristine instance
        this.pristine = new Pristine(form, formConfig);
    }


    sendForm() {
        let newsletterNMCV = $('input[name="nmcv"]').is(":checked");


        if (newsletterNMCV) {
            UserManager.getLoggedUser().then((d) => {
                let params = {
                    subscriptions: {},
                    callback: (data) => {
                        if (data.status === "OK") {
                            window.location.href = "/newsletter-thank-you";
                        }
                    }
                };

                if(newsletterNMCV){
                    params.subscriptions.NL_NMCV = {
                        email: {
                            isSubscribed: true
                        }
                    }
                }

                GigyaManager.setAccountInfo(params);

                const obj = {
                    'data-form-id': 'newsletter-nmcv',
                    'data-form-name': 'newsletter Nmcv'
                }

                GoogleAnalytics.send('form_submitted', obj);
            }, () => {
                // save values in localstorage
                localStorage.setItem("newsletter-goodmorning-nmcv", newsletterNMCV);
                localStorage.setItem("newsletter-goodmorning-redirect", "false");
                window.location.href = "/login?regSource=890";
            });


        }else{
            $(".newsletter-good-morning__radio-container").append('<span class="pristine-error error-field" id="error-tre-notizie">Per iscriverti ad una delle newsletter Mulino Bianco è necessario compilare uno dei campi</span>');
        }
    }

}

export default NewsletterNmcvCtrl;
