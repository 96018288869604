import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";

class AryelHubCtrl extends Controller {
    constructor(element) {
        super(element);

        this.init();
    }

    init() {
        // check if user is logged
        GigyaManager.getLoggedUser().then(() => {
            let listUrls = [
                '/acchiappa-la-gommina',
                '/centra-la-scatolina',
                '/tiro-alla-sorpresina',
            ];

            const shuffleUrl = listUrls.sort(() => Math.random() - 0.5);
            window.location.href = shuffleUrl[0];
        }, () => {
            window.location.href = '/login?urlBack=' + Utility.getCurrentUri() + '&regSource=922';
        });
    }
}

export default AryelHubCtrl;
