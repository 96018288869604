/**
 *
 */

import Controller from '../model/Controller';

class DefaultCtrl extends Controller {
    constructor(element) {
        super(element);
    }


}

export default DefaultCtrl;
