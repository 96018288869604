import Controller from '../model/Controller';
import CollectionPromoApi from "mbpromo/src/collection-api/CollectionPromoApi";
import $ from "jquery";
import UserManager from "../business/UserManager";
import Constants from "../business/Constants";
import Pristine from "pristinejs";
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";
import Cookies from "js-cookie";
import HttpClient from "../business/HttpClient";

class PersonalAreaCartaceaCtrl extends Controller {
    constructor(element) {
        super(element);
        this.collectionPromoApi = new CollectionPromoApi({promoCode: 'raccolta-punti'});
        this.emailLoggedUser = '';
        this.userBadgeCode = '';
        this.pristine;
        this.endpointTessera = window.API_ENDPOINT + `/api/mulinobianco/1.0/web/promo-engine/collection/raccolta-punti/download/tessera/public`;
        this.userToken = Utility.getUrlParameter("t");
        this.usedToken = false;
        this.isValidToken = false;
        this.userId = null;
        this.chAccepted = false;
        this.communityRegulation = false
        this.init();
        this.initForm();
    }

    init() {
        $('#overlayer').show();
        GigyaManager.getLoggedUser().then((u) => {
            this.userId = u.UID;
            this.chAccepted = !!u.data['CH_992_MLB_WEB_RACPUNTI24_BIS_CRS_20240115_20240115'];
            this.communityRegulation = !!u.data['CH_HUBMONDOCARTACEO'];


        }, () => {
            window.location.href = '/raccolte-punti/on-boarding';
        });

        window.document.addEventListener("LOGIN_DONE", event => {
            console.log("LOGIN_DONE");
            UserManager.init();
        });

        window.document.addEventListener(Constants.events.USER_LOGGGED_IN, event => {
            $('#overlayer').show();
            let userInfo = event.detail;
            $('#welcome-message').html("Ciao " + userInfo.profile.firstName);

            this.emailLoggedUser = userInfo.profile.email;
            this.collectionPromoApi.areaPersonale(this.userToken).then((resp) => {
                let body = resp.body;
                this.isValidToken = body.isValidToken;

                let authToken = Cookies.get(Constants.AUTH_TOKEN_COOKIE);

                this.endpointTessera += '?t=' + authToken + '&promo_code=MB_raccolta_colazione_2024';


                if (!body.registrato) {
                    window.location.href = '/raccolte-punti/on-boarding';
                }

/*                if(!this.communityRegulation){
                    this.showModalRegulation();
                }*/

                if (body.codici_tessera[0]) {
                    this.userBadgeCode = body.codici_tessera[0];
                    $('#cpc-code').html(this.userBadgeCode);
                }

                if(this.isValidToken && !this.usedToken){
                    $('#wrapper-spedisci-tessera').show();
                    $('#wrapper-download-tessera').hide();
                    $('#wrapper-download-tessera-regalo').hide();

                    $('#spedisci-tessera-button').click((e) => {
                        e.preventDefault();
                        this.sendTessera();
                    })
                }

                if(body.premi_richiesti.length > 0){
                    let allRaccoltePunti =  body.premi_richiesti.map(function(obj){
                        return obj.promo_code;
                    });
                    let sanitizeRaccoltePunti = [...new Set(allRaccoltePunti)];
                    sanitizeRaccoltePunti = sanitizeRaccoltePunti.sort().reverse();

                    let counter = 0;
                    for(let i = 0; i < sanitizeRaccoltePunti.length; i++){
                        let premiFilter = body.premi_richiesti.filter(p => p.promo_code === sanitizeRaccoltePunti[i]);

                        let getYearPromotion = sanitizeRaccoltePunti[i].replace('MB_raccolta_colazione_','');

                        $('#container-prizes').append(`<div class="tab__list-title tab__list-title--per-year">Raccolta punti ${getYearPromotion}</div>`);

                        let itemRow = '';
                        premiFilter.forEach((element, index) => {
                            let punti = element.punti_premio;
                            let data_richiesta = element.data_richiesta_premio;
                            let descrizione_premio = element.descrizione_premio;
                            let protocollo = element.protocollo;
                            let stato_premio = element.stato_premio;
                            let codice_premio = element.codice_premio;

                            let stato_premio_label = '';
                            switch (stato_premio){
                                case 'REGISTRATO':
                                    stato_premio_label = 'Richiesta arrivata';
                                    break;
                                case 'IN LAVORAZIONE':
                                    stato_premio_label = 'In lavorazione';
                                    break;
                                default:
                                    stato_premio_label = stato_premio;
                                    break;
                            }

                            let urlImage = `/fe-web/img/landing-hub-cartacea/premio-${codice_premio}.png`;

                            itemRow += `
                              <div class="tab__list-item">
                                    <div class="tab__list-item-image">
                                        <img src="${urlImage}">
                                    </div>
                                    <div class="tab__list-item-points"><b>${punti}</b><br>punti</div>
                                    <div class="tab__list-item-info">
                                        <span class="tab__list-item-date">${data_richiesta}</span>
                                        <span class="tab__list-item-info-label">Richiesta premio <br>${descrizione_premio}</span>
                                        <span class="tab__list-item-protocol">ID Protocollo: ${protocollo}</span>
                                    </div>
                                    <div class="tab__list-item-status">
                                        <div class="tab__list-item-status-label">${stato_premio_label}</div>
                                        <div class="tab__list-item-more-info">
                                            <a class="button" id="${counter}">Maggiori informazioni</a>
                                        </div>
                                    </div>
                                    <div class="tab__list-shipping-info"
                                        id="more-info-shipping-info-${counter}">
                                        <ul class="tab__list-steps">
                                            <li class="tab__list-step tab__list-step--completed">
                                                Richiesta arrivata (${data_richiesta})
            
                                                <div class="input-tooltip">
                                                    <span class="tooltip-info"><img src="/fe-web/img/landing-hub-cartacea/info-icon.svg"/></span>
                                                    <div class="tooltip-text">
                                                        <div class="tooltip-content">
                                                            I premi verranno spediti il prima possibile e comunque entro il termine massimo di 180 giorni dalla data di registrazione della Tessera richiesta premio completa dei punti validi necessari per il premio e compilata con tutti i tuoi dati.
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="tab__list-step ${ ( stato_premio === 'IN LAVORAZIONE' || stato_premio.indexOf('SPEDITO') > -1 || stato_premio === 'CONSEGNATO' ) ? 'tab__list-step--completed' : ''}">
                                                In lavorazione
                                            </li>
                                            <li class="tab__list-step ${ ( stato_premio.indexOf('SPEDITO') > -1 || stato_premio === 'CONSEGNATO' ? 'tab__list-step--completed' : '' ) }">
                                                Pacco spedito
            
                                                <div class="input-tooltip">
                                                    <span class="tooltip-info"><img src="/fe-web/img/landing-hub-cartacea/info-icon.svg"/></span>
                                                    <div class="tooltip-text">
                                                        <div class="tooltip-content">
                                                            I premi verranno spediti il prima possibile e comunque entro il termine massimo di 180 giorni dalla data di registrazione della Tessera richiesta premio completa dei punti validi necessari per il premio e compilata con tutti i tuoi dati.
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            `;

                            counter++;
                        });

                        $('#container-prizes').append(itemRow);
                    }

                    $('#container-prizes').wrapInner('<div class="tab__list-item-wrapper"></div>');
                } else {
                    let itemRow = `<div class="tab__list-item-wrapper"><div class="tab__list-item tab__list-item--empty">Al momento non hai ancora richiesto nessun premio</div></div>`;
                    $('#container-prizes').append(itemRow);
                }

                $('#overlayer').hide();
            }, (error) => {
                $('#overlayer').hide();
            });


        });

        $(document).ready(() => {
            $('.tab-cta__link--first').trigger("click");
        });

        this.tabEvents();
        this.showModal();
        this.formEvents();
        this.retrieveCard();
    }

    retrieveCard() {
        HttpClient.doCall({
            url: window.API_ENDPOINT + '/api/mulinobianco/1.0/web/stamp-card/',
            type: "GET"
        }, false).then((data) => {
            console.log(data)
            if (data.body.length > 0) {
                this.buildUserCards(data.body);
            }
        }, (error) => {
            console.log(error)

        });
    }

    buildUserCards(cards) {
        $('.tab__user-cards').addClass('recipe');
        (cards.length > 1) ? $('.tab__user-cards-title').html('Le tue tessere punti') : null;
        $('.tab__user-cards-wrapper').addClass('list').html('');
        let userCard = '';

        cards.forEach((card) => {
            let cardTime = new Date(card.insertTime).toLocaleDateString('it-IT');
            let cardPrize = card.premio.replaceAll('_', ' ');
            userCard += `
                <div class="tab__card-box">
                    <div class="tab__card-box-title">Tessera punti #${card.codice}</div>
                    <div class="tab__card-box-info date">Data di caricamento: ${cardTime}</div>
                    <div class="tab__card-box-info cardPrize">Premio desiderato: <b>${cardPrize}</b></div>
                </div>
            `;
        });

        $('.tab__user-cards-wrapper').append(userCard);
        // this.buildExtraInfo();
    }

    buildExtraInfo() {
        let loadUserCardHtml = `
            <div class="tab__card-box--new tab__card-box">
                <div class="tab__card-box-title">Stai collezionando i punti Colazione 2024 per richiedere un altro premio?</div>
                <a class="button tab__card-box-cta" href="/caricamento-tessera">Carica nuova tessera</a>
            </div>
        `;

        let recipeBookHtml = `
            <div class="tab__recipe-book">
                <div class="tab__recipe-book-icon"></div>
                <div class="tab__recipe-book-text">Grazie per aver dialogato con noi: adesso puoi scaricare il Ricettario!</div>
                <a class="button tab__recipe-book-cta" target="_blank" href="/fe-web/pdf/Ricettario-Frullati-MB.pdf">Scarica ricettario</a>
            </div>
        `;

        $('.tab__user-cards-wrapper').append(loadUserCardHtml);
        $('.tab__user-cards').append(recipeBookHtml);
    }

    initForm() {
        let form = document.getElementById("download-tessera");

        let formConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'generic-modal__radio',
            errorClass: 'error',
            successClass: 'has-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'generic-modal__radio',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'error-field'
        };

        this.trimRequired();

        // init form validator
        this.pristine = new Pristine(form, formConfig);
    }

    formEvents() {
        $('#privacy-marketing-on').change((e) => {
            if ($(e.currentTarget).is(":checked")) {
                $('#preferences-marketing').show();
            }

            this.pristine.destroy();
            this.initForm();
        });

        $('#privacy-marketing-off').change((e) => {
            if ($(e.currentTarget).is(":checked")) {
                $('#preferences-marketing').hide();
                $('input[name="privacy_preferences"]').prop('checked', false);
            }

            this.pristine.destroy();
            this.initForm();
        });

        $('#download-tessera').submit((e) => {
            console.log('submit')
            e.preventDefault();
            let valid = this.pristine.validate();

            let radioButtonMarketing = $('input[name="privacy_marketing"]').is(":checked");
            let reg = $('input[name="reg"]').is(":checked");

            if (valid && radioButtonMarketing && reg) {
                let fields = $('#download-tessera').serializeArray();
                let parsingData = {};
                if (fields.length > 0) {
                    for (let i = 0; i < fields.length; i++) {

                        if (fields[i].name === 'privacy_preferences') {
                            parsingData[fields[i].value] = 1;
                        } else {
                            if (!parsingData.hasOwnProperty(i)) {
                                parsingData[fields[i].name] = fields[i].value;
                            }
                        }
                    }

                    const mandatoryFields = [
                        'privacy_marketing_postale',
                        'privacy_marketing_email',
                        'privacy_marketing_cellulare'
                    ]

                    if(!parsingData.hasOwnProperty('privacy_marketing_postale') && !parsingData.hasOwnProperty('privacy_marketing_email') && !parsingData.hasOwnProperty('privacy_marketing_cellulare')){
                        parsingData['privacy_marketing_postale'] = 1;
                        parsingData['privacy_marketing_email'] = 1;
                        parsingData['privacy_marketing_cellulare'] = 1;
                    }else{
                        for (let l = 0; l < mandatoryFields.length; l++) {
                            if (!parsingData.hasOwnProperty(mandatoryFields[l])) {
                                parsingData[mandatoryFields[l]] = 0;
                            }
                        }
                    }

                    const doTesseraDownload = () => {
                        console.log('doTesseraDownload')
                        Utility.trackEvent(this.userId, "CLICK", "CH_992_MLB_WEB_RACPUNTI24_BIS_CRS_20240115_20240115", "DOWNLOAD_TESSERA");
                        let link = document.createElement('a');
                        link.href = this.endpointTessera + '&' + $.param(parsingData);
                        link.target = '_blank';
                        link.click();
                    }

                    if(!this.chAccepted && reg){
                        GigyaManager.addCH("CH_992_MLB_WEB_RACPUNTI24_BIS_CRS_20240115_20240115").then(()=> {
                            console.log('call doTesseraDownload with CH')
                            doTesseraDownload();
                            $('#modal-download-card').hide();
                            this.chAccepted = true;
                        }).catch((err)=>{
                            console.log("error download tessera")
                        });
                    }else{
                        console.log('call doTesseraDownload no CH')
                        doTesseraDownload();
                        $('#modal-download-card').hide();
                    }


                }
            } else {
                let form = document.getElementById("download-tessera");

                if(!radioButtonMarketing){
                    const checkboxPrivacy = form.querySelector('input[name="privacy_marketing"]');
                    if (checkboxPrivacy?.pristine?.errors === undefined) checkboxPrivacy.pristine.errors = [];
                    this.pristine.addError(checkboxPrivacy, "Campo obbligatorio");
                }

                if(!reg){
                    const checkboxReg = form.querySelector('input[name="reg"]');
                    if (checkboxReg?.pristine?.errors === undefined) checkboxReg.pristine.errors = [];
                    this.pristine.addError(checkboxReg, "Campo obbligatorio");
                }

                this.scrollToError();
            }
        });

        $('#download-tessera-regalo-button').click((e) => {
            e.preventDefault();
            Utility.trackEvent(this.userId, "CLICK", "CH_992_MLB_WEB_RACPUNTI24_BIS_CRS_20240115_20240115", "DOWNLOAD_TESSERA_REGALO");
            let link = document.createElement('a');
            link.href = this.endpointTessera + '&gift=1';
            link.target = '_blank';
            link.click();
        })

        $('#acceptRegulation').click((e) => {
            GigyaManager.addCH("CH_HUBMONDOCARTACEO").then(()=>{
                $('#modal-community-tc').hide();
            }).catch((err)=>{
                console.log("error download tessera")
            });
        })
    }

    showModal() {
        $('#download-tessera-button').click((e) => {
            e.preventDefault();
            if(this.chAccepted){
                $('#download-tessera [name="reg"]')[0].checked = true;
                $('#download-tessera .regolamento').hide();
            }
            $('#modal-download-card').show();
        });
    }

    showModalRegulation() {
        $('#modal-community-tc').show()
    }
    tabEvents() {
        $('.tab-cta__link').click((e) => {
            let id = e.target.id;

            $('.tab-cta__link').removeClass('--active');
            $('#' + id).toggleClass('--active');

            if (id === 'tab__edit-form') {
                $('.tab__list-requests').hide();
                $('.tab__edit-form').show();
            } else {
                $('.tab__edit-form').hide();
                $('.tab__list-requests').show();
            }
        });

        $('#container-prizes').on('click', '.tab__list-item-more-info .button',(e) => {
            let id = e.target.id;
            $('#' + id).toggleClass('button--open');
            $('#more-info-shipping-info-' + id).toggleClass('tab__list-shipping-info--open');
        });
    }

    /**
     * Trim all required fields
     */
    trimRequired() {
        Pristine.addValidator('required', (value) => {
            return (value.trim() !== '');
        }, "", 5, false);
    }

    /**
     * Scroll to error
     */
    scrollToError() {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".error").offset().top - 25
        }, 1500);
    }


    sendTessera() {
        $('#overlayer').show();
        this.collectionPromoApi.sendTesseraHome(this.userToken).then(() => {
            this.usedToken = true;

            let html = `<p>Grazie per aver richiesto la tua tessera personalizzata, <br>la spediremo direttamente a casa tua.</p><a href="javascript:void(0);" class="button" onclick="window.location.reload();">OK</a>`;
            $("#send-tessera-result .content-wrapper .content").html(html);
            $('#overlayer').hide();
            document.getElementById('send-tessera-result').dispatchEvent(new CustomEvent('MB_MODAL_OPEN', () => this.open()));
            Utility.trackEvent(this.userId, "CLICK", "CH_992_MLB_WEB_RACPUNTI24_BIS_CRS_20240115_20240115", "SEND_TESSERA_HOME");

        },(resp) => {
            let message = resp.message;
            let html = `<p>Ops, qualcosa è andato storto.<br/>${message}</p><a href="javascript:void(0);" class="button" onclick="window.location.reload();">OK</a>`;
            $("#send-tessera-result .content-wrapper .content").html(html);

            $('#overlayer').hide();
            document.getElementById('send-tessera-result').dispatchEvent(new CustomEvent('MB_MODAL_OPEN', () => this.open()));

        });

    }
}

export default PersonalAreaCartaceaCtrl;