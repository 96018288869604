import Component from '../model/Component';
import $ from "jquery";

export default class GenericModalComponent extends Component {

    constructor(element, parentComponent, params) {
        super(element, parentComponent, params);
    }

    filterTag(type) {
        type = type.replace(/'/g, '');
        $(".tag[data-type='" + type + "']").toggleClass('tag--active');
        if ($(".tag--active").length > 0) {
            $(".pack-item[data-type]").hide();
            $.each($(".tag--active"), function (index, item) {
                $(".pack-item[data-type='" + item.dataset.type + "']").show();
            });
        } else {
            $(".pack-item[data-type]").hide();
            $(".pack-item[data-type]").show();
        }
    }

    open() {
        $('.generic-modal').show().fadeIn(300);
        document.body.classList.add('no-scroll');
    }

    close() {
        console.log('close');
        document.body.classList.remove('no-scroll');
        $('.generic-modal').hide().fadeOut(300);
    }
}


