import Component from '../model/Component';
import $ from "jquery";
import GigyaManager from "../business/GigyaManager";
import HttpClient from "../business/HttpClient";
import {Utils} from "./unMorsoDiFelicita/Utils";

class PiccoloMugnaioBiancoFormComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.key = "CH_1058_MLB_WEB_PICCOLOMUGNAIO_NAV_NAV_20250303_20250303";
        this.urlBack = `/login?urlBack=${window.location.pathname}&regSource=1058`;
        this.$form = this.$element.find('form');
        this.init();
    }

    init() {
        this.checkUser();

        this.$element.find('form .button').on('click',()=> {
            this.submitForm();
        });
    }

    checkUser() {
        $('#overlayer').show();
        GigyaManager.getLoggedUser().then((user) => {
            const ch = user.data[this.key];
            if (typeof ch !== "undefined" && ch != null && ch !== "") {
                window.location.href = "/piccolo-gran-finale";
            }
        }, () => {
            window.location.href = this.urlBack;
        });
    }

    submitForm() {
        if (this.$form.is(':invalid')) {
            return false;
        } else {
            //chiamata api
            $('#overlayer').show();
            this.granFinale().then(() => {
                this.setCH();
                $('.piccoloMugnaioBianco__form-hero-info h2').html('Il tuo Piccolo Gran Finale<br>è stato inviato!');
                $('.piccoloMugnaioBianco__form-hero-info h4').html('Ti abbiamo inviato una email con la tua storia.<br><br>Per ringraziarti, ecco il tuo <b>Ricettario dei Ricordi</b> per ricreare a casa le iconiche ricette di Mulino Bianco.');
                this.$form.remove();
                $('.piccoloMugnaioBianco__form-hero').after(`
                <div class="content-wrapper-cnt">
                    <section class="piccoloMugnaioBianco__form-ty">
                        <img class="piccoloMugnaioBianco__form-ty-image" src="/fe-web/img/piccolo-mugnaio-bianco/ricettario-new.png" alt="immagine ricettario piccolo mugnaio bianco" />
                        <div class="piccoloMugnaioBianco__form-ty-cta-wrapper">
                            <a class="button" target="_blank" href="/fe-web/pdf/Il-Ricettario-dei-ricordi_Stampabile.pdf">Versione stampabile</a>
                            <a class="button" target="_blank" href="/fe-web/pdf/Il-Ricettario-dei-ricordi_Digitale.pdf">Versione digitale</a>
                        </div>
                        <span class="piccoloMugnaioBianco__form-ty-claim">Tutti i partecipanti riceveranno il finale ufficiale in anteprima via email!</span>
                    </section>
                </div>
            `);
                $(window).scrollTop(0);
                $('#overlayer').hide();
            }, (error) => {
                if (error.status == 400) {
                    $('piccoloMugnaioBianco__form-box').append(`<div class="piccoloMugnaioBianco__form-error">Hai già inviato il tuo Gran Finale</div>`);
                } else {
                    $('piccoloMugnaioBianco__form-box').append(`<div class="piccoloMugnaioBianco__form-error">${error}</div>`);
                    console.log(error);
                }
            });
        }
    }

    setCH() {
        GigyaManager.addCH(this.key).then(() => {
            $('#overlayer').hide();
        }).catch((error) => {
            console.log(error);
            $('piccoloMugnaioBianco__form-box').append(`<div class="piccoloMugnaioBianco__form-error">${error}</div>`);
            $('#overlayer').hide();
        });
    }

    granFinale() {
        const data = {story: $('.piccoloMugnaioBianco__form-input').val()};
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'POST',
                url: API_ENDPOINT + `/api/mulinobianco/1.0/web/landing/piccologranfinale/item`,
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(data)
            }).then((data) => resolve(data.body), (error) => {
                $('#overlayer').hide();
                reject(error);
            });
        });
    }
}


export default PiccoloMugnaioBiancoFormComponent;
