let Constants = {
    events: {
        USER_LOGGGED_IN: "MB_USER_LOGGGED_IN",
        USER_LOGGGED_OUT: "LOGOUT_DONE",
        AUTH_FAILED: "MB_AUTH_FAILED",
        RATING_CHANGE: "MB_RATING_CHANGE",
        MODAL_OPEN: "MB_MODAL_OPEN",
        COUPON_FOUND: "MB_MODAL_FOUND",
        REQUIRE_GEOLOCATION: "MB_REQUIRE_GEOLOCATION",
        COUPON_NOTFOUND: "MB_MODAL_NOTFOUND",
        PRIVACY_CONSENSE_CHANGED: "MB_PRIVACY_CONSENSE_CHANGED",
        tracking: {
            SEND: "TRACKING_EVENT_SEND"
        },
        productFinder: {
            SEARCH_RESULTS_UPDATED: "PRODUCT_FINDER_SEARCH_RESULTS_UPDATED"
        },
        promoLocatorInformationWindow: {
            displayDetail: "PROMO_LOCATOR_WINDOW:DISPLAY_DETAIL",
            action: "PROMO_LOCATOR_WINDOW:ACTION"
        },
        promoLocator: {
            highlightStore: "PROMO_LOCATOR:HIGHLIGHT_STORE",
            doSearch: "PROMO_LOCATOR:DO_SEARCH"
        },
        PromoLocatorEventFilterComponent: {
            filterChanged: "PROMO_LOCATOR_EVENT_FILTER:FILTER_CHANGED"
        },
        SHOW_SEARCH_MODAL: "SHOW_SEARCH_MODAL"
    },
    menuComponentPropertyName: 'mb-menu-component',
    headerComponentPropertyName: 'mb-header-component',
    loginComponentPropertyName: 'mb-login-compoment',
    AUTH_TOKEN_COOKIE: 'stkauthmb',
    COOKIE_CONSENT: 'mbcookieaccepted',


};


export default Constants;
