import $ from 'jquery';
import Component from '../model/Component';
import Constants from "../business/Constants";

class MenuComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.$el = $(element);
        this.menuWrapper = $(this.element).parent();
        this.menu = $('.menu')[0];
        this.swiper = null

        this.menuWrapper.on('click', '.sections .element.active .brown-arrow', (e) => {
            e.stopPropagation();
            e.preventDefault();
            this.closeMenuTabs();
        });

        this.menuWrapper.on('click', '.sections .element', (e) => {
            if (!$(e.currentTarget).is(".tapped") && $(window).width() < 768) {

                if ($(e.currentTarget).data('mobileAction') == 'direct') {
                    //Vado direttamente al link su mobile
                } else {
                    //Apro la tab su mobile
                    e.preventDefault();
                    e.stopPropagation();
                    $(e.currentTarget).addClass("tapped");
                    this.changeMenuTab($(e.currentTarget));
                }
            } else {
                // apro la tab su desktop
                this.changeMenuTab($(e.currentTarget));
            }
        });

        this.menuWrapper.find('.sections .element').bind('mouseover', (e) => {
            if ($(window).width() > 767) {
                this.changeMenuTab($(e.currentTarget));
            }
        });

        //TODO - piccone
        $(element).find('.close').click(() => {
            this.hideMenu();
        })

        // on mobile, remove class active on link Prodotti
        if ($(window).width() <= 375) {
            this.menuWrapper.find('.sections .element.active').removeClass('active');
        }
    }

    generateSwiperLayout() {
        this.menuWrapper.find('.recipe-container-wrapper').append(`<div class="swiper-button-prev recipe-button-prev"><img src="/fe-web/img/ricette/keyboard-arrow-right-24-px-brown.svg"></div><div class="swiper-button-next recipe-button-next"> <img src="/fe-web/img/ricette/keyboard-arrow-right-24-px-brown.svg"></div>`)
        this.menuWrapper.find('.recipe-container').addClass("swiper-container")
        this.menuWrapper.find('.recipe-boxes').removeClass('no-slide').addClass("swiper-wrapper do-slide")
        this.menuWrapper.find('.recipe-box').addClass("swiper-slide")
    }

    setRecipeSlider() {
        if (this.menuWrapper.find('.recipe-box').length > 4) {
            this.generateSwiperLayout()

            this.swiper = new Swiper('.recipe-container.swiper-container', {
                slidesPerView: 4,
                spaceBetween: 30,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev'
            });
        }
    }

    closeMenuTabs() {
        this.menuWrapper.find('.sections .element.active').removeClass('active');
        // this.menuWrapper.find('.sections .element:not(.no-submenu, .only-mobile):eq(0)').addClass('active');
        this.menuWrapper.find('.sections .element.tapped').removeClass('tapped');
        this.menuWrapper.find('.body.active').removeClass('active');
        this.menuWrapper.find('.body:not(.only-mobile):eq(0)').addClass('active');
        this.menuWrapper.removeClass('selected-option');
    }

    changeMenuTab($el) {
        if (!$el.hasClass('no-submenu')) {
            if (this.menuWrapper.hasClass('selected-option')) {
                //Se clicco su un elemento che
                this.menuWrapper.removeClass('selected-option');
            } else {
                this.menuWrapper.addClass('selected-option');
            }
            this.menuWrapper.find('.sections .element.active').removeClass('active');
            this.menuWrapper.find('.body.active').removeClass('active');
            this.menuWrapper.find($el.data('selector')).addClass('active');
            $el.addClass('active');
            this.updateUnderscorePosition();
        }

        if ($el.data('selector') === '.ricette' && this.swiper === null) {
            this.setRecipeSlider()
        }
    }

    updateUnderscorePosition() {
        let $el = this.menuWrapper.find('.sections .element.active');
        if ($el.length > 0) {
            let $section = this.menuWrapper.find('.sections');
            let left = ($el.offset().left + ($el.width() / 2)) - (window.innerWidth - ($section.width() / 2));
            this.menuWrapper.find('.underscore').css('left', left);
        }
    }

    showMenu() {
        $(this.parentCtrl.element).addClass('no-scroll');
        $(this.menu).on('click', '.menu__hasChildren', function(e) {
            e.stopImmediatePropagation();

            let thisParent = e.target.parentElement.parentElement;
            let thisTarget = document.querySelector(`[data-parent="${e.target.dataset.menu}"].show`);

            e.target.parentElement.parentElement.parentElement.querySelectorAll('.show').forEach(function(elem){
                if (elem != thisParent && elem != thisTarget)elem.classList.remove('show');
            });
            $(`[data-parent="${e.target.dataset.menu}"]`).toggleClass('show');

            $('.menu__back').html('').html(e.target.innerText);
            MenuComponent.cleanBackMenu();
        });

        $(this.menu).on('click', '.menu__back', function(e) {
            e.stopImmediatePropagation();
            $('.menu .show').last().removeClass('show');

            $('.menu__back').html('').html($(`[data-menu=${$('.menu .show').last().attr('data-parent')}`).text());
            MenuComponent.cleanBackMenu();
        });

        $(this.menu).fadeIn("slow").addClass('open');
    }

    hideMenu() {
        $(this.menu).addClass('closing').removeClass('open').fadeOut(300, function() {
            $('.menu .show').removeClass('show');
            $('.menu').removeClass('closing');
        });
        $('.menu__back').addClass('hidden').html('');
        $(this.parentCtrl.element).removeClass('no-scroll');
    }

    static cleanBackMenu() {
        let lastItemText = $('.menu .show').length > 0;
        (lastItemText) ? $('.menu__back').removeClass('hidden') : $('.menu__back').addClass('hidden');
    }
}


export default MenuComponent;
