import Controller from '../model/Controller';
import {configureStore, createSlice} from '@reduxjs/toolkit'
import CollectionPromoApi from "mbpromo/src/collection-api/CollectionPromoApi";
import LandingStepFormComponent from "../components/unMorsoDiFelicita/LandingStepFormComponent";
import $ from "jquery";
import UserManager from "../business/UserManager";
import Constants from "../business/Constants";
import LoginStepComponent from "../components/unMorsoDiFelicita/LoginStepComponent";
import TopicStepComponent from "../components/unMorsoDiFelicita/TopicStepComponent";
import MessageStepFormComponent from "../components/unMorsoDiFelicita/MessageStepFormComponent";
import SandwichStepFormComponent from "../components/unMorsoDiFelicita/SandwichStepFormComponent";
import VideoPreviewStepFormComponent from "../components/unMorsoDiFelicita/VideoPreviewStepFormComponent";
import InstantWinStepFormComponent from "../components/unMorsoDiFelicita/InstantWinStepFormComponent";


class UnMorsoDiFelicitaFormCtrl extends Controller {
    constructor(element) {
        super(element);
        this.collectionPromoApi = new CollectionPromoApi({promoCode: 'raccolta-punti'});
        this.init();
    }

    init() {
        let initialState = {
            initialStep: 0,
            previousStep: 0,
            currentStep: 0,
            totalStep: 6,
            data: {}
        };

        UserManager.getLoggedUser().then(() => {
            console.log('già loggato');
            this.store.dispatch({...this.store.getState(), step: 2, type: 'unMorsoDiFelicitaForm/goToStep'});
        });

        console.log('prima qui');
        window.document.addEventListener(Constants.events.USER_LOGGGED_IN, (event) => {
            this.store.dispatch({...this.store.getState(), step: 2, type: 'unMorsoDiFelicitaForm/goToStep'});
        });

        window.document.addEventListener('screensetOnAfterScreenLoad', (event) => {
            if (event.detail.currentScreen === 'gigya-progressive-profiling-screen') {
                document.getElementById('login-modal').dispatchEvent(new CustomEvent('MB_MODAL_OPEN', () => this.open()));
            }
        });

        window.document.addEventListener("LOGIN_DONE", event => {
            $('#overlayer').hide();
            $(document.getElementById('login-modal')).hide();
            UserManager.init();
        });


        const formSlice = createSlice({
            name: 'unMorsoDiFelicitaForm',
            initialState: initialState,
            reducers: {
                updateFormdata: (state, params) => {
                    $.extend(state.data, params.payload);
                },
                deleteFormdata: (state, params) => {
                    delete state.data.formData;
                },
                goToNextStep: (state, params) => {
                    state.previousStep = state.currentStep;
                    state.currentStep = state.currentStep + 1;
                    if (params.payload) $.extend(state.data, params.payload);
                    if(state.currentStep < state.totalStep) this.updateProgressBar(state.currentStep);
                    this.updateStepVisibility(state.currentStep);
                },
                back: (state, params) => {
                    state.previousStep = state.currentStep
                    state.currentStep = state.currentStep - 1;
                    if (params && params.payload) $.extend(state.data, params.payload);
                    this.updateProgressBar(state.currentStep);
                    this.updateStepVisibility(state.currentStep);
                },

                goToStep: (state, params) => {
                    state.previousStep = params.step - 1;
                    state.currentStep = params.step;
                    if(state.currentStep < state.totalStep) this.updateProgressBar(state.currentStep);
                    this.updateStepVisibility(state.currentStep);
                },
            }
        });

        this.store = configureStore({reducer: formSlice.reducer});

        this.store.subscribe(() => {
            let state = this.store.getState();
            if (!this.stepComponents[state.currentStep]) {
                this.loadStep(state.currentStep);
            }
        });

        this.stepComponents = [];
        this.loadStep(this.store.getState().initialStep);
        this.initStep(this.store.getState());
    }

    loadStep(index) {
        const wrapper = this.element.querySelector(`[data-step-id="step-${index}"]`);
        switch (index) {
            case 0:
                this.stepComponents[index] = new LandingStepFormComponent(wrapper, this, this.store);
                break;
            case 1:
                this.stepComponents[index] = new LoginStepComponent(wrapper, this, this.store);
                break;
            case 2:
                this.stepComponents[index] = new TopicStepComponent(wrapper, this, this.store);
                break;
            case 3:
                this.stepComponents[index] = new MessageStepFormComponent(wrapper, this, this.store);
                break;
            case 4:
                this.stepComponents[index] = new SandwichStepFormComponent(wrapper, this, this.store);
                break;
            case 5:
                this.stepComponents[index] = new VideoPreviewStepFormComponent(wrapper, this, this.store);
                break;
            case 6:
                this.stepComponents[index] = new InstantWinStepFormComponent(wrapper, this, this.store);
                break;
            default:
        }
    }


    initStep(state) {
        // this.setProgressLabel();
        const context = this;
        $('.step-container').each(function (index, item) {
            const $step = $(item);
            const currentStep = parseInt($step.data('stepid'));

            if (Number.isNaN(currentStep)) {
                console.error('Invalid step id');
                return;
            }
            if (currentStep < state.initialStep) {
                $step.css("display", "none")
                $step.addClass('move-out')
                setTimeout(() => {
                    $step.removeAttr('style')
                }, 2000)

            } else if (currentStep === state.initialStep) {
                $step.addClass('active-step');
            } else {
                $step.addClass('background-step');
                context.setStackElement($step, currentStep)
            }
        });
        this.updateProgressBar(state.currentStep);
    }

    updateProgressBar(currentStep) {
        document.querySelector('.un-morso-di-felicita-form__counter b').innerHTML = currentStep + 1;
        const selectedDot = document.querySelectorAll('.un-morso-di-felicita-form__dot')[currentStep];
        document.querySelector('.un-morso-di-felicita-form__dot.selected').classList.remove('selected');
        selectedDot.classList.add('selected');
    }

    updateStepVisibility(currentStep) {
        this.element.querySelector(`div[data-step-id].visible`).classList.remove('visible');
        this.element.querySelector(`div[data-step-id="step-${currentStep}"]`).classList.add('visible');
        window.scrollTo({top: 100, behavior: 'smooth'});
    }
}

export default UnMorsoDiFelicitaFormCtrl;
