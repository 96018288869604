import Component from '../../model/Component';
import {StepHandler} from "./StepHandler";
import Templates from "./templates";
import $ from "jquery";
import GoogleAnalytics from "../../business/GoogleAnalytics";

class InputStep4Component extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;

        $(this.element).html(this.createHtml(store.getState().userChoices.step3));

        this.stepHandler = StepHandler($(this.element))
        this.stepHandler.handleInput()

        this.store.subscribe(() => {
            let state = this.store.getState();
            if (state.currentStep === 4) {
                $(this.element).html(this.createHtml(store.getState().userChoices.step3));
                this.stepHandler.reset()
                this.stepHandler.handleInput()
            }
        });
    }

    goToNextStep(id) {
        if (this.stepHandler.canGoNext()) {

            let submissionStatus = {isLoading: true, error: false, response: false};
            this.stepHandler.loader(submissionStatus)
            const {submit, submittedData} = this.sendData()
            submit.then((response) => {

                submissionStatus = {isLoading: false, error: false, response: response}
                this.stepHandler.loader(submissionStatus)
                //GO NEXT ON STORE ACTION
                /*UPDATE STORE*/
                const data = this.stepHandler.getFormData()
                let userChoice = {}
                this.store.dispatch({
                    ...this.store.getState(),
                    type: 'contactForm/goToNextStep',
                    payload: {step5: userChoice, formData: data}
                });

                const obj = {
                    'data-form-id': 'welcome-form',
                    'data-form-name': 'contact form'
                }

                GoogleAnalytics.send('form_submitted', obj);
            }).catch((error) => {
                submissionStatus = {isLoading: false, error: error, response: {}}
                this.stepHandler.loader(submissionStatus)
            })

        }
    }

    sendData() {
        const endPoint = `${API_ENDPOINT}/api/mulinobianco/2.0/web/customercare/open-ticket`
        const fileList = Object.assign({}, this.store.getState().userChoices.formData.fileList, this.stepHandler.getFormData().fileList)
        const rawDataMerge = Object.assign({}, this.store.getState().userChoices.formData, this.stepHandler.getFormData())

        rawDataMerge.fileList = fileList

        const data = this.stepHandler.getProcessedData(rawDataMerge, true)

        const submitAction = () => {
            return $.ajax({
                method: "POST",
                url: endPoint,
                data: data,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
            })
        }

        return {
            submit: submitAction(),
            submittedData: data
        }
    }

    back() {
        this.store.dispatch({...this.store.getState(), type: 'contactForm/back'});
    }

    createHtml(config) {
        let templateConfiguration = Object.assign({}, config, {prefillData: this.store.getState().prefillData})
        const templates = Templates()
        return templates.fullContact(templateConfiguration)
    }

}


export default InputStep4Component;
