import Component from '../model/Component';
import HttpClient from "../business/HttpClient";
import UserManager from "../business/UserManager";

class PromoCustomizationComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.id = $(element).data("id");

        UserManager.getLoggedUser().then((d) => {
            this.getPromoCustomization();
        }, () => {
            //NOOP
        })
    }


    getPromoCustomization() {
        HttpClient.doCall({
            url: API_ENDPOINT + "/api/mulinobianco/1.0/web/promo/" + this.id + "/customization",
            method: "GET",
            dataType: "json"
        }, false).then((data) => {
            if (data.body != null) {
                if (data.body.title) {
                    $(this.element).find('.text-container .mb-title').html(data.body.title);
                }

                if (data.body.description) {
                    $(this.element).find('.text-container .text').html(data.body.description);
                }

                if (data.body.link) {
                    $(this.element).find('.text-container a').attr("href", data.body.link.url);
                    $(this.element).find('.text-container a').html(data.body.link.label);

                }
            }
        }, (err) => {
            console.info(err);
        });
    }

}


export default PromoCustomizationComponent;
