/**
 *
 */

import Controller from '../model/Controller';
import Tracker from "../business/Tracker";

class SenzaZuccheriCtrl extends Controller {
    constructor(element) {
        super(element);

        this.init();
    }

    init() {

        Tracker.send("EVENT", {interactionType: "ZCATEGORY_CLICK", productCode: '9991000083' });
    }
}

export default SenzaZuccheriCtrl;
