import HttpClient from "../../business/HttpClient";
import ContactStepFormComponent from "./ContactStepFormComponent";

class ContactFormComponent {
    constructor(element) {
        this.element = element;
        this.selectionWrapper = this.element.querySelector('.form-selection-wrapper');
        this.loader = this.element.querySelector('.loader');
        this.externalUrl = 'https://www.mulinobianco.com';
        this.data = {};
        this.activeSteps = [];
        this.currentStep = 1;
        this.init();
    }

    initStep() {
        const stepId = this.activeSteps[this.currentStep - 1];
        this.getStepData(stepId);
    }

    getStepData(stepId, label) {
        switch (stepId) {
            case 'country':
                this.getCountries();
                break;
            case 'categories':
                this.getProductStep('categories');
                break;
            case 'products':
                this.getProductStep('products');
                break;
            case 'packagings':
                this.getProductStep('packagings', true);
                break;
            default:
                this.overflowAnimation();
                break;
        }

        if (label) this.appendSelection(label);
    }


    getProductStep(stepId, isNotAvailableOption) {
        this.loader.classList.remove('hidden');
        let endpoints = `${API_ENDPOINT}/api/mulinobianco/3.0/web/customercare/form-config/product/${stepId}?country=${this.data.country}&idStep1=${this.data.step1}`;
        if (stepId === 'packagings') endpoints += `&product=${this.data.productId}`;
        if (stepId === 'products') endpoints += `&category=${this.data.category}`;
        HttpClient.doCall({
            url: endpoints,
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            const values = isNotAvailableOption ? this.addNotAvailableOption(data.body.values) : data.body.values;
            this.appendProductStep(values, stepId);
            this.loader.classList.add('hidden');
            this.overflowAnimation();
        }, () => {
            this.loader.classList.add('hidden');
        });
    }


    addNotAvailableOption(values) {
        const option = {label: 'Peso non disponibile', value: values[0].value};
        values.forEach((el) => {
            if (el.value < option.value) option.value = el.value;
        });

        return [...values, option];
    }

    getCountries() {
        this.loader.classList.remove('hidden');
        HttpClient.doCall({
            url: `${API_ENDPOINT}/api/mulinobianco/3.0/web/customercare/countries`,
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.appendCountries(data.body);
            this.formStep.appendCountries(data.body);
            this.loader.classList.add('hidden');
            this.overflowAnimation();
        }, () => {
            this.loader.classList.add('hidden');
        });
    }

    appendCountries(countries) {
        let html = '';
        const input = this.element.querySelector('.form-step #countrySelect');
        countries.forEach((country) => html += `<option value="${country.value}">${country.label}</option>`);
        input.insertAdjacentHTML('beforeend', html);
        input.value = 'IT';
        input.dispatchEvent(new Event('change'));
    }

    appendSelection(value) {
        this.element.querySelector('.form-selection').classList.remove('hidden');
        const stepId = this.activeSteps[this.currentStep - 2];
        const stepButton = this.selectionWrapper.querySelector(`#selection-${stepId}`);
        if (stepButton) stepButton.innerHTML = value;
        else {
            this.selectionWrapper.insertAdjacentHTML('beforeend', `<button class="button special" type="button" id="selection-${stepId}">${value}</button>`);
            this.selectionWrapper.querySelector('button.special:last-child').addEventListener('click', (e) => this.removeSelection(e.target));
        }
    }

    removeSelection(button) {
        const clickedStep = button.id.replace('selection-', '');
        const clickedStepIndex = this.activeSteps.findIndex((el) => el === clickedStep) + 1;
        for (let i = clickedStepIndex; i <= this.currentStep; i++) {
            const stepId = this.activeSteps[i - 1];
            this.selectionWrapper.querySelector(`#selection-${stepId}`)?.remove();
            this.element.querySelector(`.form-stepper > span:nth-child(${i})`).classList.remove('active');
        }

        this.currentStep = clickedStepIndex;
        if (!this.selectionWrapper.childElementCount) this.element.querySelector('.form-selection').classList.add('hidden');
        this.overflowAnimation();
    }


    appendProductStep(list = [], stepId) {
        let html = '';
        list.forEach((option) => html +=
            `<button type="button" id="${option.value}" data-type="${option.data || ''}" class="contact-form-option">${option.label}</button>`);
        this.element.querySelector(`#${stepId} .step-wrapper`).innerHTML = html;

        if (stepId === 'packagings' && (list.length <= 2 || this.data.triageCategory === 'S')) this.selectPackaging();
        this.addProductStepListener(stepId);
    }

    selectPackaging() {
        const button = this.element.querySelector(`#packagings .step-wrapper button`);
        this.data.productPackagingId = button.id;
        this.goToNextStep();
    }

    addProductStepListener(stepId) {
        const currentStepButtonList = this.element.querySelectorAll(`#${stepId} .step-wrapper button`);
        currentStepButtonList.forEach((button) => {
            button.addEventListener('click', (event) => {
                if (stepId === 'categories') {
                    this.data.category = event.target.id;

                    if (event.target.dataset.type === 'NEW_PRODUCT' || event.target.dataset.type === 'GENERIC_PRODUCT') {
                        this.goToNextStep(button.innerText);
                        this.goToNextStep();
                        return;
                    }
                }

                if (stepId === 'products') this.data.productId = event.target.id;
                if (stepId === 'packagings') this.data.productPackagingId = event.target.id;
                this.goToNextStep(button.innerText);
            });
        });
    }

    goToNextStep(label) {
        this.currentStep++;
        this.element.querySelector(`.form-stepper > span:nth-child(${this.currentStep - 1})`).classList.add('active');
        const stepId = this.activeSteps[this.currentStep - 1];
        this.getStepData(stepId, label);
    }

    overflowAnimation() {
        const overflowWrapper = this.element.querySelector('.form-overflow-wrapper');
        overflowWrapper.setAttribute('step', this.currentStep);
        const height = this.element.querySelector(`#${this.activeSteps[this.currentStep - 1]}`)?.clientHeight;
        overflowWrapper.style.maxHeight = (height) ? `${height}px` : 'auto';

        // caso form
        if (height && this.currentStep === this.activeSteps.length) overflowWrapper.style.maxHeight = `${height + 180}px`;
    }

    getActiveStep() {
        switch (this.data.type) {
            case 'PRODUCTS':
                return ['country', 'categories', 'products', 'packagings', 'form'];
            case 'PROMO':
                return ['form'];
            default:
                return ['country', 'form'];
        }
    }

    goToPrevStep() {
        this.currentStep--;
        this.element.querySelector(`.form-stepper > span:nth-child(${this.currentStep})`)?.classList.remove('active');
        const stepId = this.activeSteps[this.currentStep];
        if (stepId) {
            this.selectionWrapper.querySelector(`#selection-${stepId}`)?.remove();
            const lastId = this.selectionWrapper.querySelector(`.button:last-child`)?.id;
            const prevStep = lastId?.replace('selection-', '');
            const prevStepIndex = this.activeSteps.findIndex((el) => el === prevStep);
            this.currentStep = prevStepIndex ? prevStepIndex + 1 : this.currentStep;
        }
        this.overflowAnimation();
    }

    addBackListener() {
        const buttons = this.element.querySelectorAll('.form-container .step-back');
        buttons.forEach((button) => {
            button.addEventListener('click', (e) => {
                const stepId = e.target.parentElement.id;
                const stepIndex = this.activeSteps.findIndex((el) => el === stepId);
                if (stepIndex === 0) {
                    this.selectionWrapper.querySelector(`#selection-${stepId}`)?.remove();
                    this.hideForm();
                } else this.goToPrevStep();
            });
        });
    }

    addCountryListener() {
        const button = this.element.querySelector('#country .step-footer button');
        button.setAttribute('disabled', 'disabled');
        this.element.querySelector('#countrySelect').addEventListener('change', (event) => {
            const value = event.target.value;
            this.data.country = value;
            value ? button.removeAttribute('disabled') : button.setAttribute('disabled', 'disabled');
        });

        button.addEventListener('click', () => {
            const countryLabel = this.element.querySelector('#countrySelect').selectedOptions[0].innerHTML;
            (this.data.country === 'IT') ? this.goToNextStep(countryLabel) : window.location.href = this.externalUrl;
        });
    }


    resetForm() {
        this.currentStep = 1;
        const stepWrapper = this.element.querySelector('.form-stepper');
        stepWrapper.innerHTML = '';
        stepWrapper.classList.add('hidden');
        this.element.querySelector('.faq-info').classList.remove('hidden');
        this.element.querySelectorAll('.form-step').forEach((el) => {
            el.classList.add('hidden');
        });
    }


    initForm() {
        let html = '';
        this.element.querySelector('.faq-info').classList.add('hidden');
        this.activeSteps = this.getActiveStep();
        const stepWrapper = this.element.querySelector('.form-stepper');
        this.activeSteps.forEach((id, index) => {
            html += `<span class="step-dot" data-id="${id}">${index + 1}</span>`;
            this.element.querySelector(`#${id}`).classList.remove('hidden');
        });

        if (this.activeSteps.length > 1) {
            stepWrapper.innerHTML = html;
            stepWrapper.classList.remove('hidden');
        }
        this.initStep();
    }


    initStepForm() {
        this.formStep.initContactStepForm(this.data);
        if (this.activeSteps[0] !== 'promo') this.formStep.getTypes();
    }


    initContactForm(dataToSave) {
        this.data = dataToSave;
        this.initForm();
        this.initStepForm();
        this.showForm();
        this.overflowAnimation();
    }

    showForm() {
        this.element.querySelector('.faq-triage-component').classList.add('hidden');
        this.element.querySelector('.form-container').classList.remove('hidden');
    }

    hideForm() {
        this.element.querySelector('.faq-triage-component').classList.remove('hidden');
        this.element.querySelector('.form-container').classList.add('hidden');
        this.resetForm();
    }


    init() {
        this.addBackListener();
        this.addCountryListener();
        this.formStep = new ContactStepFormComponent(this.data);
    }
}

export default ContactFormComponent;
