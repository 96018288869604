import $ from 'jquery';
import Component from '../model/Component';
import HttpClient from "../business/HttpClient";
import Utility from "../business/Utility";
import UserManager from '../business/UserManager';
import Tracker from "../business/Tracker";
import GigyaManager from "../business/GigyaManager";



class LiteRegistrationComponent extends Component {

	constructor(element, parentCtrl) {
		super(element, parentCtrl);

        this.init();
	}

	init() {
		console.log(this.element.dataset);
        this.dataset = this.buildDataset(this.element.dataset);
        this.serviceError = $('#service-error');
        this.formWrapper = $(this.element).find('.form');
        
        this.sendButton = $(this.element).find("#sendButton");

        this.sendButton.on("click", () => {
            this.submit();
        });
        
        this.nome = $(this.element).find('input[name="nome"]');
        this.email = $(this.element).find('input[name="email"]');
        
//        window.document.getElementById(this.dataset.thankyouTarget).dispatchEvent(new CustomEvent("MB_MODAL_OPEN"));
	}
	
	buildDataset(dataset){
		const d = {};
		
		d.ch = dataset.ch ? dataset.ch : null;
		d.nl = dataset.nl ? dataset.nl : null;
		d.thankyouBehaviour = dataset.thankyoubehaviour ? dataset.thankyoubehaviour : null;
		d.thankyouTarget = dataset.thankyoutarget ? dataset.thankyoutarget : null;
		
		return d;
		
	}
	
	validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
	
	submit(){
		console.log('SUBMITTING...');
		var valid = true;
		
		var nome = $(this.nome).val();
        var email = $(this.email).val();
        console.log(`Nome: ${nome} Email: ${email}`);
        
        if (nome == "") {
            valid = false;
            $(this.nome).addClass("error");
        } else {
        		$(this.nome).removeClass("error");
//            this.submitObj.profile.firstName = nome;
        }
        
        if (email == "") {
            valid = false;
            $(this.element).find('input[name="email"] ~ .hint.error').text("Il campo è obbligatorio");
            $(this.email).addClass("error");
        } else {
        		$(this.email).removeClass("error");
            if (!this.validateEmail(email)) {
                valid = false;
                $(this.element).find('input[name="email"] ~ .hint.error').html("Email non valida");
                $(this.email).addClass("error");
            } else {
            		$(this.email).removeClass("error");
//                this.submitObj.profile.email = email;
            }
        }
        
        var ch = this.dataset.ch;
        if (valid) {
            this.serviceError.hide();
            this.formWrapper.addClass('loading');
            
            
            var submitObj = {};
            submitObj['profile'] = {
                    "email": email,
                    "firstName": nome
                };
            
            submitObj['data'] = {};
            submitObj['data'][this.dataset.ch] = Utility.formatDate();
            submitObj['subscriptions'] = {};
            submitObj['subscriptions'][this.dataset.nl] = {"email": { "isSubscribed": true } }
            
            
            GigyaManager.liteRegistration(submitObj).then((data) => {
            		if(data['body']=='OK'){
            			if(this.dataset.thankyouBehaviour=='modal'){
            				window.document.getElementById(this.dataset.thankyouTarget).dispatchEvent(new CustomEvent("MB_MODAL_OPEN"));
            			}
            			else{
            				document.location.href = this.dataset.thankyouTarget;
            			}
            		}
            }, () => {
                this.serviceError.show();
                this.serviceError.addClass('error');
                this.formWrapper.removeClass('loading');
            });
        }
	}
}	

export default LiteRegistrationComponent;
