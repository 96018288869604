class GoogleAnalytics {
    constructor(params = {}) {

    }

    send(eventType, payload) {
        try {
            let obj = {
                'event': eventType
            };
            obj = {...obj, ...payload}

            window.dataLayer.push(obj);
        } catch (error) {
            console.log(error)
        }

    }
}
export default new GoogleAnalytics();
