import Controller from '../model/Controller';


class ArmonieGlutenFreeInsightsCtrl extends Controller {
    constructor(element) {
        super(element);
        this.element = element;

        this.init();
    }

    init() {}

}

export default ArmonieGlutenFreeInsightsCtrl;