import Component from '../model/Component';
import Utility from '../business/Utility';
import TemplatingUtility from '../business/TemplatingUtility';
import Constants from '../business/Constants';
import HttpClient from "../business/HttpClient";
import Swiper from 'swiper';
import $ from 'jquery';
import recipeBlockTemplate from "../template/recipe-block";

class SearchComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.$input = this.$element.find('.search-input');
        this.init();
    }

    init() {
        this.NMCV_DOMAIN = 'https://www.nelmulinochevorrei.it';
        this.textualItemsPerTimes = 5;
        this.boxOptions = {
            productcategory: {
                boxSelector: '.search-category-box',
                counterId: 'productcategory-counter',
                slideTemplate: (c) => {
                    return `
                        <div class="category swiper-slide">
                            <a href="${c.seoUrl}">
                            	<div style="background-image: url('${c.thumbnail}');" class="image"></div>
                            	</a>
                            <a class="name" href="${c.seoUrl}">${c.name}</a>
                        </div>
                    `;
                }
            },
            product: {
                boxSelector: '.search-product-box',
                counterId: 'product-counter',
                customSwiperOptions: () => {
                    return {
                        slidesPerView: $(window).width() > 767 ? 4 : 'auto',
                        slidesPerGroup: $(window).width() > 767 ? 4 : 1
                    }
                },
                slideTemplate: (p) => {
                    return `
                        <div class="product swiper-slide" data-product-id="${p.id}">
                            <a  href="${p.seoUrl}?yxpt-from=search">
                            <div style="background-image: url('${p.image}');" class="image"></div>
                            </a>
                            ${TemplatingUtility.printCoupon(p.coupon)}
                            <a class="name" href="${p.seoUrl}?yxpt-from=search">${p.name}</a>
                        </div>
                    `;
                }
            },
            promo: {
                entityGroup: 'promoEvent',
                slideTemplate: (p) => {
                    return (!p.backgroundImage) ? '' : `
                        <div class="promo swiper-slide">
                            <div style="background-image: url('${p.backgroundImage}');" class="image"></div>
                            <h3 class="title">${TemplatingUtility.defaultOrCustom(p.defaultMainTitle, p.customMailTitle)}</h3>
                            <span class="text">${TemplatingUtility.defaultOrCustom(p.defaultDescription, p.customDescription)}</span>
                            ${TemplatingUtility.linkHtml(p.link)}
                        </div>
                    `;
                }
            },
            instoreevent: {
                entityGroup: 'promoEvent',
                slideTemplate: (p) => {
                    return (!p.thumbnailBackgroundImage) ? '' : `
                        <div class="promo swiper-slide">
                            <div style="background-image: url('${p.thumbnailBackgroundImage}');" class="image">
                                <img class="inner-image" src="${p.thumbnailImage}">
                            </div>
                            <h3 class="title">${p.title}</h3>
                            <a class="link" href="${p.seoUrl}">Scopri</a>
                        </div>
                    `;
                }
            },
            ingredient: {
                boxSelector: '.search-ingredient-box',
                counterId: 'ingredient-counter',
                isTextual: true,
                parseJson: (res) => {
                    return {
                        title: res.name,
                        text: res.description,
                        url: this.ingredientBaseUrl + "?ingredientId=" + res.id
                    };
                }
            },
            page: {
                boxSelector: '.search-page-box',
                counterId: 'page-counter',
                isTextual: true,
                parseJson: (res) => {
                    return {
                        title: res.title,
                        text: res.description,
                        url: res.seoUrl
                    };
                }
            },
            promoEvent: {
                boxSelector: '.search-promo-box',
                counterId: 'promo-event-counter',
            },
            idea: {
                boxSelector: '.search-idea-box',
                counterId: 'idea-counter',
                customSwiperOptions: () => {
                    return {
                        slidesPerView: $(window).width() > 767 ? 2 : 1,
                        slidesPerGroup: $(window).width() > 767 ? 2 : 1
                    }
                },
                slideTemplate: (i) => {
                    return `
                        <div class="swiper-slide">
                            <div class="card nmcv">
                                <div class="face front">
                                    <div class="left">
                                        <div class="profile">
                                                    <img src="${i.avatar ? i.avatar : '/fe-web/img/nmcv/user_placeholder.jpg'}" class="avatar"/>
                                            <p>Un'idea di...
                                                <br/>
                                                <a href="${this.NMCV_DOMAIN}/utente/${i.idUser}/index.html">${i.nickname}</a>
                                            </p>
                                        </div>
                                        <span class="info">
                                            <a href="javascript:void(0);"
                                               style="cursor: default;">
                                                ${i.categoriaParent}
                                            </a>  <span> - ${TemplatingUtility.formatDate(i.date)}</span>
                                        </span>
                                        <a href="${this.NMCV_DOMAIN}/${i.urlSeo}">
                                            <h2>${i.title.substring(0, 60)}</h2>
                                        </a>
                                    </div>
                                    <div class="right hidden-xs">
                                        <p>${i.text.substring(0, 140)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    `;
                }
            },
            editorialrecipe: {
                boxSelector: '.editorial-recipe-box',
                counterId: 'editorial-recipe-counter',
                customSwiperOptions: () => {
                    return {
                        slidesPerView: $(window).width() > 767 ? 2 : 1,
                        slidesPerGroup: $(window).width() > 767 ? 2 : 1
                    }
                },
                slideTemplate: (i) => {
                    return ' <div class="swiper-slide">' + recipeBlockTemplate(i) + '</div>';
                }
            }
        };
        this.swipers = {};
        this.lastSearchKey = null;
        this.searchInProgress = false;
        this.debouncedSearch = Utility.debounce(this.doSearch, 500);
        this.currentResolution = $(window).width() > 767 ? 'D' : 'M';
        this.ingredientBaseUrl = this.$element.data('ingredient-base-url');

        window.document.addEventListener(Constants.events.SHOW_SEARCH_MODAL, () => {
            this.showSearch();
        });

        this.$input.on('keyup change', (e) => {
            //Watch input changes
            let key = this.$input.val();

            if (key.length == 0) {
                this.clearInput();
            } else {
                this.$element.find('.clear-input').addClass('visible');

                if (key.length >= 3) {
                    this.debouncedSearch(key);
                }
            }
        }).keydown((e) => {
            //Watch input Enter press
            if (e.originalEvent.which == 13 || e.originalEvent.keyCode == 13) {
                this.doSearch(this.$input.val());
            }
        });

        this.$element.on('click', '.textual-box .more', (e) => {
            let $el = $(e.currentTarget).parent();
            $el.find('.hidden:lt(' + this.textualItemsPerTimes + ')').removeClass('hidden');
            if ($el.find('.hidden').length == 0) {
                $el.removeClass('showMore');
            }
        });

        $(window).resize(() => {
            this.onResize();
        });
    }

    onResize() {
        let newResolution = $(window).width() > 767 ? 'D' : 'M';

        if (newResolution != this.currentResolution) {
            this.currentResolution = newResolution;

            for (let key in this.swipers) {
                this.destroySwiper(key);
            }
        }
    }

    showSearch() {
        $('body').addClass("no-scroll");
        this.$element.show();
    }

    hideSearch() {
        $('body').removeClass("no-scroll");
        this.$element.hide();
        this.clearInput();
    }

    triggerValue(key) {
        key = key.replace(/'/g, '');
        this.$element.find('.clear-input').addClass('visible');
        this.debouncedSearch(key);
        this.$input.val(key);
    }

    doSearch(key) {
        if (!this.searchInProgress && !(this.lastSearchKey === key)) {
            this.searchInProgress = true;
            this.lastSearchKey = key;
            this.$element.addClass('searching');

            HttpClient.doCall({
                url: API_ENDPOINT + '/api/mulinobianco/1.0/web/searchengine/search?q=' + key,
                type: "GET",
                dataType: "json",
                contentType: "application/json"
            }, false).then((data) => {
                this.$element.find('.search-pages-box').html('');
                this.$element.addClass('active-search');
                this.$element.removeClass('searching');
                this.printResults(data.body);
                this.searchInProgress = false;
            }, () => {
                this.$element.removeClass('searching');
                this.searchInProgress = false;
                this.clearInput();
            });
        }
    }

    //Metodo che riporta allo stato iniziale la ricerca
    reInitSearchView() {
        //Nascondo tutti gli swiper
        this.$element.find('.on-tab .swiper-box').hide();

        //Svuoto l'elenco delle pagine
        this.$element.find('.textual-box').removeClass('showMore').hide();
        this.$element.find('.textual-box .textual-item').remove();
        this.clearCounters();

        for (let t in this.swipers) {
            this.destroySwiper(t, true);
        }
    }

    clearInput() {
        this.$element.find('.clear-input').removeClass('visible');
        this.$element.removeClass('active-search');
        this.debouncedSearch('');
        this.$input.val('');
        this.reInitSearchView();
    }

    getTextualItem(title, text, url, hidden) {
        let html = `<div class="textual-item ${hidden ? 'hidden' : ''}">`;

        html += `<a class="title ${!url ? '' : 'clickable" href="' + url}">${title}</a>`;
        if (!!text) {
            html += '<span class="text">' + text + '</span>';
        }

        html += '</div>';

        return html;
    }

    printResults(body) {
        this.reInitSearchView();

        for (let i in body.results) {
            let res = body.results[i];

            if (!!this.boxOptions[res.entityName]) {
                if (!this.boxOptions[res.entityName].isTextual) {
                    this.printSlides(res.items, res.entityName, this.boxOptions[res.entityName].entityGroup);
                    this.insertCounter(res);
                } else {
                    this.printText(res.items, res.entityName);
                    this.insertCounter(res);
                }
            }
        }

        //TODO - se devo stampare il tips al configuratore lo mostro e lo stampo

        this.parentCtrl.loadComponents();
    }

    clearCounters() {
        this.$element.find(".on-tab .summary .voice[data-entity-counter]").remove();
    }

    insertCounter(res) {
        if (res.count > 0 && !!res.entityName) {
            let opt = !this.boxOptions[res.entityName].entityGroup ? this.boxOptions[res.entityName] : this.boxOptions[this.boxOptions[res.entityName].entityGroup];
            var $previousCount = this.$element.find(`.on-tab .summary [data-counter-id="${opt.counterId}"]`);
            if ($previousCount.length == 0) {
                this.$element.find('.on-tab .summary').append(`
                    <li class="voice" data-entity-counter mb-component-click="scrollToSelector('${opt.boxSelector}')" data-counter-id="${opt.counterId}" data-count="${res.count}">${res.count} ${res.label}</li>
                `);
            } else {
                var totalCount = $previousCount.data('count') + res.count;
                $previousCount.html(totalCount + " " + res.label);
                $previousCount.data('count', totalCount);
            }
        }
    }

    hideSwiper(boxType) {
        this.$element.find(this.boxOptions[boxType].boxSelector).hide();
    }

    printText(results, entityName) {
        let boxOption = this.boxOptions[entityName];
        let $container = this.$element.find(boxOption.boxSelector);

        if (results.length > this.textualItemsPerTimes) {
            $container.addClass('showMore');
        }

        for (let i in results) {
            let info = boxOption.parseJson(results[i]);
            $container.append(this.getTextualItem(info.title, info.text, info.url, i >= this.textualItemsPerTimes));
        }
        $container.show();
    }

    printSlides(results, entityName, entityGroup) {
        let boxType = entityName;
        let isGroup = false;

        if (!!entityGroup) {
            boxType = entityGroup;
            isGroup = true;
        }


        let contents = '';

        if (results.length > 0) {
            for (let i in results) {
                if (!!results[i]) {
                    let c = results[i];
                    contents += this.boxOptions[entityName].slideTemplate(c);
                }
            }

            if (contents.length > 0) {
                this.$element.find(this.boxOptions[boxType].boxSelector).show();
                if (isGroup && !!this.swipers[boxType]) {
                    //Se sto stampando gli elementi di un gruppo, ed ho già stampato qualcosa per questo gruppo, appendo allo swiper esistente invece di crearne uno nuovo.
                    this.$element.find(this.boxOptions[boxType].boxSelector + ' .swiper-container .swiper-wrapper').append(contents);
                    this.destroySwiper(boxType);
                } else {
                    let html = '<div class="swiper-wrapper">' + contents + '</div>';
                    this.$element.find(this.boxOptions[boxType].boxSelector + ' .swiper-container').html(html);
                    //Mostro il box che sto per inizializzare in quanto potrebbe essere stato nascosto in precedenza nel caso di una ricerca senza risultati di quel tipo.
                    this.initSwiper(boxType);
                }
            }
        }
    }

    destroySwiper(boxType, avoidReinit) {
        if (!!this.swipers[boxType]) {
            this.swipers[boxType].destroy(true, true);
            delete this.swipers[boxType];
            if (!avoidReinit) {
                this.initSwiper(boxType);
            }
        }
    }

    initSwiper(boxType) {
        if (!!this.swipers[boxType]) {
            this.swipers[boxType].destroy(true, true);
            this.swipers[boxType] = null;
        }

        let bSel = this.boxOptions[boxType].boxSelector;
        let options = {
            slidesPerView: $(window).width() > 767 ? 3 : 'auto',
            slidesPerGroup: $(window).width() > 767 ? 3 : 1,
            nextButton: bSel + ' .arrows .right',
            prevButton: bSel + ' .arrows .left',
            spaceBetween: $(window).width() > 767 ? 30 : 15
        };

        if (!!this.boxOptions[boxType].customSwiperOptions) {
            options = $.extend(options, this.boxOptions[boxType].customSwiperOptions());
        }

        if (!this.boxOptions[boxType].avoidCentering) {
            let centeredSlides = options.slidesPerGroup > this.$element.find(bSel + ' .swiper-container .swiper-slide').length;
            this.$element.find(bSel + ' .swiper-container').toggleClass('centeredSlides', centeredSlides);
        }

        this.swipers[boxType] = new Swiper(this.$element.find(bSel + ' .swiper-container'), options);
    }

    scrollToSelector(selector) {
        //TODO - togliamo questo piccone
        if (!!selector && selector.indexOf('\'' >= 0)) {
            selector = selector.replace(/[']/g, '');

            let top = this.$element.find(selector).position().top + 250;
            this.$element.animate({
                scrollTop: top
            }, 800);
        }
    }
}

export default SearchComponent;