import Component from '../../model/Component';
import GigyaManager from "../../business/GigyaManager";

class SecondStepComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;

        this.loadScreenSets();
    }

    goToNextStep() {
        console.log(this.store.getState());

        this.store.dispatch({...this.store.getState(), type: 'onBoardingForm/goToNextStep'});
    }

    loadScreenSets() {
        GigyaManager.isGigyaSdkReady().then(() => {

            let screensetCfg = {
                screenSet: 'Barilla-RegistrationLogin',
                startScreen: 'gigya-login-screen-v2',
                containerID: 'loginPageContainer',
                context: {
                    noovleRaasGlobalCH: 'CH_MULINOBIANCO',
                    noovleProgressiveProfilingId: 'login-modal',
                    noovleRaasGlobalRegSource: 992
                }
            }

            gigya.accounts.showScreenSet(screensetCfg);
        });
    }
}


export default SecondStepComponent;
