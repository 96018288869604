/**
 *
 */
import $ from 'jquery';
import Controller from '../model/Controller';

class ProductCategoryListlCtrl extends Controller {
    constructor(element) {
        super(element);
        this.mansory = null;
        this.init();
    }

    init() {

        $("[scroll-button-ref]").click(function () {
            let stickyIsPresent = false;
            let buttonAttr = $(this).attr("scroll-button-ref");
            var top = $("[scroll-target='" + buttonAttr + "']").offset().top;
            if ($(".sticky-element-container.unpinned .buttons").length > 0) {
                top = top - $(".sticky-element-container.unpinned .buttons").outerHeight();
                stickyIsPresent = true;
            }
            $('html, body').animate({
                scrollTop: top
            }, 1000, function () {
                if (!stickyIsPresent) {
                    if ($(".sticky-element-container.unpinned .buttons").length > 0) {
                        $('html, body').animate({
                            scrollTop: window.pageYOffset - $(".sticky-element-container.unpinned .buttons").outerHeight()
                        }, 200);
                    }
                }
            });
        })
    }

}

export default ProductCategoryListlCtrl;
