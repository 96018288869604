import $ from "jquery";
import Controller from '../model/Controller';
export default class StabilimentiCtrl extends Controller {
    constructor(element) {
        super(element)
        $(element).find(".slick-slide").click((e) => {
            var title = $(e.currentTarget).find(".video-box-small h4").text();
            $(element).find(".js-title").html(title);
        });
    }
}