import $ from 'jquery';
import Component from '../model/Component';

class YoutubeVideoComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.options = {
            scriptId: '#youtube-api-loader',
            previewImage: null,
            playerId: 'player',
            playTriggerSelector: null,
            height: '100%',
            width: '100%',
            videoId: '',
            host: 'https://www.youtube-nocookie.com'
        }

        this.$element = $(element);

        this.loadOption('playerId');
        this.loadOption('videoId');
        this.loadOption('previewImage');
        this.loadOption('playTriggerSelector');

        var html = '<div id="' + this.options.playerId + '" class="video-player"></div>';
        html += `<div class="preview-image" style="background-image:url('${this.options.previewImage}');">`;

        if (!this.options.playTriggerSelector) {
            html += '<svg><use xlink:href="#svg_video_play"/></svg>';
        }

        html += '</div>';

        this.$element.prepend(html);
        this.$previewImage = this.$element.find('.preview-image');

        this.init();
    }

    loadOption(optionName) {
        var val = this.$element.attr(optionName);
        if (!!val) {
            this.options[optionName] = val;
        }
    }

    init() {
        var playerid = this.options.playerId;
        var mythis = this;
        window.jQuery('body').on("ytloaded", function(){
            console.log("📽 Per "+playerid+" YT è loaded 🏁");
            mythis.initPlayer();
        });

        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = () => {
            window.jQuery('body').attr("data-ytloaded", true).data("ytloaded",true).trigger("ytloaded");
        };
    }

    initPlayer() {
        $(this.options.scriptId).addClass('already-loaded');
        this.player = new YT.Player(this.options.playerId, {
            height: this.options.height,
            width: this.options.width,
            videoId: this.options.videoId,
            host: 'https://www.youtube-nocookie.com',
            playerVars: {
                'modestbranding': 1,
                'controls': 0,
                'showinfo': 0,
                'rel': 0
            }
            // , events: {
            //     'onStateChange': this.onStateChange
            // }
        });

        if (!!this.options.playTriggerSelector) {
            $(this.options.playTriggerSelector).click(() => {
                this.playVideo();
            });
        }

        this.$previewImage.click(() => {
            this.playVideo();
        });
    }

    onStateChange(data) {
        if (data.data == 0) {
            this.showPreview();
        }
    }

    showPreview() {
        this.$element.removeClass('foreground');
    }

    playVideo() {
        this.$element.addClass('foreground');
        this.player.playVideo();
    }
}

export default YoutubeVideoComponent;
