"use strict";

const Card = (props) => {
    let component = `<button type="button" mb-component-click="goToNextStep(${props.nextStep})" class="button card flat-cta shadow">
                    <div class="card-title">${props.title}</div>
                    <div class="card-description">${props.subtitle}</div>
                    <i class="arrow full right"></i>
                </button>`
       
    return component;

}

export default Card;