import Component from '../model/Component';
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";

class ChatbotComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);

        this.promoCode = element.dataset.promoCode || '';
        this.init();
    }

    init() {
        var pspScript = document.createElement('script');
        pspScript.setAttribute('src', 'https://chatbot.promoserviceparma.it/webchat/ec.js');
        document.head.appendChild(pspScript);

        this.isChatReadyPromise = new Promise((resolve) => {
            // eslint-disable-next-line no-undef
            if (typeof window.$ec === undefined) {
                if (!window.checkIntervalChat) {
                    window.checkIntervalChat = setInterval(function () {
                        // eslint-disable-next-line no-undef
                        if (window.$ec) {
                            clearInterval(window.checkIntervalChat);
                            resolve();
                            $('.elly_iframe_invite').css("visibility", "visible")
                        }
                    }, 500);
                }
            } else {
                return resolve();
            }
        });

        this.$element.find(".button").click(() => {
            this.isChatReadyPromise.then(() => {
                window.$ec.startChat();
            });
        })

        GigyaManager.getLoggedUser().then((u) => {
            this.isChatReadyPromise.then(() => {
                if (window.$ec) {
                    window.$ec.addUserInfo({
                        idGigya: u.UID,
                        promozione: this.promoCode,
                        pagecode: document.location.href,
                        browserName: Utility.getDeviceInfo().browserName,
                        browserVersione: Utility.getDeviceInfo().browserVersion,
                        osName: Utility.getDeviceInfo().osName,
                        osVersione: Utility.getDeviceInfo().osVersion,
                        device: Utility.getDeviceInfo().device,
                        email: u.profile.email,
                        telefono: u.phoneNumber,
                        nome: u.profile.firstName,
                        cognome: u.profile.lastName
                    });
                }

            });
        }, (resp) => {

            this.isChatReadyPromise.then(() => {
                if (window.$ec) {
                    window.$ec.addUserInfo({
                        promozione: this.promoCode,
                        pagecode: document.location.href,
                        browserName: Utility.getDeviceInfo().browserName,
                        browserVersione: Utility.getDeviceInfo().browserVersion,
                        osName: Utility.getDeviceInfo().osName,
                        osVersione: Utility.getDeviceInfo().osVersion,
                        device: Utility.getDeviceInfo().device
                    });
                }
            });
        })
    }


}

export default ChatbotComponent;
