import Component from '../model/Component';
import $ from 'jquery';

class FileUploadComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        let $el = $(element);

        this.parentCtrl = parentCtrl;
        this.uploadInProgress = false;

        this.options = {
            fakeInputTriggerSelector: $el.data('fake-input-trigger-selector') || null,
            endpoint: API_ENDPOINT + $el.data('endpoint'),
            callback: $el.data('upload-callback') || null
        }

        if (!this.options.fakeInputTriggerSelector) {
            this.$fakeInput = $(element);
        } else {
            this.$fakeInput = $el.find(this.options.fakeInputTriggerSelector);
        }

        $(document).ready(() => {
            this.init();
        });
    }

    init() {
        this.$realInput = $('<input type="file" class="real-input" accept="" aria-label="upload">');
        this.$fakeInput.after(this.$realInput);

        this.$fakeInput.click(() => {
            if (!this.uploadInProgress) {
                this.$realInput.click();
            }
        });

        this.$realInput.change(() => {
            this.uploadFiles();
        });
    }

    uploadFiles() {
        this.uploadInProgress = true;
        this.$fakeInput.addClass('loading');

        let files = this.$realInput.prop('files');
        let originalName = files[0].name;

        var formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append("file", files[i]);
        }

        $.ajax({
            method: "POST",
            url: this.options.endpoint,
            data: formData,
            processData: false,
            contentType: false
            // headers: {'Content-Type': undefined}
            // ,uploadEventHandlers: {
            //     progress: progressFn
            // }
        }).then((response) => {
            //TODO - recuperare filename da response
            this.callback(response.body, originalName);
        }, () => {
            this.callback(null, null);
        });
    }

    callback(file, originalName) {
        this.uploadInProgress = false;
        if (!!this.options.callback) {
            file.oldName = originalName;
            this.parentCtrl[this.options.callback](file);
        }
        this.$fakeInput.removeClass('loading');
    }
}

export default FileUploadComponent;
