import Controller from '../model/Controller';
import Swiper from "swiper";
import $ from "jquery";
import Tracker from "../business/Tracker";

class ArmonieLandingGlutenFreeCtrl extends Controller {
    constructor(element) {
        super(element);
        this.element = element;

        this.init();
    }

    init() {
        this.galleryTop = $('#gallery-top');
        this.galleryThumbs = $('#gallery-thumbs');
        this.initVideoGallery();

        this.recipesSlider = $('#recipes-slider');
        this.initRecipesSlider();

        let eh = new EqualHeight();
        eh.init('#recipes-slider .recipe-title');

        Tracker.send("EVENT", {interactionType: "ZCATEGORY_CLICK", productCode: '9991000087'});
    }

    initVideoGallery() {
        let galleryTop = new Swiper(this.galleryTop[0], {
            slidesPerView: 1,
            nextButton: '#gallery-top-next',
            prevButton: '#gallery-top-prev',
        });

        let galleryThumbs = new Swiper(this.galleryThumbs[0], {
            spaceBetween: 30,
            slidesPerView: 3,
            slidesPerGroup: 3,
            slideToClickedSlide: true,
            loop: true,
            breakpoints: {
                479: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 10,
                },
                1023: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 20,
                },
            },
        });

        galleryTop.on('slideChangeStart', () => {
            if (galleryTop.activeIndex === galleryThumbs.realIndex) return;
            if (galleryTop.activeIndex < galleryTop.previousIndex) galleryThumbs.slidePrev();
            else galleryThumbs.slideNext();
        });

        galleryTop.on('slideChangeEnd', () => {
            this.galleryTop.find('iframe.video').remove();
        });

        galleryThumbs.on('slideChangeStart', () => {
            if (galleryTop.activeIndex === galleryThumbs.realIndex) return;
            galleryTop.slideTo(galleryThumbs.realIndex);
        });

        this.galleryTop.find('[data-video-id]').on('click', (e) => {
            let cont = $(e.currentTarget),
                slide = cont.parent();

            $('<iframe class="video" frameborder="0" allowfullscreen></iframe>').prop('src', 'https://www.youtube.com/embed/' + cont.data('video-id') + '?autoplay=1&rel=0&showinfo=0').appendTo(slide);

            return false;
        });
    }

    initRecipesSlider() {
        new Swiper(this.recipesSlider[0], {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20,
            nextButton: this.recipesSlider.parent().find('.swiper-button-next')[0],
            prevButton: this.recipesSlider.parent().find('.swiper-button-prev')[0],
            breakpoints: {
                479: {
                    slidesPerView: 1,
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                1023: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                }
            }
        });
    }

}

export default ArmonieLandingGlutenFreeCtrl;