import $ from 'jquery';
import Swiper from 'swiper';
import Component from '../model/Component';

class VisualCarouselComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        $(window).resize(() => {
            this.onResize();
        });
        this.initSwiper();
    }

    onResize() {
    }

    initSwiper() {
        var slidesNumber = this.$element.find('.swiper-slide').length;
        this.$element.find('.swiper-pagination').toggle(slidesNumber > 1);

        this.swiper = new Swiper(this.$element[0], {
            pagination: this.$element.find('.swiper-pagination')[0],
            paginationClickable: true,
            slidesPerView: 1,
            loop: true,
            allowSwipeToNext: slidesNumber > 1,
            allowSwipeToPrev: slidesNumber > 1
        })
    }
}

export default VisualCarouselComponent;
