/**
 *
 */
import Controller from '../model/Controller';
import $ from 'jquery';
import Mustache from "mustache";
import Utility from '../business/Utility';
import HttpClient from "../business/HttpClient";
import UserManager from "../business/UserManager";
import range from 'rangeslider.js';
import TweenMax from "gsap";
import gReveal from "../vendor/gReveal";

class FilieraCtrl extends Controller {
    constructor(element) {
        super(element);
        this.init();
    }

    init() {
        this.reveal = new gReveal(
            {
                initialDelay: 0,
                offset: 120
            }
        );
        
        if($('#sgv_italia').html()!=null){
        		
        		$("path, circle").hover(function(e) {
        			$('.single_hover').removeClass('active');
        			$('#reg_' + $(this).attr('id')).addClass('active');
        		});
        		
	        $("path, circle").mouseleave(function(e) {
	        	  $('.single_hover').removeClass('active');
	        	});
	
	        	$(document).mousemove(function(e) {
	//        	  $('#info-box').css('top',e.pageY-$('#info-box').height()-30);
	//        	  $('#info-box').css('left',e.pageX-($('#info-box').width())/2);
//	        		console.log($(this).attr('id'));
	        	}).mouseover();
	
	        	var ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	        	if(ios) {
	        	  $('a').on('click touchend', function() { 
	        		$('.single_hover').removeClass('active');
	        		$('#reg_' + $(this).attr('id')).addClass('active');
	        	  });
	        	}
        }
        
        
        
        
    }
    
 
}

export default FilieraCtrl;
