/**
 *
 */

import $ from 'jquery'
import AppRegistry from '../business/AppRegistry'

class Controller {

    constructor(element) {
        this.element = element;

        this.components = {};

        this.bindedElements = [];

        this.loadComponents();

        $(element).on("click", "[mb-click]", (el) => {
            this.bindedElements.push({
                target: el.currentTarget,
                event: "click"
            });
            let functionCode = el.currentTarget.getAttribute('mb-click');
            let functionName = functionCode.split("(")[0];
            let params = functionCode.split("(")[1].split(")")[0].split(',');
            let args = [];
            if (functionCode.length > 0) {
                for (var i = 0; i < params.length; i++) {
                    var ret = params[i];
                    if (isNaN(params[i]) && params[i].indexOf("'") == -1) {
                        ret = this[params[i]];
                    }
                    args.push(ret);
                }
            }
            this[functionName].apply(this, args)
        });
    }

    destroy() {
        this.bindedElements.forEach((obj) => {
            $(obj.target).unbind(obj.event);
        });
    }

    loadComponents() {
        var registry = new AppRegistry();
        var mbComponentsEls = this.element.querySelectorAll('[mb-component]');
        for (var i = 0; i < mbComponentsEls.length; i++) {
            var $domEl = $(mbComponentsEls[i]);
            var componentId = $domEl.data('_componentId');

            if (typeof componentId === 'undefined') {
                var component = $domEl.attr('mb-component');
                var Clazz = registry.getComponent(component);
                try {
                    new Clazz(mbComponentsEls[i], this);
                } catch (e) {
                    console.log("😱 Unable to find Component " + component);
                    console.log(e);
                }
            }
        }
    }
}

export default Controller;
