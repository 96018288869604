import Component from '../model/Component';
import autocomplete from '../vendor/jquery.autocomplete';
import $ from 'jquery';
import HttpClient from "../business/HttpClient";

class IngredientsAutocompleteComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.init();
        this.suggest = null;
        this.ingredientId = null;
    }

    init() {
        autocomplete;
        HttpClient.doCall({
            url: API_ENDPOINT + "/api/mulinobianco/1.0/web/ingredient/all",
            method: "GET",
            dataType: "json"
        }, false).then((resp) => {

            let lookup = resp.body.map(ing => {
                return {value: ing.name, data: {id: ing.id}};
            });

            if ($(this.element).siblings("[data-auto-complete-button]").length > 0) {
                let $button = $(this.element).siblings("[data-auto-complete-button]");
                this.actionUrl = $button.attr("data-url");
                if (this.actionUrl) {
                    $button.click(() => {
                        document.location.href = this.ingredientId ? this.actionUrl + "?ingredientId=" + this.ingredientId : this.actionUrl;
                    });

                    $(this.element).on('keyup', (e) => {
                        if (e.keyCode == 13) {
                            setTimeout(() => {
                                if (this.ingredientId) {
                                    document.location.href = this.actionUrl + "?ingredientId=" + this.ingredientId;
                                }
                            }, 300)

                        }
                    });
                }
            }


            if (window.location.search.indexOf('ingredientId=') >= 0) {
                this.ingredientId = window.location.search.split('ingredientId=')[1].split('&')[0];
                window.document.dispatchEvent(new CustomEvent("IngredientsAutocompleteComponent:valueSelected", {detail: {data: {id: this.ingredientId}}}));
            }


            $(this.element).autocomplete(
                {
                    lookup: lookup,
                    lookupFilter: function (suggestion, query, queryLowerCase) {
                        if (lookup.filter(it => it.value.toLowerCase().search(queryLowerCase) != -1).length == 0) {
                            return true;
                        } else {
                            return lookup.filter(it => it.value.toLowerCase().search(queryLowerCase) != -1 && suggestion.value == it.value).length > 0;
                        }
                    },
                    onSelect: (sugg) => {
                        window.document.dispatchEvent(new CustomEvent("IngredientsAutocompleteComponent:valueSelected", {detail: sugg}));
                        this.ingredientId = sugg.data.id;
                    }
                });

        }, () => {
        })
    }
}


export default IngredientsAutocompleteComponent;
