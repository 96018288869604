import UserManager from "./UserManager";
import HttpClient from "./HttpClient";
import Utilility from "./Utility";
import Constants from "./Constants";

class CouponManager {


    constructor() {
        this.registeredIds = [];
        this.doFindByKeysDebounced = Utilility.debounce(this.doFindByUserAndKeys, 400);
        //this.EVENTS = {BYKEYS_DONE: "MB_BYKEYS_DONE", RATE_DONE: "MB_RATE_DONE"};
    }

    register(externalId) {
        if (!this.registeredIds.includes(externalId)) {
            this.registeredIds.push(externalId);
        }
        this.doFindByKeysDebounced();
    }

    doFindByUserAndKeys() {
        UserManager.getLoggedUser().then((d) => {
            var item = "";
            for (var i = 0; i < this.registeredIds.length; i++) {
                item += "idProduct=" + this.registeredIds[i] + "&";
            }

            HttpClient.doCall({
                url: API_ENDPOINT + '/api/mulinobianco/1.0/web/coupon/all?' + item,
                type: "GET",
                dataType: "json",
                contentType: "application/json; charset=utf-8"
            }, false).then((d) => {
                var leftIds = this.registeredIds.slice(0);
                for (var i = 0; i < d.body.length; i++) {
                    var item = d.body[i];
                    window.document.dispatchEvent(new CustomEvent(Constants.events.COUPON_FOUND, {detail: item}));
                    let idx = leftIds.indexOf((item.idProduct ));
                    if (idx > -1) {
                        leftIds.splice(idx, 1); //Remove elems
                    }
                }

                for (var i = 0; i < leftIds.length; i++) {
                    window.document.dispatchEvent(new CustomEvent(Constants.events.COUPON_NOTFOUND, {detail: leftIds[i]}));
                }

            }, () => {
            })

        }, () => {
        })
    }
}

export default new CouponManager();