import Component from '../model/Component';
import UserManager from '../business/UserManager';
import Utilility from "../business/Utility";
import CryptoJS from "crypto-js";

class LoginComponent extends Component {

    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.user = null;
        console.log("🍺 login component ready");

        this.oneTrustLoaded = new Promise((resolve) => {
            this.checkOneTrustLoaded(resolve);
        });

        this.manageOneTrust();


        UserManager.getLoggedUser().then((d) => {
            this.user = d;
            console.log("🍺Found logged user :");
            console.log(d);

            //this.element.addEventListener(Constants.events.MODAL_OPEN, () => this.open());

            UserManager.getAvatar(d.UID).then((data) => {

                if (data.body.path) {
                    $(this.element).append("<img src='" + data.body.path + "' class='img'/>");
                    $(this.element).children("svg").hide();
                    $(this.element).addClass("withImg")
                } else {
                    $(this.element).append("<img src='/fe-web/img/icons/avatar_placeholder.jpg' class='img'/>");
                    $(this.element).children("svg").hide();
                    $(this.element).addClass("withImg")
                }
            });

            UserManager.getNotification().then((d) => {
                console.log(d);
                if (d.body.unread > 0) {
                    $(this.element).append("<div class='unreadNotification'>" + d.body.unread + "</div>");
                }
            }, () => {
            })

        }, () => {
            this.user = null;

            console.log("🤹‍ No user present")
        });

        element.addEventListener("click", () => {
            if (this.user == null) {
                var href = "/login?urlBack=" + Utilility.getCurrentUri();
                if (!!$(this.element).data("regSource")) {
                    href += "&regSource=" + $(this.element).data("regSource");
                }
                window.location.href = href;
            } else {
                window.location.href = "/area-riservata";
            }
        });
    }

    checkOneTrustLoaded(resolve) {
        const interval = 250;
        if (window.OneTrust) {
            resolve();
        } else {
            setTimeout(() => {
                this.checkOneTrustLoaded(resolve);
            }, interval);
        }
    }

    async manageOneTrust() {
        await this.oneTrustLoaded;

        if (window.OneTrust) {
            this.trackUser();

            window.OneTrust.OnConsentChanged(() => {
                this.trackUser();
            })
        }

    }

    async sendEventToAnalytics(data) {
        let uid = data.UID;
        let uidHashed = await this.getIdHashed(uid);

        var obj = {
            'event': 'user_info',
            //'loggedStatus': 'loggedin',
            'user_id': uidHashed,
            // 'user_email': data.profile.email,
            //'user_phone_number': data.phoneNumber,
            //'user_name': data.profile.firstName,
            //'user_surname': data.profile.lastName,
            //'user_zip_code': data.profile.zip,
            //'user_country_code': data.data.COD_PROVINCE,
            //'country': 'IT',
            //'city': this.formatData(data.profile.city),
            //'birthDate': this.formatData(data.profile.birthYear),
            //'gender': this.formatData(data.profile.gender),
            //'family': this.getFamily(data),
            //'favoriteBrand': this.formatData(data.data.PreferredBarillaGroupBrand)
        };

        if (typeof _neodataBarilla !== "undefined") {
            _neodataBarilla.setGigyaId(uidHashed);
        }
        window.dataLayer.push(obj);
    }

    async getIdHashed(id) {
        const endpoint = 'https://utils-hashing.digital.p.retexcloud.io/api/IdHashing';
        // Default options are marked with *
        const response = await fetch(endpoint, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({inputId: id}) // body data type must match "Content-Type" header
        });
        return response.text(); // parses JSON response into native JavaScript objects
    }

    formatData(data) {
        return !!data ? data : '(not specified)';
    }

    getFamily(obj) {
        let familyMembers = obj.data.FAMILY_MEMBERS + '|';

        let childrenCounter = 0;

        let childrenString = "";
        for (var i = 1; i <= 5; i++) {
            var val = obj.data['BIRTHYEAR_SON' + i];
            if (val) {
                childrenCounter++;
                childrenString = val;
            }
            childrenString += "|";
        }

        if (!obj.data.FAMILY_MEMBERS && childrenCounter == 0) {
            return '(not specified)';
        } else {
            return familyMembers + childrenCounter + "|" + childrenString;
        }

    }

    trackUser() {
        UserManager.getLoggedUser().then((u) => {
            try {
                let marketingCookies = OnetrustActiveGroups && OnetrustActiveGroups.indexOf("C0004") > 0;
                if (marketingCookies && u.preferences.privacy_profiling.isConsentGranted) {
                    this.sendEventToAnalytics(u);
                }

                window.dataLayer.push({
                    'event': 'gigya_info',
                    'isLoggedIn': true,
                    'hasAcceptedGigyaPrivacy': u.preferences.privacy_profiling.isConsentGranted,
                    'hasAcceptedMarketingCookies': marketingCookies
                });

            } catch (err) {
                console.log("Error sending to Analytics...");
            }
        }, () => {
            console.log('test logout');
            // send datalayer obj
            // let obj = {
            //     'event': 'userInfo',
            //     'loggedStatus': 'not-logged'
            // }
            // window.dataLayer.push(obj);
        });
    }


}


export default LoginComponent;
