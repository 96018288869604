import CollectionPromoApi from "mbpromo/src/collection-api/CollectionPromoApi";
import DeviceDetector from "device-detector-js";
import Tracker from "./Tracker";

var Utility = {

    debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    },

    getCurrentUri() {
        return window.location.href.replace(window.location.origin, "");
    },

    isMobile() {
        return  /mobi/i.test(navigator.userAgent);
    },

    getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            sParameterValue,
            index,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            index = sURLVariables[i].indexOf('=');
            sParameterName = sURLVariables[i].slice(0, index);
            sParameterValue = sURLVariables[i].slice(index + 1);

            if (sParameterName === sParam) {
                return sParameterValue === undefined ? true : sParameterValue;
            }
        }
    },

    formatDate(timestamp, format) {
        let date;
        if (!timestamp) {
            date = new Date();
        } else {
            date = new Date(timestamp);
        }
        if (!format || format == "yyyy-mm-dd") {
            return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        } else if (format == "dd-mmm-yyyy") {
            let monthNames = ["GEN", "FEB", "MAR", "APR", "MAG", "GIU", "LUG", "AGO", "SET", "OTT", "NOV", "DIC"];
            return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear();
        }
    },

    /**
     * Welcome message
     * @returns {{}}
     */
    welcomeMessage() {
        let hours = new Date().getHours();
        let minutes = new Date().getMinutes();
        let hoursMinutes = hours + '.' + minutes;
        let obj = {};

        obj.welcomeDescription = "Ogni giorno, alle 7 del mattino, una rassegna di buone notizie nella tua casella di posta.";

        if (hours >= 6 && hours < 13) {
            obj.welcomeMessage = "Buongiorno";
        } else if (hours >= 13 && hours <= 18) {
            obj.welcomeMessage = "Buon pomeriggio";
        } else if (hoursMinutes > 18.01 && hoursMinutes <= 23.29) {
            obj.welcomeMessage = "Buonasera";
        } else {
            obj.welcomeMessage = "Buonanotte";
        }

        return obj;
    },

    getShareLink(shareButton, titlePage, urlPage) {
        let urlSocial = '';
        switch (shareButton) {
            case 'facebook':
                urlSocial = 'https://www.facebook.com/sharer/sharer.php?u=' + urlPage;
                break;
            case 'pinterest':
                urlSocial = 'http://pinterest.com/pin/create/link/?url=' + urlPage;
                break;
            case 'twitter':
                urlSocial = 'https://twitter.com/intent/tweet?url=' + urlPage + '&text=' + titlePage;
                break;
            case 'email':
                urlSocial = 'mailto:?subject=' + titlePage + '&body=' + urlPage;
                break;
            case 'whatsapp':
                urlSocial = 'https://api.whatsapp.com/send?text=' + titlePage + ' ' + urlPage;
                break;
        }
        return urlSocial;
    },

    getDeviceInfo() {
        let deviceDetector = new DeviceDetector();
        let userAgent = navigator.userAgent;
        let device = deviceDetector.parse(userAgent);

        return device = {
            browserName: device.client.name,
            browserVersion: device.client.version,
            osVersion: device.os.version,
            osName: device.os.name,
            device: device.device.type
        }
    },

    fillCapProvCityForm(cap) {
        this.collectionPromoApi = new CollectionPromoApi({promoCode: 'raccolta-punti'});

        let capCheck = /^\d+$/.test(cap);
        if (cap && cap.length == 5 && capCheck) {
            this.collectionPromoApi.capUtil(3, {"cap": cap}).then((resp) => {
                if (resp.body.localita[0]) {
                    let checkSelected = resp.body.localita.length == 1 ? 'selected' : '';
                    // Utility.startLoader();
                    let body = resp.body.localita[0];

                    $('[name="cpc-zip"]').val(cap);
                    $('[name="cpc-city"]').html('');
                    if (resp.body.localita.length > 1) {
                        $('[name="cpc-city"]').append(`<option selected disabled value="">Seleziona...</option>`);
                    }

                    $.each(resp.body.localita, function () {
                        $('[name="cpc-city"]').append(`<option value="${this.nome}" ${checkSelected}>${this.nome}</option>`);
                    })

                    $('[name="cpc-province"]').val(body.provincia);
                    $('.location-cnt .pristine-error').html('').hide();

                } else {
                    $('[name="cpc-city"]').html('');
                    $('[name="cpc-city"]').val('')
                    $('[name="cpc-province"]').val('');
                }
            }, (resp) => {
                console.log(resp.message)
            })
        }
    },

    prepareCap(fillOnLoad) {
        $('[name="cpc-zip"]').on('input change', function (e) {
            e.preventDefault();
            e.stopPropagation();
            Utility.fillCapProvCityForm($('[name="cpc-zip"]').val());
        });

        if (fillOnLoad) {
            Utility.fillCapProvCityForm($('[name="cpc-zip"]').val());
        }
    },

    preCompileOnBoardingForm(data) {
        if (data.nome) {
            $("[name='cpc-firstname']").val(data.nome);
            $("[name='cpc-firstname']").attr('readonly', true);
        }
        if (data.cognome) {
            $("[name='cpc-lastname']").val(data.cognome);
            $("[name='cpc-lastname']").attr('readonly', true);
        }
        if (data.ind_denurb) {
            $("[name='cpc-den-urb']").val(data.ind_denurb.toUpperCase());
        }
        if (data.ind_toponimo) {
            $("[name='cpc-address']").val(data.ind_toponimo);
        }
        if (data.citofono) {
            $("[name='cpc-notes']").val(data.citofono);
        }
        if (data.ind_civico) {
            $("[name='cpc-civic-number']").val(data.ind_civico);
        }
        if (data.cap) {
            $("[name='cpc-zip']").val(data.cap);
        }
        if (data.provincia) {
            $("[name='cpc-province']").val(data.provincia);
        }
        if (data.citta) {
            $("[name='cpc-city']").html('').append(`<option value="${data.citta}" selected>${data.citta}</option>`);
        }
        if (data.telefono) {
            $("[name='cpc-phone']").val(data.telefono != null ? data.telefono : (data.cellulare != null ? data.cellulare : ''));
        }
    },

    trackEvent(userId, type, promoCode, ctaName) {
        try {
            Tracker.send('EVENT',{payload:{
                    contactId: userId,
                    sourceUrl: window.location.href,
                    promoCode: promoCode,
                    interactionType: type,
                    ctaName: ctaName
                }});
        } catch (error) {
            console.log(error)
        }
    },

    buildModal(title, subtitle, content= '', closeEnable = true){

        const close = (closeEnable === false ? 'style="display: none;"' : '');
        let htmlTitle = title ? `<h2>${title}</h2>` : '';
        let htmlSubtitle = subtitle ? `<div class="generic-modal__subtitle">${subtitle}</div>` : '';

        let modalTmpl = `
            <div class="generic-modal generic-modal__active" 
            	component="GenericModalComponent">
                <div class="generic-modal__wrap">
                     <div class="generic-modal__close" 
                     		component-click="close()"
                     		${close}>
						<svg>
							<use xlink:href="#svg_circle_close"></use>
						</svg>
					</div>
                    <div class="generic-modal__content">
						<div>
							${htmlTitle}
							${subtitle}
						</div>
			
						${content}
					</div>
                 </div>
            </div>`;
        $('body').append(modalTmpl);
        $('body').addClass('no-scroll');

        $(document).on('click','.generic-modal__close', ()=> {
            document.body.classList.remove('no-scroll');
            $('.generic-modal').remove();
        });
    }
};

export default Utility;
