import Cookies from 'js-cookie';
import Constants from '../business/Constants';
import HttpClient from "../business/HttpClient";
import GigyaManager from "../business/GigyaManager";
import PrivacyCluster from "../enums/PrivacyCluster";


class UserManager {
    constructor() {
        this.init();
    }

    init() {
        this.promiseUserLogged = this.waitUserLoggedIn();
        let authToken = Cookies.get(Constants.AUTH_TOKEN_COOKIE);
        if (typeof authToken !== "undefined" && authToken != null) {
            this.verifySession().then(
                () => {
                    GigyaManager.getLoggedUser().then((detail) => {
                            window.document.dispatchEvent(new CustomEvent(Constants.events.USER_LOGGGED_IN, {detail}))
                        },
                        () => {
                            this.deleteSession().then(() => {
                                window.document.dispatchEvent(new CustomEvent(Constants.events.AUTH_FAILED, {}));
                            });
                        });
                },
                () => {
                    this.deleteSession().then(() => {
                        GigyaManager.logout();
                        window.document.dispatchEvent(new CustomEvent(Constants.events.AUTH_FAILED, {}));
                    });
                }
            )
        } else {
            GigyaManager.getLoggedUser().then((detail) => {
                    HttpClient.doAuth().then(() => {
                            window.document.dispatchEvent(new CustomEvent(Constants.events.USER_LOGGGED_IN, {detail}))
                        },
                        () => {
                            window.document.dispatchEvent(new CustomEvent(Constants.events.AUTH_FAILED, {}));
                            console.log("💣  Something went wrong with auth!");
                        });
                },
                () => {
                    window.document.dispatchEvent(new CustomEvent(Constants.events.AUTH_FAILED, {}));
                    console.log("🕵 No logged user!");
                });

        }
    }


    waitUserLoggedIn() {
        return new Promise((resolve, reject) => {
            window.document.addEventListener(Constants.events.USER_LOGGGED_IN, (data) => resolve(data.detail));
            window.document.addEventListener(Constants.events.AUTH_FAILED, () => reject());
        });
    }

    waitUserLoggedOut() {
        return new Promise((resolve, reject) => {
            window.document.addEventListener(Constants.events.USER_LOGGED_OUT, (data) => resolve(data.detail));
        });
    }

    getLoggedUser(refresh) {
        if (refresh) {
            return GigyaManager.getLoggedUser();
        } else {
            return this.promiseUserLogged;
        }
    }

    isLoggedUser() {

    }

    getPrivacyCluster(refresh) {
        return new Promise((resolve, reject) => {
            this.getLoggedUser(refresh).then((account) => {
                let PRIVACY_PROFILING = window.SCREENSETS_VERSION > 3 ? account.preferences.privacy_profiling.isConsentGranted : account.data.PRIVACY_PROFILING == 'true';
                let GIGYA_PRIVACY_RECALL = window.SCREENSETS_VERSION > 3 ? account.preferences.privacy_recall.isConsentGranted : account.data.PRIVACY_RECALL;
                let PRIVACY_RECALL = GIGYA_PRIVACY_RECALL && (!account.data.OPTOUT_BRAND || account.data.OPTOUT_BRAND.indexOf("BR_mulinobianco") == -1);

                if (!PRIVACY_PROFILING && !PRIVACY_RECALL) {
                    resolve(PrivacyCluster.U1);
                } else if (PRIVACY_PROFILING && !PRIVACY_RECALL) {
                    resolve(PrivacyCluster.U2);
                } else if (!PRIVACY_PROFILING && PRIVACY_RECALL) {
                    resolve(PrivacyCluster.U3);
                } else {
                    resolve(PrivacyCluster.U4);
                }

            }, () => {
                resolve(PrivacyCluster.U5)
            });
        });
    }

    deleteSession() {
        Cookies.remove(Constants.AUTH_TOKEN_COOKIE, {path: '/'});
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'DELETE',
                url: API_ENDPOINT + '/api/1.0/auth',
                dataType: "json"
            }, true).then(
                (data) => {
                    resolve();
                });
        });
    }

    verifySession() {
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                url: API_ENDPOINT + '/api/1.0/auth/verify',
                method: 'GET',
                dataType: "json"
            }, true).then(
                (data) => {
                    if (data.body) {
                        resolve();
                    } else {
                        reject();
                    }
                }, () => reject())
        });
    }

    getAvatar(userId) {
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'GET',
                url: API_ENDPOINT + '/api/mulinobianco/1.0/web/member/' + userId + '/avatar',
                dataType: "json"
            }, true).then(
                (data) => {
                    resolve(data);
                }, () => reject())
        });
    }

    getNotification() {
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'GET',
                url: API_ENDPOINT + '/api/mulinobianco/1.0/web/notification/count',
                dataType: "json"
            }, true).then(
                (data) => {
                    resolve(data);
                }, () => reject())
        });
    }
}

export default new UserManager();
