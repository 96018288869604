import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import $ from "jquery";

class SpecialitaCasaCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.init();
        this.piccoloMugnaioBiancoCH = "CH_1071_MLB_WEB_SPECIALITACASA_NAV_NAV_20250313_20250328";
        this.regSource = 1071;
    }

    init(){
        $('#overlayer').show();
        GigyaManager.getLoggedUser().then((user) => {
            const ch = user.data[this.piccoloMugnaioBiancoCH];
            if (typeof ch !== "undefined" && ch != null && ch !== "") {
                this.showLoggedArea(true);
            } else {
                this.showLoggedArea(false);
            }
        }, () => {
            this.showLoggedArea(false);
        });
    }

    showLoggedArea(canUser){
        $(`.promo-${canUser}`).addClass('check');
        $('#overlayer').hide();
    }
}

export default SpecialitaCasaCtrl;