import {config, tracker} from "../vendor/youserxp.umd";
import HttpClient from "./HttpClient";
import GigyaManager from "./GigyaManager";
import PrivacyCluster from "../enums/PrivacyCluster";
import Constants from "./Constants";
import $ from "jquery";
import UserManager from "./UserManager";

class Tracker {
    constructor(params = {}) {
        this.oneTrustLoaded = new Promise((resolve) => {
            this.checkOneTrustLoaded(resolve);
        });
        this.isTrackerReady = this.init(params);

    }

    checkOneTrustLoaded(resolve) {
        const interval = 250;
        if (window.OneTrust) {
            resolve();
        } else {
            setTimeout(() => {
                this.checkOneTrustLoaded(resolve);
            }, interval);
        }
    }

    init({trackId}) {
        return new Promise(async (resolve, reject) => {
            config({
                host: API_ENDPOINT
            });

            let trackingId = !!window.MB_TRACKING_ID ? window.MB_TRACKING_ID : "MULINO_BIANCO";
            if (trackId) {
                trackingId = trackId;
            }
            // this.instance = tracker.getInstance({trackingId: trackingId});
            this.instance = new tracker.Tracker({trackingId: trackingId});

            let localStorage = window.localStorage;

            this.udid = localStorage.getItem("mb-dev-udid");

            if (!this.udid) {
                this.addUdid();
            }

            this.user = {UID: "ANONYMOUS", privacyCluster: null};

            await this.oneTrustLoaded;

            UserManager.getLoggedUser().then((u) => {
                if (OnetrustActiveGroups && OnetrustActiveGroups.indexOf("C0004") > 0 && u.preferences.privacy_profiling.isConsentGranted) {
                    this.user.UID = u.UID;
                    resolve(true);
                } else {
                    resolve(true);
                }

                this.listenForTrackingEvent();
            });
        });
    }

    addUdid() {
        HttpClient.doCall({
            url: API_ENDPOINT + "/api/mulinobianco/1.0/web/device/udid",
            method: "GET",
            dataType: "json"
        }, true).then((d) => {
            this.udid = d.body.udid;
            window.localStorage.setItem("mb-dev-udid", d.body.udid);
        })
    }

    send(eventType, payload) {
        return this.isTrackerReady.then(() => {
            var p = $.extend({
                contactId: this.user.UID,
                contactIdOrigin: this.user.UID,
                sourceUrl: window.location.href,
                sessionId: {udid: this.udid},
                privacyCluster: this.user.privacyCluster
            }, payload);

            return this.instance.send(eventType, {payload: p}, "worker");
        });

    }

    listenForTrackingEvent() {
        window.document.addEventListener(Constants.events.tracking.SEND, (event) => {
            let eventData = event.detail;
            this.send(eventData.eventName, eventData.params, eventData.mode);
        });
    }


}

export const getTracker = (params) => {
    return new Tracker(params);
};
export default new Tracker();
