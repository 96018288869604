/**
 *
 */

import Swiper from 'swiper';
import Controller from '../model/Controller';
import Constants from '../business/Constants';
import Tracker from "../business/Tracker";
import Utility from "../business/Utility";
import GoogleAnalytics from "../business/GoogleAnalytics";

class RecipeDetailCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.init()
    }

    init() {
        if (Utility.getUrlParameter("yxpt-from") == "search") {
            Tracker.send("EVENT", {
                interactionType: "ZRECIPE_VIS_SEARCH",
                productCode: $('#mb-product-externalid').text(),
                name: $('#mb-editorialrecipe-name').text()
            });
        } else {
            Tracker.send("EVENT", {
                interactionType: "ZRECIPE_VISIT",
                productCode: $('#mb-product-externalid').text(),
                name: $('#mb-editorialrecipe-name').text()
            });
        }

        this.mySwiper = new Swiper('#relatedRecipesSwiper', {
            loop: true,
            slidesPerView: 3,
            breakpoints: {
                768: {
                    slidesPerView: 1
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        })

        $('.recipe-block-container .swiper-button-prev').click(() => {
            this.mySwiper.slidePrev();
        })

        $('.recipe-block-container .swiper-button-next').click(() => {
            this.mySwiper.slideNext();
        })

    }

    onPrintClick() {
        Tracker.send("EVENT", {
            interactionType: "ZRECIPE_PRINT",
            productCode: $('#mb-product-externalid').text(),
            name: $('#mb-editorialrecipe-name').text()
        });

        let id = $('#mb-editorialrecipe-id').text();
        let fileName = $('#mb-editorialrecipe-fileName').text();

        window.location.href = "/internal/recipe-to-pdf.do?id=" + id + "&downloadFileType=PDF&fileName=" + fileName;
    }

    showShareModal() {
        document.getElementById('shareModal').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
    }

    onShareClick(shareButton) {
        const urlPage = window.location.href;
        const titlePage = document.title;
        shareButton = shareButton.replaceAll("'", '');
        let urlSocial = Utility.getShareLink(shareButton, titlePage, urlPage);
        if(urlSocial) window.open(urlSocial, '_blank');

        Tracker.send("EVENT", {
            interactionType: "ZRECIPE_SHARE",
            productCode: $('#mb-product-externalid').text(),
            name: $('#mb-editorialrecipe-name').text()
        });

        const obj = {
            'data-name-product': $('#mb-editorialrecipe-name').text(),
            'data-name-method': shareButton,
            'data-content-type': 'recipe'
        }
        GoogleAnalytics.send('social_share', obj);
    }

}

export default RecipeDetailCtrl;
