import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";
import UserManager from "../business/UserManager";
import $ from 'jquery';

class LoginCtrl extends Controller {
    constructor(element) {
        super(element);
        UserManager.init();
        UserManager.getLoggedUser().then(() => {
            // if user is logged yet
            window.location.href = '/home';
        });

        window.document.addEventListener('screensetOnAfterScreenLoad', (event) => {
            if (event.detail.currentScreen == "gigya-login-screen-v2" || event.detail.currentScreen == "gigya-login-screen") {
                this.onAfterLoginLoad();
            }

            if( event.detail.currentScreen === 'gigya-progressive-profiling-screen'){
                // open modal
                document.getElementById('login-modal').dispatchEvent(new CustomEvent('MB_MODAL_OPEN', () => this.open()));
            }
        });

        GigyaManager.isGigyaSdkReady().then(() => {
            let regSource = this.getParameterByName("regSource");

            const newsletterSubscription = localStorage.getItem("newsletter-goodmorning");
            const newsletterNMCV = localStorage.getItem("newsletter-goodmorning-nmcv");
            const newsletterAnni80 = localStorage.getItem("newsletter-goodmorning-anni-80");

            if (Utility.getCurrentUri().indexOf("/registrazione") != -1) {
                let screensetCfg = {
                    screenSet: 'Barilla-RegistrationLogin',
                    startScreen: 'gigya-register-screen-v2',
                    containerID: 'registrationPageContainer',
                    context: {
                        noovleRaasGlobalCH: 'CH_MULINOBIANCO',

                    }
                }

                if (!Utility.isMobile()) {
                    screensetCfg.authFlow = 'popup';
                }

                if (regSource) {
                    screensetCfg.context['noovleRaasGlobalRegSource'] = regSource;
                }

                if ((regSource === "677" || regSource === "893") && (newsletterSubscription === 'Goodmorning_fullweek' || newsletterSubscription === 'Goodmorning_weekend')) {
                    screensetCfg.context['NL_3NAC'] = [newsletterSubscription];

                    document.querySelector('.header-wrapper .left').style.display = 'none';
                    document.querySelector('.header-wrapper .search-icon').style.display = 'none';
                    document.querySelector('.header-wrapper .profile-icon').style.display = 'none';
                    document.getElementById('alert-newsletter-good-morning').style.display = 'block';

                }

                if((regSource === "677" || regSource === "890") && newsletterNMCV === "true"){
                    screensetCfg.context['NL_NMCV'] = [];
                }

                if((regSource === "677" || regSource === "891") && newsletterAnni80 === "true"){
                    screensetCfg.context['NL_ANNI80'] = [];
                }

                gigya.accounts.showScreenSet(screensetCfg);
                $('#registrationPageContainer').show();
            } else if (Utility.getCurrentUri().indexOf("/recupera-password") != -1) {
                let screensetCfg = {
                    screenSet: 'Barilla-RegistrationLogin',
                    startScreen: 'gigya-reset-password-screen',
                    containerID: 'barilla-raas-resetPasswordContainer',
                    context: {
                        noovleRaasGlobalCH: 'CH_MULINOBIANCO',

                    }
                }
                if (regSource) {
                    screensetCfg.context['noovleRaasGlobalRegSource'] = regSource;
                }
                gigya.accounts.showScreenSet(screensetCfg);
                $('#barilla-raas-resetPasswordContainer').show();
            } else {
                let screensetCfg = {
                    screenSet: 'Barilla-RegistrationLogin',
                    startScreen: 'gigya-login-screen-v2',
                    containerID: 'loginPageContainer',
                    context: {
                        noovleRaasGlobalCH: 'CH_MULINOBIANCO',
                        noovleProgressiveProfilingId: 'login-modal'
                    }
                }

                if (!Utility.isMobile()) {
                    screensetCfg.authFlow = 'popup';
                }

                if (regSource) {
                    screensetCfg.context['noovleRaasGlobalRegSource'] = regSource;
                }

                if ((regSource === "677" || regSource === "893")  && (newsletterSubscription === 'Goodmorning_fullweek' || newsletterSubscription === 'Goodmorning_weekend')) {
                    screensetCfg.context['NL_3NAC'] = [newsletterSubscription];


                    document.querySelector('.header-wrapper .left').style.display = 'none';
                    document.querySelector('.header-wrapper .search-icon').style.display = 'none';
                    document.querySelector('.header-wrapper .profile-icon').style.display = 'none';
                    document.getElementById('alert-newsletter-good-morning').style.display = 'block';
                }

                if((regSource === "677" || regSource === "890") && newsletterNMCV === "true"){
                    screensetCfg.context['NL_NMCV'] = [];
                }

                if((regSource === "677" || regSource === "891") && newsletterAnni80 === "true"){
                    screensetCfg.context['NL_ANNI80'] = [];
                }

                gigya.accounts.showScreenSet(screensetCfg);
            }
        });


        window.document.addEventListener("LOGIN_DONE", this.onLoginDone);
    }


    onAfterLoginLoad() {
        $("#goToRegistration").click(() => {
            let regSource = this.getParameterByName("regSource");
            gigya.accounts.hideScreenSet({containerID: "loginPageContainer"})
            let screensetsCfg = {
                screenSet: 'Barilla-RegistrationLogin',
                startScreen: 'gigya-register-screen-v2',
                containerID: 'loginPageContainer',
                context: {
                    noovleRaasGlobalCH: 'CH_MULINOBIANCO',

                }
            };
            if (regSource) {
                screensetsCfg.context['noovleRaasGlobalRegSource'] = regSource;
            }

            const newsletterSubscription = localStorage.getItem("newsletter-goodmorning");
            const newsletterNMCV = localStorage.getItem("newsletter-goodmorning-nmcv");
            const newsletterAnni80 = localStorage.getItem("newsletter-goodmorning-anni-80");
            if (newsletterSubscription === 'Goodmorning_fullweek' || newsletterSubscription === 'Goodmorning_weekend') {
                screensetsCfg.context['NL_3NAC'] = [newsletterSubscription];
                document.getElementById('alert-newsletter-good-morning').style.display = 'block';
            }

            if((regSource === "677" || regSource === "890") && newsletterNMCV === "true"){
                screensetsCfg.context['NL_NMCV'] = [];
            }

            if((regSource === "677" || regSource === "891") && newsletterAnni80 === "true"){
                screensetsCfg.context['NL_ANNI80'] = [];
            }

            gigya.accounts.showScreenSet(screensetsCfg);

        })

        $('#loginPageContainer').show();
    }

    onLoginDone() {
        let urlBack = Utility.getUrlParameter("urlBack");
        if (!urlBack) {
            urlBack = '/home';
            if(localStorage.getItem("newsletter-goodmorning-redirect") && localStorage.getItem("newsletter-goodmorning-redirect") === "false"){
                urlBack = 'newsletter-thank-you';
            }
        }

        localStorage.removeItem("newsletter-goodmorning");
        localStorage.removeItem("newsletter-goodmorning-nmcv");
        localStorage.removeItem("newsletter-goodmorning-anni-80");
        localStorage.removeItem("newsletter-goodmorning-redirect");

        UserManager.init();
        UserManager.getLoggedUser().then(() => {
            if (Utility.getCurrentUri().indexOf("/recupera-password") == -1) {
                window.location.href = decodeURI(urlBack);
            }
        })
    }

    getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
}

export default LoginCtrl;