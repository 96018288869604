import $ from 'jquery';
import Swiper from 'swiper';
import Component from '../model/Component';

class ProductSliderComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.spv = 0;

        $(window).resize(() => {
            this.onResize();
        })
        this.onResize();
    }

    onResize() {
        let newSpv = null;
        if ($(window).width() > 767) {
            newSpv = 4;
        } else {
            newSpv = 2;
        }

        if (newSpv != this.spv) {
            this.spv = newSpv;
            this.destroySwiper();
            this.initSwiper();
        }
    }

    initSwiper() {
        this.swiper = new Swiper(this.$element[0], {
            slidesPerView: this.spv,
            slidesPerGroup: this.spv,
            setWrapperSize: true,
            nextButton: '.arrows .right',
            prevButton: '.arrows .left'
        })
    }

    destroySwiper() {
        if (!!this.swiper) {
            this.swiper.destroy(true, true);
            delete this.swiper;
        }
    }
}

export default ProductSliderComponent;
