"use strict";

const StepHeader = (props) => {
    return `<div class="step-title">
                <button type="button" class="back-button" mb-component-click="back()"><i class="arrow left"></i> Indietro</button>
                <div class="title">${props.title ? props.title : ''}</div>
                <div class="subtitle">${props.subtitle ? props.subtitle : ''}</div>
            </div>`
}
export default StepHeader;
