import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";

class LoginScreensetsCtrl extends Controller {
    constructor(element) {
        super(element);
        GigyaManager.isGigyaSdkReady().then(() => {
            if (Utility.getCurrentUri().indexOf("/registrati") != -1) {
                setUpRegistration();
            } else if (Utility.getCurrentUri().indexOf("/recupera-password") != -1) {
                setUpResetPassword();
            } else {
                setUpLogin();
            }
        });

        window.document.addEventListener("LOGIN_DONE", this.onLoginDone);


    }

    onLoginDone() {
         GigyaManager.getLoggedUser().then((account) =>{
             var ch = account.data['CH_MULINOBIANCO_APP'];
             if(ch == null || ch == '' || typeof ch === 'undefined'){
                 var d = new Date();
                 var m = d.getMonth() + 1;
                 var month = (m + "").length > 1 ? m : "0" + m;
                 var fullstr = d.getFullYear() + "-" + month + "-" + d.getDate();
                 GigyaManager.setAccountInfo({data: {"CH_MULINOBIANCO_APP": fullstr}})
             }

             window.location.href = "inapp://loginSuccess";
        });



    }


}

export default LoginScreensetsCtrl;
