/**
 *
 */

import Controller from '../model/Controller';

class StyleGuideCtrl extends Controller {
  constructor(element) {
    super(element);
    this.init();
  }

  init() {

  }
}
export default StyleGuideCtrl;
