import ProductTemplate from './product.js'
import ProductSimpleTemplate from './productSimple.js'
import PromoTemplate from './promo.js'
import TextTemplate from './text.js'
import CurriculumTemplate from "./curriculum";
import FullContactTemplate from "./fullContact";

export default function Templates() {
    return {
        product: ProductTemplate,
        productSimple: ProductSimpleTemplate,
        promo: PromoTemplate,
        text: TextTemplate,
        curriculum: CurriculumTemplate,
        fullContact: FullContactTemplate
    }
}