/**
 *
 */

import Swiper from 'swiper';
import Controller from '../model/Controller';

class BowlCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.init()
    }

    init() {

        $("[scroll-button-ref]").click(function () {
            let buttonAttr = $(this).attr("scroll-button-ref");
            var top = $("[scroll-target='" + buttonAttr + "']").offset().top;
            $('html, body').animate({
                scrollTop: top
            }, 1000);
        });

        this.swiperContainer = $('#swiper-c');
        this.initSwiperContainer();
        
//        if($('#social_wall .thumbnail.nope')!=null && $('#social_wall .thumbnail.nope')!= undefined && $('#social_wall .thumbnail.nope').length>0){
//	        $(window).scroll(function() {
//				if($(window).scrollTop() + $(window).height() > $(document).height() - $('.bowl-list-container').height() - $('footer').height()) {
//					var thumb = $('#social_wall .thumbnail.nope');
//					$(thumb[0]).removeClass('nope');
//					$(thumb[1]).removeClass('nope');
//					$(thumb[2]).removeClass('nope');
//			   }
//			});
//      }
        
        	$(window).resize(() => {
        		this.socialSwiperManager();
        	});

        	this.socialSwiperManager();
        
    }
    
    socialSwiperManager() {
    		console.log('socialSwiperManager');
        if ($(window).width() > 767) {
            if (this.swiperInitialized) {
                this.destroySocialSwiper()
            }
        } else if (!this.swiperInitialized) {
            this.initSocialSwiper();
        }
    }

    initSocialSwiper() {
    	console.log('initSocialSwiper');
        this.swiperInitialized = true;
        this.swiper = new Swiper('.social-swiper', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            spaceBetween: 30,
            slidesPerView: 1
        })
    }

    destroySocialSwiper() {
    	console.log('destroySocialSwiper');
        this.swiperInitialized = false;
        if (!!this.swiper) {
            this.swiper.destroy(true, true);
            delete this.swiper;
        }
    }

    initSwiperContainer() {
        new Swiper(this.swiperContainer[0], {
            slidesPerView: 6,
            slidesPerGroup: 6,
            // spaceBetween: 20,
            initialSlide: 0,
            nextButton: this.swiperContainer.parent().find('.swiper-button-next')[0],
            prevButton: this.swiperContainer.parent().find('.swiper-button-prev')[0],
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                767: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1023: {
                    slidesPerView: 6,
                    slidesPerGroup: 6
                }
            }
        });
    }

}

export default BowlCtrl;
