/**
 *
 */

import Controller from '../model/Controller';
import {getTracker} from '../business/Tracker';
import $ from 'jquery';
import gsap from "gsap";
import UserManager from "../business/UserManager";

class Result {
    constructor({min = 0, max = 0, value = {}}) {
        this.next = null;
        this.min = min;
        this.max = max;
        this.value = value;
    }

    getValue(points) {
        if (points >= this.min && points <= this.max) {
            return this.write(points);
        }
        if (this.next !== null) {
            return this.next.getValue(points);
        }
    }

    setNext(next) {
        this.next = next;
        return next;
    }

    write(points) {
        return this.value;
    }
}


class QuizCioccogranoCtrl extends Controller {
    constructor(element) {
        super(element);
        this.quizCTR = $(element).find(".js-quiz-cioccograno");
        this.quizWrapper = $(element).find(".js-qc-wrapper");
        this.thankyouPage = $(element).find(".js-quiz-cioccograno-thankyou");
        this.slides = this.quizWrapper.find(".js-slide");
        var _tempThumb = 0;

        this.slides.each((slideIndex, s) => {
            const thumbs = $(s).find(".js-thumb");
            thumbs.each((answerIndex, thumb) => {
                this.handleThumbsClick(slideIndex, _tempThumb, answerIndex + 1, $(thumb), thumbs);
                _tempThumb++;
            });
        });
        this.form = $("#quiz-form");
        this.firstName = $("#firstName");
        this.profiloField = $("#quizProfilo");
        this.risposteField = $("#quizRisposte");
        this.buttons = $(".js-qc-progress-bar .js-btn");
        this.progressBar = $(".js-progress-bar");
        this.buttons.each((i, btn) => {
            this.handleBtnClick(i, btn);
        });
        this.points = [];
        this.thumbsPoints = JSON.parse($("#quiz-cioccograno-points").text());
        this.resultsChain = this.getChainOfResults(JSON.parse($("#quiz-cioccograno-results").text()));
        this.tracker = getTracker({trackId: "CIOCCOGRANO_LANDING"});
    }

    getChainOfResults(_results) {

        var results = _results.map(r => {
            let result = new Result(r);
            return result;
        });
        results.slice(1).reduce((prev, curr) => {
            return prev.setNext(curr);
        }, results[0]);

        return results[0];
    }

    handleBtnClick(slideIndex, button) {
        button = $(button);
        button.on("click", _ => {
            if (button.hasClass("completed") || button.hasClass("selected")) {
                this.updatePoints(slideIndex);
                this.updateSlideAndProgressBar(slideIndex);

            }

        })
    }

    updatePoints(slideIndex) {
        this.points = this.points.slice(0, slideIndex);
    }

    handleThumbsClick(slideIndex, thumbIndex, answerIndex, thumb, thumbs) {
        thumb.on("click", _ => {
            let isLastSlide = slideIndex == this.slides.length - 1;
            thumbs.removeClass("selected");
            thumb.addClass("selected");
            this.updateSlideAndProgressBar(slideIndex + 1, isLastSlide);
            this.addPoints(slideIndex, thumbIndex, answerIndex);
            if (isLastSlide) {
                this.getResult();
            }
        });
    }

    updateSlideAndProgressBar(slideIndex, isLastSlide = false) {
        let nextSlide = Math.min(slideIndex, this.slides.length - 1);
        nextSlide = Math.max(0, nextSlide);

        if (!isLastSlide) {
            let title = $(this.slides[Math.max(0, slideIndex - 1)]).find(".js-title");
            let ntitle = $(this.slides[nextSlide]).find(".js-title");
            gsap.to(title, {y: -100, alpha: 0})
            gsap.set(ntitle, {y: -100, alpha: 0})
            gsap.to(ntitle, {delay: 0.5, y: 0, alpha: 1})
            gsap.to(this.quizWrapper, {delay: 0.2, x: -nextSlide * this.slides.first().width()})
        }

        this.buttons.each((i, btn) => {
            btn = $(btn);
            btn.removeClass("selected completed");
            if (i < nextSlide) {
                btn.addClass("completed")
            } else if (i == nextSlide) {
                btn.addClass("selected");

            }

        });
        if (isLastSlide) {
            let btn = this.buttons.last();
            btn.addClass("completed");
        }
        let width = `${Math.round((nextSlide / (this.slides.length - 1)) * 100)}%`

        this.progressBar.css({width})

    }

    addPoints(slideIndex, thumbIndex, answer) {
        this.points[slideIndex] = {
            answer
            , points: this.thumbsPoints[thumbIndex]
        };
        console.log("savePoints", this.points);
    }

    getResult() {
        const points = this.points.reduce((total, {points}) => total + points, 0);
        const risposte = this.points.map(({points, answer}, question) => ({answer, question: question + 1}));
        const result = this.resultsChain.getValue(points)
        console.log("risposte:", risposte);
        console.log("result:", result);
        console.log("points:", points);
        // const downloadBtn = this.thankyouPage.find(".js-download");
        // this.quizCTR.addClass("quiz-hidden");
        // this.thankyouPage.addClass("display-thankyou");
        // downloadBtn.attr("href", result.pdf);
        // this.thankyouPage.find(".js-points").html(points);
        // this.thankyouPage.find(".js-label").html(result.label);
        // this.thankyouPage.find(".js-text").html(result.text);
        // this.thankyouPage.find(".js-image").attr("src", result.image);
        // downloadBtn[0].click();
        //
        this.profiloField.val(result.code);
        this.risposteField.val(JSON.stringify(risposte));
        UserManager.getLoggedUser()
            .then(({profile}) => {

                this.firstName.val(profile.firstName);
                //  this.form.submit();
                return this.track({risposte, result, points});
                // this.thankyouPage.find(".js-username").html(profile.firstName);
            })
            .catch(_ => {
                return this.track({risposte, result, points});
                //this.thankyouPage.find(".js-username").html("");
            })
            .then(_ => {
                this.form.submit();
            })

//TODO tracciare

    }

    track({risposte, result, points}) {
        return this.tracker.send("EVENT", {
            interactionType: "ZPARTICIPATION",
            quiz: risposte
            , profile: result.code
            , points
        });
    }
}

export default QuizCioccogranoCtrl;
