/**
 *
 */
import Controller from '../model/Controller'
import Swiper from "swiper";
import $ from "jquery";
import Tracker from "../business/Tracker";
import Utilility from "../business/Utility";
import Constants from "../business/Constants";
import Utility from "../business/Utility";
import GoogleAnalytics from "../business/GoogleAnalytics";


class ProductDetailCtrl extends Controller {
    constructor(element) {
        super(element);

        this.init();


    }

    init() {
        this.swiperInitialized = false;
        $(window).resize(() => {
            this.socialSwiperManager();
        });

        this.socialSwiperManager();

        if (Utilility.getUrlParameter("yxpt-from") == "pf") {
            Tracker.send("EVENT", {
                interactionType: "ZWEBSITE_VISIT_FROMPRODUCTFINDER",
                productCode: $('#mb-product-externalid').text()
            });
        } else if (Utilility.getUrlParameter("yxpt-from") == "search") {
            Tracker.send("EVENT", {
                interactionType: "ZWEBSITE_VISIT_FROMSEARCH",
                productCode: $('#mb-product-externalid').text()
            });
        } else {
            Tracker.send("EVENT", {interactionType: "WEBSITE_VISIT", productCode: $('#mb-product-externalid').text()});
        }

        if (Utilility.getUrlParameter("yxpt-from-promo")) {
            Tracker.send("EVENT", {
                interactionType: "WEBSITE_VISIT_FROM_PROMO",
                promoCode: Utilility.getUrlParameter("yxpt-from-promo")
            });
        }

        this.initPackagingSwiper();
        this.initDedicatoATeSlider();
        this.initNutritionalInfo();
    }

    initPackagingSwiper() {
        this.packagingSwiper = new Swiper('.packaging-swiper', {
            loop: true,
            autoplay: 5000,
            spaceBetween: 30,
            slidesPerView: 1,
            nextButton: $('.packaging-swiper .packaging-swiper-button-next')[0],
            prevButton: $('.packaging-swiper .packaging-swiper-button-prev')[0]
        })
    }

    initDedicatoATeSlider() {
        this.dedicatoATeSwiper = new Swiper('.dedicatoATeSwiper', {
            loop: true,
            slidesPerView: 1,
            nextButton: '.dat-swiper-button-next',
            prevButton: '.dat-swiper-button-prev',
            onSlideChangeEnd: () => {
                let id = $('.dedicatoATeSwiper .swiper-slide-active').data("id");
                $('.dedicato-a-te-box .image-cnt img').removeClass("active");
                $('.dedicato-a-te-box .image-cnt img[data-id="' + id + '"]').addClass("active");
            }
        })
    }

    /**
     * Init swiper nutritional info
     */
    initNutritionalInfo() {
        this.packagingSwiper = new Swiper('.swiper-nutritional-info', {
            loop: false,
            autoplay: 5000,
            spaceBetween: 30,
            slidesPerView: 1,
            nextButton: $('.swiper-nutritional-info-arrow__next')[0],
            prevButton: $('.swiper-nutritional-info-arrow__prev')[0]
        })
    }

    showShareModal() {
        document.getElementById('shareModal').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
    }


    onShareClick(shareButton) {
        Tracker.send('EVENT', {interactionType: 'PROD_SHARED', productCode: $('#mb-product-externalid').text()});
        const urlPage = window.location.href;
        const titlePage = document.title;
        shareButton = shareButton.replaceAll("'", '');
        let urlSocial = Utility.getShareLink(shareButton, titlePage, urlPage);
        if(urlSocial) window.open(urlSocial, '_blank');

        const obj = {
            'data-name-product': $('#mb-product-name').text(),
            'data-name-method': shareButton,
            'data-content-type': 'product'
        }
        GoogleAnalytics.send('social_share', obj);
    }

    showNutritionalTable() {
        $('#nutritionalValues').show();
        $('body').addClass("no-scroll");
    }


    hideNutritionalTable() {
        $('#nutritionalValues').hide();
        $('body').removeClass("no-scroll");
    }

    showComparationTable() {
        $('#comparationTable').show();
        $('body').addClass("no-scroll");
    }


    hideComparationTable() {
        $('#comparationTable').hide();
        $('body').removeClass("no-scroll");
    }

    socialSwiperManager() {
        if ($(window).width() > 767) {
            if (this.swiperInitialized) {
                this.destroySocialSwiper()
            }
        } else if (!this.swiperInitialized) {
            this.initSocialSwiper();
        }
    }

    initSocialSwiper() {
        this.swiperInitialized = true;
        this.swiper = new Swiper('.social-swiper', {
            pagination: '.swiper-pagination',
            paginationClickable: true,
            spaceBetween: 30,
            slidesPerView: 4,
            breakpoints: {
                640: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                767: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                1023: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                }
            }
        })
    }

    destroySocialSwiper() {
        this.swiperInitialized = false;
        if (!!this.swiper) {
            this.swiper.destroy(true, true);
            delete this.swiper;
        }
    }
}

export default ProductDetailCtrl;
