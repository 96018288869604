import Component from '../../model/Component';
import Pristine from "pristinejs/dist/pristine";
import HttpClient from "../../business/HttpClient";
import {Utils} from "./Utils";
import $ from "jquery";

class SandwichStepFormComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);
        this.store = store;
        this.breadModal = document.getElementById('bread-modal');
        this.ingredientModal = document.getElementById('ingredient-modal');
        this.payload = {};
        this.init();
    }

    goToNextStep() {
        this.store.dispatch({
            ...this.store.getState(),
            type: 'unMorsoDiFelicitaForm/goToNextStep',
            payload: this.payload
        });
    }

    trimRequired() {
        Pristine.addValidator('required', (value) => (value.trim() !== ''), "", 5, false);
    }

    addBackListener() {
        this.element.querySelector('.data-step-form__back').addEventListener('click', () => {
            this.store.dispatch({...this.store.getState(), type: 'unMorsoDiFelicitaForm/back'});
        });
    }

    saveFormData() {
        let formData = new FormData(this.form);

        this.payload.sandwichImage = this.imageUrl;
        this.payload.sandwichImageId = this.imageId;
        const bread = this.getSelectedBread();
        const ingredients = JSON.parse(formData.get("ingredients"));
        let allIngredients = [bread, ...ingredients];
        this.payload.sandwichName = formData.get("sandwichName");
        this.payload.ingredients = allIngredients;
        this.goToNextStep();
    }


    initForm() {
        this.form = document.forms["sandwichStepForm"];

        let formConfig = {
            classTo: 'data-step-form__input',
            errorClass: 'error',
            successClass: 'has-success',
            errorTextParent: 'data-step-form__input',
            errorTextTag: 'div',
            errorTextClass: 'error-field'
        };

        this.trimRequired();
        this.pristine = new Pristine(this.form, formConfig);
        this.addInputFileListener();
        Utils.addForbiddenWordValidators('sandwichName', this.pristine);


        this.form.addEventListener("submit", (event) => {
            event.preventDefault();
            let valid = this.pristine.validate();
            valid ? this.saveFormData() : Utils.scrollToError(this.element);
        });
    }

    addInputFileListener() {
        const fileInput = this.form.querySelector('input[type=file]');
        fileInput.onchange = () => {
            const [file] = fileInput.files;
            if (!file) return;

            $('#overlayer').show();
            this.element.querySelector('.umdf-file__wrapper .file-error-field').innerHTML = '';
            Utils.getBase64(file).then((base64) => {
                this.validateFile(base64, fileInput);
            });
        }
    }

    validateFile(base64, fileInput) {
        Utils.uploadAndValidateFile('sandwich', base64).then(
            (resp) => {
                resp.isValid ? this.appendFile(resp, fileInput) : this.appendError(fileInput);
                $('#overlayer').hide();
            },
            (error) => console.log(error)
        )
    }

    appendError(fileInput) {
        const previewWrapper = this.element.querySelector('.umdf-file__preview.photo');
        this.resetInputFile(fileInput, previewWrapper);
        this.element.querySelector('.umdf-file__wrapper .file-error-field').innerHTML =
            'Il file non rispetta la <a href="/promozioni/dedica-un-morso-di-felicita/netiquette" target="_blank">netiquette</a>, carica una nuova foto';
    }

    appendFile(data, fileInput) {
        const previewWrapper = this.element.querySelector('.umdf-file__preview.photo');
        this.imageUrl = data.imageMediaUrl;
        this.imageId = data.imageId;

        previewWrapper.innerHTML = `
            <div class="data-step-form__file">
                <img class="data-step-form__close" alt="Close" src="/fe-web/img/icons/remove-circle.svg">
                <img class="data-step-form__file-image" alt="preview" src="${this.imageUrl}">
            </div>
        `;

        previewWrapper.querySelector('.data-step-form__close').addEventListener('click', () => {
            this.resetInputFile(fileInput, previewWrapper);
        });
    }

    resetInputFile(fileInput, previewWrapper) {
        fileInput.value = null;
        this.imageId = null;
        this.imageUrl = null;
        previewWrapper.innerHTML = '';
        this.element.querySelector('.umdf-file__wrapper .file-error-field').innerHTML = '';
    }

    getIngredients() {
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'GET',
                url: API_ENDPOINT + `/api/mulinobianco/1.0/web/mdf/ingredients-config`,
                dataType: "json",
                contentType: "application/json; charset=utf-8"
            }).then((data) => {
                this.breads = data.body.find((el) => el.categoryCode === 'BREAD').ingredients;
                this.other = data.body.find((el) => el.categoryCode === 'OTHER').ingredients[0];
                this.ingredients = data.body.filter((el) => el.categoryCode !== 'BREAD' && el.categoryCode !== 'OTHER');
                this.appendBreads();
                this.appendIngredients();
                this.addButtonListener();
                resolve();
            }, (error) => reject(error));
        });
    }


    appendBreads() {
        let breadHtml = '';
        this.breads.forEach((bread) => {
            breadHtml += `
                <div class="bread-modal-card" data-id="${bread.id}">
                    <div class="bread-modal-card__image">
                        <img alt="${bread.name}" src="${bread.nudeImagePath}">
                    </div>
                    <h4 class="umdf-small-text">${bread.name}</h4>
                </div>
            `;
        });
        this.breadModal.querySelector('.bread-modal-card-wrapper').insertAdjacentHTML('beforeend', breadHtml);
        this.addBreadListener();
    }


    addBreadListener() {
        const cards = this.breadModal.querySelectorAll('.bread-modal-card');
        const confirmButton = this.breadModal.querySelector('.umdf-modal__button .button');
        cards.forEach((card) => {
            card.addEventListener('click', (e) => {
                document.getElementById("bread").value = card.dataset.id;
                const selectedCard = this.breadModal.querySelector('.bread-modal-card.selected');
                selectedCard && selectedCard.classList.remove('selected');
                confirmButton.removeAttribute('disabled');
                card.classList.add('selected');
            });
        })

        confirmButton.addEventListener('click', () => {
            this.appendSelectedBread();
            Utils.closeModal();
        });
    }

    getSelectedBread() {
        const breadId = document.getElementById("bread").value;
        return this.breads.find((el) => el.id === parseInt(breadId));
    }

    appendSelectedBread() {
        const previewWrapper = this.element.querySelector('.umdf-file__preview.bread');
        const bread = this.getSelectedBread();
        previewWrapper.innerHTML = `
            <div class="bread-modal-card">
                <img class="bread-modal-card__close" alt="Close" src="/fe-web/img/icons/remove-circle.svg">
                <div class="bread-modal-card__image">
                    <img alt="${bread.name}" src="${bread.nudeImagePath}">
                </div>
                <h4 class="umdf-small-text">${bread.name}</h4>
            </div>
        `;

        previewWrapper.querySelector('.bread-modal-card__close').addEventListener('click', () => {
            document.getElementById("bread").value = null;
            previewWrapper.innerHTML = '';
            this.breadModal.querySelector('.bread-modal-card.selected').classList.remove('selected');
            this.breadModal.querySelector('.umdf-modal__button .button').setAttribute('disabled', 'true');
        });
    }

    appendIngredients() {
        let ingredientHtml = '';
        this.ingredients.forEach((category) => {
            ingredientHtml += `
                <div class="ingredient-modal-card">
                    <div class="ingredient-modal-card__category">${category.categoryName}</div>
                    <div class="ingredient-modal-card__list">
                        ${this.getIngredientsList(category.ingredients, category.id)}
                    </div>
                </div>
            `;
        });

        ingredientHtml += `
        <div class="ingredient-modal-card">
            <div class="ingredient-modal-card__category">${this.other.name}</div>
            <div class="ingredient-modal-card__other">
                <input name="otherText" type="text" placeholder="Inserisci ingrediente"/>
                <input id="checkOther" name="OTHER" value="${this.other.id}" data-image-path="${this.other.videoImagePath}" type="checkbox">        
            </div>
        </div>`;
        this.ingredientModal.querySelector('.ingredient-modal-card-wrapper').insertAdjacentHTML('beforeend', ingredientHtml);
        this.addIngredientsListener();
        this.addOtherListener();
    }


    getIngredientsList(ingredients, categoryId) {
        let html = '';
        ingredients.forEach((element) => {
            html += `
              <div class="ingredient-modal-card__checkbox">
                   <label class="umdf-text data-step-form-checkbox"> 
                        ${element.name} 
                        <input name="${categoryId}" value="${element.id}" type="checkbox">
                        <div class="input-square"></div>
                   </label>
              </div>`;
        });
        return html;
    }

    addIngredientsListener() {
        const labelError = this.ingredientModal.querySelector('.error-field');
        const labelErrorForbidden = this.ingredientModal.querySelector('.error-field-forbidden');
        const confirmButton = this.ingredientModal.querySelector('.umdf-modal__button .button');
        confirmButton.addEventListener('click', () => {
            labelError.classList.add('hidden');
            labelErrorForbidden.classList.add('hidden');
            const selectedCheckboxes = this.ingredientModal.querySelectorAll('input[type=checkbox]:checked');
            if (selectedCheckboxes.length > 4 || selectedCheckboxes.length < 1) {
                labelError.classList.remove('hidden');
                return;
            }

            const otherValue = this.ingredientModal.querySelector('input[name="otherText"]').value;
            if(!Utils.forbiddenWordsValidation(otherValue)) {
                labelErrorForbidden.classList.remove('hidden');
                return;
            }

            this.appendSelectedIngredients(selectedCheckboxes);
            Utils.closeModal();
        });
    }


    addOtherListener() {
        const checkboxInput = this.ingredientModal.querySelector('#checkOther');
        const otherInput = this.ingredientModal.querySelector('input[name="otherText"]');
        otherInput.addEventListener('focusout', () => {
            checkboxInput.checked = (otherInput.value && otherInput.value !== '');
        });
    }

    appendSelectedIngredients(selectedCheckboxes) {
        let selectedIngredient = [];
        let ingredientsHtml = '';
        selectedCheckboxes.forEach(el => {
            const ingredientId = parseInt(el.value);
            if (el.name === 'OTHER') {
                const name = this.ingredientModal.querySelector('input[name="otherText"]').value;
                ingredientsHtml += `<div class="data-step-form__tag">${name}</div>`;
                selectedIngredient.push({id: ingredientId, name: name, videoImagePath: el.dataset.imagePath});
            } else {
                const categoryId = parseInt(el.name);
                const ingredients = this.ingredients.find((category) => category.id === categoryId).ingredients;
                const ingredientObj = ingredients.find((ing) => ing.id === ingredientId);
                ingredientsHtml += `<div class="data-step-form__tag">${ingredientObj.name}</div>`;
                selectedIngredient.push(ingredientObj);
            }
        });

        document.getElementById("ingredients").value = JSON.stringify(selectedIngredient);
        this.element.querySelector('.umdf-file__preview.ingredients').innerHTML = ingredientsHtml;
    }

    addButtonListener() {
        this.element.querySelector('.data-step-form__add-bread').addEventListener('click', () => {
            Utils.openModal(this.breadModal);
        });
        this.element.querySelector('.data-step-form__add-ingredients').addEventListener('click', () => {
            Utils.openModal(this.ingredientModal);
        });
    }


    init() {
        this.addBackListener();
        this.getIngredients();
        this.initForm();
    }
}


export default SandwichStepFormComponent;
