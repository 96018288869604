var template = (recipe) => `<div class="recipe-block">
                    <a href="${recipe.seoUrl}" style="font-size: 0;">
                        <img src="${recipe.visualImage}" style="min-width: 233px"/>
                    </a>
                    <div class="text-content">
                        <div class="product-name">${recipe.product ? recipe.product.name : ''}</div>
                            <a href="${recipe.seoUrl}">
                                <h4 class="recipe-title">${recipe.name}</h4>
                            </a>
                        <div class="preparation">
                            <div class="time">
                                <svg class="blue-arrow">
                                    <use xlink:href="#svg_recipe_block_time"/>
                                </svg>
                                <span>${recipe.totalTime}'</span>
                            </div>
                            <div class="difficulty">
                                <svg class="blue-arrow">
                                    <use xlink:href="#svg_recipe_block_difficulty"/>
                                </svg>
                               ${recipe.difficultyLevel == 1 ? `<span class="desc">facile</span>` : ``}
                               
                               ${recipe.difficultyLevel == 2 ? `
                                <svg class="icon">
                                    <use xlink:href="symbol-defs.svg#svg_recipe_block_difficulty"/>
                                </svg>
                                <span class="desc">media</span>` : ``}
                               
                               ${recipe.difficultyLevel == 3 ? ` 
                                <svg class="icon">
                                    <use xlink:href="symbol-defs.svg#svg_recipe_block_difficulty"/>
                                </svg>
                                <svg class="icon">
                                    <use xlink:href="symbol-defs.svg#svg_recipe_block_difficulty"/>
                                </svg>
                                <span class="desc">difficile</span>` : ``}
                        
                            </div>
                        </div>
                        <div class="button-container">
                            <a href="${recipe.seoUrl}" class="rounded-button">${recipe.ctaText ? recipe.ctaText : 'Scopri la ricetta'}</a>
                        </div>
                    </div>
            </div>`;

export default template;
