import HttpClient from "./HttpClient";

class GigyaManager {

    constructor() {
        this.gigyaSdkReadyPromise = new Promise((resolve, reject) => {
            if (!window.gigya) {
                if (!window.checkInterval) {
                    window.checkInterval = setInterval(function () {
                        if (window.gigya) {
                            clearInterval(window.checkInterval);
                            resolve();
                        }
                    }, 500);
                }
            } else {
                return resolve();
            }
        });
    }

    getLoggedUser() {
        return new Promise((resolve, reject) => {
            this.isGigyaSdkReady().then(() => {
                window.gigya.accounts.getAccountInfo({
                    callback: (data) => {
                        if (data.status == "OK") {
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    },
                    include: "profile,data,preferences,subscriptions,emails"
                });
            })
        });

    }


    isGigyaSdkReady() {
        return this.gigyaSdkReadyPromise;
    }


    setAccountInfo(params) {
        this.isGigyaSdkReady().then(() => {
            window.gigya.accounts.setAccountInfo(params);
        })
    }


    liteRegistration(data) {
        return HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/member/liteRegistration',
            type: "POST",
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(data)
        });
    }

    addCH(chName){
        return new Promise((res, err)=>{
            var d = new Date();
            var m = d.getMonth() + 1;
            var month = (m + "").length > 1 ? m : "0" + m;
            var fullstr = d.getFullYear() + "-" + month + "-" + d.getDate();
            var data = {};
            data[chName] = fullstr;
            this.setAccountInfo({data:data, callback: (d) => {
                if(d.status == "OK"){
                    res(true);
                }else{
                    err(false);
                }
            }});
        });
    }

    logout() {
        return new Promise((resolve, reject) => {
            this.isGigyaSdkReady().then(() => {
                window.gigya.accounts.logout({
                    callback: (data) => {
                        resolve();
                    }
                });
            })
        });
    }
}

export default new GigyaManager();
