// vendor
import Swiper from "swiper";
import $ from "jquery";

// k+
import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import HttpClient from "../business/HttpClient";
import Utility from "../business/Utility";
import Pristine from "pristinejs";

class VideoLectureCtrl extends Controller {
    constructor(element) {
        super(element);
        this.percentageUnderMin = 30;
        this.percentageUnderTwoMins = 20;
        this.percentageOverTwoMins = 10;
        this.iframe = '';
        this.player = '';
        this.idVideo = '';
        this.$element = $(element);
        this.idCourse = this.$element.find('.videolecture').data('course-id');
        this.idLecture = this.$element.find('.videolecture').data('lecture-id');
        this.complete = this.$element.find('.videolecture').data('complete');
        this.modal = document.querySelectorAll('.modal-videolecture');
        this.closeBtn = document.querySelectorAll('.modal-videolecture__close-modal');
        this.videoLectureArmonieCH = 'CH_988_MLB_WEB_ARMONIAQ12024_NAV_NAV_20240104_20240104';
        this.pristine;
        document.addEventListener('MB_VIDEO_LECTURE_LOGIN_DONE', () => this.init());
        this.init();
    }

    init() {
        GigyaManager.getLoggedUser().then((user) => {
            $('#videolecture-login').hide();
            $('#videolecture-loading-video').show();
            $('.videolecture').removeClass('not-logged');

            // check if user has CH in his account
            const ch = user.data[this.videoLectureArmonieCH];
            if (typeof ch !== "undefined" && ch != null && ch !== "") {
                this.showLoggedArea();
            }else{
                this.initForm();
                this.handleForm();
                setTimeout(() => $('#modal').show(), 500);
            }
        }, () => {
            $('#videolecture-loading-video').hide();
            $('#videolecture-login').show();
            $('.videolecture').addClass('not-logged');
        });

        this.closeModal();
    }

    /**
     * Wrapper video events, on timeupdate and ended
     */
    videoEvents() {
        let currentPos, percentage, vdoEndTym = "";
        var debounce = true;

        var percentageRatio = '';
        let idVideo = this.idVideo;
        this.debounce(
            this.player.on('timeupdate', (getAll) => {
                currentPos = getAll.seconds; //get currentime
                vdoEndTym = getAll.duration; //get video duration
                percentage = (getAll.percent * 100);


                if (vdoEndTym <= 60) {
                    percentageRatio = this.percentageUnderMin;
                } else if (vdoEndTym > 60 && vdoEndTym <= 120) {
                    percentageRatio = this.percentageUnderTwoMins;
                } else {
                    percentageRatio = this.percentageOverTwoMins;
                }

                if (Math.floor(percentage) !== 0 && Math.floor(percentage) % percentageRatio === 0 && debounce === true) {
                    debounce = false;
                    let videoLecture = JSON.parse(localStorage.getItem('video-lecture')) || [];
                    if (videoLecture.length > 0) {
                        let singleNodeItemVideo = videoLecture.findIndex(item => item.id === this.idLecture);
                        if (singleNodeItemVideo > -1) {
                            delete videoLecture[singleNodeItemVideo];
                        }
                    }

                    let objItemVideo = {
                        id: this.idLecture,
                        currentTime: currentPos
                    };
                    videoLecture.push(objItemVideo);

                    // remove empty key
                    const cleanVideoLecture = videoLecture.filter(n => n);
                    localStorage.setItem('video-lecture', JSON.stringify(cleanVideoLecture));

                } else {
                    setTimeout(function () {
                        debounce = true;
                    }, 1000);
                }
            })
            , 10000);


        this.debounce(
            this.player.on('ended', (data) => {
                const seconds = data.seconds;


                let videoLecture = JSON.parse(localStorage.getItem('video-lecture')) || [];
                if (videoLecture.length > 0) {
                    let singleNodeItemVideo = videoLecture.findIndex(item => item.id === this.idLecture);
                    if (singleNodeItemVideo > -1) {
                        delete videoLecture[singleNodeItemVideo];
                    }
                }

                let objItemVideo = {
                    id: this.idLecture,
                    currentTime: seconds
                };
                videoLecture.push(objItemVideo);

                this.setVideoWatchedById().then((resp) => {
                    const videolectureViewsCount = resp.body.videolectureViewsCount;
                    const videolecturesCount = resp.body.videolecturesCount;

                    if (videolectureViewsCount === videolecturesCount) {
                        $("#modal-videolecture-progress").addClass('hidden');
                        $("#modal-videolecture-finish").removeClass('hidden');
                        $('body').addClass('no-scroll');
                    } else {
                        $("#video-lecture-views-count").text(videolectureViewsCount);
                        const percentageWidth = (videolectureViewsCount / videolecturesCount) * 100;

                        $('#progress-bar').css('width', percentageWidth + '%');
                        $("#modal-videolecture-finish").addClass('hidden');
                        $("#modal-videolecture-progress").removeClass('hidden');
                        $('body').addClass('no-scroll');

                    }
                }).catch((e) => {
                    console.log(e);
                });

            })
            , 10000);
    }


    /**
     * Load video in specific seconds
     * @param idLecture {Number}
     */
    setCurrentTimeVideo(idLecture) {
        // check if this idVideo is saved into localstorage
        let videoLectureById = JSON.parse(localStorage.getItem('video-lecture')) || [];
        let currentTime = 0;
        if (videoLectureById.length > 0) {
            let singleNodeItemVideo = videoLectureById.findIndex(item => item.id === idLecture);
            if (singleNodeItemVideo > -1) {
                currentTime = videoLectureById[singleNodeItemVideo].currentTime;
            }
        }

        this.player.setCurrentTime(currentTime).then((seconds) => {
            // seconds = the actual time that the player seeked to
        }).catch(function (error) {
            switch (error.name) {
                case 'RangeError':
                    // the time was less than 0 or greater than the video’s duration
                    break;

                default:
                    // some other error occurred
                    break;
            }
        });
    }

    /**
     * Generate carousel of related videos
     */
    relatedVideos() {
        new Swiper($(this.element).find('#videolecture-related-videos'), {
            slidesPerView: 3,
            spaceBetween: 8,
            nextButton: $(this.element).find('.videolecture-button-next'),
            prevButton: $(this.element).find('.videolecture-button-prev'),
            setWrapperSize: true,
            breakpoints: {
                480: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 8
                },
                768: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 8
                }
            },
        });
    }


    /**
     *
     * @returns {Promise<unknown>}
     */
    setVideoWatchedById() {
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'PUT',
                url: API_ENDPOINT + '/api/mulinobianco/1.0/web/videocourse/' + this.idCourse + '/videolecture-view/' + this.idLecture,
                dataType: "json"
            }, true).then(
                (data) => {
                    resolve(data);
                }, () => reject())
        });
    }

    /**
     * Use this method for clearTimeout and setTimeout
     * @param func
     * @param delay
     * @returns {(function(): void)|*}
     */
    debounce(func, delay) {
        let timeout;
        return function () {
            clearTimeout(timeout);
            timeout = setTimeout(func, delay);
        };
    }

    closeModal() {
        this.closeBtn.forEach((el) => el.addEventListener('click', () => {
            console.log('hello')
            el.closest('.modal-videolecture').classList.add('hidden')
            $('body').removeClass('no-scroll');
        }))
    }


    initForm() {
        let form = document.getElementById("accept-rule");

        let formConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'generic-modal__radio',
            errorClass: 'error',
            successClass: 'has-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'generic-modal__radio',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'error-field'
        };

        this.trimRequired();

        // init form validator
        this.pristine = new Pristine(form, formConfig);
    }

    /**
     * Trim all required fields
     */
    trimRequired() {
        Pristine.addValidator('required', (value) => {
            return (value.trim() !== '');
        }, "", 5, false);
    }

    handleForm(){
        $('#accept-rule').submit((e) => {
            e.preventDefault();
            let valid = this.pristine.validate();

            if(valid){
                if($('#acceptance').length){
                    GigyaManager.addCH(this.videoLectureArmonieCH).then(() => {
                        $('#modal').hide();
                        this.showLoggedArea();
                    }, () => {

                    });
                }
            }
        })
    }

    showLoggedArea(){
        let iframe = document.querySelector('iframe');
        this.player = new Vimeo.Player(iframe);

        // initialize events
        this.videoEvents();

        // initialize video in specific time
        this.setCurrentTimeVideo(this.idLecture);

        // initialize related videos
        this.relatedVideos();
    }
}

export default VideoLectureCtrl;
