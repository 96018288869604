// dependencies
import $ from 'jquery';
import L from 'leaflet';

// K+ component
import Component from '../model/Component';

class QrCodeComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        this.init();
    }

    init() {
        var map = L.map('map', {
            minZoom: 2,
            maxZoom: 4,
            zoomDelta: 1,
            maxBoundsViscosity: 0.5,
            tap: false
        });

        let videoIcon = L.icon({
            iconUrl: '/fe-web/img/interactive-map/marker/video-yt.png',
            iconSize: [75, 91], // size of the icon
            iconAnchor: [37, 45], // point of the icon which will correspond to marker's location
            popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
        });

        let bookIcon = L.icon({
            iconUrl: '/fe-web/img/interactive-map/marker/book.png',
            iconSize: [75, 91], // size of the icon
            iconAnchor: [37, 45], // point of the icon which will correspond to marker's location
            popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
        });

        let mapIcon = L.icon({
            iconUrl: '/fe-web/img/interactive-map/marker/map.png',
            iconSize: [75, 91], // size of the icon
            iconAnchor: [37, 45], // point of the icon which will correspond to marker's location
            popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
        });

        let giocoApiIcon = L.icon({
            iconUrl: '/fe-web/img/interactive-map/marker/gioco-delle-api.png',
            iconSize: [75, 91], // size of the icon
            iconAnchor: [37, 45], // point of the icon which will correspond to marker's location
            popupAnchor: [0, 0] // point from which the popup should open relative to the iconAnchor
        });

        let markerVideoGrain = L.marker([-57.040729838360875, 2.2851562500000004],
            {icon: videoIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 8 - Capitolo Il Cantiere</span></div><div class="mb-leaflet-popup__content"><img src="/fe-web/img/interactive-map/preview-video/video-grain.png" alt="Mulino Bianco | La Carta del Mulino | Il Cantiere" mb-component-click="openModal(\'oJOOCy2ihp4\');"></div></div>', {
                maxWidth: "auto"
            });

        let markerVideoSlug = L.marker([-12.211180191503997, -50.44921875000001],
            {icon: videoIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 2 - Capitolo La Traversata</span></div><div class="mb-leaflet-popup__content"><img src="/fe-web/img/interactive-map/preview-video/video-slug.png" alt="Mulino Bianco | La Carta del Mulino | La Traversata"  mb-component-click="openModal(\'_FAhCFpeHtI\');"></div></div>', {
                maxWidth: "auto"
            });

        let markerVideoBee = L.marker([-15.623036831528252, 19.511718750000004],
            {icon: videoIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 3 - Capitolo Lo Starnuto</span></div><div class="mb-leaflet-popup__content"><img src="/fe-web/img/interactive-map/preview-video/video-bee.png" alt="Mulino Bianco | La Carta del Mulino | Lo Starnuto" mb-component-click="openModal(\'bw3HdtOG7fE\');"></div></div>', {
                maxWidth: "auto"
            });


        let markerBook1 = L.marker([2.8113711933311403, -105.82031250000001],
            {icon: bookIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 1</span><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular mb-leaflet-popup__title--blue">Certificazione ISCC Plus</span><div class="mb-leaflet-popup__description">Gli aderenti alla "Carta del Mulino" devono rispettare i requisiti della certificazione di sostenibilità ISCC PLUS</div><a href="/lacartadelmulino?scroll-to=regola1" class="mb-leaflet-popup__cta-link">Scopri di più</a></div></div>', {
                maxWidth: "auto"
            });

        let markerBook2 = L.marker([-52.908902047770255, -105.64453125000001],
            {icon: bookIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 4</span><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular mb-leaflet-popup__title--blue">Scelta varietà e Semente certificata</span><div class="mb-leaflet-popup__description">Le aziende agricole devono impiegare esclusivamente semente certificata e varietà indicate da Barilla</div><a href="/lacartadelmulino?scroll-to=regola4" class="mb-leaflet-popup__cta-link">Scopri di più</a></div></div>', {
                maxWidth: "auto"
            });

        let markerBook3 = L.marker([4.915832801313164, 71.19140625000001],
            {icon: bookIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 5</span><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular mb-leaflet-popup__title--blue">Gestione Digitale Barilla Farming</span><div class="mb-leaflet-popup__description">Le aziende agricole devono utilizzare la piattaforma digitale “Barilla Farming” al fine di garantire il calcolo degli impatti ambientali in termini di emissioni CO₂.</div><a href="/lacartadelmulino?scroll-to=regola5" class="mb-leaflet-popup__cta-link">Scopri di più</a></div></div>', {
                maxWidth: "auto"
            });

        let markerBook4 = L.marker([-49.38237278700955, 84.19921875000001],
            {icon: bookIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 6</span><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular mb-leaflet-popup__title--blue">Divieto utilizzo fanghi</span><div class="mb-leaflet-popup__description">Le aziende agricole, in riferimento alla parcella "Carta del Mulino", non devono utilizzare fanghi di depurazione</div><a href="/lacartadelmulino?scroll-to=regola6" class="mb-leaflet-popup__cta-link">Scopri di più</a></div></div>', {
                maxWidth: "auto"
            });

        let markerBook5 = L.marker([44.59046718130883, 14.941406250000002],
            {icon: bookIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 7</span><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular mb-leaflet-popup__title--blue">Divieto glifosate</span><div class="mb-leaflet-popup__description">Le aziende agricole non devono utilizzare glifosate nella parcella di grano tenero dalla presemina al raccolto</div><a href="/lacartadelmulino?scroll-to=regola7" class="mb-leaflet-popup__cta-link">Scopri di più</a></div></div>', {
                maxWidth: "auto"
            });

        let markerBook6 = L.marker([39.63953756436671, 84.19921875000001],
            {icon: bookIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 9</span><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular mb-leaflet-popup__title--blue">Conservazione</span><div class="mb-leaflet-popup__description">Gli aderenti alla "Carta del Mulino" posso utilizzare solo tecniche di conservazione specifiche</div><a href="/lacartadelmulino?scroll-to=regola9" class="mb-leaflet-popup__cta-link">Scopri di più</a></div></div>', {
                maxWidth: "auto"
            });

        let markerBook7 = L.marker([34.30714385628804, -42.36328125000001],
            {icon: bookIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Regola 10</span><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular mb-leaflet-popup__title--blue">Trasferimento del valore</span><div class="mb-leaflet-popup__description">Con la "Carta del Mulino" si garantisce un riconoscimento economico distribuito lungo tutta la filiera</div><a href="/lacartadelmulino?scroll-to=regola10" class="mb-leaflet-popup__cta-link">Scopri di più</a></div></div>', {
                maxWidth: "auto"
            });


        let markerMap = L.marker([-60.930432202923335, 107.05078125000001],
            {icon: mapIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Mappa</span><div class="mb-leaflet-popup__description">Non abbiamo segreti: esplora i campi e scopri l’origine del grano tenero che usiamo per le farine della Carta del Mulino</div><a href="/lacartadelmulino?scroll-to=maps" class="mb-leaflet-popup__cta-link">Scopri la Mappa</a></div></div>', {
                maxWidth: "auto"
            });

        let markerGiocoDelleApi = L.marker([-56.752722872057355, -47.98828125000001],
            {icon: giocoApiIcon})
            .addTo(map)
            .bindPopup('<div class="mb-leaflet-popup"><div class="mb-leaflet-popup__title"><span class="mb-leaflet-popup__title mb-leaflet-popup__title--regular">Gioco delle Api</span><div class="mb-leaflet-popup__description">cos’è un fuco? Cos’è il nettare? Quanto va veloce un’ape? Gioca e scopri tante curiosità sull’incredibile mondo delle api</div><a href="/il-gioco-delle-api" class="mb-leaflet-popup__cta-link">Gioca subito</a></div></div>', {
                maxWidth: "auto"
            });

        //

        var positron = L.tileLayer('https://www.mulinobianco.it/fe-web/img/interactive-map/{z}/{y}/{x}.jpg', {
            noWrap: true
        }).addTo(map);


        var ZoomViewer = L.Control.extend({
            onAdd: function () {
                var container = L.DomUtil.create('div');
                var gauge = L.DomUtil.create('div');
                container.style.width = '200px';
                container.style.background = 'rgba(255,255,255,0.5)';
                container.style.textAlign = 'left';
                map.on('zoomstart zoom zoomend', function (ev) {
                    gauge.innerHTML = 'Zoom level: ' + map.getZoom();
                });
                container.appendChild(gauge);
                return container;
            }
        });

        var zoomViewerControl = (new ZoomViewer()).addTo(map);
        map.setView([0, 0], 1);
        markerVideoSlug.openPopup();

        // get limit map
        let bounds = map.getBounds().pad(0.1);
        map.on('moveend', () => {
            // don't show grey background after 0,5s
            if (!this.isMobile) {
                setTimeout(() => {
                    map.panInsideBounds(bounds, {animate: true});
                }, 500);
            }
        });

        /**
        map.on('click', function (e) {
            console.log("land,long : " + e.latlng.lat + ", " + e.latlng.lng)
        });
         */
    }

    /**
     *
     * @param {String} codeYtVideo
     */
    openModal(codeYtVideo) {
        const codeSanitize = codeYtVideo.replaceAll("'", '');
        if (codeSanitize) {
            let iframe = '<iframe src="https://www.youtube.com/embed/' + codeSanitize + '" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" autoplay="1" allowfullscreen></iframe>';
            $("#modal-leaflet-video .content").html('<div class="modal-content">' + iframe + '</div>');
            document.getElementById('modal-leaflet-video').dispatchEvent(new CustomEvent('MB_MODAL_OPEN', () => this.open()));
        }
    }

    /**
     *
     * @param idvideo
     */
    playVideo(idvideo) {

        var htmlPlayer = '<a href="javascript:void(0);" id="closeVideo" mb-component-click="closeVideo();">close</a>';
        var videoHeightClass = '';
        var vQuality = '';

        if (!this.is_touch_device()) {
            videoHeightClass = 'h-100';
            vQuality = '&vq=hd720';
        }
        idvideo = idvideo.replaceAll("'", '');
            htmlPlayer += '<iframe id="ytplayer" width="100%" height="100%" class="' + videoHeightClass + '" type="text/html" src="https://www.youtube.com/embed/' + idvideo + '?autoplay=1&rel=0"' + vQuality + ' frameborder="0" allowfullscreen></iframe>';

        $('.video').parent().prepend(htmlPlayer);
    }

    /**
     *
     * @returns {boolean}
     */
    is_touch_device() {
        return !!('ontouchstart' in window);
    }

    closeVideo() {
        $('#closeVideo').remove();
        $('#ytplayer').remove();
    }
}

export default QrCodeComponent;
