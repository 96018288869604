import Component from '../../model/Component';

class FirstStepComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;

        $(this.element).append(this.createHtml());
    }

    goToNextStep() {
        console.log(this.store.getState());

        this.store.dispatch({...this.store.getState(), type: 'onBoardingForm/goToNextStep'});

        console.log(this.store.getState());
    }

    createHtml() {
        return `
            <div class="first-step-content">
                <div class="first-step-content__title">Utilizza il tuo account su Mulino Bianco per partecipare alla raccolta.</div>
                <div class="first-step-content__subtitle">Scarica la tessera punti <b>contenente i preziosi punti omaggio</b>,  resta aggiornato 
sulle novità  e verifica lo stato della spedizione dei tuoi premi!</div>
                <div class="first-step-content__cta">
                    <a class="button" mb-component-click="goToNextStep()">Accedi o registrati</a>
                </div>
                <div class="first-step-content__goto-raccolta"><a href="/raccolte-punti/2024" class="button-underscore">Vai alla raccolta</a></div>
            </div>
        `;
    }

}


export default FirstStepComponent;
