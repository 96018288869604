/**
 *
 */
import Controller from '../model/Controller';
import Tracker from "../business/Tracker";


class LandingIntegraliCtrl extends Controller {

    constructor(element) {
        super(element);
        this.init();
        this.slider = window.jQuery('.spots-slider');
        this.main = window.jQuery('#main-spot');
        this.icon_play = window.jQuery('.icon-play');


        this.dataVideoGallery = [
            {videoId: 'q-oBF2QAA04'},
            {videoId: 'psTzdPyxebI'},
            {videoId: '72z8WSvMo_k'},
            {videoId: 'uERMtG9qbrE'}
        ];


        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        this.player = null;

        window.onYouTubeIframeAPIReady = () => {
            this.player = new YT.Player('player-gallery', {
                height: '100%',
                width: '100%',
                videoId: 'FKNbgDp0yN4',
                controls: 2,
                events: {
                    onReady: (event) => {
                        this.onReady(event)
                    },
                    'onStateChange': (event) => {
                        this.onPlayerStateChange(event)
                    }
                }
            });
        };
        this.initSlider();

    }


    onReady(event) {

    }

    onPlayerStateChange(event) {
        if (event.data == 5) {
            this.player.playVideo();
        } else if (event.data == 0) {
            $('#cover').show();
        }
    }

    playOrChange(id) {
        $('#cover').hide();
        this.player.loadVideoById(this.dataVideoGallery[id]);
    }

    init() {
        $("[scroll-button-ref]").click(function () {
            let stickyIsPresent = false;
            let buttonAttr = $(this).attr("scroll-button-ref");
            var top = $("[scroll-target='" + buttonAttr + "']").offset().top;
            if ($(".sticky-element-container.unpinned .buttons").length > 0) {
                top = top - $(".sticky-element-container.unpinned .buttons").outerHeight();
                stickyIsPresent = true;
            }
            $('html, body').animate({
                scrollTop: top
            }, 1000, function () {
                if (!stickyIsPresent) {
                    if ($(".sticky-element-container.unpinned .buttons").length > 0) {
                        $('html, body').animate({
                            scrollTop: $('html, body').scrollTop() - $(".sticky-element-container.unpinned .buttons").outerHeight()
                        }, 200)
                    }
                }
            });
        })

        Tracker.send("EVENT", {interactionType: "ZCATEGORY_CLICK", productCode: '9991000084' });
    }

    initSlider() {
        this.slider.slick(
            {
                accessibility: false,
                nextArrow: $('.slick-next'),
                prevArrow: $('.slick-prev'),
                arrows: true,
                centerMode: false,
                slide: '.slide',
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                focusOnSelect: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings:
                            {
                                slidesToShow: 2
                            }
                    }
                ],
            });
    }
}

export default LandingIntegraliCtrl;
