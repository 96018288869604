import Controller from '../model/Controller';
import $ from 'jquery';
import TemplatingUtility from '../business/TemplatingUtility';
import UserManager from "../business/UserManager";
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";

class ReservedAreaCtrl extends Controller {

    constructor(element) {
        super(element);
        this.$el = $(element);
        this.element = element;
        this.tabs = this.element.querySelectorAll('.tab-link');
        this.activeTab = 'promo';
        this.init();
    }


    addLogoutListener() {
        UserManager.waitUserLoggedOut().then(() => {
            UserManager.deleteSession().then(
                () => window.location.href = "/home",
                () => window.location.href = "/home");
        });

        this.$el.find('.logout_btn').click(() => {
            this.$el.find('.total-loader').show();
            this.sendTracking(false, this.user.preferences.privacy_profiling.isConsentGranted);
            GigyaManager.logout().then(() => {
                UserManager.deleteSession().then(
                    () => document.location.href = '/',
                    () => document.location.href = '/');
            }, () => document.location.href = '/');
        });
    }


    addTabsListener() {
        this.tabs.forEach((tab) => {
            tab.addEventListener('click', () => {
                const selected = tab.dataset.tab;
                if (selected === this.activeTab) return;
                this.activeTab = selected;
                this.element.querySelector('.tabs-content-wrapper').dataset.selectedTab = selected;
                this.element.querySelector('.tab-link.selected').classList.remove('selected');
                tab.classList.add('selected');
            })
        });
    }


    getUserInfo() {
        UserManager.getLoggedUser().then((d) => {
            this.user = d;
            UserManager.getLoggedUser().then((data) => {
                this.user = data;
                const welcomeMessage = Utility.welcomeMessage().welcomeMessage;
                this.element.querySelector('#profile-name').innerHTML = `${welcomeMessage} ${d.profile.firstName}!`;
            });
        }, () => document.location.href = '/');
    }


    showUnsubPage() {
        let screensetCfg = {
            screenSet: 'Barilla-ProfileUpdate',
            startScreen: 'gigya-unsubscription-screen-v2',
            containerID: 'barilla-raas-unsubPageContainer',
            context: {
                noovleRaasGlobalCH: 'CH_MULINOBIANCO',
            },
            onAfterSubmit: (e) => {
                this.sendTracking(true, e.preferences.privacy_profiling.isConsentGranted);
            }
        }
        gigya.accounts.showScreenSet(screensetCfg);
    }

    showMyProfile() {
        let screensetCfg = {
            screenSet: 'Barilla-ProfileUpdate',
            startScreen: 'gigya-update-profile-screen-v2',
            containerID: 'barilla-raas-myProfileContainer',
            context: {
                noovleRaasGlobalCH: 'CH_MULINOBIANCO',
            }
        }
        gigya.accounts.showScreenSet(screensetCfg);
    }

    sendTracking(loggedIn, privacy) {
        window.dataLayer = window.dataLayer || [];
        let marketingCookies = OnetrustActiveGroups && OnetrustActiveGroups.indexOf("C0004") > 0;
        window.dataLayer.push({
            'event': 'gigya_info',
            'isLoggedIn': loggedIn,
            'hasAcceptedGigyaPrivacy': privacy,
            'hasAcceptedMarketingCookies': marketingCookies
        });
    }

    init() {
        this.getUserInfo();
        this.addLogoutListener();
        this.addTabsListener();

        GigyaManager.isGigyaSdkReady().then(() => {
            this.showMyProfile();
            this.showUnsubPage();
        });
    }
}

export default ReservedAreaCtrl;
