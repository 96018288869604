import Component from '../model/Component';
import {gsap, Linear} from "gsap";
import $ from "jquery";

class AppearanceAnimationComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.animationList = {
            fromLeft: {
                initialState: {x: -75, alpha: 0.2},
                finalState: {x: 0, alpha: 1, ease: Linear.easeOut}
            },
            fromRight: {
                initialState: {x: 75, alpha: 0.2},
                finalState: {x: 0, alpha: 1, ease: Linear.easeOut}
            }
        };

        this.$el = $(this.element);
        this.animation = this.$el.data("aoscAnimation");
        this.triggerMoment = this.$el.data("aoscTriggerMoment") || 'scroll-reached';

        this.init();
    }

    init() {
        if (!!this.animationList[this.animation]) {
            let selectedAnimation = this.animationList[this.animation];
            if (!!selectedAnimation.initialState) {
                gsap.set(this.$el, selectedAnimation.initialState);
            }

            if (this.triggerMoment == 'scroll-reached') {
                this.checkAndExcecuteFinalAnimation();
                this.animationOccurred = false;
                $(window).scroll(() => {
                    this.checkAndExcecuteFinalAnimation();
                });
            } else if (this.triggerMoment == 'instantly') {
                setTimeout(() => {
                    gsap.to(this.$el, selectedAnimation.finalState);
                }, 800);
            }
        }
    }

    checkAndExcecuteFinalAnimation() {
        if (this.animationOccurred || this.elementInSight()) {
            gsap.to(this.$el, this.animationList[this.animation].finalState);
            this.animationOccurred = true;
        }
        if (!this.elementInSight()) {
            gsap.to(this.$el, this.animationList[this.animation].initialState);
        }
    }

    elementInSight() {
        let triggerPoint = this.$el.offset().top + (this.$el.outerHeight() / 1.4);
        let currentScroll = $(window).scrollTop();
        let windowHeight = $(window).height();

        return currentScroll < triggerPoint && triggerPoint < (currentScroll + windowHeight);
    }
}


export default AppearanceAnimationComponent;
