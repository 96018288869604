import StepHeader from "../common/stepHeader";
import InputField from "../common/inputField";
import Button from "../common/button";

export default function CurriculumTemplate(config) {
    let html = `
        ${InputField({type: 'hidden', name: "Classification", value: config.config.classificationId})}
        ${StepHeader({title: config.title, subtitle: config.subtitle})}
        <div class="telephone-info">${config.config.telephoneInfo}</div>  
        <div class="spaced-container">
            <div class="upload-card form-item">Per <strong>inviare il tuo CV</strong>,          
                <span class="upload-link"><a href="${config.config.externalLink}" target=_blank"" id="upload_link">clicca su questo link</a></span>
            </div>
             <div class="items-container">     
             ${InputField({type: 'textarea', name: 'MessageText', placeholder: 'Cosa ci vuoi suggerire?*'})}
             </div>
        <div class="step-disclaimer">*I campi contrassegnati con l’asterisco sono obbligatori</div>
        <div class="item-centered">
           ${Button({
        id: 3,
        type: 'goNext',
        nextStep: 4,
    })}
        </div>
        `;
    return html
}

