import Component from '../../model/Component';
import HttpClient from "../../business/HttpClient";
import $ from "jquery";
import VideoPreviewComponent from "./VideoPreviewComponent";
import {Utils} from "./Utils";

class VideoPreviewStepFormComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);
        this.store = store;
        this.submit = this.element.querySelector('.data-step-form__save');
        this.stateObject = this.store.getState();
        Utils.showVideoLoader();
        this.preview = new VideoPreviewComponent(this.stateObject.data);
        this.init();
    }


    addBackListener() {
        this.element.querySelector('.data-step-form__back').addEventListener('click', () => {
            this.store.dispatch({...this.store.getState(), type: 'unMorsoDiFelicitaForm/back'});
        });
    }

    goToNextStep(data) {
        const payload = {id: data.id};
        this.store.dispatch({...this.store.getState(), type: 'unMorsoDiFelicitaForm/goToNextStep', payload: payload});
    }

    save() {
        let data = {...this.stateObject.data};
        data.sandwichImage = data.sandwichImageId;
        data.senderImage = data.senderImageId;
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'POST',
                url: API_ENDPOINT + `/api/mulinobianco/1.0/web/mdf/sandwich`,
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(data)
            }).then(
                (data) => resolve(data.body),
                (error) => reject(error)
            );
        });
    }

    initForm() {
        this.form = document.forms["videoPreviewStepForm"];
        const checkbox = this.form.querySelector('input[type=checkbox]');
        const errorField = this.form.querySelector('.error-field');

        checkbox.addEventListener('change', () => {
            if (checkbox.checked) errorField.classList.add('hidden');
        });


        this.form.addEventListener("submit", (event) => {
            event.preventDefault();
            const checked = this.form.querySelector('input[type=checkbox]').checked;
            if (!checked) {
                errorField.classList.remove('hidden');
                return;
            }

            this.preview.pauseVideo();
            this.element.querySelector('.data-step-form__error').innerHTML = '';
            $('#overlayer').show();
            this.save().then(
                (data) => {
                    this.appendThankyouPage(data);
                    $('#overlayer').hide();
                },
                (error) => this.printError(error));
        });
    }


    appendThankyouPage(data) {
        document.querySelector('.un-morso-di-felicita-form__header').innerHTML =
            '<h1 class="umdf-title">Complimenti, il tuo video <br> è stato creato e ora puoi condividerlo!</h1>';
        this.goToNextStep(data);
    }


    printError(error) {
        let message = '';
        switch (error.responseJSON.errorCode) {
            case 'SENDER_IMAGE_NOT_FIT':
                message = 'La tua foto caricata non è valida. Carica una nuova foto e riprova.';
                break;
            case 'SANDWICH_IMAGE_NOT_FIT':
                message = 'La foto del panino non è valida. Carica una nuova foto e riprova.';
                break;
            default:
                message = 'Si è verificato un problema con il salvataggio.';
                break;
        }

        const errorWrapper = this.element.querySelector('.data-step-form__error');
        errorWrapper.innerHTML = message;
        $('#overlayer').hide();
    }

    init() {
        this.addBackListener();
        this.initForm();
        this.store.subscribe(() => {
            let state = this.store.getState();
            if (state.currentStep !== 5) return;
            Utils.showVideoLoader();
            this.stateObject = state;
            this.preview.update(this.stateObject.data);
        });
    }
}


export default VideoPreviewStepFormComponent;
