import Constants from '../business/Constants';
import $ from 'jquery';
import Cookies from 'js-cookie';
import GigyaManager from "./GigyaManager";
import Utilility from "./Utility";

class HttpClient {
    constructor() {
    }

    doCall(opts, isRetry) {
        var authToken = Cookies.get(Constants.AUTH_TOKEN_COOKIE);

        opts['beforeSend'] = (req) => {
            if (authToken) {
                req.setRequestHeader('Authorization', authToken)
            }

        };
        return new Promise((resolve, reject) => {
            $.ajax(opts).done(
                (resp) => {
                    resolve(resp);
                }
            ).fail(
                (resp) => {
                    if (resp.status == 401 && !isRetry) {
                        this.doAuth().then(
                            () => {
                                this.doCall(opts, true);
                            }
                        );
                    } else {
                        reject(resp);
                    }
                }
            );
        })

    }

    doAuth() {
        return new Promise((resolve, reject) => {
            GigyaManager.getLoggedUser().then((user) => {
                var serverLoginParams = {
                    uid: user.UID,
                    uidSignature: user.UIDSignature,
                    signatureTimestamp: user.signatureTimestamp,
                    clientId: "mulinobianco"
                };
                $.ajax(API_ENDPOINT + '/api/gigya/1.0/auth/mulinobianco', {
                    type: "POST",
                    dataType: "json",
                    data: $.param(serverLoginParams)
                }).done((resp) => {
                    let token = resp.body.sessionId;
                    Cookies.set(Constants.AUTH_TOKEN_COOKIE, token, {expires: 1, path: '/'});
                    resolve(true);
                }).fail((resp) => {
                    console.error("💣 Error doing auth " + serverLoginParams);
                    reject();
                });
            }, () => {
                window.location.href = "/internal/login.do?urlBack=" + Utilility.getCurrentUri();
                reject();
            });
        });

    }
}

export default new HttpClient();
