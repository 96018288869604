import $ from 'jquery';
import Component from '../model/Component';

class VideoGalleryComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.element = $(element);
        var playerid = this.element.data("playerid");
        this.init();
        this.slider = window.jQuery($(element)).find('.video-slider');
        this.dataVideoGallery = $(this.slider).find('.slide').map(function (i, el) {
            return $(el).data('videoid');
        });

        this.numberOfItem = this.slider.find('.slide').length;

        this.player = null;
        window.jQuery('body').on("ytloaded", (e) => {
            console.log("📽 Per " + playerid + " YT è loaded 🏁");
            this.player = new YT.Player(playerid, {
                height: '100%',
                width: '100%',
                videoId: this.dataVideoGallery[0],
                host: 'https://www.youtube-nocookie.com',
                controls: 2,
                arrows: true,
                events: {
                    onReady: (event) => {
                        this.onReady(event)
                    },
                    onStateChange: (event) => {
                        this.onPlayerStateChange(event)
                    }
                }
            });
        });

        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = () => {
            window.jQuery('body').attr("data-ytloaded", true).data("ytloaded", true).trigger("ytloaded");
        };

       if($(this.element).attr('data-fetch-cover')=='youtube'){
    	   		$.each($('img[attr-img-fetch="youtube"]'), function( index, value ) {
    	   			//	youtube mette a disposizione degli url specifici per le immagini di copertina che sono
    	   			//	https://i1.ytimg.com/vi/<insert-youtube-video-id-here>/default.jpg -   default
    	   			//	https://i1.ytimg.com/vi/<insert-youtube-video-id-here>/mqdefault.jpg - medium
    	   			//	https://i1.ytimg.com/vi/<insert-youtube-video-id-here>/hqdefault.jpg - high
    	   			//	https://i1.ytimg.com/vi/<insert-youtube-video-id-here>/sddefault.jpg - standard

    	   			$(value).attr('src','https://i1.ytimg.com/vi/' + $(value).attr('data-videoid')  + '/hqdefault.jpg')
    	   		});
       }

        this.initSlider();
    }

    init() {
        this.videoFn($(this.element).find('.video-box'));
        this.scrollFn($(this.element).find('a.scroll'));
    }

    onReady(event) {
    }

    videoFn(selector) {
        $(selector).find('> a').on('click', (e) => {
            e.preventDefault();
            let box = $(e.target).closest(selector);
            $('<iframe frameborder="0" allowfullscreen></iframe>').prop('src', box.data('embed')).wrap('<div class="responsive-video"></div>').parent().appendTo(box);
            box.find('> a').remove();
            return false;
        });
    }

    scrollFn(selector) {
        this.time = 800;
        this.easing = 'swing';

        $(selector).on('click', (e) => {
            e.preventDefault();
            let target = $($.attr(e.target, 'href'));
            if (target.length) this.scroll(target.offset().top);
            return false;
        });
    }

    initSlider() {
        if (this.numberOfItem > 1) {
            this.slider.slick(
                {
                    accessibility: false,
                    nextArrow: $(this.element).find('.slick-next'),
                    prevArrow: $(this.element).find('.slick-prev'),
                    centerMode: false,
                    slide: '.slide',
                    slidesToShow: this.numberOfItem > 3 ? 3 : this.numberOfItem,
                    slidesToScroll: 1,
                    infinite: false,
                    focusOnSelect: false,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings:
                                {
                                    slidesToShow: 2
                                }
                        }
                    ]
                });
        } else {
            this.slider.remove()
        }
    }

    onPlayerStateChange(event) {
        if (event.data == 5) {
            this.player.playVideo();
        } else if (event.data == 0) {
            $(this.element).find('.coverVideoGallery').show();
        }
    }

    playOrChange(id) {
        $(this.element).find('.coverVideoGallery').hide();
        this.player.loadVideoById(this.dataVideoGallery[id]);
    }
}

export default VideoGalleryComponent;
