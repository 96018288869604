/**
 * E' un singleton
 */

import Utility from './Utility';
import HttpClient from './HttpClient';
import UserManager from './UserManager';

class RatingManager {


    constructor() {
        this.categorieskeys = {};
        this.checkUserStatus();
        this.doFindByKeysDebounced = Utility.debounce(this.doFindByKeys, 300);
        this.EVENTS = {BYKEYS_DONE: "MB_BYKEYS_DONE", RATE_DONE: "MB_RATE_DONE"};
    }

    registerCategoryKey(rateKey, category) {
        if (!this.categorieskeys[category]) {
            this.categorieskeys[category] = [];
        }
        if (this.categorieskeys[category].indexOf(rateKey) < 0) {
            this.categorieskeys[category].push(rateKey);
        }
        this.doFindByKeysDebounced();
    }

    checkUserStatus() {
        return UserManager.getLoggedUser();
    }

    formatCategoriesKeysToSend() {
        var dataToSend = {"data": []};
        Object.keys(this.categorieskeys).forEach((category) => {
            var categoryKeys = {"category": category};
            categoryKeys.keys = this.categorieskeys[category];
            dataToSend.data.push(categoryKeys);
        });
        return dataToSend;
    }

    getRatingStatus(ratingKey, category, ratingData) {
        var ratingKeyObjResult = null;
        ratingData.forEach((ratingElement) => {
            if (ratingElement["category"] == category) {
                ratingElement.keys.forEach((ratingKeyObj) => {
                    if (ratingKeyObj.key == ratingKey) {
                        ratingKeyObjResult = ratingKeyObj;
                    }
                })
            }
        });
        return ratingKeyObjResult;
    }

    doFindByKeys() {
        this.checkUserStatus().then(() => {
            HttpClient.doCall({
                url: API_ENDPOINT + '/api/1.0/plugin/comments/ratings/byKeys',
                type: "POST",
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(this.formatCategoriesKeysToSend())
            }).then((data) => {
                window.document.dispatchEvent(new CustomEvent(this.EVENTS.BYKEYS_DONE, {'detail': data.body.output}));
            });
        }, () => {
        });
    }

    rate(category, key) {
        HttpClient.doCall({
            url: API_ENDPOINT + `/api/mulinobianco/1.0/web/ratings/${category}/${key}/1`,
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
        }, true).then((data) => {
            window.document.dispatchEvent(new CustomEvent(this.EVENTS.RATE_DONE, {
                'detail': {
                    ratingData: data.body,
                    key,
                    category
                }
            }));
        });
    }

    unrate(category, key) {
        HttpClient.doCall({
            url: API_ENDPOINT + `/api/mulinobianco/1.0/web/ratings/${category}/${key}`,
            type: "PUT",
            dataType: "json",
            contentType: "application/json; charset=utf-8"
        }).then((data) => {
            window.document.dispatchEvent(new CustomEvent(this.EVENTS.RATE_DONE, {
                'detail': {
                    ratingData: data.body,
                    key,
                    category
                }
            }));
        });
    }

}


export default new RatingManager();
