

// K+ component
import Swiper from 'swiper';
import Component from '../model/Component';

class HomepageCinquantesimoComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.element = element;
        this.init();
    }

    initSwiper() {
        this.swiper = new Swiper('.cinquantesimo-page.homepage .swiper-container', {
            pagination: '.swiper-pagination',
            nextButton: this.element.querySelector('.swiper-button-next'),
            prevButton: this.element.querySelector('.swiper-button-prev'),
            slidesPerView: 3,
            spaceBetween: 50,
            paginationClickable: true,
            loop: true,
            centeredSlides: true,
            keyboardControl: true,
            mousewheelControl: true,
            mousewheelForceToAxis: true,

            breakpoints: {
                767: {
                    slidesPerView: 1.2,
                }
            }
        });
    }

    init() {
        this.initSwiper();
    }
}

export default HomepageCinquantesimoComponent;
