/**
 *
 */
import $ from 'jquery';
import Controller from '../model/Controller';
import gReveal from "../vendor/gReveal";
import Swiper from "swiper";
import Tracker from "../business/Tracker";

class EqualHeight {
    init(selector, event = 'load resize') {
        $(window).on(event, () => this.equalheight($(selector)));
    }

    equalheight(elements) {
        let currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition, currentDiv;

        elements.each(function () {
            $el = $(this);
            $($el).height('auto');
            topPosition = $el.position().top;

            if (currentRowStart !== topPosition) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) rowDivs[currentDiv].height(currentTallest);
                rowDivs.length = 0;
                currentRowStart = topPosition;
                currentTallest = $el.height();
                rowDivs.push($el);
            }
            else {
                rowDivs.push($el);
                currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) rowDivs[currentDiv].height(currentTallest);
        });
    }
}

class SenzaGlutineCtrl extends Controller {

    constructor(element) {
        super(element);
        $(() => this.init());
    }

    init() {
        this.reveal = new gReveal({
            initialDelay: 0,
            offset: 120
        });

        this.galleryTop = $('#gallery-top');
        this.galleryThumbs = $('#gallery-thumbs');
        this.initVideoGallery();

        this.recipesSlider = $('#recipes-slider');
        this.initRecipesSlider();

        let eh = new EqualHeight();
        eh.init('#recipes-slider .recipe-title');

        Tracker.send("EVENT", {interactionType: "ZCATEGORY_CLICK", productCode: '9991000087' });

    }

    initVideoGallery() {
        let galleryTop = new Swiper(this.galleryTop[0], {
            slidesPerView: 1,
            nextButton: '#gallery-top-next',
            prevButton: '#gallery-top-prev',
        });

        let galleryThumbs = new Swiper(this.galleryThumbs[0], {
            spaceBetween: 30,
            slidesPerView: 3,
            slidesPerGroup: 3,
            slideToClickedSlide: true,
            loop: true,
            breakpoints: {
                479: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 10,
                },
                1023: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 20,
                },
            },
        });

        galleryTop.on('slideChangeStart', () => {
            if (galleryTop.activeIndex === galleryThumbs.realIndex) return;
            if (galleryTop.activeIndex < galleryTop.previousIndex) galleryThumbs.slidePrev();
            else galleryThumbs.slideNext();
        });

        galleryTop.on('slideChangeEnd', () => {
            this.galleryTop.find('iframe.video').remove();
        });

        galleryThumbs.on('slideChangeStart', () => {
            if (galleryTop.activeIndex === galleryThumbs.realIndex) return;
            galleryTop.slideTo(galleryThumbs.realIndex);
        });

        this.galleryTop.find('[data-video-id]').on('click', (e) => {
            let cont = $(e.currentTarget),
                slide = cont.parent();

            $('<iframe class="video" frameborder="0" allowfullscreen></iframe>').prop('src', 'https://www.youtube.com/embed/' + cont.data('video-id') + '?autoplay=1&rel=0&showinfo=0').appendTo(slide);

            return false;
        });
    }

    initRecipesSlider() {
        new Swiper(this.recipesSlider[0], {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20,
            nextButton: this.recipesSlider.parent().find('.swiper-button-next')[0],
            prevButton: this.recipesSlider.parent().find('.swiper-button-prev')[0],
            breakpoints: {
                479: {
                    slidesPerView: 1,
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                1023: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                }
            }
        });
    }
}

export default SenzaGlutineCtrl;
