import Component from '../../model/Component';

class LandingStepFormComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);
        this.store = store;
        this.initListener();
    }

    initListener() {
        this.element.querySelector('.login-button').addEventListener('click', () => {
            this.store.dispatch({...this.store.getState(), type: 'unMorsoDiFelicitaForm/goToNextStep'});
        });
    }
}


export default LandingStepFormComponent;
