import $ from 'jquery';
import Controller from '../model/Controller';
import UserManager from '../business/UserManager';
import HttpClient from "../business/HttpClient";
import Mustache from "mustache";
import Constants from "../business/Constants";
import GigyaManager from "../business/GigyaManager";

/*
import Mustache from "mustache";
import HttpClient from '../business/HttpClient';*/

class Tour2019PlayCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$element = $(element);
        this.$partButton = $(element).find("[data-do-partecipation]");
        this.$prizeButton = $(element).find("[data-prize-request]");
        this.$regAcc = $(element).find('input[type="checkbox"][name="regulationflag"]');
        this.$acceptRegCnt = $(element).find("#acceptrule");
        this.tpl = this.$element.find('#box-invitation').length > 0 ? this.$element.find('#box-invitation').get(0).innerHTML : '';
        this.$target = this.$element.find('#target-invitation').length > 0 ? this.$element.find('#target-invitation') : null;

        this.init();
    }

    init() {
        UserManager.getLoggedUser().then((data) => {
            let e = this.$element.find('[type="email"][name="email"]');
            e.val(data.profile.email);
            this.setEvents();
        }, (error) => {

        });
        this.$regAcc.change((ev) => {
            if (this.$regAcc.is(":checked")) {
                this.$partButton.removeClass("disabled");
            } else {
                this.$partButton.addClass("disabled");
            }
        });
    }

    setEvents() {
        this.$partButton.click((ev) => {
            this.$partButton.addClass("disabled");
            this.$partButton.css("display", "none");
            this.$partButton.siblings(".loader").show();
            GigyaManager.addCH("CH_278_MLB_WEB_ITIWTOUR19_CRS_CRS_20190415_20191013").then(()=>{
                HttpClient.doCall({
                    url: API_ENDPOINT + '/api/mulinobianco/1.0/web/tour/2019/partecipation',
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json"
                }, false).then((data) => {
                    if (data && data.status && data.status == "OK" && data.body && data.body.hasAlreadyParticipated) {
                        this.$acceptRegCnt.hide();
                        this.$target.html(Mustache.render(this.tpl, data.body));
                        let b = data.body;
                        this.userPartecipation = true;
                        if (b.prize && b.prize.hasWon) {
                            $("#requestprize").show();
                            $('[data-prize-request]').attr("data-prize-request",b.prize.id)
                        }else{
                            $("#nowin").show();
                        }
                    }
                    this.$partButton.siblings(".loader").hide();
                }, (error) => {
                    document.getElementById('generic-error-modal').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
                    this.$partButton.siblings(".loader").hide();
                    this.$partButton.removeClass("disabled");
                    this.$partButton.css("display", "inline-block");
                });
            }, (err)=>{
              console.log(err);
              document.getElementById('generic-error-modal').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
              this.$partButton.siblings(".loader").hide();
              this.$partButton.removeClass("disabled");
              this.$partButton.css("display", "inline-block");
          })

        });
        this.$prizeButton.click((ev) => {
            let fld = this.$element.find('.play-tour-2019 input[type="text"][required],.play-tour-2019 input[type="email"][required],.play-tour-2019 input[type="tel"][required]');
            let valid = true;
            let id = typeof this.$prizeButton.data("prizeRequest") == "number" ? this.$prizeButton.data("prizeRequest") : false;
            $.each(fld, (ind, el) => {
                if ($(el).val() == "") {
                    $(el).addClass("error");
                } else {
                    if($(el).attr("name") == "zipCode" || $(el).attr("name") == "telephone"){
                        var isValid = /^\d+$/.test($(el).val());
                        if(!isValid){
                            $(el).addClass("error");
                            valid=false;
                        }else{
                            $(el).removeClass("error");
                        }
                    }else{
                        $(el).removeClass("error");
                    }

                }
                valid = $(el).val() != "" && valid;
            });
            if (id && valid) {
                let params = this.getParams();
                this.$prizeButton.addClass("disabled");
                this.$prizeButton.css("display", "none");
                this.$prizeButton.siblings(".loader").show();
                HttpClient.doCall({
                    url: API_ENDPOINT + '/api/mulinobianco/1.0/web/tour/2019/prize/' + id,
                    type: "POST",
                    dataType: "json",
                    data: JSON.stringify(params),
                    contentType: "application/json"
                }, false).then((data) => {
                    if (data && data.status && data.status == "OK" && data.body) {
                        this.$element.find("#requestprize").slideUp(() => {
                            this.$element.find("#requestprize").remove();
                            this.$element.find("#prizerequest-completed").show();
                        });
                    } else {
                        this.$prizeButton.removeClass("disabled");
                        this.$prizeButton.css("display", "block");
                    }
                    this.$prizeButton.siblings(".loader").hide();
                    console.log(data);
                }, (error) => {
                    this.$prizeButton.removeClass("disabled");
                    this.$prizeButton.css("display", "block");
                    console.log(error);
                    document.getElementById('generic-error-modal').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
                });
            }
            console.log(valid);
        });
    }

    getParams() {
        let fields = this.$element.find('.play-tour-2019 input[type="text"],.play-tour-2019 input[type="email"],.play-tour-2019 input[type="tel"]')
        let params = {};
        $.each(fields, (ind, el) => {
            params[$(el).attr("name")] = $(el).val();
        })
        return params;
    }

    handleLoading() {
        let l = this.$element.find('.box-loader');
        l.show();
        l.siblings('.box-invito').hide();
    }

    removeLoading(dflt) {
        let l = this.$element.find('.box-loader');
        l.hide();
        if (dflt) {
            l.siblings('[data-logged="false"]').show();
        } else if (this.userInfo) {
            let cond = '[data-logged="true"]';
            if (this.userPartecipation) {
                cond += '[data-partecipation="true"]';
            } else {
                cond += '[data-partecipation="false"]';
            }
            if (this.userWon) {
                cond += ',[data-logged="true"][data-won="true"]'
            }
            l.siblings(cond).show();
        }
    }
}

export default Tour2019PlayCtrl;
