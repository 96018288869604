import $ from 'jquery';

let generateUid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

class Component {

    constructor(element, parentCtrl) {

        this.element = element;
        this.bindedElements = [];
        this._componentId = generateUid();
        this.parentCtrl = parentCtrl;

        let propertyName = $(this.element).attr("mb-component-property-name");

        if (typeof propertyName !== "undefined") {
            this.parentCtrl.components[propertyName] = this;
        }

        $(this.element).data('_componentId', this._componentId);

        $(element).on("click", "[mb-component-click]", (el) => {
            this.bindedElements.push({
                target: el.currentTarget,
                event: "click"
            });
            let functionCode = el.currentTarget.getAttribute('mb-component-click');
            let functionName = functionCode.split("(")[0];
            let params = functionCode.split("(")[1].split(")")[0].split(',');
            let args = [];
            for (var i = 0; i < params.length; i++) {
                var ret = params[i];
                if (isNaN(params[i]) && params[i].indexOf("'") == -1) {
                    ret = this[params[i]];
                }
                if (ret != '')
                    args.push(ret);
            }
            this[functionName].apply(this, args);
        });
    }

    registerEvent(event, fn) {
        this.element.addEventListener(event, fn);
    }

}

export default Component;
