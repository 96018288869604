/**
 *
 */

import Swiper from 'swiper';
import Controller from '../model/Controller';

class BowlCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.init()
    }

    init() {
        var options = {
            slidesPerView: 1,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        };

        this.swiper = new Swiper(this.$el.find('.swiper-container'), options);
        
        $(window).resize(() => {
            this.swiper.update();
        });
    }

}

export default BowlCtrl;
