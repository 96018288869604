import $ from 'jquery';
import Swiper from 'swiper';
import Component from '../model/Component';

class SliderPromoCartaceaComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.spv = 0;

        $(window).resize(() => {
            this.onResize();
        })
        this.onResize();

        this.eventModal();
    }

    onResize() {
        let newSpv;
        if ($(window).width() > 820) {
            newSpv = 3;
        } else if ($(window).width() > 767) {
            newSpv = 2;
        } else {
            newSpv = 1;
        }

        if (newSpv != this.spv) {
            this.spv = newSpv;
            this.destroySwiper();
            this.initSwiper();
        }
    }

    initSwiper() {
        this.swiper = new Swiper(this.$element[0], {
            slidesPerView: this.spv,
            spaceBetween: 35,
            nextButton: '.products .swiper-button-next',
            prevButton: '.products .swiper-button-prev',
            pagination: '.swiper-pagination',
            paginationClickable: true,
            loop: false,
            speed: 1000
        });

        console.log(this.swiper);
    }

    destroySwiper() {
        if (!!this.swiper) {
            this.swiper.destroy(true, true);
            delete this.swiper;
        }
    }

    eventModal(){
        $('#showAllProducts').click((e) => {
            e.preventDefault();
            $('#modal-products').toggle();
        })
    }
}

export default SliderPromoCartaceaComponent;
