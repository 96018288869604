import Component from '../model/Component';

class FooterComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
    }


}


export default FooterComponent;
