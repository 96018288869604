import Cookies from 'js-cookie';
import Component from '../model/Component';
import Constants from "../business/Constants";
import $ from "jquery";

class CookieBarComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        if (!Cookies.get(Constants.COOKIE_CONSENT)) {
            window.onscroll = () => {
                if(!Cookies.get(Constants.COOKIE_CONSENT)){
                    this.addCookieAndCloseBar();
                }
            }
            $(element).show();
        }else{
            this.injectGtm();
        }


    }

    addCookieAndCloseBar() {
        Cookies.set(Constants.COOKIE_CONSENT, true, {expires: 365, path: '/'});
        $(this.element).hide();
        this.injectGtm();
    }

    injectGtm(){
    let gtmScript = `<script>(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push(
                {'gtm.start': new Date().getTime(), event: 'gtm.js'}
            );
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'datalayer', 'GTM-N76LC6');</script>`;


        $('head').append(gtmScript);
    }

}


export default CookieBarComponent;
