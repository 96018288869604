import Component from '../model/Component';
import ProductFinderManager from "../business/ProductFinderManager";
import $ from "jquery";


class ProductFinderCtaComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.init();
    }

    init() {
        ProductFinderManager.loadUserProfile().then(() => {
            ProductFinderManager.attachProductFinderBundle();
        });
    }

    search() {
        let objectParam = {page: 1, pageSize: 14};
        let $consumptionMomentEL = $(this.element).find("[data-property='consumptionMoment'] .combo-option.selected");
        if ($consumptionMomentEL.length > 0) {
            if ($consumptionMomentEL.attr("data-value") != "") {
                objectParam.idConsumptionMoment = $consumptionMomentEL.attr("data-value");
            }
        }
        let $ingredientsEL = $(this.element).find("[data-property='ingredients'] .combo-option.selected");
        if ($ingredientsEL.length > 0) {
            if ($ingredientsEL.attr("data-value") != "") {
                objectParam.idIngredient = $ingredientsEL.attr("data-value");
            }
        }
        let $freeFromEL = $(this.element).find("[data-property='freeFrom'] .combo-option.selected");
        if ($freeFromEL.length > 0) {
            if ($freeFromEL.attr("data-value") != "") {
                objectParam.idFreeFrom = $freeFromEL.attr("data-value");
            }
        }

        ProductFinderManager.getConfiguration(objectParam).then((data) => {
            window.location.href = data.body.search.url;
        })
    }
}

export default ProductFinderCtaComponent;
