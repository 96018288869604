import Component from '../model/Component';
import UserManager from '../business/UserManager';
import PrivacyCluster from "../enums/PrivacyCluster";
import Tracker from "../business/Tracker";
import $ from "jquery";

class NewsletterComponent extends Component {


    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.user = null;
        this.cluster = null;
        UserManager.getLoggedUser()
            .then((d) => {
                this.user = d;

                if (typeof this.user.subscriptions.NL_3NAC != 'undefined' && this.user.subscriptions.NL_3NAC.email.isSubscribed == true) {
                    $(this.element).hide();
                    let $parentEl = $("[data-hide-parent-on-load-disabled]");
                    if ($parentEl && $parentEl.attr("data-hide-parent-on-load-disabled") == "true") {
                        $parentEl.parent().hide();
                    }
                } else {
                    element.getElementsByClassName("title")[0].innerHTML = 'Le migliori notizie, selezionate per te';
                    element.getElementsByClassName("text")[0].innerHTML = 'Tante notizie, tutte diverse per essere sempre aggiornato sul mondo di Mulino e tanto altro.<br>Scegli la newsletter che vuoi ricevere!<div class="nl-box__cnt"><div class="nl-box__wrapper"><div class="nl-box__title">Notizie a colazione</div><div class="nl-box__subtitle">Tre buone notizie per iniziare al meglio</div></div><div class="nl-box__wrapper"><div class="nl-box__title">Nel Mulino che vorrei</div><div class="nl-box__subtitle">Anteprima, sondaggi e contenuti esclusivi</div></div><div class="nl-box__wrapper"><div class="nl-box__title">Ti sblocco un ricordo</div><div class="nl-box__subtitle">Curiosità, ricordi, sensazioni... Riavvolgendo il nastro</div></div></div>';
                    element.getElementsByClassName("button")[0].innerHTML = 'Iscriviti';
                    element.getElementsByClassName("button")[0].href = 'javascript:void(0);';
                    element.getElementsByClassName("button")[0].setAttribute("data-href", "/newsletter");
                }
            }, () => {
                element.getElementsByClassName("title")[0].innerHTML = 'Le migliori notizie, selezionate per te';
                element.getElementsByClassName("text")[0].innerHTML = 'Tante notizie, tutte diverse per essere sempre aggiornato sul mondo di Mulino e tanto altro.<br>Scegli la newsletter che vuoi ricevere!<div class="nl-box__cnt"><div class="nl-box__wrapper"><div class="nl-box__title">Notizie a colazione</div><div class="nl-box__subtitle">Tre buone notizie per iniziare al meglio</div></div><div class="nl-box__wrapper"><div class="nl-box__title">Nel Mulino che vorrei</div><div class="nl-box__subtitle">Anteprima, sondaggi e contenuti esclusivi</div></div><div class="nl-box__wrapper"><div class="nl-box__title">Ti sblocco un ricordo</div><div class="nl-box__subtitle">Curiosità, ricordi, sensazioni... Riavvolgendo il nastro</div></div></div>';
                element.getElementsByClassName("button")[0].innerHTML = 'Iscriviti';
                element.getElementsByClassName("button")[0].href = 'javascript:void(0);';
                element.getElementsByClassName("button")[0].setAttribute("data-href","/login?urlBack=/newsletter");
                console.log("🤹‍ No user Logged")
            });
        //this.parentCtrl.loadComponents();
        this.trackNL(element);
    }

    trackNL(element) {
        let NLbtn = element.getElementsByClassName("button")[0];

        $(NLbtn).on('click', (e) => {
            e.stopImmediatePropagation();
            Tracker.send('EVENT', {interactionType: 'NEWSLETTER_BTN_CLICK'});
            document.location.href = $(NLbtn).attr('data-href');
        });
    }
}


export default NewsletterComponent;