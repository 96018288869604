import Component from '../model/Component';
import ComboBox from "./ComboBox";
import $ from "jquery";
import Mustache from "mustache";
import HttpClient from "../business/HttpClient";
import UserManager from "../business/UserManager";
import Constants from "../business/Constants";
import Utility from "../business/Utility";


class ReviewListComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.$comboBoxHtmlNode = null;
        this.idProduct = $(element).attr("data-product-id");
        this.detailSeoUrl = $(element).attr("data-detail-review-seo-url");
        this.productName = $(element).attr("data-product-name");
        this.paginationOffset = 1;
        this.paginationLimit = 2;
        this.userLogged = false;
        this.searchType = {
            "SELECTED": {label: "più rilevanti", value: "SELECTED"},
            "RECENT": {label: "più recenti", value: "RECENT"}
        };
        this.searchResult = null;
        this.comboBoxDataTemplate = {
            "placeholder": "Ordinamento",
            "comboLabel": "Ordina per",
            "options": [{"label": this.searchType.SELECTED.label, "value": this.searchType.SELECTED.value}, {
                "label": this.searchType.RECENT.label,
                "value": this.searchType.RECENT.value
            }]
        };
        this.comboBox = null;
        this.init();

    }


    init() {
        $(this.element).addClass("icon-rating-component");
        $('#mb-review-shortcut').click(() => {
            UserManager.getLoggedUser().then(()=>{
                this.onStickyElementClick();
            }, () => {
                //POPUP
                var loginModal = document.getElementById("generic-go-login-modal-from-review");
                loginModal.style.display = "";

                var button = loginModal.getElementsByClassName("button")[0];
                button.setAttribute("href","/login?urlBack=" + Utility.getCurrentUri());
                loginModal.setAttribute("data-href","/login?urlBack=" + Utility.getCurrentUri());


                $('.page-cnt').append(loginModal);
            });
        });
        this.getReviewData().then(() => {
            if (!this.searchResult.reviews || this.searchResult.reviews.length == 0) {
                $(this.element).append(`${this.getNoReviewHtml()}`);
                return Promise.reject(false);
            } else {
                if (!!Utility.getUrlParameter("mbOpenMyReview")) {
                    setTimeout(() => {
                        this.openMyReviewModal();
                    }, 800)

                }
                return this.initHtml();
            }
        }).then(() => {
            this.bindInputClick();
            this.displayReview();
            this.printWriteReviewCTA();
        }).catch((err) => {
            //NOOP
        });
    }

    bindInputClick() {
        this.$comboBoxHtmlNode[0].addEventListener("combo-box-changed", (ev) => {
            $(this.element).find(".review-items").html("");
            this.resetPagination();
            this.doSearch();
        });
        $(this.element).find("[data-pagination]").click(() => {
            this.doSearch();
        });
    }


    doSearch() {
        this.getReviewData(this.comboBox.selectedValue).then(() => {
            this.displayReview();
        })
    }


    onStickyElementClick() {
        if (this.searchResult.userReview == null) {
            window.location.href = this.detailSeoUrl;
        } else {
            if (this.searchResult.userReview.workflowStatus == "DRAFT") {
                $("html, body").animate({scrollTop: ($('[mb-component="ReviewListComponent"]').offset().top - 250) + 'px'}, 400, 'swing');
            } else {
                this.openMyReviewModal();
            }
        }
    }

    buildComboFilter() {

        this.comboBoxDataTemplate.options = [];
        this.searchResult.availableSearchType.forEach((searchType) => {
            this.comboBoxDataTemplate.options.push({
                "label": this.searchType[searchType].label,
                "value": this.searchType[searchType].value
            })
        });

        //if (this.comboBoxDataTemplate.options.length > 1) {
        return new Promise((resolve, reject) => {
            let $tmpContainer = $("<div/>");
            $tmpContainer.load('/fe-web/include/combobox-tmpl.html .mb-combobox', () => {
                $(this.element).find("[data-combobox]").html(Mustache.render($tmpContainer[0].innerHTML, this.comboBoxDataTemplate));
                this.$comboBoxHtmlNode = $(this.element).find("[data-combobox] div");
                this.$comboBoxHtmlNode.addClass("single-filter");
                this.comboBox = new ComboBox(this.$comboBoxHtmlNode);
                let searchType = this.searchType[this.searchResult.searchType];
                this.comboBox.setValue(searchType.label, searchType.value);
                this.parentCtrl.loadComponents();
                if (this.comboBoxDataTemplate.options.length < 2) {
                    $(this.element).find("[data-combobox]").hide();
                }
                resolve(true);
            });
        });


    }

    initHtml() {
        let data = this.searchResult;
        $(this.element).append(`<div class="review-head-info">
                                        <div  class="small" mb-component="IconRatingComponent" data-initial-value="${data.averageRate}" data-read-only="true"></div>
                                        <span class="total-number">${data.totalCount}</span>
                                        <span class="label">Recensioni inviate</span>
                                        <div data-combobox class="combobox-container"></div>
                                    </div>
                                    <div class="review-items">
                                    </div>
                                    <div data-pagination class="pagination-button"><svg>
                                                                                        <use  xlink:href="#svg_button_circle_plus"></use>
                                                                                    </svg>
                                    </div>
                                    
                                `);


        return this.buildComboFilter();
    }


    waitForUser() {
        return new Promise((resolve, reject) => {
            UserManager.getLoggedUser().then(() => {
                resolve(true);
                this.userLogged = true;
            }, () => {
                resolve(true)
            });
        })
    }

    getAvatarHtml(review) {
        let result = `<svg class="avatar-placeholder"><use xlink:href="#svg_circle_profile"></use></svg>`;
        if (review.avatar) {
            result = `<div class="avatar" style="background-image: url(${review.avatar})"></div>`;
        }
        return result;
    }


    printWriteReviewCTA() {
        let nmcvUrl = "https://www.nelmulinochevorrei.it";
        let userMessage = "";
        let buttonText = "";
        let buttonUrl = this.detailSeoUrl;
        if (!this.userLogged) {
            userMessage = `Accedi e invia la tua recensione su ${this.productName}`;
            buttonText = "Accedi";
        } else if (this.userLogged && (!this.searchResult.userReview || this.searchResult.userReview == null)) {
            userMessage = `Invia la tua recensione su ${this.productName}`;
            buttonText = "Scrivi una recensione";
        } else if (this.userLogged && this.searchResult.userReview) {
            if (this.searchResult.userReview.workflowStatus != "PUBLISHED") {
                userMessage = `Stiamo approvando la tua recensione su ${this.productName}`;
                buttonText = "Scopri di più";
                buttonUrl = nmcvUrl;
            } else {
                $(this.element).append(`<div class="write-review-cta">
                  <div class="review-cta-button"><a class="button" href="javascript:void(0)" mb-component-click="openMyReviewModal()" >Guarda la tua recensione</a></div>
                </div>`);
                return;
            }
        } else {
            return;
        }

        $(this.element).append(`<div class="write-review-cta">
                  <h3>${userMessage}</h3>
                  <div class="review-cta-button"><a class="button" href="${buttonUrl}">${buttonText}</a></div>
                </div>`);
    }

    openMyReviewModal() {

        if ($(".page-cnt #modal-review").length == 0) {
            $('.page-cnt').append(`<div id="modal-review" class="modal-dialog" mb-component="ModalComponent"
                                         data-custom-html="true" style="display: none">
                                     <div class='content-wrapper'>
                                        <a mb-component-click="close()" class="close-ico"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_circle_close"></use> </svg></a>
                                        <div class="content modal-review-detail">
                                        </div>
                                     </div>
                                    </div>`);
            let $modal = $('#modal-review .content');
            $modal.html(`<h4>La tua recensione su ${this.productName}:</h4>` + this.buildReviewHtml(this.searchResult.userReview));
            this.parentCtrl.loadComponents();
        }


        $('#modal-review')[0].dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
    }

    getNoReviewHtml() {


        let button = this.searchResult.userReview == null ? `<a class="button" href="${this.detailSeoUrl}">Scrivi una recensione</a>` : this.searchResult.userReview.workflowStatus == "PUBLISHED" ? `<a class="button" href="javascript:void(0)" mb-component-click="openMyReviewModal()">Guarda la tua recensione</a>` : '<a class="button" href="https://www.nelmulinochevorrei.it/">Scopri di più</a>';
        let noReview = this.searchResult.userReview == null ? 'Non ci sono recensioni su questo prodotto. Vuoi essere tu a scrivere la prima?' : this.searchResult.userReview.workflowStatus == "DRAFT" ? `Non ci sono recensioni su questo prodotto. <br/>Stiamo approvando la tua recensione su ${this.productName}.` : '';

        return `<div class="no-review-avaliable">
                  <div class="">${noReview}</div>
                  <div>${button}</div>
                </div>`;
    }

    buildReviewHtml(review) {
        return `<div class="review-item">
                                        <div class="left-side">
                                           <div class="userInput">
                                             ${this.getAvatarHtml(review)}
                                             <div class="user-info">
                                                <div class="name">${review.firstName}</div>
                                                <div class="date">${review.date}</div>  
                                             </div>
                                           </div>
                                           <div class="review-content">
                                            <div class="review-title">Quando mangio ${this.productName} mi sento<span class="emoji">                              
                                                <svg class="brown-arrow">
                                                    <use  xlink:href="#svg_emoji_${review.emoji.toLowerCase()}_on"></use>
                                                </svg>       
                                            </span>
                                            </div>
                                            <div class="review-text">${review.text}</div>
                                           </div>
                                        </div>
                                        <div class="right-side">
                                            <div class="rate ${$(window).width() > 767 ? '' : 'small'}"  mb-component="IconRatingComponent" data-initial-value="${review.avgRate}" data-read-only="true"></div>
                                            <div class="partial-rate">
                                                <div class="rate-title">${this.searchResult.rate1Label || ''}</div>
                                                <div class="mini" mb-component="IconRatingComponent" data-initial-value="${review.rate1}" data-read-only="true"></div>    
                                            </div>
                                            <div class="partial-rate">
                                                <div class="rate-title">${this.searchResult.rate2Label || ''}</div>
                                                <div class="mini" mb-component="IconRatingComponent" data-initial-value="${review.rate2}" data-read-only="true"></div>    
                                            </div>
                                            <div class="partial-rate">
                                                <div class="rate-title">${this.searchResult.rate3Label || ''}</div>
                                                <div class="mini" mb-component="IconRatingComponent" data-initial-value="${review.rate3}" data-read-only="true"></div>    
                                            </div>
                                        </div>
                                     </div>`;
    }

    displayReview() {
        let reviews = this.searchResult.reviews;
        let $reviewContainer = $(this.element).find(".review-items");

        this.searchResult.reviews.forEach((review) => {
            $reviewContainer.append(this.buildReviewHtml(review));
        });


        this.parentCtrl.loadComponents();
        this.updatePagination();
    }

    resetPagination() {
        this.paginationOffset = 1;
    }

    updatePagination() {
        let $paginationEl = $(this.element).find("[data-pagination]");
        if (this.searchResult.searchTypeCount > (this.paginationLimit * this.paginationOffset)) {
            this.paginationOffset++;
            $paginationEl.show();
        } else {
            $paginationEl.hide();
        }
    };

    toggleLoader(display) {
        if (display) {
            $(this.element).append(`<div  data-reviews-loader class="reviews_loader"><svg>
            <use  xlink:href="#svg_loader"></use>
            </svg> 
         </div>`)
        } else {
            $(this.element).find("[data-reviews-loader]").remove();
        }
    }

    getReviewData(searchType = this.searchType.SELECTED.value) {
        this.toggleLoader(true);
        return this.waitForUser().then(() => {
            return HttpClient.doCall({
                method: 'GET',
                url: API_ENDPOINT + `/api/mulinobianco/1.0/web/review/${this.idProduct}/${searchType}`,
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: {offset: this.paginationOffset, limit: this.paginationLimit},
            },true).then((data) => {
                this.searchResult = data.body;
                this.toggleLoader(false);
                return this.searchResult;
            })
        })
    }


}


export default ReviewListComponent;
