import Component from '../model/Component';
import Utility from '../business/Utility';

import $ from 'jquery';

class StickyElementComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.$stickyContainer = null;
        this.elementTop = null;
        this.stickyContainerHeight = null;
        this.resolutionAttr = null;
        this.resolutionAttr = $(this.element).attr("sticky-element-min-resolution");
        this.windowScrollHandler = null;
        this.handleStickyElementDebounced = Utility.debounce(this.handleStickyElement, 300);
        this.init();

    }

    updateDimension() {
        this.stickyContainerHeight = this.$stickyContainer.outerHeight();
        this.elementTop = $(this.element).offset().top;
    }

    scrollHandling() {
        let scrollTop = $(window).scrollTop();
        if (scrollTop >= this.elementTop) {
            this.$stickyContainer.addClass("unpinned");
            this.$stickyContainer.css("margin-bottom", this.stickyContainerHeight);
        } else {
            this.$stickyContainer.removeClass("unpinned");
            this.$stickyContainer.css("margin-bottom", 0);
        }
    }


    handleStickyElement() {
        if (!this.resolutionAttr || $(window).width() >= this.resolutionAttr) {
            if (!this.$stickyContainer) {
                $(this.element).wrap("<div class=\"sticky-element-container\"/>");
                this.$stickyContainer = $(this.element).parent(".sticky-element-container");
            }

            this.updateDimension();

            if (!this.windowScrollHandler) {
                this.windowScrollHandler = () => {
                    this.scrollHandling()
                };
                $(window).scroll(this.windowScrollHandler);
                this.windowScrollHandler = true;
            }
        } else {
            if (this.$stickyContainer) {
                $(this.element).unwrap(".sticky-element-container");
                this.$stickyContainer = null;
                this.windowScrollHandler = null;
                $(window).off("scroll", this.windowScrollHandler);
            }
        }
    }

    init() {
        this.handleStickyElement();
        $(window).resize(() => {
            this.handleStickyElementDebounced();
        });
    }


}

export default StickyElementComponent;
