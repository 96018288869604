import Component from '../model/Component';
import RatingManager from '../business/RatingManager';
import $ from 'jquery';
import Utility from '../business/Utility';
import Tracker from "../business/Tracker";
import UserManager from "../business/UserManager";
import GoogleAnalytics from "../business/GoogleAnalytics";

class RatingComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.instance = null;
        this.ratingKey = this.element.getAttribute("data-id");
        this.ratingExternalId = this.element.getAttribute("data-externalid");
        this.ratingCategory = this.element.getAttribute("data-category");
        this.ratingTrackingName = this.element.getAttribute("data-tr-name");
        this.init();
        this.rateDebounced = Utility.debounce(this.rate, 1200);
        this.unrateDebounced = Utility.debounce(this.unrate, 1200);
        this.rate = null;
    }

    init() {
        RatingManager.registerCategoryKey(this.ratingKey, this.ratingCategory);
        window.document.addEventListener(RatingManager.EVENTS.BYKEYS_DONE, (event) => {
            let ratingElement = RatingManager.getRatingStatus(this.ratingKey, this.ratingCategory, event.detail);
            this.updateRatingElement(ratingElement.rate);
        });

        window.document.addEventListener(RatingManager.EVENTS.RATE_DONE, (event) => {
            if (this.ratingCategory == event.detail.category && this.ratingKey == event.detail.key) {
                this.updateRatingElement(event.detail.ratingData.rate);
            }
        });

        this.element.addEventListener("click", () => {
            if (this.rate > 0) {
                this.rate = 0;
                this.unrateDebounced()
            } else {
                this.rate = 1;
                this.rateDebounced()
            }
            $(this.element).addClass("loadingRate");
        })

    }

    updateRatingElement(rate) {
        this.rate = rate;
        $(this.element).toggleClass("on", rate > 0);
        $(this.element).removeClass("loadingRate");
    }


    rate() {
        UserManager.getLoggedUser().then(()=>{
            var rateStatus = RatingManager.rate(this.ratingCategory, this.ratingKey);

            if (!!this.ratingExternalId && this.ratingKey.startsWith("product")) {
                Tracker.send("EVENT", {interactionType: "ZPRODUCT_LIKE", productCode: this.ratingExternalId});
            }else if(!!this.ratingExternalId && this.ratingKey.startsWith("editorialRecipe")){
                Tracker.send("EVENT", {interactionType: "ZRECIPE_LIKE", productCode: this.ratingExternalId, name:  this.ratingTrackingName});
            }

            const obj = {
                'data-id': this.ratingKey + " " + this.ratingTrackingName,
            }
            GoogleAnalytics.send('add_to_favorite', obj);
        }, () => {
            //POPUP
            $(this.element).removeClass("loadingRate");
            var loginModal = document.getElementById("generic-go-login-modal");
            var button = loginModal.getElementsByClassName("button")[0];
            loginModal.style.display = "";
            loginModal.setAttribute("data-href","/login?urlBack=" + Utility.getCurrentUri());
            button.setAttribute("href","/login?urlBack=" + Utility.getCurrentUri());
            $('.page-cnt').append(loginModal);
        });


    }

    unrate() {
        var rateStatus = RatingManager.unrate(this.ratingCategory, this.ratingKey);
    }
}

export default RatingComponent;
