import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";

class LandingCartaceaCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.userId = null;
        this.init();
    }

    init() {
        GigyaManager.getLoggedUser().then((u) => {
            this.userId = u.UID;
        });

        $('#download-tessera-not-logged-button').click((e) => {
            Utility.trackEvent(this.userId, "CLICK", "CH_992_MLB_WEB_RACPUNTI24_BIS_CRS_20240115_20240115", "DOWNLOAD_TESSERA_NON_PERS");
        })
    }

}

export default LandingCartaceaCtrl;
