import $ from 'jquery';
let $Win = $(window),
    $Doc = $(document);

export default class gReveal
{
    constructor(options)
    {
        this.settings = $.extend(
        {
            selector: '.g-reveal',
            hiddenClass: 'g-reveal-hidden',
            hiddenPrefix: 'g-reveal-hidden-',
            visiblePrefix: 'g-reveal-visible-',
            defaultEffect: 'bottom',
            effectDataKey: 'reveal-class',
            offset: 80,
            delay: 200,
            initialDelay: 400
        }, options);

        this.elements = $(this.settings.selector);

        this.c = 0;
        this.o = 0;
        this.ste = 0;

        this.initElements();
        $Win.on('load.gReveal', () =>
        {
            setTimeout(() =>
            {
                this.update();
                $Win.on('scroll.gReveal resize.gReveal', () => this.update());
            }, this.settings.initialDelay);
        });
    }

    initElements()
    {
        this.elements.each((index, e) =>
        {
            e = $(e);
            if (!e.data('gRevealInit')) e.addClass(this.settings.hiddenClass).data('gRevealInit', true);
        });
    }

    update()
    {
        this.ste = $Doc.scrollTop() + $Win.height() - this.settings.offset;

        this.elements.each((index, e) =>
        {
            e = $(e);
            let co = Math.round(e.offset().top);

            if (this.ste > co && e.is(':visible'))
            {
                if (this.o !== co)
                {
                    this.o = co;
                    this.c = 0;
                }

                e.addClass(e.data(this.settings.effectDataKey) ? this.settings.hiddenPrefix + e.data(this.settings.effectDataKey) : this.settings.hiddenPrefix + this.settings.defaultEffect);

                setTimeout(() =>
                {
                    e.addClass(e.data(this.settings.effectDataKey) ? this.settings.visiblePrefix + e.data(this.settings.effectDataKey) : this.settings.visiblePrefix + this.settings.defaultEffect);
                }, (this.settings.delay * this.c) + this.settings.delay);

                this.elements = this.elements.not(e);
                this.c++;
            }
        });
    }

    destroy()
    {
        $Win.off('.gReveal');
        this.elements.each((index, e) =>
        {
            $(e)
                .data('gRevealInit', null)
                .removeClass(this.settings.hiddenClass)
                .removeClass(e.data(this.settings.effectDataKey) ? this.settings.hiddenPrefix + e.data(this.settings.effectDataKey) : this.settings.hiddenPrefix + this.settings.defaultEffect)
                .removeClass(e.data(this.settings.effectDataKey) ? this.settings.visiblePrefix + e.data(this.settings.effectDataKey) : this.settings.visiblePrefix + this.settings.defaultEffect);
        });
    }
}