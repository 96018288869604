import $ from "jquery";
import {Utils} from "./Utils";

class VideoPreviewComponent {
    constructor(data) {
        this.wrapper = document.querySelector('.umdf-video');
        this.video = document.getElementById('dedica');
        this.data = data;

        this.breakpoints = {};
        this.breakpoints.FRIENDSHIP = [
            {start: 0, end: 1800, id: 'sandwichBag'},
            {start: 20800, end: 23600, id: 'phone'},
            {start: 25700, end: 32900, id: 'card'},
            {start: 45900, end: 52900, id: 'fridge'}
        ];

        this.breakpoints.LOVE = [
            {start: 0, end: 1800, id: 'sandwichBag'},
            {start: 18700, end: 21600, id: 'phone'},
            {start: 23700, end: 30900, id: 'card'},
            {start: 43900, end: 50800, id: 'fridge'}
        ];

        this.breakpoints.FAMILY = [
            {start: 0, end: 1800, id: 'sandwichBag'},
            {start: 18800, end: 21600, id: 'phone'},
            {start: 23700, end: 30900, id: 'card'},
            {start: 43900, end: 50900, id: 'fridge'}
        ];

        this.init();
    }

    checkBreakpointVisibility(currentTime, breakpoint) {
        const element = this.wrapper.querySelector(`#${breakpoint.id}`);
        (currentTime >= breakpoint.start && currentTime <= breakpoint.end) ?
            element.classList.add('visible') :
            element.classList.remove('visible');
    }

    addVideoMessages(time) {
        const currentTime = time * 1000;
        this.breakpoints[this.data.topic.code].forEach((breakpoint) => this.checkBreakpointVisibility(currentTime, breakpoint));
    }

    getIngredientsHtml(list = []) {
        let html = '';
        list.forEach((element) => html += `<div>- ${element.name}</div>`);
        return html;
    }

    getMagnetsHtml(list) {
        let html = '';
        const magnetsList = list.filter((el) => el.videoImagePath && el.categoryCode !== 'BREAD').slice(0, 3);
        magnetsList.forEach((element) => {
            html += `<img class="custom" alt="${element.name}" src="${element.videoImagePath}"/>`;
        });
        return html;
    }

    getSandwichImageUrl() {
        if (this.data.sandwichImage) return this.data.sandwichImage;
        const breadObject = this.data.ingredients.find((element) => element.categoryCode === 'BREAD');
        return breadObject.videoImagePath;
    }

    updateFridgeScreen() {
        this.wrapper.querySelector('.umdf-video__sandwich-name').innerHTML = this.data.sandwichName;

        const ingredientList = this.getIngredientsHtml(this.data.ingredients);
        this.wrapper.querySelector('.umdf-video__sandwich-list').innerHTML = ingredientList;

        const magnets = this.getMagnetsHtml(this.data.ingredients);
        this.wrapper.querySelector('.umdf-video__magnet').insertAdjacentHTML('beforeend', magnets);

        const sandwichImage = this.getSandwichImageUrl();
        this.wrapper.querySelector('.umdf-video__fridge-foto img').setAttribute('src', sandwichImage);
    }

    checkImageLoad() {
        const images = this.wrapper.querySelectorAll('.umdf-video__layer img.custom');
        let counter = images.length;
        images.forEach((img) => {
            img.addEventListener('load', () => {
                counter--;
                !counter && Utils.hideVideoLoader();
            });
        });
        this.updateVideoData();
    }

    cleanVideo() {
        this.wrapper.querySelector('#sandwichBag').innerHTML = '';
        this.wrapper.querySelector('.umdf-video__phone-name').innerHTML = '';
        this.wrapper.querySelector('.umdf-video__card-content').innerHTML = '';
        this.wrapper.querySelector('.umdf-video__phone-img').innerHTML = '';
        this.wrapper.querySelector('.umdf-video__sandwich-name').innerHTML = '';
        this.wrapper.querySelector('.umdf-video__sandwich-list').innerHTML = '';
        this.wrapper.querySelector('.umdf-video__magnet').innerHTML = '';
        this.wrapper.querySelector('.umdf-video__fridge-foto img').setAttribute('src', '');
    }

    pauseVideo() {
        this.video.pause();
    }

    updateVideoData() {
        this.wrapper.querySelector('#sandwichBag').innerHTML =
            `<div class="umdf-video__recipient ${this.data.topic.code}">Per <br>${this.data.recipientName}</div>`;
        this.wrapper.querySelector('.umdf-video__phone-name').innerHTML = this.data.senderName;
        this.wrapper.querySelector('.umdf-video__card-content').innerHTML = this.data.message.replace(/\n/g, '<br>');

        if (this.data.senderImage) {
            const imgHtml = `<img class="custom" alt="${this.data.senderName}" src="${this.data.senderImage}"/>`;
            this.wrapper.querySelector('.umdf-video__phone-img').innerHTML = imgHtml;
        }

        this.updateFridgeScreen();
    }


    addVideoListener() {
        if ('requestVideoFrameCallback' in HTMLVideoElement.prototype) {
            const step = (timestamp, metadata) => {
                this.addVideoMessages(metadata.mediaTime);
                this.video.requestVideoFrameCallback(step);
            }
            this.video.requestVideoFrameCallback(step);
        } else {
            this.video.addEventListener('timeupdate', (e) => this.addVideoMessages(e.target.currentTime), false);
        }
    }

    update(updatedData) {
        this.data = updatedData;
        this.video.addEventListener('canplaythrough', () => this.checkImageLoad(), {once: true});
        this.cleanVideo();
        this.video.src = this.data.topic.video + "#t=0.001";
    }

    init() {
        this.video.src = this.data.topic.video + "#t=0.001";
        this.video.addEventListener('canplaythrough', () => {
            this.checkImageLoad();
            this.addVideoListener();
        }, {once: true});
    }
}


export default VideoPreviewComponent;
