/**
 *
 */
import Controller from '../model/Controller';
import $ from 'jquery';
// import Mustache from "mustache";
import Utility from '../business/Utility';
// import HttpClient from "../business/HttpClient";
// import UserManager from "../business/UserManager";
import range from 'rangeslider.js';
import gReveal from "../vendor/gReveal";
import Masonry from "masonry-layout";

class ImpegnoCtrl extends Controller {
    constructor(element) {
        super(element);
        this.init();
    }

    init() {
        this.reveal = new gReveal(
            {
                initialDelay: 0,
                offset: 120
            }
        );


        this.$toolBox = $('.tool-box');
        this.$range = this.$toolBox.find('input[type="range"]');
        this.currentValue = this.$range.attr('value');
        this.$timeWall = $('#time-wall');

        this.onSlide = Utility.debounce(this.updateTool, 300);
        var r = range;
        var values = [12, 15, 17, 24, 28, 34];
        this.$range.rangeslider({
            polyfill: false,
            onSlide: (position, value) => {
                console.log(position);
                console.log(value);
                this.updateTime(value);
//                this.toggleLoader(true);
                this.onSlide();
                if (($('.time-wall-entry.selected').attr('id') != 'time_' + value) && (values.indexOf(value) >= 0)) {
                    $('.time-wall-entry.selected').stop(false, false).fadeOut(300, function () {
                        $('.time-wall-entry.selected').removeClass('selected');
                        $('#time_' + value).addClass('selected');
                        $('#time_' + value).stop(false, false).fadeIn(300);
                    })
                }
            },
            onInit: () => {
                this.updateTime(12);
                $('#time_12').addClass('selected');
                $('#time_12').fadeIn(300);

            }
        });

        if ($('#slideshow_bottom').html() != null) {
            $("#slideshow_bottom > div:gt(0)").hide();

            setInterval(function () {
                $('#slideshow_bottom > div:first')
                    .fadeOut(1000)
                    .next()
                    .fadeIn(1000)
                    .end()
                    .appendTo('#slideshow_bottom');
            }, 6000);
        }

        const autoplaySlideshowTop = () => {
            return setInterval(function () {
                $('#slideshow_top > div:first')
                    .fadeOut(1000)
                    .next()
                    .fadeIn(1000)
                    .end()
                    .appendTo('#slideshow_top');
            }, 6000);
        }
        let autoplayInterval;
        if ($('#slideshow_top').html() != null) {
            $("#slideshow_top > div:gt(0)").hide();
            autoplayInterval = autoplaySlideshowTop();
        }
        $('.slideshow_top .arrow-forward').click(() => {
            clearInterval(autoplayInterval)
            $('#slideshow_top > div:first').fadeOut(1000)
                .next()
                .fadeIn(1000)
                .end()
                .appendTo('#slideshow_top');
            autoplayInterval = autoplaySlideshowTop();
        })
        $('.slideshow_top .arrow-back').click(() => {
            clearInterval(autoplayInterval)
            $('#slideshow_top div')
                .first().fadeOut(1000).end()
                .last().fadeIn(1000).prependTo('#slideshow_top');

            autoplayInterval = autoplaySlideshowTop();
        })

        if ($('#customslider .box-slider').html() != null) {
            this.slideCount = $('#customslider .box-slider').length;
            this.slideWidth = $('#customslider .box-slider').outerWidth();
            this.slideHeight = $('#customslider .box-slider').outerHeight();
            this.sliderUlWidth = this.slideCount * this.slideWidth;

//	        $('#customslider').css({ width: '990px', height: this.slideHeight });

            $('#customslider .box-slider-container').css({width: this.sliderUlWidth, marginLeft: -this.slideWidth});

            $('#customslider .box-slider-container .box-slider:last-child').prependTo('#customslider .box-slider-container');

//	        if(  this.autoscroll){
//		        setInterval(function () {
//	//	        		console.log('interval ' + this.slideWidth + ' 1');
//		        	 	scrollRight();
//		        }, 5000);
//	        }


        }

        let queryParam = Utility.getUrlParameter('scroll-to');

        $(window).on("load", () => {
            setTimeout(() => {
                if(queryParam){
                    if(queryParam.indexOf('regola') > -1){
                        $('html, body').animate({
                            scrollTop: $("#regola-scrollable").offset().top
                        }, 2000, () => {
                            $('html, body').animate({
                                scrollTop: $('#' + queryParam).offset().top
                            }, 2000);
                        });
                    }else{
                        $('html, body').animate({
                            scrollTop: $('#' + queryParam).offset().top
                        }, 2000);
                    }

                }

                let masonryGrid = $('#masonry-grid');
                if(masonryGrid){
                    this.activeMasonry();
                }
            }, 1200);
        });


    }

    updateTime(value) {

        this.currentValue = value;
        var orario = parseInt(value / 2);
        var minuti = value % 2 == 0 ? "00" : "30";

        $('#time-container').html(orario + ':' + minuti);
    }

    scrollLeft() {
        console.log('prev');
        $('#customslider .box-slider-container').animate({
            left: +$('#customslider .box-slider').outerWidth()
        }, 600, function () {
            $('#customslider .box-slider-container .box-slider:last-child').prependTo('#customslider .box-slider-container');
            $('#customslider .box-slider-container').css('left', '');
        });
    }

    scrollRight() {
        console.log('next');
        $('#customslider .box-slider-container').animate({
            left: -$('#customslider .box-slider').outerWidth()
        }, 600, function () {
            $('#customslider .box-slider-container .box-slider:first-child').appendTo('#customslider .box-slider-container');
            $('#customslider .box-slider-container').css('left', '');
        });
    }

    activeMasonry(){
        this.elMasonry = this.element.querySelector("#masonry-grid");
        new Masonry(this.elMasonry, {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true
        });
    }

}

export default ImpegnoCtrl;
