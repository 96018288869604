/**
 *
 */

import Controller from '../model/Controller';
import HttpClient from "../business/HttpClient";
import GigyaManager from "../business/GigyaManager";

class CartattackUpload extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.init()

        this.fileName = null;
        this.requestInProgress = false;
    }

    init() {

        this.initFotoBtn();
    }

    upload() {
        const inputFile = $('input[name="image"]');
        inputFile.click();
    }

    initFotoBtn() {
        const inputFile = $('input[name="image"]');

        $('#foto-btn').click(function (e) {
            e.preventDefault();
            inputFile.click();
        });

        inputFile.change(() => {
            const file = inputFile[0];
            const selected = inputFile[0].value;

            let fdata = new FormData();
            fdata.append("file", file.files[0]);
            if (selected) {
                $('.white .image-cnt').html(`
					
						<svg><use xlink:href="#svg_loader" /></svg>
					`);

                HttpClient.doCall({
                    method: 'POST',
                    url: API_ENDPOINT + `/api/mulinobianco/1.0/web/ugc/upload`,
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: fdata
                }).then((data) => {
                    this.fileName = data.body.relativePath;

                    let html =
                        `<div class="row">
                                <img src="${data.body.path}">
                                <div class="label"><p>${selected.replace(/.*[\/\\]/, '')}</p></div>
                                <div class="close" mb-click="removeItem();"></div>
                         </div>`
                    $('.white .image-cnt').html(html);

                    console.log(data);
                });
            }
        });
    }

    removeItem() {
        $('.white .image-cnt').html("");
        this.fileName = null;
    }


    send() {
        let name = this.$el.find('[name="name"]').val();
        let city = this.$el.find('[name="city"]').val();
        let privacy = this.$el.find('[name="privacy"]:checked').length;

        if (!name || name == '') {
            this.$el.find('[name="name"]').addClass("error")
            return false;
        } else {
            this.$el.find('[name="name"]').removeClass("error")
        }

        if (!city || city == '') {
            this.$el.find('[name="city"]').addClass("error")
            return false;
        } else {
            this.$el.find('[name="name"]').removeClass("error")
        }

        if (!this.fileName) {
            $('.white .image-cnt').html(`<div class="row"><p class="error">Per procedere è necessario caricare un'immagine!</p></div>`);
            return false;
        }

        if (!privacy) {
            $('.privacy-cnt').append(`<p class="error">Il campo è obbligatorio</p>`)
            return false;
        }

        let obj = {
            name: name,
            city: city,
            image: this.fileName,
            privacy: privacy
        }

        if (!this.requestInProgress) {
            this.requestInProgress = true;

            GigyaManager.addCH('CH_459_MLB_WEB_CARTATTACK_BIS_NAV_20200715_20200909').then((r) => {
                HttpClient.doCall({
                    method: 'POST',
                    url: API_ENDPOINT + `/api/mulinobianco/1.0/web/cartattack/item`,
                    data: JSON.stringify(obj),
                    dataType: "json",
                    contentType: "application/json; charset=utf-8"
                }).then((data) => {
                    $('#upload-successful').show();
                    this.requestInProgress = false;
                }, () => {
                    $('#generic-error-modal').show();
                    this.requestInProgress = false;
                })
            }, (err) => {
                $('#generic-error-modal').show();
                this.requestInProgress = false;
            })

        }

    }
}

export default CartattackUpload;
