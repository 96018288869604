/**
 *
 */
import Controller from '../model/Controller';
import Swiper from "swiper";


class CartattackDetailCtrl extends Controller {

    constructor(element) {
        super(element);
        this.init();
        this.slider = window.jQuery('.spots-slider');
        this.main = window.jQuery('#main-spot');

        this.$el = $(element);

        this.dataVideoGallery = window.dataVideoGallery;

        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        this.player = null;
        window.onYouTubeIframeAPIReady = () => {
            this.player = new YT.Player('player-gallery', {
                height: '100%',
                width: '100%',
                videoId: this.dataVideoGallery[0],
                controls: 2,
                arrows: true,
                events: {
                    onReady: (event) => {
                        this.onReady(event)
                    },
                    'onStateChange': (event) => {
                        this.onPlayerStateChange(event)
                    }
                }
            });
        };

        var options = {
            slidesPerView: 1,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        };

        this.swiper = new Swiper(this.$el.find('.swiper-container'), options);

        $(window).resize(() => {
            this.swiper.update();
        });
    }

    init() {

        this.videoFn('.video-box');
        this.scrollFn('a.scroll');
    }

    onReady(event) {
    }

    videoFn(selector) {
        window.jQuery(selector).find('> a').on('click', (e) => {
            e.preventDefault();
            let box = $(e.target).closest(selector);
            window.jQuery('<iframe frameborder="0" allowfullscreen></iframe>').prop('src', box.data('embed')).wrap('<div class="responsive-video"></div>').parent().appendTo(box);
            box.find('> a').remove();
            return false;
        });
    }

    scrollFn(selector) {

        this.time = 800;
        this.easing = 'swing';

        window.jQuery(selector).on('click', (e) => {
            e.preventDefault();
            let target = window.jQuery(window.jQuery.attr(e.target, 'href'));
            if (target.length) this.scroll(target.offset().top);
            return false;
        });
    }

    initSlider() {
        this.slider.slick(
            {
                accessibility: false,
                nextArrow: $('.slick-next'),
                prevArrow: $('.slick-prev'),
                centerMode: false,
                slide: '.slide',
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: false,
                focusOnSelect: false,
                responsive: [
                    {
                        breakpoint: 768,
                        settings:
                            {
                                slidesToShow: 2
                            }
                    }
                ],
            });
    }

    onPlayerStateChange(event) {
        if (event.data == 5) {
            this.player.playVideo();
        } else if (event.data == 0) {
            $('#cover').show();
        }
    }

    playOrChange(id) {
        $('#cover').hide();
        this.player.loadVideoById(this.dataVideoGallery[id]);
    }

}

export default CartattackDetailCtrl;
