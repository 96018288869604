import Controller from '../model/Controller';
import UserManager from "../business/UserManager";
import GigyaManager from "../business/GigyaManager";
import $ from 'jquery';
import Pristine from "pristinejs/dist/pristine";
import GoogleAnalytics from "../business/GoogleAnalytics";

class NewsletterGoodMorningCtrl extends Controller {
    constructor(element) {
        super(element);
        this.pristine = '';
        this.$el = $(this.element);
        this.initForm();
        this.toggleInput();
    }

    initForm(){
        let form = document.getElementById("newsletter-good-morning");

        let formConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'newsletter-good-morning__radio-container',
            errorClass: 'error',
            successClass: 'has-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'newsletter-good-morning__radio-container',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'error-field'
        };

        // create the pristine instance
        this.pristine = new Pristine(form, formConfig);
    }

    toggleInput(){
        $('input[name="newsletter-goodmorning"]').change((e) => {
            let currentId = $(e.currentTarget).attr('id');

            if ($(e.currentTarget).is(":checked")) {
                if(currentId === 'goodmorning-fullweek'){
                    $('#second-check').addClass('disable');
                }else{
                    $('#first-check').addClass('disable');
                }
            }else{
                $('#first-check').removeClass('disable');
                $('#second-check').removeClass('disable');
            }
        });
    }

    sendForm() {
        let newsletter3nac = $('input[name="newsletter-goodmorning"]:checked').val();
        let newsletterNMCV = $('input[name="nmcv"]').is(":checked");
        let newsletterAnni80 = $('input[name="newsletter-anni-80"]').is(":checked");

        if (newsletter3nac || newsletterNMCV || newsletterAnni80) {
            let choiceSelected = [newsletter3nac];

            UserManager.getLoggedUser().then((d) => {
                let params = {
                    subscriptions: {},
                    callback: (data) => {
                        if (data.status === "OK") {
                            window.location.href = "/newsletter-thank-you";
                        }
                    }
                };

                if(newsletter3nac){
                    params.subscriptions.NL_3NAC = {
                        email: {
                            isSubscribed: true,
                            tags: choiceSelected
                        }
                    }
                }

                if(newsletterNMCV){
                    params.subscriptions.NL_NMCV = {
                        email: {
                            isSubscribed: true
                        }
                    }
                }

                if(newsletterAnni80){
                    params.subscriptions.NL_ANNI80 = {
                        email: {
                            isSubscribed: true
                        }
                    }
                }

                GigyaManager.setAccountInfo(params);

                const obj = {
                    'data-form-id': 'newsletter-good-morning',
                    'data-form-name': 'newsletter Good Morning'
                }

                GoogleAnalytics.send('form_submitted', obj);
            }, () => {
                // save values in localstorage
                localStorage.setItem("newsletter-goodmorning", newsletter3nac);
                localStorage.setItem("newsletter-goodmorning-nmcv", newsletterNMCV);
                localStorage.setItem("newsletter-goodmorning-anni-80", newsletterAnni80);
                localStorage.setItem("newsletter-goodmorning-redirect", "false");
                window.location.href = "/login?regSource=677";
            });


        }else{
            $(".newsletter-good-morning__radio-container").append('<span class="pristine-error error-field" id="error-tre-notizie">Per iscriverti ad una delle newsletter Mulino Bianco è necessario compilare uno dei campi</span>');
        }
    }

}

export default NewsletterGoodMorningCtrl;
