"use strict";

const Button = (props) => {
    let component;
    switch (props.type) {
        case 'goNext':
            component = `<button type="button" id="gonext-${props.id}" class="button dark action-button disabled small-size" mb-component-click="goToNextStep(${props.nextStep})">Avanti</button>`
            break
        case 'submitForm':
            component = `<button type="button" id="form-submit-button" class="button dark action-button disabled small-size  form-button" mb-component-click="goToNextStep(${props.nextStep})">Avanti</button>`
            break
        case 'card':
            component = `<button type="button" mb-component-click="goToNextStep(${props.nextStep})" class="button card flat-cta shadow">
                    <div class="card-title">${props.title}</div>
                    <div class="card-description">${props.subtitle}</div>
                    <i class="arrow full right"></i>
                </button>`
            break
    }

    return component;

}

export default Button;
