import Choices from "choices.js";
import $ from "jquery";
import Controller from '../model/Controller';

import HttpClient from "../business/HttpClient";
import Mustache from "mustache";

class ArmonieCatalogoCtrl extends Controller {
    constructor(element) {
        super(element);
        this.element = element;
        this.urlArmoniaByTag = API_ENDPOINT + '/api/mulinobianco/1.0/web/product/search-by-tag/ARMONIA';
        this.queryParams = {};

        this.idIngredient = this.getParameterByName('idIngredient');
        this.idFoodPreference = this.getParameterByName('idFoodPreference');
        this.idConsumptionMoment = this.getParameterByName('idConsumptionMoment');
        this.init();
    }

    init() {
        this.initSelectRecipe();
        this.initSelectPreferences();
        this.initSelectMoment();
        this.serchByTagClick();
        this.serchByTagQueryString();
    }

    initSelectRecipe(){
        const element = document.getElementById('armonie-recipe');
        const armonieRecipe = new Choices(element,{
            allowHTML: true,
            searchEnabled: false,
            removeItemButton: true,
            shouldSort: false
        });

        // pre-flag
        if(this.idIngredient){
            this.queryParams.idIngredient = this.idIngredient;
            $('.armonie-catalogo__search').removeClass('armonie-catalogo__search--disabled');
            armonieRecipe.setChoiceByValue(this.idIngredient);
        }
        element.addEventListener(
            'choice',
            (event) => {
                if(event.detail.choice.value !== ""){
                    this.queryParams.idIngredient = event.detail.choice.value;
                    $('.armonie-catalogo__search').removeClass('armonie-catalogo__search--disabled');
                }else{
                    delete this.queryParams.idIngredient;
                }
            },
            false,
        );
    }

    initSelectPreferences(){
        const element = document.getElementById('armonie-preferences');
        const armoniePreferences = new Choices(element, {
            allowHTML: true,
            searchEnabled: false,
            removeItemButton: true,
            shouldSort: false
        });

        // pre-flag
        if(this.idFoodPreference){
            this.queryParams.idFoodPreference = this.idFoodPreference;
            $('.armonie-catalogo__search').removeClass('armonie-catalogo__search--disabled');
            armoniePreferences.setChoiceByValue(this.idFoodPreference);
        }
        element.addEventListener(
            'choice',
            (event) => {
                if(event.detail.choice.value !== ""){
                    this.queryParams.idFoodPreference = event.detail.choice.value;
                    $('.armonie-catalogo__search').removeClass('armonie-catalogo__search--disabled');
                }else{
                    delete this.queryParams.idFoodPreference;
                }
            },
            false,
        );
    }

    initSelectMoment(){
        const element = document.getElementById('armonie-moment');
        const armonieMoments = new Choices(element, {
            allowHTML: true,
            searchEnabled: false,
            removeItemButton: true,
            shouldSort: false
        });

        // pre-flag
        if(this.idConsumptionMoment){
            this.queryParams.idConsumptionMoment = this.idConsumptionMoment;
            $('.armonie-catalogo__search').removeClass('armonie-catalogo__search--disabled');
            armonieMoments.setChoiceByValue(this.idConsumptionMoment);
        }
        element.addEventListener(
            'choice',
            (event) => {
                if(event.detail.choice.value !== ""){
                    this.queryParams.idConsumptionMoment = event.detail.choice.value;
                    $('.armonie-catalogo__search').removeClass('armonie-catalogo__search--disabled');
                }else{
                    delete this.queryParams.idConsumptionMoment;
                }
            },
            false,
        );
    }

    /**
     * Search products by click button
     */
    serchByTagClick(){
        $('#armonie-search-button').click(() => {
            this.searchByTag();
        });

    }

    /**
     * Search products by query string
     */
    serchByTagQueryString(){
        $(document).ready(() => {
            if(this.idIngredient || this.idFoodPreference || this.idConsumptionMoment){
                this.searchByTag();
            }
        });
    }

    searchByTag(){
        const params = new URLSearchParams(this.queryParams);
        let url = this.urlArmoniaByTag + (params.toString() !== "" ? '?' + params.toString() : '');

        $(".armonie-catalogo__products-wall").addClass('on-loading');
        HttpClient.doCall({
            url: url,
            type: "GET"
        }, false).then((data) => {
            if(data.body){
                let body = '';

                if(data.body.length > 0){
                    let $tmpContainer = $("<div/>");

                    let addItemEvent = new CustomEvent("product-list-remove-all-items", {"detail": $('.thumbnails').find('.thumbnail')});
                    document.dispatchEvent(addItemEvent);

                    $tmpContainer.load('/fe-web/include/product-finder/thumbnail-product.jsp', (resp) => {
                        for (let i = 0; i < data.body.length; i++) {
                            let dataTmpl = {
                                id: data.body[i].content.id,
                                name: data.body[i].content.name,
                                isNew: data.body[i].content.isNew,
                                linkUrl: data.body[i].content.seoUrl,
                                linkTarget: '_self',
                                externalId: data.body[i].content.externalId,
                                couponValue: data.body[i].content.couponValue,
                                image: data.body[i].content.image,
                                packImage: data.body[i].content.packImage,
                                isGlutenFree: data.body[i].content.glutenFree,
                                isLactoseFree: data.body[i].content.lactoseFree,
                                nutritionTipText: data.body[i].content.nutritionTips
                            }

                            let thumbType = '';
                            let bckColor = '';
                            if(data.body[i].content.highlighted){
                                thumbType = 'thumbnail--double-x';
                            }

                            if(data.body[i].content.color){
                                bckColor = 'style="background-color: ' + data.body[i].content.color + '"';
                                dataTmpl.color = bckColor;
                            }

                            let thumbnailProductString = Mustache.render($tmpContainer[0].innerHTML, dataTmpl);
                            let thumbnailProductHtml = $.parseHTML(thumbnailProductString);
                            let thumbHtml = $(thumbnailProductHtml[0]).wrap(`<div class='thumbnail ${thumbType}' ${bckColor} data-type='PRODUCT'></div>` );

                            $(".thumbnails").append(thumbHtml[0].parentElement.outerHTML);

                            let addItemEvent = new CustomEvent("product-list-add-item", {"detail": thumbHtml[0].parentElement.outerHTML});
                            document.dispatchEvent(addItemEvent);
                        }
                        $('.armonie-catalogo__empty-products').hide();


                        var event = new CustomEvent("product-list-component-resize", {"detail": ""});
                        document.dispatchEvent(event);
                    });
                }else{
                    $('.thumbnails').html('');
                    $('.thumbnails').height(0);
                    $('.armonie-catalogo__empty-products').show();
                }
            }


            $(".armonie-catalogo__products-wall").removeClass('on-loading');
        }, () => {
            $(".armonie-catalogo__products-wall").removeClass('on-loading');
        });
    }

    getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
}

export default ArmonieCatalogoCtrl;
