import $ from "jquery";

class ComboBox {

    constructor(target) {
        this.target = target;
        this.options = target.find('.combo-options');
        this.optionStatus = false;
        this.placeholder = target.data('placeholder');

        this.label = target.find('.combo-value .text');
        this.selectedValue = -1;
        $(window).click((e) => {
            this.toggleOptions(false);
        });

        target[0].toggleOptions = this.toggleOptions.bind(this);

        target.find('.combo-value').click((e) => {
            e.stopPropagation();
            this.toggleOptions();
        });
        target.find('.combo-options .combo-close').click((e) => {
            e.stopPropagation();
            this.toggleOptions(false);
        });
        target.find('.combo-options .combo-option').click((e) => {
            e.stopPropagation();
            this.toggleOptions(false);
            // var element = $(e.currentTarget);

            this.setValue(e.currentTarget.innerText, e.currentTarget.dataset.value);
            this.updateSelected(e.currentTarget);

        });


    }

    updateSelected(element) {
        this.target.find('.combo-options .combo-option').removeClass('selected');
        if (element) {
            element.classList.add("selected");
        }

    }


    setValue(l, id = -1) {
        var oldValue = this.selectedValue;
        if (oldValue != id.toString()) {
            var element = Array.from(this.target[0].querySelectorAll(".combo-options .combo-option"))
                .filter(option => {
                    return option.dataset.value === id.toString()
                })[0];

            var label = "";
            if (element) {
                label = element.innerText;
            }
            this.updateLabel(label);
            this.updateSelected(element);
            this.selectedValue = id.toString();
            this.target[0].dispatchEvent(new CustomEvent("combo-box-changed", {
                detail: {
                    id: isNaN(id) ? id : Number(id),
                    label
                }
            }));
        }

    }

    toggleOptions(show) {
        var open;

        if (show == undefined) {
            open = !this.optionStatus;
        } else {
            if (show && !this.optionStatus) {
                open = true;
            } else if (!show && this.optionStatus) {
                open = false;
            }
        }

        if (open) {
            Array.from(document.querySelectorAll(".mb-combobox.open")).forEach(combo => {
                combo.toggleOptions(false);
            });
            this.target.addClass("open");
            this.optionStatus = true;

            this.options.show();
            document.body.classList.add("mb-combobox-opened");

        } else if (!open) {
            this.target.removeClass("open");
            this.optionStatus = false;
            this.options.hide();
            document.body.classList.remove("mb-combobox-opened");
        }
    }

    updateLabel(newLabel) {
        if (newLabel) {
            this.target.find('.combo-value .text').text(newLabel);
            this.target.addClass("valorized");
        } else {
            this.target.find('.combo-value .text').text(this.placeholder);
            this.target.removeClass("valorized");
        }
    }
}

export default ComboBox;
