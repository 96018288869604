import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";
import Constants from "../business/Constants";
import $ from "jquery";
import Pristine from "pristinejs";

class LandingCalendario2025Ctrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.pristine;
        this.landingCalendarioCH = 'CH_1039_MLB_WEB_CALENDARIO2025_NAV_NAV_20241201_20241201';
        this.regSource = 1039;

        this.init();
    }

    init() {
        GigyaManager.getLoggedUser().then((user) => {
            const ch = user.data[this.landingCalendarioCH];
            if (typeof ch !== "undefined" && ch != null && ch !== "") {
                this.showLoggedArea();
            }else{
                this.initForm();
                this.handleForm();
                $('#modal').show();
            }
        }, () => {
            $('.not-logged').css('display','flex');
            $('.logged').remove();
            $('.login-registration-button').attr('href','/login?urlBack=' + Utility.getCurrentUri() + '&regSource=' + this.regSource);
        });
    }

    initForm() {
        let form = document.getElementById("accept-rule");

        let formConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'generic-modal__radio',
            errorClass: 'error',
            successClass: 'has-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'generic-modal__radio',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'error-field'
        };

        this.trimRequired();

        // init form validator
        this.pristine = new Pristine(form, formConfig);
    }

    handleForm(){
        $('#accept-rule').submit((e) => {
            e.preventDefault();
            let valid = this.pristine.validate();

            if(valid){
                if($('#acceptance').length){
                    GigyaManager.addCH(this.landingCalendarioCH).then(() => {
                        $('#modal').hide();
                        this.showLoggedArea();
                    }, () => {

                    });
                }
            }
        })
    }

    showShareModal() {
        document.getElementById('shareModal').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
    }

    onShareClick(shareButton) {
        const urlPage = window.location.href;
        const titlePage = 'Scopri subito il nuovo calendario 2025 di Mulino Bianco';

        shareButton = shareButton.replaceAll("'", '');
        let urlSocial = Utility.getShareLink(shareButton, titlePage, urlPage);
        if(urlSocial) window.open(urlSocial, '_blank');
    }

    showAllUsersArea() {

    }

    /**
     * Trim all required fields
     */
    trimRequired() {
        Pristine.addValidator('required', (value) => {
            return (value.trim() !== '');
        }, "", 5, false);
    }

    /**
     * Show some areas if user is logged, hide otherwise
     */
    showLoggedArea(){
        $('.logged').css('display','flex');
        $('#isuu-iframe').html('<div style="position:relative;padding-top:max(60%,326px);height:0;width:100%"><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style="position:absolute;border:none;width:100%;height:100%;left:0;right:0;top:0;bottom:0;" src="https://e.issuu.com/embed.html?d=mulino_bianco_calendario_50_issuu_1_&u=mulinobianco"></iframe></div>');
        $('.landing-calendario-2025__isuu').css('display','block');
    }
}

export default LandingCalendario2025Ctrl;
