import Select from "../common/select";
import ImageUpload from "../common/imageUpload";
import InputField from "../common/inputField";
import Button from "../common/button";
import StepHeader from "../common/stepHeader";

export default function PromoTemplate(config) {
    const prefill = config.prefillData
    const promoList = config.config.promoList || []
    let contestList = [];
    let subjectList = {};
    for (let promo of promoList) {
        contestList.push({label: promo.name, value: promo.promoCode})
        //   subjectList[promo.promoCode] = promo.subject
    }
    let selected = prefill && prefill.contest

    const contextSelect = Select("PromoCode", contestList, {value: '', label: 'Scegli il concorso*'}, {
        size: 'm',
        class: 'sm-full',
        selected: selected
    })

    const subjectSelect = Select("Classification", [], {
        label: "Tipologia richiesta",
        value: ""
    }, {size: 'm', class: 'sm-full'});

    let html = `       
            ${StepHeader({title: config.title, subtitle: config.subtitle})}
            <div class="items-container">  
                 ${contextSelect}
                 ${subjectSelect}           
            </div>          
            <div class="telephone-info"></div>   
            <div class="items-container items-container__upload-image">                
                ${ImageUpload({id: 1, next: 2})}
                ${ImageUpload({id: 2, next: 3, isDisabled: true})}
                ${ImageUpload({id: 3, isDisabled: true})}       
            </div>
             <div class="items-container">     
            ${InputField({type: 'textarea', name: 'MessageText', placeholder: 'Cosa ci vuoi suggerire?*'})}
            </div>
            <div class="step-disclaimer">*I campi contrassegnati con l’asterisco sono obbligatori</div>
            <div class="item-centered">
                ${Button({
        id: 3,
        type: 'goNext',
        nextStep: 4,
    })}
            </div>
            `;
    return html
}