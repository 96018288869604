import Component from '../../model/Component';

class FirstStepComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;

        $(this.element).append(this.createHtml());
    }

    goToNextStep() {
        console.log(this.store.getState());

        this.store.dispatch({...this.store.getState(), type: 'armonieForm/goToNextStep'});

        console.log(this.store.getState());
    }

    createHtml() {
        return `
            
        `;
    }

}


export default FirstStepComponent;
