// dependencies
import $ from 'jquery';

// K+ component
import Component from '../model/Component';
import Constants from "../business/Constants";

class FeedInstagramComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.element = element;
        this.feedEndpoint = "/fe-web/data/wawawiwa/feed.json";
        this.init();
        this.openEmbed();
    }

    init(){
        $.getJSON(this.feedEndpoint, function (data) {
            // clear
            localStorage.removeItem('feed-wawawiwa');

            localStorage.setItem('feed-wawawiwa', JSON.stringify(data));
            if(data.length > 0){
                let items = '';
                $.each(data, (key, value) => {
                    items += `
                        <div class="section-feed-instagram__item" 
                            id="${value.id}"
                            data-published="${value.published}">
                            <i class="icon ${value.published === true ? 'icon-layers' : 'icon-instagram'}"></i>
                            <img src="${value.image}" class="section-feed-instagram__image">
                            ${value.published === false ? '<div class="overlay-filter">In arrivo<div class="overlay-instagram"><a href="https://www.instagram.com/mulinobianco/" target="_blank"><div class="overlay-instagram__claim">Segui le prossime uscite <br>su Instagram!</div><div class="overlay-instagram__logo"></div><div class="overlay-instagram__label">@mulinobianco</div></div></a></div>' : ''}
                        </div>`;
                });

                $(".section-feed-instagram__wrapper-items").html(items);
            }
        });
    }

    openEmbed(){

        $(document).on('click', '.section-feed-instagram__item[data-published="true"]', function(e) {
            let itemId = parseInt($(this).attr('id'));
            let objectFeed = JSON.parse(localStorage.getItem('feed-wawawiwa'));

            let singleNodeItem = objectFeed.filter(item => item.id === itemId);

            // reset
            $('#modal-feed-detail .content-wrapper .content').html();

            if(singleNodeItem){
                let embedItem = singleNodeItem[0].embed;
                embedItem = $.parseHTML(embedItem);
                $('#modal-feed-detail .content-wrapper .content').html(embedItem);
                window.instgrm.Embeds.process();
            }
            document.getElementById('modal-feed-detail').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
        });
    }
}

export default FeedInstagramComponent;
