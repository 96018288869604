import $ from 'jquery';
import Component from '../model/Component';
import Tracker from "../business/Tracker";

class TrackingComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        let $el = $(element);
        let map = $el.data();

        this.payload = {};
        this.hasClicked = false;

        Object.keys(map).forEach((key) => {
            if (key.startsWith("trk")) {
                let newKey = this.lowerizeFirstLetter(key.replace("trk", ""));
                this.payload[newKey] = map[key];
            }
        });

        $(this.element).click((e) => {

            if (!this.hasClicked) {
                var copy = $.extend(true, {}, e);
                e.preventDefault();
                e.stopPropagation();
                this.hasClicked = true;

                Tracker.send("EVENT", this.payload).then(() => {
                    $(copy.target.parentNode).trigger(copy);
                }).catch((err) => {
                    $(copy.target.parentNode).trigger(copy);
                    console.error(err);
                });
            }
        });


    }


    lowerizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }
}


export default TrackingComponent;
