import Component from '../../model/Component';
import {StepHandler} from "./StepHandler";
import Templates from "./templates"

class InputStep3Component extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;

        $(this.element).html(this.createHtml(store.getState().userChoices.step2));

        this.stepHandler = StepHandler($(this.element))
        this.stepHandler.handleInput(store.getState().userChoices.step2)


        this.store.subscribe(() => {
            let state = this.store.getState();

            if (state.currentStep === 3) {
                if (state.previousStep < state.currentStep) {
                    $(this.element).html(this.createHtml(store.getState().userChoices.step2));
                    this.stepHandler.reset()
                    this.stepHandler.handleInput(store.getState().userChoices.step2)
                } else {
                    this.stepHandler.reload()
                }
            }
        });
    }

    goToNextStep(id) {
        if (this.stepHandler.canGoNext()) {
            const data = this.stepHandler.getFormData()
            let userChoice = {}//this.store.getState().step2.children.filter(o => o.id == id)[0];
            this.store.dispatch({
                ...this.store.getState(),
                type: 'contactForm/goToNextStep',
                payload: {step3: userChoice, formData: data}
            });
        }
    }

    back() {
        this.store.dispatch({...this.store.getState(), type: 'contactForm/back'});
    }

    deleteFormdata() {
        const data = this.stepHandler.getFormData()
        this.store.dispatch(...this.store.getState(), {
            type: 'contactForm/deleteFormdata',
            payload: {step3: {}, formData: data}
        });
    }

    createHtml(config) {
        let templateConfiguration = Object.assign({}, config, {prefillData: this.store.getState().prefillData})
        const templates = Templates()
        switch (config.type) {
            case 'PRODUCT':
                return templates.product(templateConfiguration)
            case 'PRODUCT_SIMPLE':
                return templates.productSimple(templateConfiguration)
            case 'PROMO':
                return templates.promo(templateConfiguration)
            case 'TEXT':
                return templates.text(templateConfiguration)
            case 'CURRICULUM':
                return templates.curriculum(templateConfiguration)
        }
    }

}

export default InputStep3Component;
