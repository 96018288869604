import Component from '../model/Component';
import $ from 'jquery';
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";

class LandingMooncakeComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.init();
        this.requestInProgress = false;
        this.urlBack = `/login?urlBack=${window.location.pathname}&regSource=1023`;
        this.mooncakeCH = 'CH_1023_MLB_WEB_MOONCUORCAKE_CRS_NAV_20240617_20240617';
        this.mooncakeUser = false;
    }

    init() {
       this.userCta();
       this.goToTheFridge();
       this.buildTy();
    }

    goToTheFridge() {
        $('.landing-mooncake__fridge-cta').on('click',()=> {
            $('.landing-mooncake__fridge-cta').addClass('disabled');
            $('.landing-mooncake__fridge-image-end').addClass('visible');
            $('.landing-mooncake__fridge-image').addClass('hidden');
        });
    }

    userCta() {
        $('.landing-mooncake #overlayer').addClass('visible');
        GigyaManager.getLoggedUser().then((u) => {
            this.mooncakeUser = Boolean(u.data.CH_1023_MLB_WEB_MOONCUORCAKE_CRS_NAV_20240617_20240617);
            $('.landing-mooncake__hero, .ice .landing-mooncake__box-info').append('<div class="landing-mooncake__hero-cta logged">Scarica subito la guida</div>');
            $('.landing-mooncake #overlayer').removeClass('visible');

        }, (err)=>{
            $('.landing-mooncake__hero, .ice .landing-mooncake__box-info').append(`<a target="_self" href="${this.urlBack}" class="landing-mooncake__hero-cta">Registrati e ottieni la guida</a>`);
            $('.landing-mooncake #overlayer').removeClass('visible');
        });
    }

    buildTy() {
        $(document).on('click','.landing-mooncake__hero-cta.logged, .mooncake-logged, .ice .landing-mooncake__box-info .landing-mooncake__hero-cta.logged', (e)=> {
            if (!this.requestInProgress) {

                if (this.mooncakeUser || e.currentTarget.classList.contains('mooncake-logged')) {
                    //create run time link
                    $('body').append('<a target="_blank" download="guida" href="/fe-web/pdf/landing-cuorcake/Guida_Freschissima_Cuorcake.pdf" class="mooncakeGuide"></a>');
                    $('.mooncakeGuide')[0].click();
                    $('.mooncakeGuide')[0].remove();

                    $('.landing-mooncake #overlayer').addClass('visible');
                    this.requestInProgress = true;

                    GigyaManager.addCH(this.mooncakeCH).then(() => {
                        $('.landing-mooncake__hero, .landing-mooncake__box:not(.ty, .social)').addClass('hidden');
                        $('.landing-mooncake__box.ty').removeClass('hidden');
                        $('.landing-mooncake__modal').hide();

                        this.requestInProgress = false;
                        $('.landing-mooncake #overlayer').removeClass('visible');

                        $([document.documentElement, document.body]).animate({
                            scrollTop: 0
                        }, 500);
                    }, (err) => {
                        console.log(err)
                        this.requestInProgress = false;
                        $('.landing-mooncake #overlayer').removeClass('visible');
                    })

                } else {
                    //modale + flag accettazione
                    $('.landing-mooncake__modal').show();
                }
            }
        })
    }
}

export default LandingMooncakeComponent;