/**
 * E' un singleton
 */
import HttpClient from './HttpClient';
import UserManager from './UserManager';

class ProductFinderManager {

    constructor() {
    }

    loadUserProfile() {

        return UserManager.getLoggedUser().then((user) => {
            return HttpClient.doCall({
                url: API_ENDPOINT + "/api/mulinobianco/1.0/web/member/prefs",
                type: "GET",
                dataType: "json",
                contentType: "application/json; charset=utf-8",
            }).then((data) => {

                if (!mb.site.searchFiltersId.consumptionMoment && mb.site.searchFiltersId.consumptionMoment == "") {
                    if (data.body.ingredient && data.body.ingredient.id) {
                        mb.site.searchFiltersId = {
                            consumptionMoment: 252
                            , ingredients: data.body.ingredient.id
                            , freeFrom: ""
                        };

                        window.document.dispatchEvent(new CustomEvent("ProductFinder.REQUEST_WALL"));
                    }
                }

                return user;
            });

        }, () => {
            return null;
        })
    }


    attachProductFinderBundle() {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.src = "/fe-web/js/product-finder-bundle.js";
        $("body").append(s);

        $('body').on('#pf-start-search', 'click', () => {
            window.stopThatThing = false;
            window.pickaxeNewPage = true;
        });
    }


    getConfiguration(objectParam) {
        return HttpClient.doCall({
            url: API_ENDPOINT + "/api/mulinobianco/1.0/web/productfinder/configuration",
            type: "GET",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            data: objectParam
        });
    }

}


export default new ProductFinderManager();
