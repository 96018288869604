import Component from '../../model/Component';
import Utility from "../../business/Utility";
import $ from "jquery";
import HttpClient from "../../business/HttpClient";
import PromoLocatorInformationWindow from "../../components/promoLocator/PromoLocatorInformationWindow";
import PromoLocatorEventFilterComponent from "../../components/promoLocator/PromoLocatorEventFilterComponent";
import Constants from "../../business/Constants";

class PromoLocatorComponent extends Component {

    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.initialZoom = 5;
        this.map = null;
        this.$map = $(document.getElementById('map'));
        this.markers = [];
        this.bounds = new google.maps.LatLngBounds();
        this.searchPromotionsDebounced = Utility.debounce(this.searchPromotions, 300);
        this.boundListenerInstance;
        this.geocoder = new google.maps.Geocoder;
        this.filters = {};
        this.init();
    }

    init() {
        let initialLocation = {lat: 42.473074, lng: 12.864645};
        this.map = new google.maps.Map(this.$map[0], {
            zoom: this.initialZoom,
            center: initialLocation,
            disableDefaultUI: true,
            zoomControl: true
        });

        window.addEventListener("orientationchange", () => {
            if (this.promoLocatorInformationWindow) {
                this.promoLocatorInformationWindow.close();
            }
        }, false);

        if ($(window).width() >= 768) {
            window.document.addEventListener(Constants.events.promoLocatorInformationWindow.action, (ev) => {
                this.boundListener(false);

                let action = ev.detail.action;
                if (action == "open") {
                    this.$map.css("width", this.$map.outerWidth() - ev.detail.windowDimension);
                } else if (action == "close") {
                    this.$map.css("width", "100%");
                }
                setTimeout(() => {
                    this.boundListener(false);
                    google.maps.event.trigger(this.map, "resize");
                    if (this.bounds === undefined || this.markers.length == 0) {
                        this.map.setCenter({
                            lat: this.filters.place.geometry.location.lat(),
                            lng: this.filters.place.geometry.location.lng()
                        });
                    } else {
                        console.log("bounds: ", this.bounds, " Get Center:", this.bounds.getCenter());
                        this.map.setCenter(this.bounds.getCenter());
                        //this.map.fitBounds(this.bounds);
                        this.updateCluster();
                    }
                    this.boundListener(true);
                }, 500);
            })
        }

        this.initEvents();
        this.initFilter();
        this.doInitSearch();
    }

    doInitSearch() {

        navigator.permissions.query({name: 'geolocation'}).then((result) => {
            if (result.state === 'granted') {
                this.setCurrentPosition().then(() => {
                    this.searchPromotions(true);
                }, () => {
                    setTimeout(() => {
                        this.searchPromotions(false)
                    }, 500);
                });
            } else if (result.state === 'prompt') {
                setTimeout(() => {
                    this.searchPromotions(false)
                }, 500);
            } else {

                setTimeout(() => {
                    this.searchPromotions(false)
                }, 500);

            }
        });


        // return !!verifyAndSearch;
    }

    initEvents() {
        window.document.addEventListener(Constants.events.promoLocator.highlightStore, (data) => {
            let marker = this.markers.filter((marker) => {
                return marker.idStore == data.detail.idStore;
            })[0];
            if (!this.highlightCluster(marker, data.detail.status)) {
                this.toogleHighlightMarker(marker, data.detail.status);
            }
        });
        window.document.addEventListener(Constants.events.promoLocator.doSearch, () => {
            this.searchPromotions(false);

        });
    }

    initFilter() {

        $(this.element).find("[data-promo-locator-search-button]").click(() => {
            this.searchPromotions(true);
        });

        window.document.addEventListener(Constants.events.PromoLocatorEventFilterComponent.filterChanged, (data) => {
            this.eventFilter = data.detail;
        });

        let plventFilterEl = $(this.element).find(".promo-locator-event-filter-component")[0];
        this.plEventFilter = new PromoLocatorEventFilterComponent(plventFilterEl, this.parentCtrl);
        if (location.search.split('idStoreEvent=')[1] > 1) {
            var param = location.search.split('idStoreEvent=')[1].split("&")[0];
            if (param) {
                this.plEventFilter.filterByEvent(param);
            }
        }

        this.autocomplete = new google.maps.places.Autocomplete($(this.element).find("#pac-input")[0]);
        this.autocomplete.bindTo('bounds', this.map);
        this.autocomplete.addListener('place_changed', () => {
            this.filters.place = this.autocomplete.getPlace();
            this.searchPromotions(true);
        });

        jQuery(this.element).find("#date-search").pickadate({
            min: true,
            today: '',
            clear: 'In ogni momento',
            close: 'Chiudi',
            onSet: (context) => {
                this.filters.date = context.select;
            }
        });

        $(this.element).find(".geo_finder").click(() => {
            this.setCurrentPosition().then(() => {
                this.searchPromotions(true);
            }, () => {
                this.searchPromotions(false);
            })
        });


    }

    boundListener(attachOrDetach) {
        google.maps.event.clearListeners(this.map, 'idle');
        if (attachOrDetach) {
            setTimeout(() => {
                this.map.addListener('idle', () => {
                    this.searchPromotionsDebounced();
                });
            }, 400);
        }
    }

    buildFilters() {

        let filters = {};
        if (this.filters && this.filters.date) {
            filters.date = this.filters.date;
        }

        if (this.eventFilter) {
            filters.idsInStoreEvents = this.eventFilter;
        }
        return filters;
    }

    initPromoLocatorInformationWindow() {
        if (!this.promoLocatorInformationWindow) {
            this.promoLocatorInformationWindow = new PromoLocatorInformationWindow($(this.element).find(".promo-information-window")[0], this.parentCtrl);
        }
    }

    toggleLoader(show) {
        $(".promo-loader").toggleClass("show-promo-loader", show);
    }

    /**
     *
     * @param notIdleSearch mi dice se la ricerca non è frutto di zoom o drag sulla mappa.
     */
    searchPromotions(notIdleSearch) {

        this.initPromoLocatorInformationWindow();

        let fitBounds = false;

        let callObject = null;

        if (notIdleSearch) {
            if ($(window).width() < 768) {
                $('html, body').animate({
                    scrollTop: this.$map.offset().top - 100
                }, 300);
            }
        }

        if (!this.filters.place || !notIdleSearch) {
            this.toggleLoader(true);
            let bounds = this.map.getBounds();
            callObject = {
                method: 'GET',
                url: API_ENDPOINT +
                    `/api/mulinobianco/1.0/web/instoreeventdetail/poi/${bounds.getNorthEast().lat()}/${bounds.getNorthEast().lng()}/${bounds.getSouthWest().lat()}/${bounds.getSouthWest().lng()}`,
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: this.buildFilters()
            }
        } else {
            let placeLcation = null;
            if (this.filters.place.geometry) {
                placeLcation = this.filters.place.geometry.location;
                this.toggleLoader(true);
            }

            fitBounds = true;
            callObject = {
                method: 'GET',
                url: API_ENDPOINT +
                    `/api/mulinobianco/1.0/web/instoreeventdetail/poi/${placeLcation.lat()}/${placeLcation.lng()}`,
                dataType: "json",
                contentType: "application/json; charset=utf-8",
                data: this.buildFilters()
            }
        }

        this.promoLocatorInformationWindow.displayLoader(true);
        return HttpClient.doCall(callObject).then((data) => {
            let searchResults = data.body;


            this.boundListener(false);
            this.markers = [];
            this.promoLocatorInformationWindow.updateList(searchResults, notIdleSearch ? this.filters.place : null, this.currentLocation);

            if (searchResults.length >= 0) {
                this.bounds = new google.maps.LatLngBounds();
                let markerInserted = 0;
                for (var i = 0; i < searchResults.length; i++) {
                    if (this.createMarker(searchResults[i])) {
                        markerInserted++;
                    }
                }
                //Fit To bound
                if (fitBounds == true) {
                    if (searchResults.length == 0 && !!this.filters.place) {
                        this.map.setCenter({
                            lat: this.filters.place.geometry.location.lat(),
                            lng: this.filters.place.geometry.location.lng()
                        });
                    } else {
                        this.map.setCenter(this.bounds.getCenter());
                        this.map.fitBounds(this.bounds);
                        var zoom = this.map.getZoom();
                        this.map.setZoom(zoom > 15 ? 15 : zoom);
                    }
                }
                this.promoLocatorInformationWindow.openOnSearch();
            }

            this.updateCluster();
            this.boundListener(true);
            this.toggleLoader(false);
        })
    }

    updateCluster() {
        if (this.markerCluster) {
            this.markerCluster.clearMarkers();
        }

        this.markerCluster = new MarkerClusterer(this.map, this.markers, {imagePath: '/fe-web/js/vendor/maps/markerClusterer/images/m'});
    }

    getClusterImagePath(iconElement, highlight) {
        let highlightImage = "/fe-web/js/vendor/maps/markerClusterer/imagesHighlighted/";
        let standardImage = "/fe-web/js/vendor/maps/markerClusterer/images/";
        let currentImagePath = $(iconElement).css("background-image");
        let postFix = currentImagePath.substring(currentImagePath.lastIndexOf("/") + 1, currentImagePath.indexOf(".png") + 4);
        return highlight ? highlightImage + postFix : standardImage + postFix;
    }

    highlightCluster(marker, status) {
        let cluster = null;


        if (status == true) {
            if (this.markerCluster && this.markerCluster.clusters_.length > 0) {
                this.markerCluster.clusters_.forEach((cl) => {
                    if (cl.markers_.length > 1) {
                        let markerInCluster = cl.markers_.filter((m) => {
                            return m.idStore == marker.idStore;
                        });
                        if (markerInCluster.length > 0) {
                            cluster = cl;
                            $(cl.clusterIcon_.div_).css("background-image", "url(" + this.getClusterImagePath(cl.clusterIcon_.div_, true) + ")");
                            //$(cl.clusterIcon_.div_).css("background-color", "red");
                        }
                    }
                })
            }
        } else {
            if (this.markerCluster && this.markerCluster.clusters_.length > 0) {
                this.markerCluster.clusters_.forEach((cl) => {
                    if (cl.markers_.length > 1) {
                        $(cl.clusterIcon_.div_).css("background-image", "url(" + this.getClusterImagePath(cl.clusterIcon_.div_, false) + ")");
                        //$(cl.clusterIcon_.div_).css("background-color", "transparent");
                    }
                });
            }
        }
        return cluster;
    }

    toogleHighlightMarker(marker, status) {
        this.markers.forEach((item) => {
            item.setIcon('/fe-web/js/vendor/maps/pointer/default_pointer.png');
        });
        if (status == true || status === undefined) {
            marker.setIcon("/fe-web/js/vendor/maps/pointer/puntatore_click.png");
        }
    }

    createMarker(poi) {
        let marker = null;

        let addMarker = !this.markers.some((marker) => {
            return marker.idStore == poi.idStore;
        });

        if (addMarker) {
            marker = new google.maps.Marker({
                position: {lat: poi.latitude, lng: poi.longitude},
                icon: '/fe-web/js/vendor/maps/pointer/default_pointer.png'
            });
            marker.idStore = poi.idStore;
            google.maps.event.addListener(marker, 'click', () => {
                this.toogleHighlightMarker(marker);
                this.promoLocatorInformationWindow.displayDetail(marker.idStore);
            });

            this.markers.push(marker);
            this.bounds.extend(marker.position);
        }

        return marker;

    }

    setCurrentPosition() {
        let pos = null;
        return new Promise((resolve, reject) => {

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    pos = position;
                    let placeLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    this.geocoder.geocode({'location': placeLocation}, (results, status) => {
                        if (status === 'OK') {
                            if (results[0]) {
                                this.filters.place = results[0];
                                this.currentLocation = results[0];
                                //this.autocomplete.set("place", this.filters.place);
                                resolve(this.filters.place);
                            } else {
                                reject(false);
                            }
                        } else {
                            reject(false);
                        }
                    });
                }, () => {
                    reject(false);
                });
            }
        });

        return pos;
    }

}


export default PromoLocatorComponent;
