import $ from 'jquery';
import Swiper from 'swiper';
import Component from '../model/Component';

class EvidenceSliderComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);

        this.initSwiper();
    }

    onResize() {
    }

    initSwiper() {
        const slidesNumber = this.$element.find('.swiper-slide').length;
        const slidesPerView = !!this.$element.data('slidesperview') ? this.$element.data('slidesperview') : $(window).width() > 767 ? 3 : 1;
        const spaceBetween = !!this.$element.data('spacebetween') ? this.$element.data('spacebetween') : 0;
        const checkNavigation = this.$element.parents("[data-navigation-wrapper]");

        let options = {
            slidesPerView: this.$element.data('slidesperview'),
            nextButton: checkNavigation.find('.swiper-button-next')[0],
            prevButton: checkNavigation.find('.swiper-button-prev')[0],
            pagination: checkNavigation.find('.swiper-pagination')[0],
            paginationClickable: true,
            spaceBetween: spaceBetween,
            breakpoints: {
                768: {
                    slidesPerView: this.$element.data('slidesperviewmobile')
                }
            },
            observer: true,
            observeParents: true,
        }

        if (slidesNumber > slidesPerView) {
            this.swiper = new Swiper(this.$element[0], options);
            setTimeout(() => {
                checkNavigation.find('.swiper-pagination-bullet-active').click();
                $('.home .evidence').addClass('evidence__slider-initialized');
                this.keepArrowsWorkingOnResize();
            }, 500)
        } else {
            checkNavigation.find('.swiper-pagination').toggle();
            checkNavigation.find('.swiper-button-next').toggle();
            checkNavigation.find('.swiper-button-prev').toggle();
        }
    }

    keepArrowsWorkingOnResize() {
        const debounce = (fn, wait = 300) => {
            let t
            return function () {
                clearTimeout(t)
                t = setTimeout(() => fn.apply(this, arguments), wait)
            }
        }

        window.addEventListener("resize", debounce(() => {
            this.$element.parents("[data-navigation-wrapper]").find('.swiper-pagination-bullet-active').click();
        }));

    }
}

export default EvidenceSliderComponent;
