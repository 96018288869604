// K+ component
import Swiper from 'swiper';
import Constants from "../business/Constants";
import Controller from "../model/Controller";
import Utility from "../business/Utility";
import GoogleAnalytics from "../business/GoogleAnalytics";

class EmojiCtrl extends Controller {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.element = element;
        this.sharedData = {};
        this.init();
    }

    showShareModal(element) {
        this.getSharedDataObject(element);
        try {
            navigator.share(this.sharedData).then(() => console.log("Native share popup opened!"));
        } catch (e) {
            this.openCustomPopup(this.sharedData);
        }
    }

    openCustomPopup(data) {
        this.setOgTag(data);
        const modal = document.getElementById('shareModal');
        modal.querySelector('.row.text p').innerHTML = `Condividi "${ data.title }"`;
        modal.dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
    }

    getSharedDataObject(emojiElement) {
        const title = emojiElement.querySelector('.landing-emoji__subtitle').textContent;
        this.sharedData = {
            title: title,
            text: title +' - '+ emojiElement.querySelector('p').textContent,
            url: emojiElement.getAttribute('share-link')
        }
    }

    setOgTag(data) {
        document.querySelector('meta[property=og\\:title]').setAttribute('content', data.title);
        document.querySelector('meta[property=og\\:description]').setAttribute('content', data.text);
        document.querySelector('meta[property=og\\:url]').setAttribute('content', data.url);
    }

    onShareClick(shareButton) {
        const urlPage = this.sharedData.url;
        const titlePage = this.sharedData.title;
        let urlSocial = Utility.getShareLink(shareButton, titlePage, urlPage);
        if (urlSocial) window.open(urlSocial, '_blank');

        const obj = {
            'data-name-product': this.sharedData.title,
            'data-name-method': shareButton,
            'data-content-type': 'product'
        }
        GoogleAnalytics.send('social_share', obj);
    }

    initEmojiDaySwiper() {
        const swiperElement = this.element.querySelector('.landing-emoji__emoji-day-carousel.swiper-container');
        this.emojiSwiper = new Swiper(swiperElement, this.getSwiperConfiguration(swiperElement, 1));
    }

    initPancakeSwiper() {
        const swiperElement = this.element.querySelector('.landing-emoji__pancake-slider .swiper-container');
        this.pancakeSwiper = new Swiper(swiperElement, this.getSwiperConfiguration(swiperElement, 'auto'));
    }

    getSwiperConfiguration(swiper, slidePerView) {
        return {
            nextButton: swiper.querySelector('.swiper-button-next'),
            prevButton: swiper.querySelector('.swiper-button-prev'),
            slidesPerView: slidePerView,
            loop: true,
            autoplay: 3000,
            keyboardControl: true,
            mousewheelControl: true,
            mousewheelForceToAxis: true
        }
    }

    addListeners() {
        const shareButtons = this.element.querySelectorAll('.landing-emoji__share');
        shareButtons.forEach((button) => {
            const emojiElement = button.parentElement;
            button.addEventListener('click', () => this.showShareModal(emojiElement));
        });
    }

    init() {
        this.initEmojiDaySwiper();
        this.initPancakeSwiper();
        this.addListeners();
    }
}

export default EmojiCtrl;
