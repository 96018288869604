import Component from '../../model/Component';
import GigyaManager from "../../business/GigyaManager";

class LoginStepComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);
        this.store = store;
        this.loadScreenSets();
    }

    goToNextStep() {
        this.store.dispatch({...this.store.getState(), type: 'unMorsoDiFelicitaForm/goToNextStep'});
    }

    loadScreenSets() {
        GigyaManager.isGigyaSdkReady().then(() => {
            let screensetCfg = {
                screenSet: 'Barilla-RegistrationLogin',
                startScreen: 'gigya-login-screen-v2',
                containerID: 'loginPageContainer',
                context: {
                    noovleRaasGlobalCH: 'CH_MULINOBIANCO',
                    noovleProgressiveProfilingId: 'login-modal',
                    noovleRaasGlobalRegSource: 888
                }
            }

            gigya.accounts.showScreenSet(screensetCfg);
        });
    }
}

export default LoginStepComponent;
