import $ from 'jquery';
import Swiper from 'swiper';
import Component from '../model/Component';

class StepsEventsSwiperComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.wasMobile = null;
        this.resizeTimer = null;
        this.swiper = null;
        this.init();
    }

    init() {
        this.handleBuild()
        $(window).resize(() => {
            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => {
                this.handleBuild();
            }, 200);
        });

    }

    handleBuild() {
        let isMobile = $(window).width() < 768;
        if (isMobile) {
            if (!this.wasMobile) {
                this.build();
            }
        } else {
            this.destroy();
        }
        this.wasMobile = isMobile;
    }

    build() {
        this.swiper = new Swiper(this.$element[0], {
            freeMode: true,
            centeredSlides: false,
            spaceBetween: 15,
            slidesPerView: 'auto'
        });
    }

    destroy() {
        if (this.swiper) {
            this.swiper.destroy(true, true);
        }
    }
}

export default StepsEventsSwiperComponent;
