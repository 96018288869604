import $ from 'jquery';
import Swiper from 'swiper';
import Component from '../model/Component';

class SwiperComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        // $(window).resize(() => {
        //     this.onResize();
        // });
        this.initSwiper();
        this.preloadBackground();
    }

    onResize() {
    }

    initSwiper() {
        const slidesNumber = this.$element.find('.swiper-slide').length;
        const slidesPerView = !!this.$element.data('slidesperview') ? this.$element.data('slidesperview') : $(window).width() > 767 ? 3 : 1;

        let options = {
            slidesPerView: slidesPerView,
            nextButton: this.$element.find('.swiper-button-next')[0],
            prevButton: this.$element.find('.swiper-button-prev')[0],
            pagination: this.$element.find('.swiper-pagination')[0],
            paginationClickable: true,
            observer: true,
            observeParents: true,
        }

        if (slidesNumber > slidesPerView) {
            this.swiper = new Swiper(this.$element[0], options);
            setTimeout(() => {
                this.$element.find('.swiper-pagination-bullet-active').click();
                this.keepArrowsWorkingOnResize();
            }, 500)
            this.handleEvents(this.$element, this.swiper)
        } else {
            this.handleEvents(this.$element, null)
            this.$element.find('.swiper-pagination').toggle();
            this.$element.find('.swiper-button-next').toggle();
            this.$element.find('.swiper-button-prev').toggle();
        }
    }

    handleEvents($element, swiper) {
        if (swiper) {
            this.changeBackground($element)
            swiper.on('onSlideChangeEnd', () => {
                this.changeBackground($element)
            })
        }


    }

    keepArrowsWorkingOnResize() {
        const debounce = (fn, wait = 300) => {
            let t
            return function () {
                clearTimeout(t)
                t = setTimeout(() => fn.apply(this, arguments), wait)
            }
        }

        window.addEventListener("resize", debounce(() => {
            this.$element.find('.swiper-pagination-bullet-active').click();
        }));

    }

    changeBackground($element) {
        const backgroundElement = $element.find('.swiper-slide-active').data('apply_background')
        if (backgroundElement) {
            const background = $element.find('.swiper-slide-active').data('background')
            $(`.${backgroundElement}`).css({
                "background-image": `url(${background})`,
                "transition": "background 1s linear"
            })
        }
    }

    preloadBackground() {
        $(this.element).find('[data-background]').each(function () {
            $('<img />').attr('src', $(this).data("background")).appendTo('body').css('display', 'none');
        });
    }
}

export default SwiperComponent;
