import Select from "../common/select";
import ImageUpload from "../common/imageUpload";
import InputField from "../common/inputField";
import Button from "../common/button";
import StepHeader from "../common/stepHeader";

export default function ProductTemplate(config) {
    const productList = config.config.productList
    let html = `
        ${StepHeader({title: config.title, subtitle: config.subtitle})}
        ${InputField({type: 'hidden', name: "Classification", value: config.config.classificationId})}
        <div class="item-centered ">
          ${Select("Product", productList, {
        label: 'Seleziona un prodotto*',
        value: ''
    }, {class: 'sm-full medium-size'})}          
        </div>         
        <div class="telephone-info">${config.config.telephoneInfo}</div>  
        <div class="items-container">                
            ${ImageUpload({id: 1, next: 2})}
            ${ImageUpload({id: 2, next: 3, isDisabled: true})}
            ${ImageUpload({id: 3, isDisabled: true})}       
        </div>         
        <div class="items-container mb-small-offset">                
            ${InputField({
        type: 'text',
        name: 'ProductBarCode',
        size: 'm',
        placeholder: 'Bar code',
        infoTooltip: '<img src="/fe-web/img/contact-form/bar_code.jpg">'
    })}  
            ${InputField({
        type: 'text',
        name: 'ProductBatch',
        size: 'm',
        placeholder: 'Batch code',
        infoTooltip: '<img src="/fe-web/img/contact-form/batch_code.jpg">'
    })} 
            ${InputField({
        type: 'text',
        name: 'ProductExpirationdate',
        size: 'm',
        pattern: '(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])\/(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])\/(?:30))|(?:(?:0[13578]|1[02])-31))\n',
        placeholder: 'Best before date',
        infoTooltip: '<img src="/fe-web/img/contact-form/best_before_date.jpg">'
    })}
            ${InputField({
        type: 'text',
        name: 'SellingPointAddress',
        size: 'm',
        class: 'mb-small',
        placeholder: 'Indirizzo del punto vendita'
    })}                       
        </div>
         <div class="items-container">
        ${InputField({type: 'textarea', name: 'MessageText', placeholder: 'Cosa ci vuoi suggerire?*'})}
        </div>
        <div class="step-disclaimer">*I campi contrassegnati con l’asterisco sono obbligatori</div>
        <div class="item-centered">
            ${Button({
        id: 3,
        type: 'goNext',
        nextStep: 4,
    })}
        </div>
                `;
    return html
}