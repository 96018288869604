/**
 *
 */
import Controller from '../model/Controller';
import $ from 'jquery';

class IngredientsCtrl extends Controller {
    constructor(element) {
        super(element);
        this.mansory = null;
        this.ingredientId = null;
        this.init();
    }

    init() {


        window.document.addEventListener("IngredientsAutocompleteComponent:valueSelected", (event) => {

            let ingredientId = event.detail.data.id;
            if (this.ingredientId != ingredientId) {
                this.ingredientId = ingredientId;

                let $accordionChild = $("[data-accordion-id='" + ingredientId + "']");

                let $parentAccordion = $($accordionChild.parents("[data-accordion-header]").find("header.accordion-header")[0]);
                if (!$parentAccordion.parent().hasClass("active")) {
                    $parentAccordion.trigger("click");
                }

                $accordionChild.trigger("click");

                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($accordionChild.offset().top)
                    }, 800);
                }, 600);

            }

        })
    }
}

export default IngredientsCtrl;
