import Component from '../../model/Component';
import HttpClient from "../../business/HttpClient";
import GigyaManager from "../../business/GigyaManager";
import ContactFormComponent from "./ContactFormComponent";

class FaqTriageComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.element = element;
        this.currentStep = 0;
        this.configuration = [];
        this.data = {};
        this.loader = this.element.querySelector('.loader');
        this.selectionWrapper = this.element.querySelector('.form-selection-wrapper');

        this.noLogged = this.element.querySelector('.no-logged');
        this.logged = this.element.querySelector('.logged');

        this.contactForm = new ContactFormComponent(this.element);
        this.init();

        GigyaManager.getLoggedUser().then(() => {
            this.noLogged.classList.add('hidden');
            this.logged.classList.remove('hidden');
        });
    }

    appendStep(stepOptions) {
        let html = '';
        stepOptions.forEach((option) => {
            const classificationId = option.config ? option.config.classificationId : '';
            html += `<button type="button" data-type="${option.type}" id="${option.id}" 
                    data-classification-id="${classificationId}" class="contact-form-option">
                        <span>${option.title}</span>
                        ${option.subtitle ? `<p>${option.subtitle}</p>` : ""}       
                    </button>`;
        });
        this.element.querySelector(`.step-${this.currentStep} .faq-questions`).innerHTML = html;
    }

    appendSelection(value) {
        this.element.querySelector('.form-selection').classList.remove('hidden');
        const stepButton = this.selectionWrapper.querySelector(`#selection-step-${this.currentStep}`);
        if (stepButton) stepButton.innerHTML = value;
        else {
            this.selectionWrapper.insertAdjacentHTML('beforeend', `<button class="button special" type="button" id="selection-step-${this.currentStep}">${value}</button>`);
            this.selectionWrapper.querySelector('button.special:last-child').addEventListener('click', (e) => this.removeSelection(e.target));
        }
    }

    removeSelection(button) {
        const clickedStep = button.id.replace('selection-step-', '');
        const clickedStepIndex = parseInt(clickedStep);
        this.selectionWrapper.querySelectorAll(`#selection-step-${clickedStepIndex}, #selection-step-${clickedStepIndex} ~ .button`)
            .forEach((el) => el.remove());

        this.currentStep = clickedStepIndex;
        if (!this.selectionWrapper.childElementCount) this.element.querySelector('.form-selection').classList.add('hidden');
        this.contactForm.hideForm();
        this.overflowAnimation();
    }

    appendCountryStep(faqs) {
        this.element.querySelector(`.step-${this.currentStep} .faq-questions`).innerHTML = `
            <h2>Seleziona una nazione</h2>
            <br><br>
            <div class="country-select">
                <label for="countrySelect">In quale nazione hai partecipato al concorso?</label>
                <select name="country" id="countrySelect">
                    <option value="IT" selected>Italy</option>
                </select>
            </div>
            
            <div class="country-disclaimer hidden">
                Se selezioni una nazione differente rispetto a quella attuale, 
                verrai reindirizzato al contact form della nazione selezionata
            </div>
            
            <div class="step-footer">
                <button type="button" class="button special">Conferma</button>
            </div>`;

        this.data.country = 'IT';
        this.overflowAnimation();
        this.addCountryListener(faqs);
    }

    appendFaq(faqs = [], isNew = 'true', promoCode) {
        let html = faqs.length ? `<div class="faq-section-title">FAQ</div>` : '';
        faqs.forEach((faq) => html += `
            <div class="faq-item">
                <b class="faq-item-header">${faq.question}</b>
                <div class="faq-item-body">${faq.answer}</div>
            </div>
        `);

        html += `<div class="form-card">
            <div class="form-card-title">Hai bisogno di aiuto?</div>
            <div>Non esitare a scriverci. Noi rispondiamo sempre.</div>
            <button class="button special" type="button">Compila il form</button>
        </div>`;

        this.element.querySelector(`.step-${this.currentStep} .faq-questions`).innerHTML = html;
        this.addFormClickListener(isNew, promoCode);

        if (!faqs.length) {
            if(isNew === 'false') {
                window.location.href = `/contatti?promoCode=${promoCode}`;
                return;
            }

            this.currentStep--;
            this.loader.classList.remove('hidden');
            this.getCategory();
        } else {
            this.overflowAnimation();
            this.addFaqListener();
        }
    }


    appendPromo(stepOptions = []) {
        let html = '';
        stepOptions.forEach((option) => html +=
            `<button type="button" id="${option.promoCode}" data-new="${option.newContactForm}" 
                data-promo-id="${option.promoId}" class="contact-form-option">${option.name}</button>`);
        this.element.querySelector(`.step-${this.currentStep} .faq-questions`).innerHTML = html;
        this.addPromoListener();
        this.overflowAnimation();
    }

    addFirstStepListener() {
        const currentStepButtonList = this.element.querySelectorAll(`.step-${this.currentStep} .faq-questions button`);
        currentStepButtonList.forEach((button) => {
            button.addEventListener('click', () => {
                this.appendSelection(button.querySelector('span').innerText);
                this.goToSecondStep(parseInt(button.id))
            });
        });
    }

    addSecondStepListener() {
        const currentStepButtonList = this.element.querySelectorAll(`.step-${this.currentStep} .faq-questions button`);
        currentStepButtonList.forEach((button) => {
            button.addEventListener('click', () => {
                this.appendSelection(button.querySelector('span').innerText);
                this.goToThirdStep(parseInt(button.id), button.dataset.classificationId);
            });
        });
    }

    addPromoListener() {
        const currentStepButtonList = this.element.querySelectorAll(`.step-${this.currentStep} .faq-questions button`);
        currentStepButtonList.forEach((button) => {
            button.addEventListener('click', () => {
                this.appendSelection(button.innerText);
                this.goToFourthStep(button.id, button.dataset.promoId, button.dataset.new);
            });
        });
    }

    addCountryListener(faqs) {
        const currentStepButton = this.element.querySelector(`.step-${this.currentStep} .faq-questions button`);
        currentStepButton.addEventListener('click', () => {
            const countryLabel = this.element.querySelector('#countrySelect').selectedOptions[0].innerHTML;
            this.appendSelection(countryLabel);
            this.goToNextCountryStep(faqs);
        });
    }


    goToNextCountryStep(list) {
        this.currentStep++;
        (this.data.type === 'PROMO') ? this.appendPromo(list) : this.appendFaq(list);
    }


    addBackListener() {
        const buttons = this.element.querySelectorAll('.faq-triage-component .step-back');
        buttons.forEach((button) => {
            button.addEventListener('click', () => this.goToPrevStep());
        });
    }

    addFormClickListener(isNew, promoCode) {
        this.element.querySelectorAll(`.step-${this.currentStep} .faq-questions button`);
        const formButton = this.element.querySelector(`.step-${this.currentStep} .form-card .button`);
        formButton.addEventListener('click', () => {
            if(isNew === 'false') {
                window.location.href = `/contatti?promoCode=${promoCode}`;
                return;
            }

            this.loader.classList.remove('hidden');
            this.appendSelection('FAQ');
            this.getCategory();
        });
    }

    getCategory() {
        HttpClient.doCall({
            url: `${API_ENDPOINT}/api/mulinobianco/3.0/web/customercare/categories?step1=${this.data.step1}&step2=${this.data.step2}`,
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.data.triageCategory = data.body;
            this.loader.classList.add('hidden');
            this.contactForm.initContactForm(this.data);
        }, () => {
            this.loader.classList.add('hidden');
        });
    }


    addFaqListener() {
        const faqButtons = this.element.querySelectorAll(`.step-${this.currentStep} .faq-item-header`);
        faqButtons.forEach((faq) => {
            faq.addEventListener('click', () => {
                faq.parentElement.classList.toggle('opened');
                this.overflowAnimation();
            });
        });
    }

    goToFirstStep() {
        this.stepCta = this.element.querySelectorAll('.step-cta');
        this.stepCta.forEach((cta) => {
            cta.addEventListener('click', () => {
                this.currentStep++;
                this.getConfiguration();
            })

        })
    }


    goToSecondStep(id) {
        this.data[`step${this.currentStep}`] = id;
        let stepObject = this.configuration.find((el) => el.id === id);
        this.currentStep++;
        this.appendStep(stepObject.children);
        this.addSecondStepListener();
        this.overflowAnimation();
    }


    goToThirdStep(id, classificationId) {
        this.data[`step${this.currentStep}`] = classificationId;
        const secondLevelOptions = this.configuration.find((el) => el.id === this.data.step1).children;
        const selectedObject = secondLevelOptions.find((el) => el.id === id);
        this.data.type = selectedObject.type;

        if (selectedObject.config.externalLink) {
            window.open(selectedObject.config.externalLink, '_blank').focus();
            return;
        }

        this.currentStep++;
        switch (selectedObject.type) {
            case 'PRODUCTS':
                this.appendFaq(selectedObject.config.faqList);
                break;
            case 'PROMO':
                this.appendCountryStep(selectedObject.config.promoList);
                break;
            case 'WEBSITE':
            case 'TEXT':
                this.appendFaq(selectedObject.config.faqList);
                break;
        }
    }

    overflowAnimation() {
        const overflowWrapper = this.element.querySelector('.faq-overflow-wrapper');
        overflowWrapper.setAttribute('step', this.currentStep);
        const height = this.element.querySelector(`.faq-step.step-${this.currentStep}`).clientHeight;
        overflowWrapper.style.maxHeight = `${height}px`;
    }


    goToFourthStep(promoCode, promoId, isNew) {
        this.data.promoCode = promoCode;
        this.data.promoId = promoId;
        this.loader.classList.remove('hidden');

        HttpClient.doCall({
            url: API_ENDPOINT + `/api/mulinobianco/3.0/web/customercare/faq?idPromo=${promoId}`,
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.loader.classList.add('hidden');
            this.currentStep++;
            this.appendFaq(data.body, isNew, promoCode);
        }, () => {
            this.loader.classList.add('hidden');
        });
    }

    goToPrevStep() {
        this.selectionWrapper.querySelector(`#selection-step-${this.currentStep}`)?.remove();
        const lastId = this.selectionWrapper.querySelector(`.button:last-child`)?.id;
        const prevStepIndex = lastId?.replace('selection-step-', '');
        this.currentStep = prevStepIndex ? parseInt(prevStepIndex) : this.currentStep - 1;
        this.overflowAnimation();
    }


    getConfiguration() {
        this.loader.classList.remove('hidden');
        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/3.0/web/customercare/configuration',
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.configuration = data.body.body;
            this.appendStep(this.configuration);
            this.addFirstStepListener();
            this.loader.classList.add('hidden');
            this.overflowAnimation();
        }, () => {
            this.loader.classList.add('hidden');
        });
    }


    init() {
        this.addBackListener();
        this.goToFirstStep();
    }
}

export default FaqTriageComponent;
