/**
 *
 */

import Controller from '../model/Controller';
import $ from 'jquery';
import Swiper from 'swiper';

class GazzettaCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.init();
    }

    init() {
        //handle external arrows
        const nextButton = $('.might-be-interested #slide-next')[0]
        const prevButton = $('.might-be-interested #slide-prev')
        const swiper = new Swiper('.might-be-interested-swiper', {
            // Default parameters
            slidesPerView: 3,
            spaceBetween: 30,
            // Responsive breakpoints
            breakpoints: {
                // when window width is <=
                767: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                450: {
                    slidesPerView: 1,
                    spaceBetween: 10
                }

            },
            nextButton: nextButton,
            prevButton: prevButton,
        });

        this.loadMore();
    }


    loadMore() {
        $('.article__load-more .button').click((e) => {
            e.preventDefault();

            // get all visible items
            let itemsShown = $(".article-item:visible").length;

            let allItems = $(".article-item").length;

            const offsetMin = itemsShown - 1;
            const offsetMax = itemsShown + 6;

            $(".article-item").slice(offsetMin, offsetMax).show();

            $('.article__load-more').insertAfter($(".article-item:visible:last"));

            // hide load more button if number of items called exceed totals
            if (offsetMax >= allItems) {
                // $('.article__load-more .button').hide();
                $('.article__load-more').remove();
            }
        });
    }


}

export default GazzettaCtrl;
