import Controller from '../model/Controller';
import HttpClient from "../business/HttpClient";
import $ from "jquery";

class SorpresineCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.page = 1;
        this.offset = 6;
        this.init();
        this.loadMoreInProgress = false;
        this.params = '';
        this.initYear = $('.hide-mobile .range-period input.min').attr('min');
        this.endYear = $('.hide-mobile .range-period input.max').attr('max');
    }

    sorpresineList(filterActive, params = '') {
        let wrapper = this.element.querySelector('.sorpresine-list__wrapper');
        let html = '';
        let page = filterActive == false ? this.page : 1;
        let endpoint = API_ENDPOINT + `/api/mulinobianco/1.0/web/sorpresine?offset=${page}&limit=${this.offset}${params}`;

        wrapper.classList.add("loading");

        HttpClient.doCall({
            url: endpoint,
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            let filterCount = data.body.count;

            data.body.results.forEach((card) => {
                html += this.sorpresineCard(card);
            });

            if (filterActive) wrapper.innerHTML = "";
            wrapper.insertAdjacentHTML('beforeend', html);

            if(filterCount > this.page * this.offset) {
                wrapper.insertAdjacentHTML('afterend', `<button type="button" class="sorpresine-list__show-more button">Visualizza altre</button>`);
            } else {
                $('.sorpresine-list__show-more').remove();
            }

            this.loadMoreManager();
            wrapper.classList.remove("loading");
            this.loadMoreInProgress = false;

            if (filterCount == 0) {
                $('.sorpresine-list__wrapper').append('<span class="no-results">Nessun risultato trovato</span>');
            } else {
                $('.no-results').remove();
            }

        }, (err) => {
            console.log(err);
            $(wrapper).removeClass('loading');
            this.loadMoreInProgress = false;
        });
    }

    loadMoreManager() {
        $('.sorpresine-list__show-more').on('click',()=> {
            if (!this.loadMoreInProgress) {
                this.loadMoreInProgress = true;
                this.page++;
                this.sorpresineList(false, this.params);
            }
        });
    }

    sorpresineCard(card) {
        return `
            <div class="sorpresine-card" data-top="${card.top100}">
                <div style="background-image: url('${card.thumbnail}')" class="sorpresine-card__image" ></div>
                <div class="sorpresine-card__info">
                    <div class="sorpresine-card__category">${card.categoryName}</div>
                    <div class="sorpresine-card__title">${card.name}</div>
                    <div class="sorpresine-card__rarity" data-rarity="${card.rarity}">
                        <div class="sorpresine-card__rarity-title">RARITÀ</div>
                        <div class="sorpresine-card__rarity-wrapper">
                            <div class="rarity-chart"></div>
                            <div class="rarity-chart"></div>
                            <div class="rarity-chart"></div>
                            <div class="rarity-chart"></div>
                        </div>
                    </div>
                    <a class="sorpresine-card__cta button" href="${card.seoUrl}" target="_self">Scopri di più</a>
                </div>
            </div>
        `;
    }

    resetFilters() {
        $('.sorpresine-filters__cancel').on('click', (e)=> {
            if ($(e.currentTarget).parents('.hide-mobile').length > 0) {
                let filterToReset = $(e.currentTarget).parents('.sorpresine-list__single-filter')[0];
                let filterParent = $(e.currentTarget).parents('.link-element');

                if ($(filterToReset).hasClass('range-filter')) {
                    $('.hide-mobile input.min').val($('.hide-mobile input.min').attr('min'));
                    $('.hide-mobile input.max').val($('.hide-mobile input.max').attr('max'));
                    $('.hide-mobile .range-selected').css({"left": "0%", "right": "0%"});
                } else {
                    $(filterToReset).find('input[type="checkbox"]').each((index,filter) => {
                        $(filter).prop('checked', false);
                    });
                }

                if ($(e.currentTarget).parents('.link-element').data('filter') == 'isTop100') {
                    $(e.currentTarget).parents('.link-element').parents('.link-element').removeClass('filterActive');
                    $(e.currentTarget).parents('.link-element').parents('.link-element').find('closeFilter').remove();
                }

                if ($(e.currentTarget).parents('.link-element').data('filter') == 'period') {
                    $(e.currentTarget).parents('.link-element').find('.link-element__title').find('.filterCount').remove();
                }

            } else {
                let filterToReset = $(e.currentTarget).parents('.sorpresine-filters__inner')[0];

                $('.show-mobile input.min').val($('.show-mobile input.min').attr('min'));
                $('.show-mobile input.max').val($('.show-mobile input.max').attr('max'));
                $('.show-mobile .range-selected').css({"left": "0%", "right": "0%"});
                $(filterToReset).find('input[type="checkbox"]').each((index,filter) => {
                    $(filter).prop('checked', false);
                });
            }

            $(e.currentTarget).next().click();
        });
    }

    setFilters() {
        $('.sorpresine-filters__save').on('click', (e)=> {
            let isDesktop = $(e.currentTarget).parents('.hide-mobile').length > 0;
            let startYear = isDesktop ? $('.hide-mobile .range-period input.min').val() : $('.show-mobile .range-period input.min').val();
            let endYear = isDesktop ? $('.hide-mobile .range-period input.max').val() : $('.show-mobile .range-period input.max').val();
            let parentFilter = isDesktop ? $(e.currentTarget).parents('.link-element')[0] : $(e.currentTarget).parents('.sorpresine-filters__inner').find('.sorpresine-filters__list');

            this.params = '';
            this.page = 1;
            let inputList = isDesktop ? $('.hide-mobile.sorpresine-list__filters') : $('.show-mobile .sorpresine-filters__list');

            $(inputList).find('input[type="checkbox"]').each((index,filter) => {
                if ($(filter).is(':checked')) {
                    let filterValue = $(filter).val();
                    let filterName = $(filter).attr('name');
                    this.params += `&${filterName}=${filterValue}`;
                }
            });

            if (isDesktop) {
                $(parentFilter).find('.link-element__title .filterCount').remove();
                if ($(parentFilter).find('input[type="checkbox"]:checked').length > 0 && $(parentFilter).data('filter') != 'isTop100') {
                    $(parentFilter).find('.link-element__title').append(`<span class="filterCount">${$(parentFilter).find('input[type="checkbox"]:checked').length}</span>`);
                }

                if ($(parentFilter).data('filter') == 'isTop100') {
                    if ($(parentFilter).find('input[type="checkbox"]:checked').length > 0) {
                        $(parentFilter).addClass('filterActive');
                        $(parentFilter).find('.link-element__title').append('<span class="closeFilter"></span>');
                    } else {
                        $(parentFilter).removeClass('filterActive');
                        $(parentFilter).find('.link-element__title .closeFilter').remove();
                    }

                    $('.filterActive .closeFilter').on('click', ()=> {
                        $(e.currentTarget).parents('.link-element').find('.sorpresine-filters__cancel').click();
                        $(e.currentTarget).parents('.link-element').find('.toggle').prop('checked', false);
                        $(e.currentTarget).parents('.link-element').removeClass('filterActive open');
                        $(e.currentTarget).parents('.link-element').find('closeFilter').remove();
                    });
                }

                if ($(parentFilter).data('filter') == 'period') {
                    let periodFilterCount = 0;
                    if (this.initYear != startYear) {
                        periodFilterCount++;
                    }

                    if (this.endYear != endYear) {
                        periodFilterCount++;
                    }

                    if (periodFilterCount > 0) {
                        $(parentFilter).find('.link-element__title').append(`<span class="filterCount">${periodFilterCount}</span>`);
                    } else {
                        $(parentFilter).find('.link-element__title').find('.filterCount').remove();
                    }
                }

            } else {
                $('.sorpresine-list__filters.show-mobile .filterCount').remove();
                let totalFilterCount = $(parentFilter).find('input[type="checkbox"]:checked').length;

                if (this.initYear != startYear) {
                    totalFilterCount++;
                }

                if (this.endYear != endYear) {
                    totalFilterCount++;
                }

                if (totalFilterCount > 0) {
                    $('.sorpresine-list__filters.show-mobile').append(`<span class="filterCount">${totalFilterCount}</span>`);
                }
            }

            this.params += `&startYear=${startYear}&endYear=${endYear}`;

            this.sorpresineList(true, this.params);
        })
    }

    openMobileFilters() {
        $('.sorpresine-list__filters.show-mobile').on('click',()=> {
            $('.sorpresine-filters.show-mobile').removeClass('hidden');
            $('body').addClass('no-scroll');
        });
    }

    init() {
        this.sorpresineList(false);
        this.openMobileFilters();
        this.resetFilters();
        this.setFilters();
    }
}

export default SorpresineCtrl;
