import Component from '../../model/Component';
import Constants from "../../business/Constants";

class LandingRssModalComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.modal = document.querySelector('#rssModal');
        this.audio = this.modal.querySelector('audio');
        this.title = this.element.dataset.title;
        this.date = this.element.dataset.date;
        this.link = this.element.dataset.link;
        this.init();
    }


    setAudio() {
        this.modal.querySelector('h3').innerHTML = this.title;
        this.modal.querySelector('.date-wrapper').innerHTML = this.date;
        this.audio.querySelector('source').src = this.link;
        this.audio.load();

        this.modal.dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN));
        this.audio.play().then(()=> console.log('audio started'));
    }


    init() {
        this.element.querySelector('a.button').addEventListener('click', (e) => {
            e.preventDefault();
            this.setAudio();
        });
    }
}

export default LandingRssModalComponent;
