import Component from '../model/Component';
import $ from "jquery";

class SpecialitaCasaFormComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.init();
    }

    init() {
        window.location.href = "/specialita-della-casa";
    }
}


export default SpecialitaCasaFormComponent;
