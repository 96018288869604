"use strict";

const InputField = (props) => {
    let component = null;
    const getSize = (size) => {
        switch (size) {
            case 'l':
                return 'large-size';
            case 'm':
                return 'medium-size';
            case 's':
                return 'small-size';
            default:
                return '';
        }
    }
    const prefilledValue = props.value || ''


    switch (props.type) {
        case 'text':
            component = `<div class="form-item input-field ${getSize(props.size)} ${props.class ? props.class : ''}">
                    <input type="text" value="${prefilledValue}" pattern="${props.pattern && props.pattern}" name="${props.name}" class="input-text" placeholder="${props.placeholder && props.placeholder}">
                    <div class="error-message">${props.errorMessage || 'Errore, campo non valido'}</div>                    
                    ${props.infoTooltip
                ? `<div class="input-tooltip"><span class="tooltip-info">i</span><span class="tooltip-text">${props.infoTooltip}</span></div>`
                : ''}
                    
                    </div>`
            break
        case 'email':
            component = `<div class="form-item input-field ${getSize(props.size)}">
                    <input type="email" value="${prefilledValue}" name="${props.name}" class="input-text" placeholder="${props.placeholder}">
                    <div class="error-message">${props.errorMessage || 'Errore, campo non valido'}</div>
                </div>`
            break
        case 'tel':
            component = `<div class="form-item input-field ${getSize(props.size)}">
                    <input type="tel" value="${prefilledValue}" name="${props.name}" class="input-text" placeholder="${props.placeholder}">
                    <div class="error-message">${props.errorMessage || 'Errore, campo non valido'}</div>
                </div>`
            break
        case 'textarea':
            component = `<div class="form-item full-size"><textarea name="${props.name}" placeholder="${props.placeholder}" class="text-area-input" rows="8"></textarea><div class="error-message">${props.errorMessage || 'Errore, campo non valido'}</div></div>`
            break
        case 'hidden':
            component = `<input type="hidden" id="${props.name}" name="${props.name}" value="${prefilledValue}">`
            break
        case 'simple-privacy':
            component = `<div class="privacy-disclaimer">
                            <div class="privacy-title">Trattamento dei dati</div>
                            <div class="form-item input-field ${getSize(props.size)} checkbox-field">                              
                                <div class="privacy-text"> 
                                    <input type="checkbox" class="input-checkbox" id="privacy" name="${props.name}" value=1>
                                    <span class="error-box"></span>
                                    <span class="privacy-error-message">È necessario accettare le condizioni per proseguire</span> Presa visione dell’<a href="/privacy" target="_blank" style="color:#007ac2;">informativa 
                                    privacy</a>, acconsento al trattamento dei miei dati personali contenuti nel modulo
                                                di richiesta, per le finalità di gestione delle e risposta alle richieste da me
                                                inviate, da parte del titolare del trattamento, Barilla G. e R. Fratelli Società per
                                                Azioni.
                                                <br><br>Nel caso in cui non venga prestato il consenso al trattamento dei dati
                                                personali per la predetta finalità non sarà possibile proseguire con l’invio della
                                                richiesta.<br><br>
                                                Il consenso eventualmente prestato in relazione alla finalità di cui sopra può
                                                essere revocato, in ogni momento, inviando una comunicazione al titolare del
                                                trattamento attraverso la sezione “Contattaci” del Sito. Tale revoca determinerà
                                                l’interruzione della procedura di gestione della richiesta.
                                </div>
                            </div>
                        </div>
                        ${props.disclaimer && false ? `<div class="privacy-disclaimer">
                            <div class="privacy-title">Trattamento dei dati</div>
                            <div class="privacy-text">Presa visione dell’<a href="/privacy" target="_blank" style="color:#007ac2;">informativa
                                            privacy</a>, acconsento al trattamento dei miei dati personali contenuti nel modulo
                                            di richiesta, per le finalità di gestione delle e risposta alle richieste da me
                                            inviate, da parte del titolare del trattamento, Barilla G. e R. Fratelli Società per
                                            Azioni.
                                            <br><br>Nel caso in cui non venga prestato il consenso al trattamento dei dati
                                            personali per la predetta finalità non sarà possibile proseguire con l’invio della
                                            richiesta.<br><br>
                                            Il consenso eventualmente prestato in relazione alla finalità di cui sopra può
                                            essere revocato, in ogni momento, inviando una comunicazione al titolare del
                                            trattamento attraverso la sezione “Contattaci” del Sito. Tale revoca determinerà
                                            l’interruzione della procedura di gestione della richiesta.
                            </div>
                        </div>` : ''}`
            break
    }

    return component;

}

export default InputField;
