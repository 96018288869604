/**
 *
 */
import Controller from '../model/Controller';
import $ from 'jquery';
import Constants from "../business/Constants";
import HttpClient from "../business/HttpClient";

class FaqCtrl extends Controller {

    constructor(element) {
        super(element);
        this.$el = $(element);
        this.files = [];
        this.init();
    }

    init() {
        $('.roundedButtonContainer a:first-child').addClass("selected");
        $(".mb-accordion .tab_1").addClass('showTab');
        this.form = {};

        this.$el.find('.input-field').each((i, el) => {
            let $el = $(el)
            this.form[$el.attr('name')] = $el.val();
        }).on('change keyup hideDropdown', (e) => {
            let $el = $(e.currentTarget);
            this.validateInput($el);
        });

        this.$el.find('[name="privacy"]').on('change', () => {
            $('[name="privacy"]').parents('.input-container').toggleClass('error', $('[name="privacy"]:checked').val() == 'false');
        });

        this.$el.on('click', '#file-name-wrapper .file-name .close', (e) => {
            let $name = $(e.currentTarget).parent('.file-name');
            this.removeFile($name.data('file-name'));
        });
    }

    showFaq(ele_class) {
        $('.showTab').removeClass('showTab');
        $('.roundedButtonContainer .selected').removeClass('selected');
        $('.roundedButtonContainer .' + eval(ele_class)).addClass('selected');
        $(".mb-accordion ." + eval(ele_class)).addClass('showTab');
    }

    uploadCallback(file) {
        if (!!file) {
            this.files.push(file);
        }
        this.updateFilesList();
    }

    removeFile(filename) {
        for (let f in this.files) {
            if (this.files[f].path == filename) {
                this.files.splice(this.files.indexOf(this.files[f]), 1);

            }
        }
        this.updateFilesList();
    }

    updateFilesList() {
        let html = "";

        for (let f in this.files) {
            let name = this.files[f].oldName;

            html += `
                <span class="file-name" data-file-name="${this.files[f].path}">
                    ${name}
                    <svg class="close">
                        <use xlink:href="#svg_circle_close"/>
                    </svg>
                </span>
            `;
        }

        this.$el.find('#file-name-wrapper').html(html);
    }

    validateInput($el) {
        let val = $el.val();
        this.form[$el.attr('name')] = val;

        if (!val || val.length <= 0) {
            if ($el.is('select.choices__input')) {
                $el.parents('.choices').addClass('error');
            } else {
                $el.addClass('error');
            }
        } else {
            if ($el.is('select.choices__input')) {
                $el.parents('.choices').removeClass('error');
            } else {
                $el.removeClass('error');
            }
        }

        return !!$el.val();
    }

    isValidForm() {
        var isValid = true;
        this.$el.find('.input-field').each((i, el) => {
            let isValidField = this.validateInput($(el));
            isValid = isValid && isValidField;
        });

        if (!$('[name="privacy"]').is(':checked')) {
            $('[name="privacy"]').parents('.input-container').addClass('error');
            isValid = false;
        }

        //TODO - creare un componente per gestire recaptcha
        if (grecaptcha.getResponse().length <= 0) {
            isValid = false;
        }
        return isValid && $('[name="privacy"]').is(':checked');
    }

    submit() {
        if (this.isValidForm()) {
            var idTopic = $('#contactsTopic').val();

            if (this.files.length > 0) {
                this.form.allegato = "";
                for (let i in this.files) {
                    this.form.allegato += this.files[i].path;

                    if (i < this.files.length - 1) {
                        this.form.allegato += ',';
                    }
                }
            }

            //TODO - manca un loader all'invio della chiamata e manca anche la gestione del captcha
            HttpClient.doCall({
                url: API_ENDPOINT + '/api/mulinobianco/1.0/web/customercare/' + idTopic,
                type: "POST",
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify(this.form)
            }, false).then((data) => {
                document.getElementById('form-submitted').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
            }, () => {
                document.getElementById('form-error').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
            });
        } else {
            var top = this.$el.find('.error:visible:eq(0)').length > 0 ? this.$el.find('.error:visible:eq(0)').offset().top - 100 : null;
            if (!!top) {
                $('html, body').animate({
                    scrollTop: top
                }, 1000);
            }
        }
    }
}

export default FaqCtrl;
