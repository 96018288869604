import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import $ from 'jquery';

class LandingSurveyCtrl extends Controller {
    constructor(element) {
        super(element);
        this.init();
    }

    init(){
        GigyaManager.getLoggedUser().then(() => {
            $('.cta-survey').attr('href','/welcome-survey-start')
        }, () => {
            $('.cta-survey').attr('href','/login?urlBack=/welcome-survey-start&regSource=867');
        });
    }
}

export default LandingSurveyCtrl;
