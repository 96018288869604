import Component from '../model/Component';
import GigyaManager from "../business/GigyaManager";

class SurveyComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.element = element;
        this.CH_PROMO = 'CH_867_MLB_WEB_SURVEY23_CRS_NAV_20230504_20240421';
        this.firstName = '';
        this.userId = null;

        document.addEventListener('yxp-form-loaded', () => this.init());
        document.addEventListener('yxp-form-submit-ok', () => this.showThankyou());
        document.addEventListener('yxp-form-config-error-already-answered', () => this.showThankyou());

        var hostUrl = window.API_ENDPOINT;
        var yxpSdkConfig = {host: hostUrl};

        GigyaManager.getLoggedUser().then(account => {
            this.firstName = account.profile.firstName;
            this.ch = account.data[this.CH_PROMO];
            this.userId = account.UID;
            this.chAccepted = !!account.data['CH_992_MLB_WEB_RACPUNTI24_BIS_CRS_20240115_20240115'];
            window.youserxp.config(yxpSdkConfig);
            var fm = youserxp.formmanager.getInstance({placeholderId: 'form-cnt', authCookieName: 'stkauthmb'});
            fm.setupForm(element.dataset.formId, function (response) {
            }).then(() => {});
        })

    }

    showThankyou() {
        let name = 'Grazie';
        if (this.firstName) {
            name += ' ' + this.firstName;
        }

        this.element.innerHTML = `
        <div class="thankyou">
            <div class="yxpType_TITLE">
                <img class="thankyou-icon" src="/fe-web/img/icons/green-check.png">
                <h2>HAI FINITO</h2>
                <h1>Ottimo!</h1>
            </div>
        
            <div class="yxpType_TEXTAREA">
                <p>Mulino Bianco ti ringrazia del contributo e ti augura un’estate davvero speciale:
                    ecco <b>subito per Te i preziosi Punti OMAGGIO</b> da utilizzare per completare più facilmente 
                    la soglia del premio che più desideri:
                    </p>
            </div>
                
            <div class="thankyou__prizes">
                <div class="thankyou__prizes-item">
                Stai raccogliendo i punti per richiedere l’allegra FELICITAZZA ?
                    <div class="thankyou__item-box">                      
                            <img src="/fe-web/img/survey/felicitazza.png"/>
                            <b>La Felicitazza</b>
                                Tazza termica in ceramica
                                con tappo in silicone
                    </div>
                </div>
                
                <div class="thankyou__prizes-item">
                        Oppure preferisci  l’idea di rinfrescarti con le bevande di frutta 
                        che potrai realizzare con il nostro FRULLOTTO ?
                        
                         <div class="thankyou__item-box-wrapper"> 
                            <div class="thankyou__item-box">                    
                                    <img src="/fe-web/img/survey/frullotto.png"/>
                                    <b> Il Frullotto</b>
                                        Frullatore pratico e compatto
                            </div>
                            <div class="thankyou__item-box">
                                    <img src="/fe-web/img/survey/frullotto-borraccia.png"/>
                                    <b> Il Frullotto e la Borraccia</b>
                                       Oltre al Frullotto hai anche la seconda Borraccia
                            </div>     
                        </div>             
                </div>
            </div>
            
            <div class="thankyou__banner">
                 <div class="yxpType_TEXTAREA">
                    <p>Ecco <b>per te 3 Punti OMAGGIO speciali di colore VERDE</b> validi per richiedere il premio che più desideri</p>
                </div>
                <img src="/fe-web/img/survey/punto-omaggio.png"/>
                <div class="yxpType_TEXTAREA">
                    <p>Scegli se scaricare la <b>Tessera completa dei Punti OMAGGIO di “Speciale Estate"</b> oppure il <b>Carnet dei Punti OMAGGIO</b> da utilizzare nelle tessere che hai già iniziato.</p>
                </div>
                
            </div>
            <div> 
                <a class="button download-tessera" href="/raccolte-punti/area-personale#wrapper-download-tessera" target="_blank" >Voglio la tessera*</a>
                <a class="button download-carnet" target="_blank" >Scarica il Carnet</a>
            </div>      
            <div class="thankyou__info-tessera">
                <b>*ATTENZIONE:</b> se vuoi la tessera Speciale Estate, clicca sul bottone per tornare nell'Area Personale dove potrai scaricare la tua tessera personalizzata aggiornata con i Punti OMAGGIO verdi.</div>   
    
            </div>
        `;

        try {
            window.dataLayer.push({
                'event': 'analyticsVPV',
                'vpvName': '/welcome-survey/thank-you-page'
            });
        } catch (error) {
            console.log(error)
        }

        this.addDownloadListener();
    }


    addDownloadListener() {
        // this.downloadTessera = this.element.querySelector('.button.download-tessera');
        this.downloadCarnet = this.element.querySelector('.button.download-carnet');

        if (this.downloadCarnet) {
            // this.endpointTessera = window.API_ENDPOINT + `/api/mulinobianco/1.0/web/promo-engine/collection/raccolta-punti/download/bonus/pdf?u=${this.userId}`;
            this.endpointCarnet = window.API_ENDPOINT + `/api/mulinobianco/1.0/web/promo-engine/collection/raccolta-punti/download/bonus/carnet?u=${this.userId}`;

            // this.downloadTessera.addEventListener('click', () => {
            //     console.log('tessera clicked')
            //     window.location.href = this.endpointTessera;
            // })

            this.downloadCarnet.addEventListener('click', () => {

                window.open(this.endpointCarnet);
            })
        }

    }


    // setStepBarLabels() {
    //     const steps = this.element.querySelectorAll('.yxpStepWrapper .yxpStepBlock > span');
    //     const total = steps.length;
    //     steps.forEach((step, index) => {
    //         step.innerHTML = `<strong>${index + 1}</strong> di <strong>${total}</strong>`;
    //     });
    // }

    setupInputListener() {
        const checkboxes = this.element.querySelectorAll('.yxpInputGroup input[type="checkbox"]');
        checkboxes.forEach((checkbox) => this.addCheckboxListener(checkbox));

        const radioButtons = this.element.querySelectorAll('.yxpInputGroup input[type="radio"]');
        radioButtons.forEach((radio) => this.addRadioListener(radio));
    }

    addCheckboxListener(checkbox) {
        checkbox.addEventListener('change', (event) => {
            let currentTarget = event.currentTarget;
            if (currentTarget.checked) {
                checkbox.parentElement.classList.add('selected');
                if (currentTarget.closest('div[data-regulation="true"]')) {
                    GigyaManager.addCH(this.CH_PROMO);
                    this.regulation.querySelector('input').setAttribute("disabled", true);
                    $('.yxpNextBtn').show();
                    $('.yxpNextBtn').click();
                }
            } else {
                checkbox.parentElement.classList.remove('selected');
            }
        });
    }

    addRadioListener(radio) {
        radio.addEventListener('change', () => {
            const radioInputs = this.element.querySelectorAll(`input[type="radio"][name="${radio.name}"]`);
            radioInputs.forEach((input) => input.parentElement.classList.remove('selected'));
            radio.parentElement.classList.add('selected');
        });
    }

    setMobileTable() {
        const tables = this.element.querySelectorAll('.yxpInputTable');
        tables.forEach((table) => {
            const choices = table.querySelectorAll('.yxpInputTableHeadingTop');
            const radioButtonWidth = (100 / choices.length) + "%";
            table.querySelectorAll('input[type=radio]').forEach((radio, index) => {
                const columnIndex = index % choices.length;
                const columnLabel = `<strong>${choices[columnIndex].innerHTML}</strong>`;
                radio.parentElement.insertAdjacentHTML('afterbegin', columnLabel);
                radio.parentElement.style.width = radioButtonWidth;
            });
        });
    }

    setRelatedInput() {
        const relatedToWrapper = this.element.querySelector('[related-to]');
        if (!relatedToWrapper) return;
        const relatedToInputs = relatedToWrapper.querySelectorAll('input[type=checkbox]');
        const value = relatedToWrapper.getAttribute('related-to');
        const relatedWrapper = this.element.querySelector(`[related-input-name=${value}]`);
        const relatedCheckboxWrapper = relatedWrapper.querySelectorAll('.yxpInputCheckboxMultipleWrapper');
        for (let wrapper of relatedCheckboxWrapper) wrapper.classList.add('checkbox-hidden');

        for (let input of relatedToInputs) {
            input.addEventListener('change', (e) => this.toggleRelatedCheckboxVisibility(input, e, relatedWrapper));
        }
    }

    toggleRelatedCheckboxVisibility(input, event, relatedWrapper) {
        const relatedInput = relatedWrapper.querySelector(`input[value="${input.value}"]`);
        if (!relatedInput) return;
        (event.currentTarget.checked) ?
            relatedInput.closest('.yxpInputCheckboxMultipleWrapper').classList.remove('checkbox-hidden') :
            relatedInput.closest('.yxpInputCheckboxMultipleWrapper').classList.add('checkbox-hidden');
    }

    //
    // checkRegolamento() {
    //
    //     this.regulation = this.element.querySelector('div[data-regulation="true"]');
    //     if (!this.regulation) {
    //         $('.yxpNextBtn').show();
    //     } else if (this.chAccepted) {
    //         this.regulation.querySelector('.yxpInputCheckboxMultipleWrapper').classList.add('selected');
    //         this.regulation.querySelector('input').setAttribute("disabled", true);
    //         $('.yxpNextBtn').show();
    //         $('.yxpNextBtn').click();
    //     }
    // }


    checkRaccoltaPunti2024() {
        this.regulation = this.element.querySelector('div[data-regulation="true"]');
        if (!this.regulation) {
            $('.yxpNextBtn').show();
        }
        if (this.chAccepted) {
            this.regulation.querySelector('.yxpInputCheckboxMultipleWrapper').classList.add('selected');
            this.regulation.querySelector('input').setAttribute("disabled", true);
            $('.yxpNextBtn').show();
            $('.yxpNextBtn').click();
        } else {
            window.location.href = '/raccolte-punti/on-boarding';
        }

    }

    init() {
        // this.setStepBarLabels();
        this.setupInputListener();
        this.setMobileTable();
        this.setRelatedInput();
        // this.checkRegolamento();
        this.checkRaccoltaPunti2024();
    }
}

export default SurveyComponent;
