import Component from '../../model/Component';
import $ from 'jquery';
import Swiper from 'swiper';
import Constants from "../../business/Constants";

class PromoLocatorEventFilterComponent extends Component {

    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.$element = $(this.element);
        this.$filterGalleryCnt = this.$element.find(".swiper-container");
        this.$currentFilterCnt = this.$element.find(".current-filter");
        this.init();
    }

    init() {
        this.initSwiper(this.$filterGalleryCnt.find('.swiper-slide').length <= 2);
    }

    initSwiper(disableSwipe) {
        this.swiper = new Swiper(this.$filterGalleryCnt[0], {
            centeredSlides: true,
            simulateTouch: !disableSwipe,
            spaceBetween: $(window).width() > 767 ? 30 : 15,
            slidesPerView: $(window).width() > 767 ? 3 : 'auto',
            //slidesPerGroup: $(window).width() > 767 ? 3 : 1,
            nextButton: ".swiper-button-next",
            prevButton: ".swiper-button-prev",
            allowTouchMove: !disableSwipe
        })

        if (disableSwipe) {
            this.$filterGalleryCnt.find('.swiper-button-next, .swiper-button-prev').hide();
        }
    }

    showFilters() {
        this.$filterGalleryCnt.show();
        this.$currentFilterCnt.removeClass("visible");
    }

    showCurrentFilter() {
        this.$filterGalleryCnt.hide();
        this.$currentFilterCnt.addClass("visible");

    }

    filterByEvent(eventId) {
        let $filterEl = this.$currentFilterCnt.find(".filter");
        $filterEl.html("");
        $filterEl.append(this.$filterGalleryCnt.find("[data-event-id='" + eventId + "']").find(".filter-element").clone());
        this.showCurrentFilter();
        this.triggerFilterChangedEvent(eventId);
    }

    removeFilters(eventId) {
        let $filterEl = this.$currentFilterCnt.find(".filter");
        $filterEl.html("");
        this.showFilters();
        this.triggerFilterChangedEvent(null);
    }

    triggerFilterChangedEvent(eventId) {
        window.document.dispatchEvent(
            new CustomEvent(Constants.events.PromoLocatorEventFilterComponent.filterChanged, {detail: eventId}))
        setTimeout(() => {
            window.document.dispatchEvent(
                new CustomEvent(Constants.events.promoLocator.doSearch));
        }, 100);
    }

}


export default PromoLocatorEventFilterComponent;
