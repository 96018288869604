// dependencies
import $ from 'jquery';

// K+ component
import Swiper from 'swiper';
import Component from '../model/Component';

class OliComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.element = element;
        this.init();
    }

    addListeners() {
        this.categories.forEach((category) => {
            category.addEventListener('click', () => this.toggleSelectedCategories(category));
        });
    }

    toggleSelectedCategories(categoryButton) {
        const isCategorySelected = categoryButton.classList.contains('landing-oli__category--selected');
        const categoryId = categoryButton.dataset.categoryId;
        isCategorySelected ? this.removeCategoryFilter(categoryButton, categoryId) : this.filterProducts(categoryButton, categoryId);
    }

    filterProducts(button, categoryId) {
        this.selectedCategories.push(categoryId);
        button.classList.add('landing-oli__category--selected');
        const products = this.element.querySelectorAll(`.landing-oli__product-card`);
        products.forEach((product) => {
            this.selectedCategories.indexOf(product.dataset.categoryId) >= 0 ?
                product.classList.add('selected') :
                product.classList.remove('selected');
        });
        this.toggleNoResult();
    }

    toggleNoResult() {
        const selectedProducts = this.element.querySelectorAll('.landing-oli__product-card.selected');
        (selectedProducts.length === 0) ?
            this.noResultContainer.classList.remove('hidden') :
            this.noResultContainer.classList.add('hidden');
    }

    removeCategoryFilter(button, categoryId) {
        this.selectedCategories = this.selectedCategories.filter((category) => category !== categoryId);
        button.classList.remove('landing-oli__category--selected');
        if(this.selectedCategories.length === 0) this.showAll();
        else {
            const products = this.element.querySelectorAll(`.landing-oli__product-card[data-category-id="${ categoryId }"]`);
            products.forEach((product) => product.classList.remove('selected'));
            this.toggleNoResult();
        }
    }

    showAll() {
        const products = this.element.querySelectorAll(`.landing-oli__product-card`);
        products.forEach((product) => product.classList.add('selected'));
        this.noResultContainer.classList.add('hidden');
    }

    initSwiper() {
        this.swiper = new Swiper('.landing-oli__carousel .swiper-container', {
            pagination: '.swiper-pagination',
            nextButton: this.element.querySelector('.swiper-button-next'),
            prevButton: this.element.querySelector('.swiper-button-prev'),
            slidesPerView: 1,
            paginationClickable: true,
            keyboardControl: true,
            mousewheelControl: true,
            mousewheelForceToAxis: true
        });
    }

    init() {
        this.categories = this.element.querySelectorAll('.landing-oli__category');
        this.noResultContainer = this.element.querySelector('.landing-oli__no-result');
        this.selectedCategories = [];
        this.initSwiper();
        this.addListeners();
        this.toggleNoResult();
    }
}

export default OliComponent;
