import Component from '../model/Component';
import {gsap, Linear} from "gsap";
import $ from "jquery";

class VisualParallaxComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        let $el = $(this.element);

        var leftElemQuery = $el.data("left-elem-query");
        var rightElemQuery = $el.data("right-elem-query");
        var detachElemQuery = $el.data("trigger-end-elem-query");


        this.init($el.find(leftElemQuery)[0], $el.find(rightElemQuery)[0], $(detachElemQuery));

    }

    init(leftElement, rightElement, $detachElement) {
        gsap.set(leftElement, {x: -100, alpha: 0});
        gsap.set(rightElement, {x: 100, alpha: 0});
        setTimeout(() => {
            gsap.to(leftElement, {x: 0, alpha: 1, ease: Linear.easeOut});
            gsap.to(rightElement, {x: 0, delay: 0.1, alpha: 1, ease: Linear.easeOut});
        }, 800);

        $(window).scroll(() => {
            if ($(window).width() > 767) {
                let currentScrollTop = $(window).scrollTop();
                if ($detachElement.length == 0 || $detachElement.offset().top > currentScrollTop)
                    if (currentScrollTop > this.scrollTop) {
                        gsap.to(leftElement, {x: -25, alpha: 1, ease: Linear.easeOut});
                        // TweenMax.to($('.vetrina .text')[0], 0.4, {x: 35, alpha: 1, ease: TweenMax.Linear.easeOut});
                    } else if (currentScrollTop < this.scrollTop) {
                        gsap.to(leftElement, {x: 0, alpha: 1, ease: Linear.easeIn});
                        gsap.to(rightElement, {x: 0, alpha: 1, ease: Linear.easeIn});
                    }
                this.scrollTop = currentScrollTop;
            }
        });
    }


}


export default VisualParallaxComponent;
