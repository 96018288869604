import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import $ from "jquery";
import Utility from "../business/Utility";
import Swiper from "swiper";

class PiccoloMugnaioBiancoCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.init();
        this.piccoloMugnaioBiancoCH = "CH_1058_MLB_WEB_PICCOLOMUGNAIO_NAV_NAV_20250303_20250303";
        this.regSource = 1058;
        this.slider = $('.piccoloMugnaioBianco__spots-slider');

        this.dataVideoGallery = [
            {videoId: 'RAc-UmxZGig'},
            {videoId: 'jUQNQTyCGcc'},
            {videoId: 'pSkuB_5ID0E'},
            {videoId: 'Y7Bz7A56VB4'},
        ];

        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        this.player = null;
        window.onYouTubeIframeAPIReady = () => {
            this.player = new YT.Player('player-gallery', {
                height: '100%',
                width: '100%',
                videoId: 'RAc-UmxZGig',
                controls: 2,
                arrows: true,
                events: {
                    onReady: (event) => {
                        this.onReady(event)
                    },
                    'onStateChange': (event) => {
                        this.onPlayerStateChange(event)
                    }
                }
            });
        };
        this.initSlider();
    }

    init(){
        $('#overlayer').show();
        GigyaManager.getLoggedUser().then((user) => {
            const ch = user.data[this.piccoloMugnaioBiancoCH];
            if (typeof ch !== "undefined" && ch != null && ch !== "") {
                this.showLoggedArea(true);
            } else {
                this.showLoggedArea(false);
            }
        }, () => {
            this.showLoggedArea(false);
        });

        this.videoFn('.piccoloMugnaioBianco__spots-video-cnt');
        this.scrollFn('a.scroll');
    }

    onReady(event) {
    }

    videoFn(selector) {
        window.jQuery(selector).find('> a').on('click', (e) => {
            e.preventDefault();
            let box = $(e.target).closest(selector);
            window.jQuery('<iframe frameborder="0" allowfullscreen></iframe>').prop('src', box.data('embed')).wrap('<div class="responsive-video"></div>').parent().appendTo(box);
            box.find('> a').remove();
            return false;
        });
    }

    scrollFn(selector) {

        this.time = 800;
        this.easing = 'swing';

        window.jQuery(selector).on('click', (e) => {
            e.preventDefault();
            let target = window.jQuery(window.jQuery.attr(e.target, 'href'));
            if (target.length) this.scroll(target.offset().top);
            return false;
        });
    }

    initSlider() {
        var options = {
            slidesPerView: 3,
            nextButton: this.$el.find('.swiper-button-next')[0],
            prevButton: this.$el.find('.swiper-button-prev')[0],
            pagination: this.$el.find('.swiper-pagination')[0],
            paginationClickable: true,
            spaceBetween: 20,
            loop: true,
            breakpoints: {
                767: {
                    slidesPerView: 2,
                }
            }
        };

        this.swiper = new Swiper(this.$el.find('.swiper-container'), options);
    }

    showLoggedArea(canUser){
        $(`.promo-${canUser}`).addClass('check');
        $('#overlayer').hide();
    }

    onPlayerStateChange(event) {
        if (event.data == 5) {
            this.player.playVideo();
        } else if (event.data == 0) {
            $('#cover').show();
        }
    }

    playOrChange(id) {
        $('#cover').hide();
        this.player.loadVideoById(this.dataVideoGallery[id]);
    }
}

export default PiccoloMugnaioBiancoCtrl;