import Component from '../model/Component';
import $ from 'jquery';
import GigyaManager from "../business/GigyaManager";
import CryptoJS from "crypto-js";

class GiocaApiComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.init();
    }

    init() {

        this.$element.html('<iframe id="game" allow="fullscreen; autoplay; encrypted-media" src="https://games.gamindo.com/demo/Barilla/K2"></iframe>');

        $('#game').on('load', () => {

            window.addEventListener('message', (evt) => {
                this.onMessageRecieved(evt);
            });

        });
    }


    sendMessageToIFrame(obj) {
        console.log("Send Message");
        console.log(obj);
        $('#game')[0].contentWindow.postMessage(JSON.stringify(obj), '*')
    }

    onMessageRecieved(message) {
        if (message.origin == "https://games.gamindo.com") {
            let obj = JSON.parse(message.data);
            console.log(obj);

            if (obj.event == "PLAY") {
                GigyaManager.getLoggedUser().then((d) => {
                    if (!d.data["CH_572_MLB_WEB_APIGAMINDO_NAV_NAV_20210520_21210520"]) {
                        GigyaManager.addCH("CH_572_MLB_WEB_APIGAMINDO_NAV_NAV_20210520_21210520");
                    }
                })

            } else if (obj.event == "LOGIN_REQUESTED") {
                window.location.href = "/login?urlBack=" + window.location.pathname + "&regSource=572";
            } else if (obj.event == "GAME_READY") {
                let item = localStorage.getItem("gamindo_api_stats_json");
                let stats;
                if (item) {
                    stats = JSON.parse(item);
                } else {
                    stats = {
                        level: 0, score: 0
                    }
                }
                stats['event'] = "PUT_USER_STATS";
                this.sendMessageToIFrame(stats);

                GigyaManager.getLoggedUser().then((data) => {
                    let event = {
                        event: "USER_LOGGED",
                        name: data.profile.firstName + " " + data.profile.lastName.charAt(0).toUpperCase() + ".",
                        id: this.hash(data.UID),
                        uid: data.UID,
                        firstName: data.profile.firstName,
                        email: data.profile.email
                    }
                    this.sendMessageToIFrame(event)
                    console.log(event);
                }, () => {
                    //noop
                });
            } else if (obj.event == "GET_USER_STATS") {
                localStorage.setItem("gamindo_api_stats_json", JSON.stringify({level: obj.level, score: obj.score}));
            } else if (obj.event == "FULLSCREEN") {
                $('#game').addClass("fullscreen");
            } else if (obj.event == "MINIMIZE") {
                $('#game').removeClass("fullscreen");
            } else if (obj.event == "ANALYTICS") {
                analyticsEvent(obj.param1, obj.param2, obj.param3); //Tracking to analytics
            }
        }
    }

    hash(message) {
        return CryptoJS.SHA256(message + "odnimag").toString(CryptoJS.enc.Hex);
    }
}

export default GiocaApiComponent;

/*
PUT_USER_STATS
GET_USER_STATS
{level: 1, score: 123}
*/