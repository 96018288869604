import Component from '../model/Component';
import Constants from "../business/Constants";
import UserManager from "../business/UserManager";
import PrivacyCluster from "../enums/PrivacyCluster";
import GigyaManager from "../business/GigyaManager";

class PrivacyConsenseComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        $(this.element).hide()

        this.element.addEventListener(Constants.events.MODAL_OPEN, () => this.open());

        this.init(false);

        $(this.element).find('#mb-save-consense').click(this.save.bind(this));

    }

    init(refresh) {
        UserManager.getPrivacyCluster(refresh).then((privacyCluster) => {
            UserManager.getLoggedUser(refresh).then((account) => {

                if(account.preferences.privacy_profiling.isConsentGranted){
                    $('#privacyprofilingt').prop('checked', true);
                } else {
                    $('#privacyprofilingf').prop('checked', true);
                }

                if(account.preferences.privacy_recall.isConsentGranted){
                    $('#privacymtkgt').prop('checked', true);
                } else {
                    $('#privacymtkgf').prop('checked', true);
                }

                if(PrivacyCluster.U2 == privacyCluster){
                    $('.profiling-consense-cnt').hide();
                }

                if(PrivacyCluster.U3 == privacyCluster){
                    $('.mtkg-consense-cnt').hide();
                }


            });
        })
    }

    save() {

        let privacyProfiling = $('.consense-cnt input[name="privacyprofiling"]:checked').val();
        let privacyRecall = $('.consense-cnt input[name="privacymtkg"]:checked').val();

        let params = {
            preferences: {
                privacy_profiling: {
                    isConsentGranted: privacyProfiling
                },
                privacy_recall: {
                    isConsentGranted: privacyRecall
                }
            },
            callback: () => {
                this.close();
                window.document.dispatchEvent(new CustomEvent(Constants.events.PRIVACY_CONSENSE_CHANGED, {detail: null}));
            }
        };

        GigyaManager.setAccountInfo(params);

        this.init(true);

    }

    close() {
        $('body').removeClass("no-scroll");
        $(this.element).hide();
    }

    open() {
        $('body').addClass("no-scroll");
        $(this.element).show();
    }
}


export default PrivacyConsenseComponent;