var TemplatingUtility = {

    linkHtml(link, extraClass) {
        if (!!link && !!link.url) {
            return `<a class="link ${extraClass || ''}" href="${link.url}" target="${!!link.target && link.target == 'BLANK'}">${link.pageLabel || 'Scopri di più'}</a>`;
        }
        return ''
    },
    newTag(n) {
        if (!!n.read) {
            return '';
        }
        return '<span class="new-tag">Nuova</span>';
    },
    printCoupon(coupon) {
        if (!coupon) {
            return '';
        }

        return `<div class="coupon">
                    <a href="http://www.nelmulinochevorrei.it/coupon.html?idc=${coupon.code}" target="_blank">
                    <span class="label">SCONTO <br><span class="value">${coupon.discountValueText}€</span></span>
                        <svg class="background">
                            <use xlink:href="#svg_coupon_marker"/>
                        </svg> 
                    </a>
                    <div class="tooltip">
                        <span class="text">Vai Nel Mulino che Vorrei per scoprire tutti i coupon pensati per te!</span>
                    </div>
                </div>`;
    },
    getEmoji(emoji) {
        return `
                <svg class="emoji on">
                    <use xlink:href="#svg_emoji_${emoji.toLowerCase()}_on"/>
                </svg>
              `;
    },
    printText(text) {
        if (text.length > 83) {
            return text.substring(0, 80) + '...<strong class="blue-bold">continua</strong>';
        }

        return text;
    },
    printStars(rate) {
        let html = `<div class="star-wrapper mini" mb-component="IconRatingComponent" data-initial-value="${rate}" data-read-only="true"></div>`;
        return html;
    },
    defaultOrCustom(defaultText, customText) {
        if (!customText || customText.length < 2) {
            return defaultText;
        }
        return customText;
    },
    formatDate(timestamp) {
        let date = new Date(timestamp);
        let months = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC'];

        return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
    }
};

export default TemplatingUtility;
