/**
 *
 */
import Controller from '../model/Controller';
import gReveal from "../vendor/gReveal";
import $ from 'jquery';

class CommitmentIngredientsCtrl extends Controller {
    constructor(element) {
        super(element);
        this.init();
    }

    init() {
        this.reveal = new gReveal(
            {
                initialDelay: 0,
                offset: 120
            });

        window.document.addEventListener("IngredientsAutocompleteComponent:valueSelected", (event) => {

            let ingredientId = event.detail.data.id;
            if (this.ingredientId != ingredientId) {
                let actionUrl = $('#ingredient-category-btn').attr('href');

                document.location.href = actionUrl + "?ingredientId=" + ingredientId;
            }
        })
    }
}

export default CommitmentIngredientsCtrl;
