/**
 *
 */

import Controller from '../model/Controller';
import Tracker from "../business/Tracker";
import $ from "jquery";
import Swiper from "swiper";
class BioCtrl extends Controller {
    constructor(element) {
        super(element);

        this.init();
    }

    init() {
        this.galleryTop = $('#gallery-top');
        let galleryTop = new Swiper(this.galleryTop[0], {
            slidesPerView: 1,
            nextButton: '#gallery-top-next',
            prevButton: '#gallery-top-prev',
        });
        galleryTop.on('slideChangeEnd', () => {
            this.galleryTop.find('iframe.video').remove();
        });
        this.galleryTop.find('[data-video-id]').on('click', (e) => {
            let cont = $(e.currentTarget),
                slide = cont.parent();

            $('<iframe class="video" frameborder="0" allowfullscreen></iframe>').prop('src', 'https://www.youtube.com/embed/' + cont.data('video-id') + '?autoplay=1&rel=0&showinfo=0').appendTo(slide);

            return false;
        });
        Tracker.send("EVENT", {interactionType: "ZCATEGORY_CLICK", productCode: '9991000085' });
    }
}

export default BioCtrl;
