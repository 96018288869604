import $ from 'jquery';
import Component from '../model/Component'
import Choices from 'choices.js'

class SelectBoxComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.choisesInstance = null;
        this.init();
    }

    init() {
        //Instanzio per i desktop e per i mobile con risoluzione alta quindi i tablet
        let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        if ((isMobile && $(window).width() > 767) || !isMobile) {
            this.choisesInstance = new Choices(this.element, {
                searchEnabled: false,
                searchChoices: false
            });
        }
        if (this.choisesInstance && $(this.element).attr("disabled")) {
            this.choisesInstance.disable();
        }

        if (this.choisesInstance && $(this.element).hasClass("error")) {
            $(this.element).parents(".choices").addClass("error");
        }

        /*$(this.element).change(() => {
          if ($(this.element).val()) {
            $(this.element).addClass("selected");
          } else {
            $(this.element).removeClass("selected");
          }
        })*/
    }

    toogleDisable(disable) {
        if (disable) {
            if (this.choisesInstance) {
                choices.disable();
            }
            $(this.element).attr("disabled", "disabled");
        } else {
            if (this.choisesInstance) {
                choices.enable();
            }
            $(this.element).removeAttr("disabled");
        }
    }

    toogleError(isInError) {
        if (isInError) {
            $(this.element).parents(".choices").addClass("error");
            $(this.element).addClass("error");
        } else {
            $(this.element).parents(".choices").removeClass("error");
            $(this.element).removeClass("error");
        }
    }

}


export default SelectBoxComponent;
