/**
 *
 */

import Controller from '../model/Controller';
import $ from 'jquery'
import Constants from "../business/Constants";
import Utilility from "../business/Utility";
import HttpClient from "../business/HttpClient";
import Tracker from "../business/Tracker";
import GoogleAnalytics from "../business/GoogleAnalytics";

class SendReviewCtrl extends Controller {
    constructor(element) {
        super(element);
        this.form = {
            idEmoji: null,
            text: null,
            rate1: 0,
            rate2: 0,
            rate3: 0,
            avgRate: 0
        };

        this.isRunningAjax = false;

        this.components.rate1.element.addEventListener(Constants.events.RATING_CHANGE, (d) => this.onRate1Change(d));
        this.components.rate2.element.addEventListener(Constants.events.RATING_CHANGE, (d) => this.onRate2Change(d));
        this.components.rate3.element.addEventListener(Constants.events.RATING_CHANGE, (d) => this.onRate3Change(d));

        $('#reviewText').focusout(() => {
            var reviewText = $('#reviewText').val();
            if (reviewText) {
                $('#reviewText').removeClass('error');

            }
        });

        $('.review-policy').on('click',()=> {
            $('#review:checked').length > 0 ? $('.review-policy').removeClass('error') : $('.review-policy').addClass('error')
        })
    }

    setEmoji(idEmoji) {
        $('.emoji').removeClass("on");
        $('#emoji_' + idEmoji).addClass("on");
        this.form.idEmoji = idEmoji;
        $('.emoji-block').removeClass("error");
    }

    onRate1Change(customEvent) {
        this.form.rate1 = customEvent.detail.value;
        this.calcAndUpdateAvg();

    }

    onRate2Change(customEvent) {
        this.form.rate2 = customEvent.detail.value;
        this.calcAndUpdateAvg();

    }

    onRate3Change(customEvent) {
        this.form.rate3 = customEvent.detail.value;
        this.calcAndUpdateAvg();
    }

    calcAndUpdateAvg() {
        let rate1 = this.form.rate1 == null ? 0 : this.form.rate1;
        let rate2 = this.form.rate2 == null ? 0 : this.form.rate2;
        let rate3 = this.form.rate3 == null ? 0 : this.form.rate3;

        let avg = (rate1 + rate2 + rate3) / 3;

        this.form.avgRate = Math.round(avg);

        this.components.avgRate.setRating(this.form.avgRate);

    }

    sendForm() {
        var reviewText = $('#reviewText').val();
        if (!reviewText) {
            $('#reviewText').addClass('error');
        }

        if ($('#review:checked').length < 1) {
            $('.review-policy').addClass('error');
        } else {
            $('.review-policy').removeClass('error');
        }

        if (this.form.idEmoji == null) {
            $('.emoji-block').addClass("error");
        }
        if (this.form.idEmoji == null || !reviewText || $('#review:checked').length < 1) {
            if (this.form.idEmoji == null || !reviewText) {
                var body = $("html, body");
                body.stop().animate({scrollTop: 300}, 500, 'swing', function () {
                });
            }
            return;
        }

        this.form.text = reviewText;

        Utilility.debounce(this.doAjaxCall(), 1500);

        const obj = {
            'data-form-id': 'send-review',
            'data-form-name': 'send review'
        }

        GoogleAnalytics.send('form_submitted', obj);
    }

    doAjaxCall() {
        if (!this.isRunningAjax) {

            this.isRunningAjax = true;

            HttpClient.doCall({
                url: API_ENDPOINT + '/api/mulinobianco/1.0/web/review/' + Utilility.getUrlParameter("pid"),
                type: "POST",
                dataType: "json",
                contentType: "application/json",
                data: JSON.stringify(this.form)
            }, false).then((data) => {
                document.getElementById('review-sent-ok').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
                this.isRunningAjax = false;
            }, (err) => {
                if (err.status == 409) {
                    document.getElementById('error-review-conflict').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
                    this.isRunningAjax = false;
                } else {
                    document.getElementById('generic-error-modal').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
                    this.isRunningAjax = false;
                }
                console.log(err);
            });

            if (this.form.avgRate >= 3) {
                Tracker.send("EVENT", {
                    interactionType: "ZPRODUCT_COMMENT",
                    productCode: $('#mb-product-externalid').html()
                })
            }

        }

    }

}

export default SendReviewCtrl;
