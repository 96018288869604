/**
 *
 */
import Controller from '../model/Controller';


import $ from 'jquery';
import Mustache from "mustache";
import Utility from '../business/Utility';
import HttpClient from "../business/HttpClient";
import UserManager from "../business/UserManager";
import range from 'rangeslider.js';
import TweenMax from "gsap";
import gReveal from "../vendor/gReveal";
import Swiper from "swiper";
import GigyaManager from '../business/GigyaManager';


class Tour2019DettaglioCtrl extends Controller {
	constructor(element) {
		super(element);
		this.init();
	}

	init() {
		console.log('init');
	}


}

export default Tour2019DettaglioCtrl;
