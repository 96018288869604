import Component from '../../model/Component';
import StepHeader from "./common/stepHeader";

class BoxStep2Component extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;

        this.store.subscribe(() => {
            let state = this.store.getState();
            if (state.currentStep == 2) {
                $(this.element).html(this.createHtml(store.getState().userChoices.step1));
            }
        });

        $(this.element).html(this.createHtml(store.getState().userChoices.step1));

    }

    goToNextStep(id) {
        let userChoice = this.store.getState().userChoices.step1.children.filter(o => o.id == id)[0];
        this.store.dispatch({
            ...this.store.getState(),
            type: 'contactForm/goToNextStep',
            payload: {step2: userChoice, formData: {}}
        });
    }

    back() {
        this.store.dispatch({...this.store.getState(), type: 'contactForm/back'});
    }

    createHtml(config) {
        return `
              ${StepHeader({title: config.title, subtitle: config.subtitle})}
            <div class="items-container">
                 ${this.createButtons(config.children)}
            </div>
        `;
    }


    createButtons(config) {
        let buttonsHtml = '';
        for (var i = 0; i < config.length; i++) {
            buttonsHtml +=
                `<button type="button" mb-component-click="goToNextStep(${config[i].id})" class="button box ${config.length > 4 ? 'small-size' : ''} flat-cta shadow">
                    <div class="title">${config[i].title}</div>
                </button>`;
        }

        return buttonsHtml;
    }
}


export default BoxStep2Component;
