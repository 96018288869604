import Component from '../model/Component';
import $ from 'jquery';

class SorpresineFilterMobileComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.$element = $(element);

        this.init();
    }

    closeFilter() {
        $('.sorpresine-filters__close, .sorpresine-filters__save').on('click', () => {
            $('.sorpresine-filters.show-mobile').addClass('hidden');
            $('body').removeClass('no-scroll');
        });
    }

    rangeSlider() {
        let rangeMin = 1;
        const range = document.querySelector(".sorpresine-filters.show-mobile .range-selected");
        const rangeInput = document.querySelectorAll(".sorpresine-filters.show-mobile .range-input input");
        const rangePeriod = document.querySelectorAll(".sorpresine-filters.show-mobile .range-period input");

        rangeInput.forEach((input) => {
            input.addEventListener("input", (e) => {
                let minRange = parseInt(rangeInput[0].value);
                let maxRange = parseInt(rangeInput[1].value);
                let minValue = $('.show-mobile .range-period input').attr('min');
                let maxValue = $('.show-mobile .range-period input').attr('max');
                let offset = parseInt(maxValue - minValue);

                if (maxRange - minRange < rangeMin) {
                    if (e.target.className === "min") {
                        rangeInput[0].value = maxRange - rangeMin;
                    } else {
                        rangeInput[1].value = minRange + rangeMin;
                    }
                } else {
                    rangePeriod[0].value = minRange;
                    rangePeriod[1].value = maxRange;

                    range.style.right = Number((100 / offset) * (rangeInput[0].max - maxRange)) + "%";
                    range.style.left = Number((100 / offset) * (minRange - rangeInput[0].min)) + "%";
                }
            });
        });

        rangePeriod.forEach((input) => {
            input.addEventListener("input", (e) => {
                let minValue = $('.show-mobile .range-period input').attr('min');
                let maxValue = $('.show-mobile .range-period input').attr('max');
                let offset = parseInt(maxValue - minValue);
                let minPeriod = rangePeriod[0].value;
                let maxPeriod = rangePeriod[1].value;
                if (maxPeriod - minPeriod >= rangeMin && maxPeriod <= rangeInput[1].max) {
                    if (e.target.className === "min") {
                        rangeInput[0].value = minPeriod;
                        range.style.left = Number((100 / offset) * (minPeriod - rangeInput[0].min)) + "%";
                    } else {
                        rangeInput[1].value = maxPeriod;
                        range.style.right = Number((100 / offset) * (rangeInput[0].max - maxPeriod)) + "%";
                    }
                }
            });
        });
    }


    init() {
        this.closeFilter();
        this.rangeSlider();
    }

}

export default SorpresineFilterMobileComponent;