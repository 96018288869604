"use strict";

import $ from "jquery";

export function StepHandler($stepReference) {

    const fieldsFormat = {
        MessageText: {pattern: '', required: true},
        PrivacyAccept: {pattern: '', required: true},
        ContactIdGigya: {pattern: '', required: false},
        ContactName: {
            pattern: '^[a-zA-ZàáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð .\'-]{2,60}$',
            required: true
        },
        ContactSurname: {
            pattern: '^[a-zA-ZàáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð .\'-]{2,60}$',
            required: true
        },
        ContactEmail: {
            pattern: '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$',
            required: true
        },
        ContactPhone: {pattern: '^[0-9+ ]{10,20}$', required: false},
        ContactAddress: {
            pattern: '^[a-zA-Z0-9àáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð ,.\'-/°]{2,120}$$',
            required: false
        },
        ContactAddressNumber: {pattern: '', required: false},
        ContactCity: {
            pattern: '^[a-zA-ZàáâäãåèéêëìíîïòóôöõøùúûüÿýñçčšžÀÁÂÄÃÅÈÉÊËÌÍÎÏÒÓÔÖÕØÙÚÛÜŸÝÑßÇŒÆČŠŽ∂ð ,.\'-/°]{2,60}',
            required: false
        },
        ContactProv: {pattern: '^[A-Za-z]{2}$', required: false},
        ContactCap: {pattern: '^\\d{5}$', required: false},
        Product: {pattern: '', required: false},
        PromoCode: {pattern: '', required: false},
        Classification: {pattern: '', required: true},
        ProductBatch: {pattern: '^[A-Za-z0-9]{1,50}$', required: false},
        ProductExpirationdate: {pattern: '', required: false},
        ProductBarCode: {pattern: '^[A-Za-z0-9]{13}$', required: false},
        SellingPointAddress: {pattern: '', required: false}
    }
    const ignoredFields = []
    let stepFormdata = {fileList: {}}
    const invalidFields = {}

    const handlers = {

        addDataImage(key, value) {
            if (!stepFormdata.fileList) {
                stepFormdata.fileList = {}
            }
            Object.assign(stepFormdata.fileList, {[key]: value})
        },
        removeDataImage(key) {
            this.addDataImage(key, '')
        },
        addData(key, value) {
            Object.assign(stepFormdata, {[key]: value})
        },
        removeData(key) {
            delete stepFormdata[key]
        },
        reload() {
            stepFormdata = Object.assign({}, stepFormdata)
            if (!!stepFormdata.fileList) {
                stepFormdata.fileList = Object.assign({}, stepFormdata.fileList)
            }
        },
        reset() {
            stepFormdata = {fileList: {}}
        },
        loadPrefilled() {
            this.getStepFields().forEach((k) => {
                let $field = $stepReference.find(`[name="${k}"]`)
                let value = $field.val()
                if (value !== '' && !$field.prop('checked')) {
                    this.addData(k, value)
                }
            })
            this.verifyStepStatus(false)
        },
        cascadeSelectHandler(config) {
            const promoList = config.config && config.config.promoList || []

            const generateList = () => {
                // let contestList = [];
                let subjectList = {};
                for (let promo of promoList) {
                    //   contestList.push({label: promo.name, value: promo.promoCode})
                    subjectList[promo.promoCode] = promo.subject
                }
                return subjectList
            }

            const setTelephoneInfo = (code = '') => {
                let telephoneInfo = '';

                for (let promo of promoList) {
                    if (promo.promoCode === code) {
                        telephoneInfo = promo.telephoneInfo
                        break
                    }
                }
                $stepReference.find('.telephone-info').html(telephoneInfo)
            }

            const fillSelect = ($contestSel, $classificationSel, subjectList) => {
                const subjectKey = $contestSel.val()
                const classificationList = subjectKey === '' ? [] : subjectList[subjectKey]
                let options = $classificationSel.find('option')[0].outerHTML
                $classificationSel.empty()
                classificationList.forEach(item => {
                    options += `<option value="${item.classificationId}">${item.label}</option>`
                })
                $classificationSel.append(options)
            }

            const $contestSel = $stepReference.find('#PromoCode');
            const $classificationSel = $stepReference.find('#Classification');
            const subjectList = generateList()

            if ($contestSel.length > 0 && $classificationSel.length > 0) {
                if ($contestSel.val() === '') {
                    $classificationSel.parent().addClass('disabled')
                } else {
                    $classificationSel.parent().removeClass('disabled')
                }
                fillSelect($contestSel, $classificationSel, subjectList)
                setTelephoneInfo($contestSel.val());
                $contestSel.change(
                    () => {
                        if ($contestSel.val() === '') {
                            $classificationSel.parent().addClass('disabled')

                        } else {
                            $classificationSel.parent().removeClass('disabled')
                        }
                        setTelephoneInfo($contestSel.val());
                        fillSelect($contestSel, $classificationSel, subjectList)
                    }
                )
            }
        },
        getStepFields() {
            const fieldList = Object.keys(fieldsFormat)
            let stepFields = [];
            $stepReference.find('input,select,textarea').each((index, item) => {
                let name = $(item).attr('name');
                if (fieldList.includes(name)) {
                    stepFields.push(name)
                }
            })
            return stepFields
        },
        canGoNext() {
            return this.isValidStep(true) && !this.isEmptyStep()
        },
        verifyStepStatus(higlightError = true) {

            if (this.isValidStep(higlightError) && !this.isEmptyStep()) {
                $stepReference.find('button.action-button').removeClass('disabled')
                return true
            } else {
                $stepReference.find('button.action-button').addClass('disabled')
                return false
            }
        },
        isEmptyField(key) {
            return stepFormdata[key] === ''
        },
        isEmptyStep() {
            return Object.keys(stepFormdata).length === 0
        },
        isValidField(key, val) {
            let pattern = fieldsFormat[key] && fieldsFormat[key].pattern
            let regex = new RegExp(pattern || '')
            return (val !== '' && regex.test(val));
        },
        isValidStep(hightlightError = true) {

            let validStep = true;
            let errorField = []

            for (let key in invalidFields) {
                errorField.push($stepReference.find(`[name="${key}"]`))
                validStep = false
            }

            this.getStepFields().forEach(key => {
                if (fieldsFormat[key] && fieldsFormat[key].required) {
                    let validField = true
                    switch ($stepReference.find(`[name="${key}"]`).attr('type')) {
                        case 'checkbox':
                            validField = $stepReference.find(`[name="${key}"]`).prop('checked')
                            break
                        default:
                            validField = $stepReference.find(`[name="${key}"]`).val() !== ''
                            break
                    }

                    if (!validField) {
                        errorField.push($stepReference.find(`[name="${key}"]`))
                        validStep = false
                    }
                }
            })
            if (hightlightError) {
                errorField.forEach($field => {
                    $field.addClass('input-error')
                    if (!$field.prop('checked')) {
                        $field.siblings('.privacy-error-message').fadeIn()
                    }
                })
            }
            return validStep
        },
        getFormData() {
            return stepFormdata
        },
        getProcessedData(rawData, stringify = false) {

            //FILTERING
            let fileList = Object.values(rawData.fileList).filter((file) => {
                return file !== ''
            })
            delete rawData.fileList

            rawData.PrivacyAccept = Number(rawData.PrivacyAccept)

            if (!!rawData.ContactAddress) {
                rawData.ContactAddress = `${rawData.ContactAddress} ${rawData.ContactAddressNumber}`
                delete rawData.ContactAddressNumber
            }


            let processed = {
                fileList: fileList,
                payload: rawData
            }

            if (stringify) {
                return JSON.stringify(processed)
            }
            return processed
        },
        loader(state = {}) {

            const loaderHandler = {
                show: () => {
                    if ($('#submit-loader').length === 0) {
                        $stepReference.prepend('<div id="submit-loader" class="loader"><div class="spinner centered"></div></div>')
                    }
                    $("#submit-loader").show()
                },
                hide: () => {
                    $("#submit-loader").hide()
                },
                showError: () => {
                    $("#submit-loader .spinner").fadeOut()
                    if ($('#submit-loader .submit-error-message').length === 0) {
                        $("#submit-loader").append('<div class="submit-error-message">Si è verificato un problema nell\'invio dei tuoi dati. Clicca <span class="exit-error-message">qui</span> per tornare al form e riprovare</div>')
                        $('#submit-loader .exit-error-message').click(() => {
                            $("#submit-loader").hide()
                        })
                    }

                }
            }

            state.error ? loaderHandler.showError() : state.isLoading ? loaderHandler.show() : loaderHandler.hide()
        },
        imageUploadHandler() {
            const activateNextUploader = ($target) => {
                let next = $target.parent().data('next')
                if (next !== '') {
                    $(`[name="${next}"]`).parent().removeClass('disabled')
                }
            }
            const deactivateNextUploader = ($target) => {
                let next = $target.parent().data('next')
                if (next !== '') {
                    let $next = $(`[name="${next}"]`)
                    if (!$next.hasClass('loaded'))
                        $next.parent().addClass('disabled')
                }
            }

            $stepReference.find('.image-icon-close').click((e) => {

                const closeElem = e.currentTarget
                $(closeElem).hide()
                $(closeElem).siblings('.input-image[type="file"]').removeClass('loaded')
                $(closeElem).siblings('.image-loaded-text').html('').hide()
                $(closeElem).siblings('.upload-input-button').show();

                const key = $(closeElem).siblings('.input-image[type="file"]').attr('name')

                this.removeDataImage(key)
                $(closeElem).parents('.upload-image').removeAttr('style')
                deactivateNextUploader($(e.currentTarget))
            })

            $stepReference.find('.input-image[type="file"]').change(e => {

                $(e.currentTarget).siblings('.error-message').css('display', 'none');

                const files = e.currentTarget.files; // puts all files into an array
                const imageEndpoint = `${API_ENDPOINT}/api/mulinobianco/1.0/web/ugc/upload/customercare`;
                const imageFile = files[0]
                let filesize = ((imageFile.size / 1024) / 1024).toFixed(4); // MB
                let filename = imageFile.name
                let filetype = imageFile.type

                if (typeof filename !== "undefined" && filesize <= 3) {
                    $(e.currentTarget).siblings('.loading-bar').css('display', 'block');
                    let formData = new FormData();
                    formData.append("file", imageFile);
                    $.ajax({
                        method: "POST",
                        url: imageEndpoint,
                        data: formData,
                        processData: false,
                        contentType: false
                    }).then((response) => {
                        /*update labels*/
                        $(e.currentTarget).addClass('loaded')
                        $(e.currentTarget).siblings('.image-loaded-text').html(filename).show()
                        $(e.currentTarget).siblings('.image-icon-close').show()
                        $(e.currentTarget).siblings('.loading-bar').css('display', 'none');
                        $(e.currentTarget).siblings('.upload-input-button').hide();
                        /* update state*/
                        this.addDataImage(e.currentTarget.name, response.body.relativePath)

                        /*update image miniature*/
                        let previewStyle = {};

                        switch (filetype) {
                            case 'application/pdf':
                                let miniature = '/fe-web/img/contact-form/pdf-miniature.png'
                                previewStyle = {
                                    'background-image': `url("${miniature}")`,
                                    'background-position': 'center',
                                    'background-color': '#fcf4d3',
                                    'background-size': 'contain'
                                }
                                break;
                            default:
                                previewStyle = {
                                    'background-image': `url("${response.body.path}")`,
                                    'background-position': 'center',
                                    'background-size': 'cover'
                                }
                                break;
                        }
                        $(e.currentTarget).parents('.upload-image').css(previewStyle);

                        /*enable next step*/
                        activateNextUploader($(e.currentTarget))
                    })
                        .catch((error) => {
                            /*remove labels*/
                            $(e.currentTarget).siblings('.image-loaded-text').html("").hide()
                            $(e.currentTarget).siblings('.image-icon-close').hide()
                            $(e.currentTarget).siblings('.loading-bar').css('display', 'none');
                            $(e.currentTarget).siblings('.error-message').html('Errore nel caricamento, riprova.').css('display', 'block');
                        })

                } else {
                    $(e.currentTarget).siblings('.image-loaded-text').html("").hide()
                    $(e.currentTarget).siblings('.image-icon-close').hide()
                    $(e.currentTarget).siblings('.loading-bar').css('display', 'none');
                    $(e.currentTarget).siblings('.error-message').html('Dimensione massima 3MB').css('display', 'block');

                }
            })
        },
        handleInput(config = {}) {

            this.loadPrefilled();

            this.imageUploadHandler()
            this.cascadeSelectHandler(config)
            $stepReference.find('input,select, textarea').change((e) => {
                const elem = e.currentTarget;
                if (ignoredFields.includes(elem.name)) {
                    return
                }
                if ($(elem).attr('type') === 'file') {
                    return
                }

                $(e.currentTarget).removeClass('input-error');

                delete invalidFields[elem.name]
                this.addData(elem.name, elem.value)

                if (this.isEmptyField(elem.name)) {
                    this.removeData(elem.name)
                } else if (!this.isValidField(elem.name, elem.value)) {
                    $(elem).addClass('input-error')
                    invalidFields[elem.name] = elem.value
                    this.removeData(elem.name)
                }

                $('.input-valid').removeClass('input-valid');

                this.verifyStepStatus(false);
            });

            $stepReference.find('input, textarea').keydown((e) => {
                $(e.currentTarget).removeClass('input-error');
            })

            $stepReference.find('input[type="text"],input[type="email"],input[type="tel"], textarea').keyup((e) => {

                if (ignoredFields.includes(e.currentTarget.name)) {
                    return
                }
                if (this.isEmptyField(e.currentTarget.name) ||
                    this.isValidField(e.currentTarget.name, e.currentTarget.value)) {
                    $(e.currentTarget).removeClass('input-error');
                    $(e.currentTarget).addClass('input-valid');
                } else {
                    $(e.currentTarget).removeClass('input-valid');
                    // $(e.currentTarget).addClass('input-error');
                }
                this.verifyStepStatus(false)
            })

            $('.privacy-error-message').click((e) => {
                $(e.currentTarget).fadeOut()
            })

        }
    }
    return handlers;
}