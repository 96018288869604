import $ from 'jquery';
import Component from '../model/Component';
import Tracker from "../business/Tracker";
import GigyaManager from "../business/GigyaManager";

class TrackingPageViewComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        let $el = $(element);
        let map = $el.data();
        this.payload = {};
        this.hasClicked = false;

        Object.keys(map).forEach((key) => {
            if (key.startsWith("trk")) {
                let newKey = this.lowerizeFirstLetter(key.replace("trk", ""));
                this.payload[newKey] = map[key];
            }
        });

        GigyaManager.getLoggedUser().then(() => {
            Tracker.send("PAGE_VIEW", this.payload).then(() => {
            }).catch((err) => {
                console.error(err);
            });
        }, () => {
            // Non traccio le page_view anonime
        })


    }


    lowerizeFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }
}


export default TrackingPageViewComponent;
