import $ from 'jquery';
import Mustache from "mustache";
import Swiper from "swiper";

import Component from '../model/Component';
import Tracker from "../business/Tracker";
import SliderEcommerceTemplating from "../business/SliderEcommerceTemplating";

class SliderEcommerceComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.productId = this.$element.attr('data-product');
        this.eShopCount = this.$element.find('.swiper-container').attr('data-items');
        this.ajax = this.$element.data('ajax');
        this.init();
    }

    buildStoresList() {
        // let slidesNum = ($(window).width() < 768) ? 2 : (this.eShopCount < 8 ? this.eShopCount : 8);
        let slidesNum = ($(window).width() < 768) ? 2 : 8;

        if (this.eShopCount > 1) {
            var options = {
                slidesPerView: slidesNum,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                pagination: '.swiper-pagination',
                paginationClickable: true
            };

            this.swiper = new Swiper(this.$element.find('.swiper-container'), options);
        }

        if (this.eShopCount <= slidesNum) {
            this.$element.find('.swiper-nav').hide();
        }

        this.selectStore(this.eShopCount);
    }

    selectStore(eShop) {
        if (eShop > 1) {
            $('.store-card').on('click', (e) => {
                let $current = $(e.currentTarget);
                $('.store-card').removeClass('active');
                $current.addClass('active');
            });
        } else {
            $('.store-card').addClass('active');
        }

        $('.store-card').on('click', (e) => {
            e.stopImmediatePropagation();
            const $currentEl = $(e.currentTarget);
            Tracker.send('EVENT', {interactionType: 'PRODUCT_CLICK_ECOMMERCE_BTN', productCode: this.productId, ecommerceLink: $currentEl.attr('data-link')});
            window.open($currentEl.attr('data-link'),'_blank');
        });
    }

    init() {
        if(this.ajax){
            SliderEcommerceTemplating.findItems(this.productId).then((data) => {
                if(data.body){
                    const dataTmpl = {
                        onlineShopList: data.body,
                        onlineShopListLength: data.body.length,
                        title: this.$element.data('title'),
                        text: this.$element.data('text'),
                        linkCta: this.$element.data('link-cta')
                    };

                    this.eShopCount = data.body.length;

                    let $tmpContainer = $("<div/>");
                    $tmpContainer.load('/fe-web/include/slider-ecommerce/slider-ecommerce.jsp', (resp) => {
                        this.$element.html(Mustache.render(resp, dataTmpl));

                        this.buildStoresList();
                    });
                }
            });
        } else{
            this.buildStoresList();
        }

    }
}

export default SliderEcommerceComponent;
