import Component from '../model/Component';
import Mustache from "mustache";
import UserManager from '../business/UserManager';
import HttpClient from '../business/HttpClient';

class InvitationTour2019Component extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.userInfo = false;
        this.userPartecipation = false;
        this.userWon = false;

        this.tpl = this.$element.find('#box-invitation').length > 0 ? this.$element.find('#box-invitation').get(0).innerHTML : '';
        this.$target = this.$element.find('#target-invitation').length > 0 ? this.$element.find('#target-invitation') : null;

        this.init();
    }

    init() {
        this.handleLoading();
        UserManager.getLoggedUser().then((data) => {
                if (data && data.status && data.status == "OK") {
                    this.userInfo = true;
                    this.testInvitation();
                } else {
                    this.removeLoading(true);
                }
            },
            (error) => {
                this.userInfo = false;
                this.removeLoading(true);
            });
    }

    testInvitation() {
        this.handleLoading();
        if (!!this.userInfo) {
            console.log("CHIAMO servizio");
            HttpClient.doCall({
                url: API_ENDPOINT + '/api/mulinobianco/1.0/web/tour/2019/partecipation',
                type: "GET",
                dataType: "json",
                contentType: "application/json"
            }, false).then((data) => {
                if (data && data.status && data.status == "OK" && data.body && data.body.hasAlreadyParticipated) {
                    this.$target.html(Mustache.render(this.tpl, data.body));
                    let b = data.body;
                    this.userPartecipation = true;
                    if (b.prize && b.prize.hasWon) {
                        let p = b.prize;
                        this.userWon = p.hasWon;
                        let btn = this.$element.find('[data-logged="true"][data-won="true"] .button-cnt a');
                        if (!p.hasAlreadyDonePrizeRequest && p.prizeRequestValidYet) {
                            /*btn.attr("href", "?id=" + p.id);*/
                        } else {
                            btn.remove();
                            if(p.hasAlreadyDonePrizeRequest){
                                this.$element.find('[data-logged="true"][data-won="true"] .text').html("Grande, sei stato fortunato e hai vinto una delle forniture di prodotti del Mulino in palio.<br/><br/><b>Abbiamo ricevuto la tua richiesta premio. Te lo invieremo al più presto.</b>");
                            }else if(!p.hasAlreadyDonePrizeRequest && !p.prizeRequestValidYet){
                                this.$element.find('[data-logged="true"][data-won="true"] .text').html("Grande, sei stato fortunato e hai vinto una delle forniture di prodotti del Mulino in palio.<br/><br/><b>Purtroppo il tempo per effettuare la richiesta premio è scaduto.</b>");
                            }
                        }
                    }
                } else {
                    this.userPartecipation = false;
                }
                this.removeLoading(false);
            }, (error) => {
                this.userPartecipation = false;
                this.removeLoading(false);
            })
        } else {
            this.removeLoading(true);
        }
    }

    handleLoading() {
        let l = this.$element.find('.box-loader');
        l.show();
        l.siblings('.box-invito').hide();
    }

    removeLoading(dflt) {
        let l = this.$element.find('.box-loader');
        l.hide();
        if (dflt) {
            l.siblings('[data-logged="false"]').show();
        } else if (this.userInfo) {
            let cond = '[data-logged="true"]';
            if (this.userPartecipation) {
                cond += '[data-partecipation="true"]';
            } else {
                cond += '[data-partecipation="false"]';
            }
            if (this.userWon) {
                cond += ',[data-logged="true"][data-won="true"]'
            }
            l.siblings(cond).show();
        }
    }
}

export default InvitationTour2019Component;
