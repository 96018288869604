import HttpClient from './HttpClient';

class SliderEcommerceTemplating {
    constructor() { }

    /**
     * @param {String} externalId
     * @return {Object}
     */
    findItems(externalId){
        return HttpClient.doCall({
            url: API_ENDPOINT + `/api/mulinobianco/1.0/web/product/${externalId}/buy-button`,
            type: "GET",
            dataType: "json",
            contentType: "application/json; charset=utf-8"
        }).then((data) => {
            return data
        });
    }
}

export default new SliderEcommerceTemplating();

