import Controller from '../model/Controller'
import $ from "jquery";
import Tracker from "../business/Tracker";
import Constants from "../business/Constants";
import GoogleAnalytics from "../business/GoogleAnalytics";
import Utility from "../business/Utility";

class HistoricalProductDetailCtrl extends Controller {
    constructor(element) {
        super(element);
    }

    showShareModal() {
        document.getElementById('shareModal').dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
    }


    onShareClick(shareButton) {
        Tracker.send('EVENT', {interactionType: 'PROD_SHARED', productCode: $('#mb-product-externalid').text()});

        const urlPage = window.location.href;
        const titlePage = document.title;

        shareButton = shareButton.replaceAll("'", '');
        let urlSocial = Utility.getShareLink(shareButton, titlePage, urlPage);
        if(urlSocial) window.open(urlSocial, '_blank');

        const obj = {
            'data-name-product': $('#mb-product-name').text(),
            'data-name-method': shareButton,
            'data-content-type': 'product'
        }
        GoogleAnalytics.send('social_share', obj);
    }
}

export default HistoricalProductDetailCtrl;
