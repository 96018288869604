let Animabili = [
    {
        "name": "Bee L'Apina",
        "image": "bee",
        "info": "Bee l’apina è un dispenser di adesivi-messaggio con cui puoi scrivere i tuoi pensieri felici. Quando finirai i foglietti continua ad utilizzare Bee l'apina per giocare con i tuoi amici! Usa le mie ali per mandare messaggi sensazionali!",
        "skills": "Adora la natura e i suoi fiori preferiti sono le camomille.",
        "superpower": "Magia della natura"
    },
    {

        "name": "Shelly la Lumachina",
        "image": "shelly",
        "info": "Shelly la lumachina è un nastro adesivo. Quando lo avrai finito puoi sostituirlo con uno della stessa dimensione, separando il corpo della lumaca dalla chiocciola. Poi, ruota le due parti della chiocciola in senso contrario e tira per separarle.",
        "skills": "La più puntuale della valle.",
        "superpower": "Aggiustatutto"
    },
    {

        "name": "Stampy il Coniglietto",
        "image": "stampy",
        "info": "Stampy il coniglietto è un timbro. Dove passa lascia il segno. Separa la parte bianca del corpo dalla base delle zampe. Sei pronto a seguire le mie tracce?",
        "skills": "Adora correre nei campi di grano.",
        "superpower": "Super velocità"
    },
    {

        "name": "Polly la Paperella",
        "image": "polly",
        "info": "Polly la paperella è un evidenziatore, giallo come le sue piume. Per utilizzare l’evidenziatore estrai il collo della paperella dal corpo. Quando finirai l’inchiostro continua ad utilizzare Polly per giocare con i tuoi amici!",
        "skills": "Adora i Pan Goccioli.",
        "superpower": "Super memoria"
    },
    {

        "name": "Birba la Volpina",
        "image": "birba",
        "info": "Birba la volpina è una lente d’ingrandimento:<br>grazie al suo aiuto puoi osservare il mondo con occhi nuovi. Per utilizzarla aprila ruotando la testa in senso orario. Se vuoi pulire la lente utilizza un panno e acqua tiepida. È importante avere occhio!",
        "skills": "Adora i colori del tramonto.",
        "superpower": "Leggere nel pensiero"
    },
    {

        "name": "Otto il Gufetto",
        "image": "otto",
        "info": "Otto il gufetto è una penna che ti aiuterà a riscoprire la magia di scrivere. Per utilizzare la tua biro estrai la testa dal corpo. Quando finirai l’inchiostro, continua ad utilizzare Otto il gufetto per giocare con i tuoi amici!",
        "skills": "È un gran burlone: fa sempre scherzi a tutti quanti.",
        "superpower": "Mimetismo"
    },
    {

        "name": "Mu la Mucchina",
        "image": "mu",
        "info": "Mu la mucchina è un temperamatite. Per svuotare Mu la mucchina, tira la testa leggermente in avanti e sollevala, in questo modo riuscirai ad aprire il temperamatite! Matite sempre appuntite per opere d’arte garantite!",
        "skills": "Ama l’eleganza e adora collezionare opere d’arte.",
        "superpower": "Teletrasporto"
    },
    {

        "name": "Nella la Gallinella",
        "image": "nella",
        "info": "Nella la gallinella è un portamatite e una gomma: ti aiuta a tenere la tua matita sempre con te o a cancellare un messaggio segreto. Assemblala appoggiando la simpatica gallinella sulle uova. Usa le mie uova ogni volta che vuoi cambiare idea!",
        "skills": "Adora la matematica, è la migliore con le addizioni.",
        "superpower": "Invisibilità"
    },
];

export default Animabili;