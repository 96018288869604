// vendor
import $ from "jquery";

// k+
import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";

class VideoCourseCtrl extends Controller {
    constructor(element) {
        super(element);
        this.init();
    }

    init() {
        const startDate = new Date('2023-12-18').getTime();
        const endDate = new Date('2024-01-08 08:00:00').getTime();
        const currentDate = new Date().getTime();

        if (currentDate >= startDate && currentDate <= endDate) {
            $('#overlay').show();

            GigyaManager.getLoggedUser().then(() => {
                $('.videocourse__listing-video #modal .generic-modal__cta').remove();
            });

            $('.videocourse__listing-video #modal').show();
        }
    }
}

export default VideoCourseCtrl;
