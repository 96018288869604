import Controller from '../model/Controller';
import HttpClient from "../business/HttpClient";
import {Utils} from "../components/unMorsoDiFelicita/Utils";
import GigyaManager from "../business/GigyaManager";
import $ from "jquery";
import Utility from "../business/Utility";

class CaricamentoTesseraCtrl extends Controller {
    constructor(element) {
        super(element);
        this.endpointCaricamentoTessera = window.API_ENDPOINT + `/api/mulinobianco/1.0/web/stamp-card/upload/`;
        this.uploadInProgress = false;
        this.init();
    }

    init() {
        this.checkLoggedUer();
    }

    checkLoggedUer() {
        $('#overlayer').show();
        GigyaManager.getLoggedUser().then(() => {
            this.uploadCtaTextOnChange();
            this.validateUpload();
        },()=> {
            window.location.href = "/login?urlBack=" + Utility.getCurrentUri();
        });
    }

    uploadCtaTextOnChange() {
        $('#overlayer').hide();
        const fileInput = document.querySelector('input[type=file]');
        fileInput.onchange = () => {
            if (fileInput.files.length > 0) {
                $('.caricamento-tessera__upload-file-name').remove();
                $('.caricamento-tessera__upload-file-preview').remove();
                $('.caricamento-tessera__upload-file-wrapper').prepend('<div class="caricamento-tessera__upload-file-name">' + fileInput.files[0].name + '</div>');
                $('.caricamento-tessera__upload-button').text('Modifica foto');
                if (fileInput.files[0].type.indexOf('pdf') < 0) {
                    Utils.getBase64(fileInput.files[0]).then((base64) => {
                        $('.caricamento-tessera__upload-file-name').after(`<div class="caricamento-tessera__upload-file-preview"><img alt="preview" src="${base64}"/></div>`)
                    });
                } else {
                    $('.caricamento-tessera__upload-file-preview').remove();
                }
            }
        }
    }

    validateUpload() {
        $('.caricamento-tessera__links .button').on('click', ()=> {
            if (!this.uploadInProgress) {
                if (typeof($('[name="prizeChoice"]:checked').val()) == 'undefined' || $('.caricamento-tessera__upload-file').val() == '') {
                    $('.caricamento-tessera__error').html('').html('Devi caricare la tessera e scegliere un premio').addClass('visible');
                } else {
                    $('#overlayer').show();
                    $('.caricamento-tessera__error').html('').removeClass('visible');
                    this.uploadInProgress = true;
                    this.base64Tessera();
                }
            }
        });
    }

    base64Tessera() {
        const fileInput = document.querySelector('input[type=file]');
        const [file] = fileInput.files;
        if (!file) return;

        Utils.getBase64(file).then((base64) => {
            this.caricaTessera(base64);
        });
    }

    caricaTessera(file) {
        HttpClient.doCall({
            url: this.endpointCaricamentoTessera + $('[name="prizeChoice"]:checked').val(),
            type: "POST",
            contentType: "application/json",
            data: file
        }, false).then((data) => {
            console.log(data)
            $('.caricamento-tessera__error').html('').removeClass('visible');
            this.uploadInProgress = false;
            this.userTesseraInfo(data.body.stampCard);
        }, (error) => {
            console.log(error)
            $('.caricamento-tessera__error').html('').html(error.responseJSON.body.error).addClass('visible');
            this.uploadInProgress = false;
            $('#overlayer').hide();
        });
    }

    userTesseraInfo(tessera) {
        let tesseraTime = new Date(tessera.insertTime).toLocaleDateString('it-IT');

        $('.caricamento-tessera__card-box-title').append(tessera.cardCode);
        if (tessera.cardCode == '') $('.caricamento-tessera__card-box-title').text().replace('#','');
        $('.caricamento-tessera__card-box-info.date').append(tesseraTime);
        $('.caricamento-tessera__card-box-info.cardPrize').append('<b>' + tessera.prize.replaceAll('_', ' ') + '</b>');
        $('.response').hide();
        $('.caricamento-tessera__response-hidden').show();
        $('#overlayer').hide();
        $([document.documentElement, document.body]).animate({
            scrollTop: $('.caricamento-tessera__response-hidden').offset().top - 25
        }, 1000);
    }
}

export default CaricamentoTesseraCtrl;