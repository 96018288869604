import Component from '../../model/Component';

class FirstStepComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;
    }

    goToNextStep() {
        console.log(this.store.getState());

        this.store.dispatch({...this.store.getState(), type: 'videoLectureLoginForm/goToNextStep'});

        console.log(this.store.getState());
    }
}


export default FirstStepComponent;
