import Component from '../model/Component';
import $ from "jquery";
import HttpClient from "../business/HttpClient";
import recipeBlockTemplate from "../template/recipe-block";
import RatingManager from "../business/RatingManager"

class FavouriteRecipeComponent extends Component {

    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.init();
    }

    init() {
        this.buildRecipeList();
    }

    buildRecipeList() {

        if ($(this.element).find(".favourite-recipe-container").length == 0) {
            $(this.element).append(`<div class="favourite-recipe-container">
            <div class="favourite-recipe-container-arrows">
                 <svg class="arrow left">
                        <use xlink:href="#svg_brown_arrow"/>
                    </svg>
                    <svg class="arrow right">
                        <use xlink:href="#svg_brown_arrow"/>
                    </svg>
            </div>
            <div class="swiper-wrapper-wrapper">
                <div class="swiper-container" >
                    <div class="swiper-wrapper"></div>
                 </div>
            </div>
        </div>`);
        }


        this.toggleLoader(true);

        HttpClient.doCall({
            method: 'GET',
            url: API_ENDPOINT +
            `/api/mulinobianco/1.0/web/recipe/favourite`,
            dataType: "json",
            contentType: "application/json; charset=utf-8"
        }).then((data) => {

            if (!!data.body && data.body.length > 0) {
                $(this.element).find(".swiper-slide").remove();
                data.body.forEach((recipe) => {
                    $(this.element).find(".swiper-wrapper")
                        .append(`<div class="swiper-slide">
                        <svg class="close-icon" mb-component-click="removeRecipe(${recipe.id});">
                            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_close_icon"></use>
                        </svg> 
                        ${this.buildRecipeBlockHtml(recipe)}
                       </div>`);
                });

                this.swiper = new Swiper($(this.element).find('.swiper-container'), {
                    slidesPerView: "auto",
                    spaceBetween: $(window).width() > 767 ? 30 : 15,
                    nextButton: '.favourite-recipe-container-arrows .arrow.right',
                    prevButton: '.favourite-recipe-container-arrows .arrow.left'
                });


            } else {
                if (data.body.length == 0) {
                    $(this.element).find(".favourite-recipe-container").remove();
                }
                $(this.element).append(`<div class="without-recipe">
                        <h3 class="title">Le Tue Ricette Preferite</h3>
                        <span class="text">
                            Clicca il cuoricino sulle ricette che ami di più per aggiungerle ai tuoi preferiti.
                        </span>
                    </div>`);
            }

            this.toggleLoader(false);
        });
    }

    //Eliminazione singola ricetta
    removeRecipe(id) {
        this.toggleLoader(true);
        //chiamata servizio cancellazione
        return new Promise((resolve, reject) => {
            $.ajax(RatingManager.unrate('FAVOURITES','editorialRecipe_'+id)).then(() => {
                this.buildRecipeList();
                resolve();
            }, (error) => {
                this.toggleLoader(false);
                reject(error);
            });
        }, (error) => {
            this.toggleLoader(false);
            reject(error);
        });
    }

    buildRecipeBlockHtml(recipe) {
        return recipeBlockTemplate(recipe);
    }

    toggleLoader(display) {
        if (display) {
            if ($(this.element).find("[data-tool-loader]").length == 0) {
                $(this.element).find(".favourite-recipe-container").addClass('loading');
                $(this.element).find(".favourite-recipe-container").append(`
                    <div data-tool-loader class="tool_loader"><svg>
                        <use  xlink:href="#svg_loader"></use>
                        </svg>
                     </div>
                `);
            }
        } else {
            $(this.element).find(".favourite-recipe-container").removeClass('loading');
            $(this.element).find("[data-tool-loader]").remove();
        }
    }

}


export default FavouriteRecipeComponent;
