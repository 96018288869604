import Component from '../../model/Component';
import $ from "jquery";
import Utility from "../../business/Utility";
import Constants from "../../business/Constants";
import Tracker from "../../business/Tracker";
import GigyaManager from "../../business/GigyaManager";

class PromoLocatorInformationWindow extends Component {

    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.stores = null;
        this.$listConatiner = $(this.element).find(".list-container");
        this.$detailConatiner = $(this.element).find(".detail-container");
        this.$loader = $(this.element).find(".loader");
        this.$openCloseButton = $(this.element).find(".open-close-button");
        this.$windowContainer = $(this.element).find(".window-container");
        this.init();
    }

    init() {
        $(this.element).show();
        this.$openCloseButton.click(() => {
            if ($(this.element).hasClass("open")) {
                this.close();
            } else {
                this.open();
            }
        });

        $(this.element).find(".close-button").click(() => {
            this.close();
        });

        $(this.element).find(".back-to-list").click((e) => {
            e.stopPropagation();
            this.displayList();
        })
    }

    openOnSearch() {
        //Open only for not mobile
        if ($(window).width() > 767) {
            this.open();
        }
    }

    open() {
        if (!$(this.element).hasClass("open")) {
            $(this.element).addClass("open");
            window.document.dispatchEvent(new CustomEvent(Constants.events.promoLocatorInformationWindow.action, {
                detail: {
                    action: "open",
                    windowDimension: $(this.element).outerWidth()
                }
            }))
        }
    }

    close() {
        $(this.element).removeClass("open");
        window.document.dispatchEvent(new CustomEvent(Constants.events.promoLocatorInformationWindow.action, {
            detail: {
                action: "close",
                windowDimension: $(this.element).outerWidth()
            }
        }))
    }

    displayLoader(active) {
        active = active == true ? true : false;
        if (active) {
            this.$loader.show();
        } else {
            this.$loader.hide(300);
        }
    }

    displayList() {
        this.$listConatiner.show();
        this.$detailConatiner.hide();
    }

    updateList(data, place, currentLocation) {
        this.stores = data;
        this.currentLocation = currentLocation;
        this.setListMobileTitleContent();
        this.setListTitle(place ? place.name : null);
        this.setListContent();
        this.$listConatiner.show();
        this.$detailConatiner.hide();
    }

    setListMobileTitleContent() {
        this.$openCloseButton.find(".mobile-cotent-container").html(`<div class="list-mobile-button-content">Mostra i risultati</div>`);
        this.$openCloseButton.removeClass("storeSelected");
    }

    setDetailMobileTitleContent(store) {

        this.$openCloseButton.find(".mobile-cotent-container").html(`
                                                    <div class="list-mobile-button-content">
                                                        <div class="title">${store.name}</div>
                                                        <div class="address">${store.address}</div>
                                                    </div>`);
        this.$openCloseButton.addClass("storeSelected");


    }

    printHostes(hostess) {
        let result = '';
        for (var i = 0; i < hostess.length; i++) {
            result += `<div class="date">${hostess[i]}</div>`;
        }
        return result;
    }

    printInstoreEvent(store) {
        let result = '';
        for (var i = 0; i < store.length; i++) {
            result += `<div class="img_container">
                        <img class="background_img" src="${store[i].thumbnailBackgroundImage}"/>
                        </div>
                        <div class="promo-title">${store[i].title}</div>
                        `
            if (store[i].formatPromoterDate.length > 0) {
                result += `<div class="hostess_container">
                            <span class="hostess">Date in cui sarà presente la Hostess:</span>
                                ${this.printHostes(store[i].formatPromoterDate)}
                            </div>`
            }

        }
        return result
    }

    setDetailContent(store) {
        this.$detailConatiner.find(".content").html(`<div class="title">${store.name}</div>
                                                    <div class="address">${store.address}</div>
                                                    <div class="distance_time">
                                                        <span class="distance"></span>
                                                        <span class="time"></span>
                                                    </div>
                                                    <a href="https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}" class="google_mpas_Icon"></a>
                                                    ${this.printInstoreEvent(store.inStoreEvents)}
`);
    }

    setDetailDistance(store) {
        if (!!this.currentLocation) {
            var origins = new google.maps.LatLng(this.currentLocation.geometry.location.lat(), this.currentLocation.geometry.location.lng());
            var destination = new google.maps.LatLng(store.latitude, store.longitude);

            var service = new google.maps.DistanceMatrixService;
            service.getDistanceMatrix({
                origins: [origins],
                destinations: [destination],
                travelMode: 'DRIVING',
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false
            }, function (response, status) {
                if (status !== 'OK') {
                    console.log("error getDistanceMatrix");
                } else {
                    $('.time').text(response.rows[0].elements[0].duration.text);
                    $('.distance').text(response.rows[0].elements[0].distance.text);
                }
            });
        }
    }

    displayDetail(idStore) {

        let store = this.stores.filter((store) => {
            return store.idStore == idStore;
        })[0];
        this.setDetailMobileTitleContent(store);
        this.setDetailContent(store);
        this.trackStore(store);
        this.$listConatiner.hide();
        this.$detailConatiner.show();
        setTimeout(() => {
            this.$windowContainer.scrollTop(0);
        }, 400);
        this.setDetailDistance(store);
    }

    trackStore(store) {
        let storeEvents  = [];
        for (let i = 0; i < store.inStoreEvents.length; i++) {
            storeEvents.push(store.inStoreEvents[i].title)
        }

        GigyaManager.getLoggedUser().then((u) => {
            let emailLoggedUser = u.profile.email;
            let userID = u.UID;
            Tracker.send("EVENT", {interactionType: "PROMOLOCATORSTORE_VIEW", storeName: store.name, storePromo: storeEvents, userId: userID, userEmail: emailLoggedUser});
        });
    }

    setListContent() {
        let $listCnt = this.$listConatiner.find(".list");
        $listCnt.html("");

        let hoverFn = function (idStore) {
            window.document.dispatchEvent(
                new CustomEvent(Constants.events.promoLocator.highlightStore, {
                    detail: {
                        idStore: idStore,
                        status: true
                    }
                }));
        };
        let outFn = function (idStore) {
            window.document.dispatchEvent(
                new CustomEvent(Constants.events.promoLocator.highlightStore, {
                    detail: {
                        idStore: idStore,
                        status: false
                    }
                }));
        };
        let debouncedHover = Utility.debounce((el) => {
            hoverFn(el);
        }, 300);
        let debouncedOut = Utility.debounce((el) => {
            outFn(el);
        }, 300);

        let isTouch = $(window).width() <= 1024;

        this.stores.forEach((store) => {
            let $storecnt = $(`<div class="store ${isTouch ? "touch" : ""}" data-hover-element="${store.idStore}" data-action-detail="${store.idStore}"></div>`);
            $storecnt.append(`<div class="store-name" >${store.name}<span class="adress_list"> </br>(${store.address})</span></div></div>`);
            if (store.inStoreEvents.length > 0) {
                let $storeEventcnt = $('<div class="store-events-container"></div>');
                store.inStoreEvents.forEach((event) => {
                    $storeEventcnt.append(`<div class="title">${event.title}</div>
                                           <div class="date">Dal ${event.startDay} ${event.startMonth} al ${event.endDay} ${event.endMonth}</div>      
                                          `);
                });
                $storecnt.append($storeEventcnt);
            }
            $listCnt.append($storecnt);
        });

        let $detailElements = $listCnt.find("[data-action-detail]");
        if (isTouch) {
            $detailElements = $listCnt.find("[data-action-detail] .store-name")
        }

        $detailElements.click((el) => {
            let idStore = $(el.currentTarget).attr("data-action-detail");
            if (!idStore) {
                idStore = $(el.currentTarget).parents("[data-action-detail]").attr("data-action-detail");
            }
            this.displayDetail(idStore);
        });


        $listCnt.find("[data-hover-element]").hover((el) => {
            debouncedHover($(el.currentTarget).attr("data-hover-element"));
            console.log($(el.currentTarget).attr("data-hover-element") + " on")
        }, (el) => {
            debouncedOut($(el.currentTarget).attr("data-hover-element"));
            console.log($(el.currentTarget).attr("data-hover-element") + " off")
        });
        this.displayLoader(false);
    }

    setListTitle(placeName) {
        let eventsCount = this.stores.length > 0 ? this.stores[0].eventCount : 0;
        let titleText = `La ricerca non ha trovato promozioni ${placeName ? `in ${placeName}` : `nella zona che stai guardando`}`;

        if (this.stores.length > 0) {
            titleText = `Ci sono <b>${eventsCount} promozioni</b> presso
                         <b>${this.stores.length} punti vendita </b>${placeName ? `in ${placeName}` : `nella zona che stai guardando`}`;
        }

        this.$listConatiner.find(".title").html(titleText);

    }
}


export default PromoLocatorInformationWindow;
