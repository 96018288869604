import Component from '../model/Component';
import $ from "jquery";
import Constants from "../business/Constants";

class IconRatingComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.value = 0;
        this.ratingItemAttribute = "data-rating-element-value";
        this.init();
    }


    init() {
        for (let i = 1; i < 6; i++) {
            $(this.element).append(`<div class="star" ${this.ratingItemAttribute}="${i}" >
                                        <svg class="">
                                            <use xlink:href="#svg_star"></use>
                                        </svg>
                                    </div>`)
        }

        var initialValue = $(this.element).attr("data-initial-value") || null;
        if (initialValue) {
            this.setRating(initialValue);
        }

        if ($(this.element).attr("data-read-only") != "true") {
            $(this.element).find(`[${this.ratingItemAttribute}]`).click((event) => {
                let value = $(event.currentTarget).attr("data-rating-element-value");
                this.setRatingFromView(value);
            })
        }
    }

    setRating(value) {
        this.value = value;
        $(this.element).find(`[${this.ratingItemAttribute}]`).each((index, el) => {
            let addOrRemove = parseInt($(el).attr("data-rating-element-value")) <= parseInt(this.value);
            $(el).toggleClass("on", addOrRemove);
        });
        this.element.dispatchEvent(new CustomEvent(Constants.events.RATING_CHANGE, {
            detail: {
                value: this.value
            }
        }));
    }

    setRatingFromView(value) {
        this.setRating(this.value == parseInt(value) ? (parseInt(value) - 1) : parseInt(value));
    }


}


export default IconRatingComponent;
