import StepHeader from "../common/stepHeader";
import InputField from "../common/inputField";
import Button from "../common/button";

export default function FullContactTemplate(config) {

    const prefill = config.prefillData
    const headerTitle = config && config.shortDesc || "Commento o Suggerimento | Come ricontattarti"
    return `
         ${StepHeader({title: headerTitle, subtitle: 'Inserisci i tuoi dati per poter essere ricontattato'})}

            <form class="full-contact form input-container">
                ${InputField({type: 'hidden', size: 'l', name: 'ContactIdGigya', value: prefill.ContactidGigya})}
                
        ${InputField({
            type: 'text', 
            size: 'l', 
            name: 'ContactName', 
            placeholder: 'Nome*',
            value: prefill.ContactName
        })}
                       
        ${InputField({
            type: 'text',
            size: 'l',
            name: 'ContactSurname',
            placeholder: 'Cognome*',
            value: prefill.ContactSurname
        })}
      
        ${InputField({
            type: 'email',
            size: 'l',
            name: 'ContactEmail',
            placeholder: 'Email*',
            value: prefill.ContactEmail
        })}
        
        ${InputField({
            type: 'tel', 
            size: 'l',
            name: 'ContactPhone', 
            placeholder: 'Telefono',
            value: prefill.ContactPhone
        })}
    
        ${InputField({
            type: 'text', 
            size: 'l', 
            name: 'ContactAddress', 
            placeholder: 'Via'
        })}

        ${InputField({
            type: 'text', 
            size: 'l',
            name: 'ContactAddressNumber', 
            placeholder: 'Numero'
        })}
                
                 <div class="field-group">
                 
                                ${InputField({
        type: 'text', size: 's', name: 'ContactCap', placeholder: 'CAP',
        pattern: "[0-9]{5}", class: 'sm-order-3 sm-half'
    })}
                
                                ${InputField({
        type: 'text', size: 's', name: 'ContactCity', placeholder: 'Città',
        pattern: "[A-Za-z]", class: 'sm-order-1 sm-full'
    })}
                    
                               ${InputField({
        type: 'text', size: 's', name: 'ContactProv', placeholder: 'Provincia (Sigla)',
        pattern: "[A-Za-z]{2}", class: 'sm-order-2 sm-half'
    })}
                </div>
                
                ${InputField({type: 'simple-privacy', size: 'full', name: 'PrivacyAccept', disclaimer: true})}

                <div class="step-disclaimer">*I campi contrassegnati con l’asterisco sono obbligatori</div>
                <div class="item-centered">
                   ${Button({type: 'submitForm', nextStep: 5})}
                </div>
               
            </form>`
}