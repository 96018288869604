import Controller from '../model/Controller';
import Constants from '../business/Constants';
import ProductFinderManager from '../business/ProductFinderManager';
import $ from "jquery";
import Utility from "../business/Utility";
import UserManager from "../business/UserManager";
import Tracker from "../business/Tracker";

class ProductFinderCtrl extends Controller {
    constructor(element) {
        super(element);
        this.init();
    }

    init() {

        let searchDone = $("#product-finder").data("search-done");
        this.attachListener();

        $(".header").toggleClass("brown-head", !searchDone);


        ProductFinderManager.loadUserProfile().then((user) => {
            ProductFinderManager.attachProductFinderBundle();
            if (user) {
                $("#product-finder  .login-button-ctr").css("display", "none");
                UserManager.getAvatar(user.UID).then((data) => {
                    if (data.body.path) {
                        $("#product-finder  .profile-image img").attr("src", data.body.path);
                    }
                })
            } else {
                $("#product-finder  [data-login]").click(() => {
                    window.location.href = "/login?urlBack=" + Utility.getCurrentUri();
                })
            }
        })
    }


    attachListener() {
        window.document.addEventListener(Constants.events.productFinder.SEARCH_RESULTS_UPDATED, () => {
            this.loadComponents();
        });

        window.document.addEventListener("ProductFinder.HANDLE_REQUEST_WALL", (d) => {

            var searchFilter = d.detail;

            var payload = {
                interactionType: "WEBSITE_SEARCH"
            };

            if (!!searchFilter.consumptionMoment.selected.id && searchFilter.consumptionMoment.selected.id != -1) {
                payload["idConsumptionMoment"] = searchFilter.consumptionMoment.selected.id;
            }

            if (!!searchFilter.ingredients.selected.id && searchFilter.ingredients.selected.id != -1) {
                payload["idIngredient"] = searchFilter.ingredients.selected.id;
            }

            if (!!searchFilter.freeFrom.selected.id && searchFilter.freeFrom.selected.id != -1) {
                payload["idFreeFrom"] = searchFilter.freeFrom.selected.id;
            }

            if (!!searchFilter.idcategories) {
                payload["idCategory"] = searchFilter.idcategories;
            }

            Tracker.send("EVENT", payload);
        });


        window.document.addEventListener("ProductFinder.REQUEST_WALL", () => {
            $("#product-finder .pf-heading-top").remove();
            $(".header").removeClass("brown-head");
            $("#product-finder").attr("data-search-done", true);
            $("[mb-controller='ProductFinderCtrl']").addClass("search-done");
        });
    }


}

export default ProductFinderCtrl;
