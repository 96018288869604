import Component from '../model/Component';
import Masonry from "masonry-layout";
import $ from 'jquery';

class ProductListComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.instance = null;
        this.init();
    }

    init() {
        if ($(this.element).data("paginationsize") && $(this.element).data("paginationtotalitems")) {
            $(".showMoreItems").removeClass("hidden");
            $(".thumbnail").addClass("hidden");
            this.count = $(this.element).data("paginationtotalitems");
            this.showMoreItems(true)
        }
        this.thumbnailsCTR = this.element.querySelector(".thumbnails");
        this.updateComponentDimension();
        this.mansory = new Masonry(this.thumbnailsCTR, {
            // options...
            resize: false,
            transitionDuration: 0,
            itemSelector: '.thumbnail',
            columnWidth: this.columnWidth,
            gutter: this.gutter,
            horizontalOrder: false,
            fitWidth: true,
            originLeft: true
        });

        window.document.addEventListener("product-list-component-resize", () => {
            this.resizeItemsWindows(this);
        });

        $(window).resize(() => {
            this.resizeItemsWindows(this);
        })

        window.document.addEventListener("product-list-add-item", (item) => {

            //this.resizeItemsWindows(this);
            this.addItem(this, item);
        });

        window.document.addEventListener("product-list-remove-all-items", (items) => {
            this.removeAllItems(this, items.detail);
        });
    }

    updateComponentDimension() {
        this.gutter = window.innerWidth < 768 ? 15 : 30;
        this.nodeWidth = window.innerWidth < 960 ? window.innerWidth : 960;
        this.real_columnWidth = Math.floor((this.nodeWidth - this.gutter - 60) / 2);
        this.columnWidth = Math.min(300, this.real_columnWidth);

        let gutter = this.gutter;
        let columnWidth = this.columnWidth;

        Array.from(this.element.querySelectorAll(".thumbnail"))
            .forEach(node => {
                node.style.width = columnWidth + "px";
                node.style.height = columnWidth + "px";
                node.style.marginBottom = gutter + "px";
                // node.querySelector(".thumbnail-product .thumbnail__image").style
                Array.from(node.querySelectorAll(".thumbnail-product .thumbnail__image"))
                    .forEach(item => {
                        item.style.marginRight = gutter + "px";
                        item.style.width = columnWidth + "px";
                        item.style.height = columnWidth + "px";
                    });
                // node.querySelector(".thumbnail-product .thumbnail__image").style.height = columnWidth + "px";
                var thumbnail__text = node.querySelector(".thumbnail-product .thumbnail__text");
                if (thumbnail__text) {
                    thumbnail__text.style.maxHeight = columnWidth + "px";
                }

                if (node.classList.contains("thumbnail--double-x")) {
                    node.style.width = (columnWidth * 2 + gutter) + "px";
                    // node.style.height = columnWidth + "px";
                    node.style.marginBottom = gutter + "px";

                    node.querySelector(".thumbnail-product__favourite").style.right = (columnWidth + gutter + 10) + "px";

                }
                if (node.classList.contains("thumbnail--double")) {
                    node.style.width = (columnWidth * 2 + gutter) + "px";
                    node.style.height = (columnWidth * 2 + gutter) + "px";
                }
            });

    }

    showMoreItems(first) {
        var pagination = $(this.element).data("paginationsize");
        var showAllOthers = $(this.element).data("paginationshowallothers");
        if (!first && showAllOthers) {
            $(this.element).find(".thumbnails").find(`.thumbnail.hidden`).removeClass('hidden');
            $(this.element).find(".showMoreItems").addClass("hidden");
        } else {
            $(".thumbnails").find(`.thumbnail.hidden:lt(${pagination})`).removeClass('hidden');
            this.count = this.count - pagination;
            if (this.count <= 0)
                setTimeout(() => {
                    $(this.element).find(".showMoreItems").addClass("hidden");
                }, 500)

        }

        this.resizeItemsWindows(this);
    }


    resizeItemsWindows(scope) {
        if (scope.mansory) {
            scope.updateComponentDimension();
            scope.mansory.option({
                columnWidth: this.columnWidth,
                gutter: this.gutter,
            });
            scope.mansory.reloadItems();
            scope.mansory.layout();
        }
    }

    addItem(scope, htmlItem){
        if(scope.mansory){
            scope.mansory.addItems(htmlItem);
        }
    }

    removeAllItems(scope, items){
        if(scope.mansory){
            scope.mansory.remove(items);
        }
    }
}

export default ProductListComponent;
