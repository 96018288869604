import Component from '../model/Component';
import Constants from "../business/Constants";
import $ from 'jquery';

class HeaderComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        //TODO - piccone
        $(element).find('.menu-icon').click(() => {
            this.showMenu();
        })
    }

    showMenu() {
        this.parentCtrl.components[Constants.menuComponentPropertyName].showMenu();
    }

    showSearch() {
        window.document.dispatchEvent(new CustomEvent(Constants.events.SHOW_SEARCH_MODAL));
    }
}


export default HeaderComponent;
