import Controller from '../model/Controller';
import GigyaManager from "../business/GigyaManager";
import Pristine from "pristinejs";
import $ from "jquery";

class ArmonieLandingMandalaCtrl extends Controller {
    constructor(element) {
        super(element);
        this.element = element;
        this.$el = $(this.element);
        this.landingMandalaCH = 'CH_1032_MLB_WEB_LANDINGARMONIA_NAV_NAV_20240916_20240916';
        this.regSource = 1032;

        this.init();
    }

    init() {
        GigyaManager.getLoggedUser().then((user) => {
            const ch = user.data[this.landingMandalaCH];
            if (typeof ch !== "undefined" && ch != null && ch !== "") {
                this.showLoggedArea();
            } else {
                this.initForm();
                this.handleForm();
                $('#modal').show();
            }
        }, () => {
            $('.armonie-mandala__cta a').attr('href', `/login?urlBack=${window.location.href.replace(window.location.origin, "")}&regSource=${this.regSource}`);
        });
    }

    initForm() {
        let form = document.getElementById("accept-rule");

        let formConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'generic-modal__radio',
            errorClass: 'error',
            successClass: 'has-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'generic-modal__radio',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'error-field'
        };

        // init form validator
        this.pristine = new Pristine(form, formConfig);
    }

    handleForm() {
        $('#accept-rule').submit((e) => {
            e.preventDefault();
            let valid = this.pristine.validate();

            if (valid) {
                if ($('#acceptance').length) {
                    GigyaManager.addCH(this.landingMandalaCH).then(() => {
                        $('#modal').hide();
                        this.showLoggedArea();
                    }, () => {

                    });
                }
            }
        })
    }


    setDailyPlanner() {
        this.element.querySelector('.daily-planner .armonie-mandala__mandala-copy p').innerHTML = 'Ottieni qui il tuo Planner!';
        const link = this.element.querySelector('.daily-planner a');
        link.innerHTML = 'Scarica il planner';
        link.setAttribute('href', '/fe-web/pdf/MB_planner_armonia.pdf');
        link.setAttribute('target', '_blank');
        link.setAttribute('download', '');

        $('#isuu-iframe-planner').html('<div style="position:relative;padding-top:max(60%,326px);height:0;width:100%"><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style="position:absolute;border:none;width:100%;height:100%;left:0;right:0;top:0;bottom:0;" src="https://e.issuu.com/embed.html?d=planner_mb_armonia&u=mulinobianco"></iframe></div>');
    }




    setMandalaPdf() {
        $('.mandala-pdf .armonie-mandala__mandala-copy p').html('Ottieni qui tutti i tuoi Mandala!');
        const link = $('.mandala-pdf a');
        link.text('Scarica i Mandala');
        link.attr('href', '/fe-web/pdf/MB_armonia_mandala_colorabile.pdf');
        link.attr('target', '_blank');
        link.attr('download', '');
        $('#isuu-iframe').html('<div style="position:relative;padding-top:max(60%,326px);height:0;width:100%"><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style="position:absolute;border:none;width:100%;height:100%;left:0;right:0;top:0;bottom:0;" src="https://e.issuu.com/embed.html?d=exe_mb_armonia_mandala_colorabile_297x210mm_tr&u=mulinobianco"></iframe></div>');
    }

    showLoggedArea() {
        $('.logged').css('display', 'block');
        this.setMandalaPdf();
        this.setDailyPlanner();
    }
}

export default ArmonieLandingMandalaCtrl;