import Controller from '../model/Controller';
import $ from 'jquery';
import TemplatingUtility from '../business/TemplatingUtility';
import UserManager from "../business/UserManager";
import GigyaManager from "../business/GigyaManager";
import HttpClient from "../business/HttpClient";
import RatingManager from "../business/RatingManager";
import Swiper from 'swiper';

class ReservedAreaAppCtrl extends Controller {

    constructor(element) {
        super(element);
        this.$el = $(element);
        this.init();
    }

    init() {

        window.document.addEventListener("MYPROFILE_DONE", this.onMyProfileDone);


        this.sectionOptions = {
            notification: {
                boxSelector: '.notification-box'
            },
            preferred: {
                boxSelector: '.preferred-box',
                customSwiperOptions: () => {
                    return {
                        slidesPerView: $(window).width() > 767 ? 4 : 'auto',
                        slidesPerGroup: $(window).width() > 767 ? 4 : 1
                    }
                }
            },
            review: {
                boxSelector: '.review-box'
            },
            promo: {
                boxSelector: '.promo-box'
            }
        };
        this.notificationSwiperEnabled = false;
        this.preferredSwiperEnabled = false;
        this.reviewSwiperEnabled = false;
        this.promoSwiperEnabled = false;

        this.currentResolution = $(window).width() > 767 ? 'D' : 'M';


        UserManager.getLoggedUser().then((d) => {
            this.user = d;
            UserManager.getAvatar(d.UID).then((data) => {
                this.$el.find('#profile-image').append(data.body.path ? "<img src='" + data.body.path + "' class='img'/>" : "<img src='/fe-web/img/icons/avatar_placeholder.jpg' class='img'/>");
            });
            UserManager.getLoggedUser().then((d) => {
                let hours = new Date().getHours();
                let prefix = (hours >= 3 && hours < 14) ? 'Buongiorno ' : 'Buonasera ';
                this.$el.find('#profile-name').html(prefix + d.profile.firstName);
            });

        }, () => {
            //document.location.href = '/';
        });

        this.$el.find('.logout_btn').click(() => {
            this.$el.find('.total-loader').show();
            GigyaManager.logout().then(() => {
                UserManager.deleteSession().then(() => {
                    document.location.href = 'inapp://userLogout';
                }, () => {
                    document.location.href = 'inapp://userLogout';
                });
            }, () => {
                document.location.href = 'inapp://userLogout';
            });
        });

        if (!this.$el.find('.reservedArea').is('.edit-profile')) {
            this.initListeners();

            this.loadNotifications();
            this.loadPreferred();
            this.loadReviews();
            this.loadPromo();
        } else {
            GigyaManager.isGigyaSdkReady().then(() => {
                this.showMyProfile();
            });
        }
    }

    onResize() {
        let newResolution = $(window).width() > 767 ? 'D' : 'M';

        if (newResolution != this.currentResolution) {
            this.currentResolution = newResolution;
            this.destroySwiper('notification', true);
            this.destroySwiper('preferred', true);
            this.destroySwiper('review', true);
            this.destroySwiper('promo', true);
        }
    }

    initListeners() {
        $(window).resize(() => {
            this.onResize();
        });

        this.$el.on('click', '.preferred.outside .add-favourite', (e) => {
            let pid = $(e.currentTarget).addClass('loadingRate').parents('.preferred').data('product-id');
            RatingManager.rate('FAVOURITES', 'product_' + pid);
        });

        window.document.addEventListener(RatingManager.EVENTS.RATE_DONE, (event) => {
            if ('FAVOURITES' == event.detail.category && (!!event.detail.key && event.detail.key.startsWith('product_'))) {
                if (this.$el.find('#preferred-wrapper .swiper-wrapper .preferred').length > 0) {
                    $('#preferred-wrapper .preferred[data-product-id="' + event.detail.key.split('_')[1] + '"]').remove();
                    this.preferredSwiper.update();

                    if (this.$el.find('#preferred-wrapper .preferred').length == 0) {
                        this.destroyPreferredSwiper();
                        this.loadPreferred();
                    }
                } else {
                    this.loadPreferred();
                }
            }
        });
    }

    onMyProfileDone(){
        window.location.href="inapp://userUpdate";
    }

    initSwiper(type) {
        let boxSelector = this.sectionOptions[type].boxSelector;
        if (this[type + 'SwiperEnabled']) {
            var options = {
                slidesPerView: $(window).width() > 767 ? 3 : 'auto',
                slidesPerGroup: $(window).width() > 767 ? 3 : 1,
                nextButton: boxSelector + ' .arrows .right',
                prevButton: boxSelector + ' .arrows .left',
                spaceBetween: $(window).width() > 767 ? 30 : 15
            };

            if (!!this.sectionOptions[type].customSwiperOptions) {
                options = $.extend(options, this.sectionOptions[type].customSwiperOptions());
            }

            this.$el.find(boxSelector + ' .arrows').removeClass('hidden');
            this[type + 'Swiper'] = new Swiper(this.$el.find(boxSelector + ' .swiper-container'), options);
        }
    }

    destroySwiper(type, reInit) {
        if (this[type + 'SwiperEnabled'] && !!this[type + 'Swiper']) {
            this[type + 'Swiper'].destroy(true, true);
            delete this[type + 'Swiper'];

            if (!!reInit) {
                this.initSwiper(type);
            }
        }
    }

    loadNotifications() {
        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/notification',
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.printNotifications(data.body);
        });
    }

    printNotifications(notifications) {
        if (!notifications || notifications.length == 0) {
            this.$el.find('#note-wrapper').hide();
            return false;
        }

        let html = '<div class="swiper-wrapper">';

        for (let i in notifications) {
            let n = notifications[i];

            html += `
                <div class="note swiper-slide">
                    <div style="background-image: url('${n.icon}');" class="image"></div>
                    <p class="date">${TemplatingUtility.formatDate(n.insertTime)}</p>
                    <span class="text">${n.text}</span>
                    ${TemplatingUtility.linkHtml(n.link)}
                    ${TemplatingUtility.newTag(n)}
                </div>
            `;
        }

        html += `
            </div>
        `;

        this.$el.find('#note-wrapper').html(html);
        this.notificationSwiperEnabled = true;
        this.initSwiper('notification');
        this.readNotifications();
    }

    readNotifications() {
        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/notification/read/all',
            type: "PUT",
            dataType: "json",
            contentType: "application/json"
        }, false);
    }


    loadPreferred() {
        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/product/favourite',
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            if (!!data.body && data.body.length > 0) {
                this.printPreferred(data.body);
            } else {
                this.loadPreferredZeroCase();
            }
        }, () => {
            this.loadPreferredZeroCase();
        });
    }

    printPreferred(preferred) {
        let reviewBaseUrl = this.$el.find('.preferred-box').data('detail-review-seo-url');
        let html = '<div class="swiper-wrapper">';

        for (let i in preferred) {
            let p = preferred[i];

            html += `
                <div class="preferred swiper-slide" data-product-id="${p.id}">
                    <div style="background-image: url('${p.image}');" class="image"></div>
                    <a class="close">
                        <svg class="close-icon">
                            <use xlink:href="#svg_close_icon"></use>
                        </svg>
                    </a>
                    ${TemplatingUtility.printCoupon(p.coupon)}
                    <div class="hover-layer">
                        <a class="edit" href="${reviewBaseUrl + p.id}">
                            <svg class="edit-icon">
                                <use xlink:href="#svg_button_circle_pencil"></use>
                            </svg>
                        </a>
                        <a class="name" href="${p.seoUrl}">${p.name}</a>
                    </div>
                </div>
            `;
        }

        html += `</div>`;

        this.$el.find('#preferred-wrapper')
            .html(html)
            .on('click', '.close', (e) => {
                let pid = $(e.currentTarget).addClass('loading').parents('.preferred').data('product-id');
                RatingManager.unrate('FAVOURITES', 'product_' + pid);
            })
            .on('click', '.edit', (e) => {
                let pid = $(e.currentTarget).parents('.preferred').data('product-id');
                let $rev = this.$el.find('#review-wrapper .review[data-product-id="' + pid + '"]');
                if ($rev.length > 0) {
                    e.preventDefault();
                    e.stopPropagation();
                    document.location.href = $rev.data('review-url');
                }
            });
        this.preferredSwiperEnabled = true;
        this.initSwiper('preferred');
    }


    loadPreferredZeroCase() {
        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/member/prefs/',
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.printPreferredZeroCase(data.body.product);
        }, () => {
            this.printPreferredZeroCase();
        });
    }

    printPreferredZeroCase(p) {
        let html = '<div class="zero-case-wrapper">';

        if (!!p) {
            html += `
                <div class="preferred outside" data-product-id="${p.id}">
                    <div style="background-image: url('${p.image}');" class="image"></div>
                    <a class="add-favourite">
                        <svg class="sprite favourite">
                            <use xlink:href="#svg_button_heart"></use>
                        </svg>
                    </a>
                    <div class="hover-layer">
                        <a class="name" href="${p.seoUrl}">${p.name}</a>
                    </div>
                </div>
            `;

            html += `
                <div class="wide-text-box with-product">
                    <h3 class="title">Aggiungilo ai tuoi prodotti preferiti!</h3>
                    <span class="text">
                        Ricevi notifiche su nuovi coupon e promozioni che lo riguardano.
                    </span>
                </div>
            `;
        } else {
            html += `
                <div class="wide-text-box without-product">
                    <h3 class="title">I Tuoi Prodotti Preferiti</h3>
                    <span class="text">
                        Clicca il cuoricino sui prodotti che ami di più per aggiungerlo ai tuoi preferiti. 
                        Ricevi notifiche su nuovi coupon e promozioni che lo riguardano.
                    </span>
                </div>
            `;
        }

        html += '</div>';

        this.$el.find('#preferred-wrapper').html(html);
    }

    loadReviews() {
        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/review/my',
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            if (!!data.body && data.body.length > 0) {
                this.printReviews(data.body);
            } else {
                this.loadReviewZeroCase();
            }
        }, () => {
            this.loadReviewZeroCase();
        });
    }

    printReviews(reviews) {
        let html = '<div class="swiper-wrapper">';

        for (let i in reviews) {
            let r = reviews[i];

            let reviewUrl = r.product.seoUrl + '?openMyReview=true';

            html += `
                <div class="review swiper-slide" data-product-id="${r.product.id}" data-review-url="${reviewUrl}">
                    <a class="product-wrapper" href="${r.product.seoUrl}">
                        <div style="background-image: url('${r.product.image}');" class="image"></div>
                        <h3 class="product-name">${r.product.name}</h3>
                    </a>
                    <span class="icons-box">
                        ${TemplatingUtility.getEmoji(r.emoji)}
                        ${TemplatingUtility.printStars(r.avgRate)}
                    </span>
                    <a class="text" href="${reviewUrl}">${TemplatingUtility.printText(r.text)}</a>
                </div>
            `;
        }

        html += `</div>`;

        this.$el.find('#review-wrapper').html(html);
        this.reviewSwiperEnabled = true;
        this.loadComponents();
        this.initSwiper('review');
    }


    loadReviewZeroCase() {
        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/member/prefs/',
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.printReviewZeroCase(data.body.product);
        }, () => {
            this.printReviewZeroCase();
        });
    }

    printReviewZeroCase(p) {
        let reviewBaseUrl = this.$el.find('.preferred-box').data('detail-review-seo-url');
        let html = '<div class="zero-case-wrapper">';

        if (!!p) {
            html += `
                <div class="preferred outside" data-product-id="${p.id}">
                    <div style="background-image: url('${p.image}');" class="image"></div>
                    <div class="hover-layer">
                        <a class="name" href="${p.seoUrl}">${p.name}</a>
                    </div>
                    <a class="edit" href="${reviewBaseUrl + p.id}">
                        <svg class="edit-icon">
                            <use xlink:href="#svg_button_circle_pencil"></use>
                        </svg>
                    </a>
                </div>
            `;
            this.$el.find('#review-wrapper').html(html);

            html += `
            <div class="wide-text-box with-product">
                <h3 class="title">Aggiungi una recensione!</h3>
                <span class="text">
                    Scrivi una recensione a questo prodotto oppure guardali tutti per trovare i tuoi preferiti!
                </span>
            </div>
        `;
        } else {
            html += `
            <div class="wide-text-box without-product">
                <h3 class="title">Aggiungi una recensione!</h3>
            </div>
        `;
        }


        html += '</div>';

        html += this.$el.find('#all-product-btn').clone().attr('id', '').get(0).outerHTML;

        this.$el.find('#review-wrapper').html(html);
    }

    loadPromo() {
        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/promo/my',
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.printPromo(data.body);
        });
    }

    printPromo(promo) {
        let html = '<div class="swiper-wrapper">';
        let nmcvBox = null;

        for (let i in promo) {
            let p = promo[i];

            if (p.name != 'Nel Mulino Che Vorrei') {
                html += `
                    <div class="promo swiper-slide">
                        <div style="background-image: url('${p.backgroundImage}');" class="image"></div>
                        <h3 class="title">${TemplatingUtility.defaultOrCustom(p.defaultMainTitle, p.customMailTitle)}</h3>
                        <span class="text">${TemplatingUtility.defaultOrCustom(p.defaultDescription, p.customDescription)}</span>
                        ${TemplatingUtility.linkHtml(p.link)}
                    </div>
                `;
            } else {
                nmcvBox = this.$el.find('#NMCV_box');
                nmcvBox.find('.title').html(TemplatingUtility.defaultOrCustom(p.defaultMainTitle, p.customMailTitle));
                nmcvBox.find('.text').html(TemplatingUtility.defaultOrCustom(p.defaultDescription, p.customDescription));
                nmcvBox.find('.button').remove();
                nmcvBox.find('.box-cta.body').append(TemplatingUtility.linkHtml(p.link, 'button'));
            }
        }

        html += `</div>`;

        if (!promo || promo.length <= 1) {
            html = `
                <div class="zero-case-wrapper">
                    <div class="wide-text-box without-product">
                        <h3 class="title">Non stai partecipando a nessuna promozione.</h3>
                        
                        <a class="button" href="/promozioni">
                            Guarda tutte le promozioni
                        </a>
                    </div>
                </div>
            `;

            this.$el.find('#promo-wrapper')
                .css('height', 'auto')
                .css('margin-bottom', '110px')
                .find('#all-promotion-btn').show();
        }

        this.$el.find('#promo-wrapper').html(html);

        if (nmcvBox == null) {
            this.$el.find('#review-wrapper .wide-text-box .text').html(`
Non hai ancora lasciato nessuna recensione: raccontaci cosa pensi dei nostri prodotti e iscrivendoti a <a href="/comunicazione/nel-mulino-che-vorrei">Nel Mulino che Vorrei</a> guadagna subito 10 Chicchi di Grano.
            `);
        }

        this.promoSwiperEnabled = true;
        this.initSwiper('promo');
    }


    uploadCallback(d) {
        var data = {
            imageTempPath: d.relativePath
        };

        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/member/avatar',
            type: "PUT",
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(data)
        }, false).then((d) => {
            console.log(d.body.path);
            $('#profile-image img').attr("src", d.body.path);
            $('.header .profile-icon img').attr("src", d.body.path);
        }, (d) => {
            console.error(d)
        })

    }

    showUnsubPage() {
        this.$el.find('.myprofile-tab').hide();
        this.$el.find('.round-button').removeClass('selected');
        this.$el.find('#unsubpagebtn').addClass('selected');
        window.setUpUnsubPage();
    }

    showMyProfile() {
        this.$el.find('.myprofile-tab').hide();
        this.$el.find('.round-button').removeClass('selected');
        this.$el.find('#myprofilebtn').addClass('selected');
        window.setUpMyProfile();
    }
}

export default ReservedAreaAppCtrl;
