import $ from 'jquery';
import Component from '../model/Component';

class CartaDelMulinoProductComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.$element = $(element);

        this.init();
    }

    init() {

        const $wallItems = this.$element.find('.wall-items-cnt');
        const $firstElement = this.$element.find('span[data-category-key]:nth-of-type(1)');
        const activeClass = 'active-product-item';

        $wallItems.hide();
        this.activateItem($firstElement, activeClass);

        this.$element.find(".buttons .product-item").click((e) => {
            const $current = $(e.currentTarget);
            if ($current.hasClass(activeClass)) {
                console.log('do nothing');
                return;
            }

            //handle selection product menu buttons
            const $previous = this.$element.find(`span[data-category-key].${activeClass}`);
            this.deactivateItem($previous, activeClass);
            this.activateItem($current, activeClass);
        });
        
    }

    deactivateItem(item, activeClass) {
        item.removeClass(activeClass);
        const category = item.data('category-key');
        this.$element.find(`.wall-items-cnt[data-category-key="${category}"]`).fadeOut();
    }

    activateItem(item, activeClass) {
        item.addClass(activeClass);
        const category = item.data('category-key');
        this.$element.find(`.wall-items-cnt[data-category-key="${category}"]`).fadeIn(300, () => {
            var event = new CustomEvent("product-list-component-resize", {"detail": ""});
            document.dispatchEvent(event);
        });

    }
}

export default CartaDelMulinoProductComponent;
