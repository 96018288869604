import Component from '../../model/Component';
import HttpClient from "../../business/HttpClient";
import Constants from "../../business/Constants";

class PromoLocatorLightComponent extends Component {

    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.geocoder = new google.maps.Geocoder;
        this.init();

        window.document.addEventListener(Constants.events.REQUIRE_GEOLOCATION, (event) => {
            this.setCurrentPosition().then((position) => {
                this.searchPromotions(position);
            });
        });
    }

    init() {
        this.doInitSearch();
    }

    searchPromotions(position) {
        let callObject = null;
        callObject = {
            method: 'GET',
            url: API_ENDPOINT +
            `/api/mulinobianco/1.0/web/instoreeventdetail/poi/${position.geometry.location.lat()}/${position.geometry.location.lng()}`,
            dataType: "json",
            contentType: "application/json; charset=utf-8",
        };
        return HttpClient.doCall(callObject)
            .then((data) => {
                if (data.body[0].eventCount > 0) {
                    let text = "Sai che ci sono <strong>" + data.body[0].eventCount + " promozioni attive</strong> vicino a te?";
                    $('.promo_num').html(text);
                    $('.geo-localizer .position .pin-icon').show();
                    $('.geo-localizer .position .promo_city').show();
                } else {
                    let text = "In questo momento non abbiamo trovato promozioni attive nella tua zona. <br/> Esplora la mappa per vedere se ci sono altri punti vendita comodi per te.";
                    $('.promo_num').html(text);
                    $('.geo-localizer .position .pin-icon').hide();
                    $('.geo-localizer .position .promo_city').hide();

                }
            }).catch(() => {
                let text = "In questo momento non abbiamo trovato promozioni attive nella tua zona. <br/> Esplora la mappa per vedere se ci sono altri punti vendita comodi per te.";
                $('.promo_num').html(text);
                $('.geo-localizer .position .pin-icon').hide();
                $('.geo-localizer .position .promo_city').hide();
            })
    }

    doInitSearch() {
        navigator.permissions.query({name: 'geolocation'}).then((result) => {
            if (result.state == 'granted') {
                this.setCurrentPosition().then((position) => {
                    this.searchPromotions(position);
                });
            } else {
                let text = 'Vuoi sapere quante <strong>promozioni attive</strong> ci sono vicino a te? <br><br><strong> Attiva la geolocalizzazione</strong> <br> oppure esplora la mappa';
                $('.promo_num').html(text);
                $('.geo-localizer .position .pin-icon').hide();
                $('.geo-localizer .position .promo_city').hide();
            }
        });
        // return !!verifyAndSearch;
    }

    setCurrentPosition() {
        let pos = null;
        return new Promise((resolve, reject) => {

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    pos = position;
                    let placeLocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    this.geocoder.geocode({'location': placeLocation}, (results, status) => {
                        if (status === 'OK') {
                            if (results[0]) {
                                this.currentLocation = results[0];
                                $(".promo_city").html(results[0].address_components[2].long_name);
                                resolve(this.currentLocation)
                            } else {
                                reject(false);
                            }
                        } else {
                            reject(false);
                        }
                    });
                }, () => {
                    reject(false);
                });
            }
        });

        return pos;
    }

}

export default PromoLocatorLightComponent;
