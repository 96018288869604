import Controller from '../model/Controller';
import Swiper from "swiper";
import $ from "jquery";

class ArmonieHubCtrl extends Controller {
    constructor(element) {
        super(element);
        this.element = element;

        this.init();
    }

    init() {
        this.ingredientsSwiperInit();
        this.preferencesSwiperInit();
        this.mealSwiperInit();
        this.eventRedirect();
    }

    ingredientsSwiperInit() {
        this.swiperIngredients = new Swiper($(this.element).find('#ingredients-filter'), {
            slidesPerView: 4,
            spaceBetween: 8,
            nextButton: $(this.element).find('.ingredients-button-next'),
            prevButton: $(this.element).find('.ingredients-button-prev'),
            setWrapperSize: true,
            breakpoints: {
                480: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 8
                },
                768: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 8
                }
            },
        });
    }

    preferencesSwiperInit() {
        this.swiperIngredients = new Swiper($(this.element).find('#preferences-filter'), {
            slidesPerView: 4,
            spaceBetween: 8,
            nextButton: $(this.element).find('.preferences-button-next'),
            prevButton: $(this.element).find('.preferences-button-prev'),
            setWrapperSize: true,
            breakpoints: {
                480: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 8
                },
                768: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 8
                }
            },
        });
    }

    mealSwiperInit() {
        this.swiperIngredients = new Swiper($(this.element).find('#meal-filter'), {
            slidesPerView: 4,
            spaceBetween: 8,
            nextButton: $(this.element).find('.meal-button-next'),
            prevButton: $(this.element).find('.meal-button-prev'),
            setWrapperSize: true,
            breakpoints: {
                480: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 8
                },
                768: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 8
                }
            },
        });
    }

    eventRedirect(){
        $('.swiper-slide').click((e) => {
            const type = e.currentTarget.dataset.type;
            const idValue = e.currentTarget.dataset.id;

            window.location.href = '/armonia-prodotti?' + type + '=' + idValue;
        })
    }
}

export default ArmonieHubCtrl;
