import Component from '../model/Component';
import $ from "jquery";

class DetailInfoComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.init();
    }

    init(){
        $('#show-detail-info').click((e) => {
            e.preventDefault();
            let label = $('#show-detail-info').text();
            $('#show-detail-info').text(
                label == "Mostra di più" ? "Mostra meno" : "Mostra di più");
            $('.detail-info__item-wrapper').toggleClass('detail-info__item-wrapper--full');
        });
    }
}

export default DetailInfoComponent;
