import Component from '../../model/Component';

class IntroStepComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;

        $(this.element).append(this.createHtml());


        /* if (store.getState().isLoggedUser) {
             this.goToNextStep()
         }*/
    }

    goToNextStep() {
        console.log(this.store.getState());

        this.store.dispatch({...this.store.getState(), type: 'contactForm/goToNextStep'});
    }

    getPageContent(isLogged = false) {
        return isLogged ? `<div class="form-item input-field full-size">
                        <button type="button" mb-component-click="goToNextStep()" class="button flat-cta shadow full-size"><strong>Inizia</strong> <i
                                class="arrow full right"></i></button>
                    </div>`
            : `<div class="form-item input-field ">
                        <a type="button" href="/registrazione" class="button dark medium-size">Registrati</a>
                    </div>
                    <div class="form-item input-field ">
                        <a type="button" href="/login?urlBack=/internal/contact-form.do" class="button dark medium-size">Accedi</a>
                    </div>
                    <div class="form-item input-field full-size">
                        <button type="button" mb-component-click="goToNextStep()" class="button flat-cta shadow full-size">Oppure prosegui senza accedere <i
                                class="arrow full right"></i></button>
                    </div>`

    }

    createHtml() {
        return `
            <div class="welcome-content">
            <img src="/fe-web/img/contact-form/intro.png">
                <div class="generic-text">
                    ${this.store.getState().isLoggedUser
            ? 'Utilizza il tuo account per compilare il modulo di contatto più velocemente'
            : 'Utilizza il tuo account su Mulino Bianco per compilare il modulo di contatto'}                    
                </div>
                <form class="form input-container welcome-form">
                ${this.getPageContent(this.store.getState().isLoggedUser)}                                                    
                </form>               
            </div>
        `;
    }

}


export default IntroStepComponent;
