"use strict";

const Select = (name, list, initialState = {}, options = {}) => {
    const selected = options.selected || ''
    const reducer = (block, item) => block + `<option value="${item.value || item.externalId || item.classificationId}" ${selected === item.value ? 'selected="selected"' : ''}>${item.name || item.label}</option>`;
    return `<div class="select-input-field ${options.size === 'm' ? 'medium-size' : ''} ${options.class ? options.class : ''}">
                    <select name="${name}" id="${name}" class="select-input">  
                           ${initialState && `<option value="${initialState.value}" ${selected === initialState.value ? 'selected="selected"' : ''}>${initialState.label}</option>`}                                                                                    
                        ${list.reduce(reducer, "")}     
                    </select>
                    <div class="error-message">Errore, campo non valido</div>
                    <i class="arrow down"></i>
                </div>`
}

export default Select;

