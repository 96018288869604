import Component from '../model/Component';
import $ from 'jquery';
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";
import Animabili from "../enums/Animabili";

class LandingSnodoSorpresineComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.init();
    }

    prepareCards() {
        let html = '';
        $(Animabili).each(function(i,v){
            html += `
                <div class="landing-snodo-sorpresine__block-card" data-card="${v.image}">
                <div class="landing-snodo-sorpresine__block-card-bg"></div>
                <img class="landing-snodo-sorpresine__block-card-animabile" src="/fe-web/img/sorpresine/animabili/${v.image}.png" alt="${v.name} image"/>
                <div class="landing-snodo-sorpresine__block-card-title">${v.name}</div>
                <a class="button button--primary" href="javascript:void(0)">Scopri di più</a>
            </div>
            `;
        });

        $('.landing-snodo-sorpresine__block-cards').append(html);
    }

    modalCard() {
        $(document).on('click','.landing-snodo-sorpresine__block-card',(e)=> {
            let selectedCard = Animabili.filter(card => ( card.image === e.currentTarget.getAttribute('data-card')))[0];

            Utility.buildModal(selectedCard.name,selectedCard.info,`<img class="landing-snodo-sorpresine__modal-image" src="/fe-web/img/sorpresine/animabili/${selectedCard.image}.png" alt="${selectedCard.name} image"/><div class="landing-snodo-sorpresine__modal-info"><b>Caratteristiche:</b> ${selectedCard.skills}</div><div class="landing-snodo-sorpresine__modal-superpower"><b>Superpotere:</b> ${selectedCard.superpower}</div>`);
        });
    }

    checkAnchor() {
        let searchParam = new URLSearchParams(document.location.search);
        let anchorLink = searchParam.get('a');
        window.setTimeout(
        $([document.documentElement, document.body]).animate({
                scrollTop: $(`#${anchorLink}`).offset().top
            }, 250)
            , 1500
        )
    }

    init() {
        this.prepareCards();
        this.modalCard();
        this.checkAnchor();
    }
}

export default LandingSnodoSorpresineComponent;