import Component from '../../model/Component';
import Pristine from "pristinejs/dist/pristine";
import {Utils} from "./Utils";
import $ from "jquery";

class MessageStepFormComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);
        this.store = store;
        this.payload = {};
        this.init();
    }

    goToNextStep() {
        this.store.dispatch({
            ...this.store.getState(),
            type: 'unMorsoDiFelicitaForm/goToNextStep',
            payload: this.payload
        });
    }

    trimRequired() {
        Pristine.addValidator('required', (value) => (value.trim() !== ''), "", 5, false);
    }

    addBackListener() {
        this.element.querySelector('.data-step-form__back').addEventListener('click', () => {
            this.store.dispatch({...this.store.getState(), type: 'unMorsoDiFelicitaForm/back'});
        });
    }

    saveFormData() {
        let formData = new FormData(this.form);
        this.payload.senderImage = this.imageUrl;
        this.payload.senderImageId = this.imageId;
        this.payload.senderName = formData.get("senderName");
        this.payload.recipientName = formData.get("recipientName");
        this.payload.message = formData.get("message");
        this.goToNextStep();
    }

    initForm() {
        this.form = document.forms["messageStepForm"];

        let formConfig = {
            classTo: 'data-step-form__input',
            errorClass: 'error',
            successClass: 'has-success',
            errorTextParent: 'data-step-form__input',
            errorTextTag: 'div',
            errorTextClass: 'error-field'
        };

        this.trimRequired();
        this.pristine = new Pristine(this.form, formConfig);
        this.addInputFileListener();
        Utils.addForbiddenWordValidators('senderName', this.pristine);
        Utils.addForbiddenWordValidators('recipientName', this.pristine);
        Utils.addForbiddenWordValidators('message', this.pristine);

        this.form.addEventListener("submit", (event) => {
            event.preventDefault();
            let valid = this.pristine.validate();
            valid ? this.saveFormData() : Utils.scrollToError(this.element);
        });
    }

    addInputFileListener() {
        const fileInput = this.form.querySelector('input[type=file]');
        fileInput.onchange = () => {
            const [file] = fileInput.files;
            if (!file) return;

            $('#overlayer').show();
            this.element.querySelector('.umdf-file__wrapper .file-error-field').innerHTML = '';
            Utils.getBase64(file).then((base64) => {
                this.validateFile(base64, fileInput);
            });
        }
    }

    validateFile(base64, fileInput) {
        Utils.uploadAndValidateFile('sender', base64).then(
            (resp) => {
                resp.isValid ? this.appendFile(resp, fileInput) : this.appendError(fileInput);
                $('#overlayer').hide();
            },
            (error) => console.log(error)
        )
    }

    appendError(fileInput) {
        const previewWrapper = this.element.querySelector('.umdf-file__preview.file');
        this.resetInputFile(fileInput, previewWrapper);
        this.element.querySelector('.umdf-file__wrapper .file-error-field').innerHTML =
            'Il file non rispetta la <a href="/promozioni/dedica-un-morso-di-felicita/netiquette" target="_blank">netiquette</a>, carica una nuova foto';
    }

    appendFile(data, fileInput) {
        const previewWrapper = this.element.querySelector('.umdf-file__preview.file');
        this.imageId = data.imageId;
        this.imageUrl = data.imageMediaUrl;
        previewWrapper.innerHTML = `
            <div class="data-step-form__file">
                <img class="data-step-form__close" alt="Close" src="/fe-web/img/icons/remove-circle.svg">
                <img class="data-step-form__file-image" alt="preview" src="${this.imageUrl}">
            </div>
        `;

        previewWrapper.querySelector('.data-step-form__close').addEventListener('click', () => {
            this.resetInputFile(fileInput, previewWrapper);
        });
    }

    resetInputFile(fileInput, previewWrapper) {
        fileInput.value = null;
        this.imageId = null;
        this.imageUrl = null;
        previewWrapper.innerHTML = '';
        this.element.querySelector('.umdf-file__wrapper .file-error-field').innerHTML = '';
    }

    init() {
        this.addBackListener();
        this.initForm();
    }
}


export default MessageStepFormComponent;
