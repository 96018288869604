import Component from '../model/Component';
import HttpClient from "../business/HttpClient";
import UserManager from "../business/UserManager";

class LaunchBoxComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.tag = $(element).data("tag");

        UserManager.getLoggedUser().then((d) => {
            this.getLaunchBoxCustomization();
        }, () => {
            //NOOP
        })
    }


    getLaunchBoxCustomization() {
        HttpClient.doCall({
            url: API_ENDPOINT + "/api/mulinobianco/1.0/web/launchbox/customization/" + this.tag,
            method: "GET",
            dataType: "json"
        }, false).then((data) => {
            if (data.body != null) {
                let boxes = $(this.element).find(".box");
                for (var i = 0; i < data.body.length; i++) {
                    let item = data.body[i];
                    $(boxes[i]).find("img").attr("src", item.image);
                    $(boxes[i]).find(".link").html(item.title);
                    $(boxes[i]).find(".link").attr("href", item.link);
                }
            }
        }, (err) => {
            console.info(err);
        });
    }

}


export default LaunchBoxComponent;
