import Component from '../../model/Component';
import Card from "./common/card";
import StepHeader from "./common/stepHeader";

class ThreeElementsListComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;
        this.stepData = {}

        $(this.element).html(this.createHtml(store.getState().config));

    }

    goToNextStep(id) {
        let userChoice = this.store.getState().config.filter(o => o.id == id)[0];
        this.store.dispatch({
            ...this.store.getState(),
            type: 'contactForm/goToNextStep',
            payload: {step1: userChoice, formData: {}}
        });
    }

    back() {
        this.store.dispatch({...this.store.getState(), type: 'contactForm/back'});
    }

    createHtml(config) {
        return `
             ${StepHeader({title: "Come possiamo aiutarti?", subtitle: config.subtitle})}
            <div>
                ${this.createButtons(config)}
            </div>
        `;
    }

    createButtons(config) {
        let buttonsHtml = '';
        for (let i = 0; i < config.length; i++) {
            buttonsHtml += Card({
                nextStep: config[i].id,
                title: config[i].title,
                subtitle: config[i].subtitle
            })
        }


        return buttonsHtml;
    }
}


export default ThreeElementsListComponent;
