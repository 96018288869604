import Controller from '../model/Controller';
import {ajax} from "mbpromo/src/core/ajax";
import Pristine from "pristinejs";
import $ from "jquery";
import GigyaManager from "../business/GigyaManager";


class ArmonieContestAreaPersonaleCtrl extends Controller {
    constructor(element) {
        super(element);
        this.CH_PROMO = 'CH_947_MLB_WEB_ARMONIA23_NAV_NAV_20230923_20230923';
        this.collectionPromoApi = window.API_ENDPOINT + `/api/mulinobianco/2.0/web/promo-engine/armonie-qr-code-2023/qr-code?profiling=true`;
        this.init();
    }

    init() {
        $('#overlayer').show();
        GigyaManager.getLoggedUser().then((u) => {
            this.userId = u.UID;

            const ch = u.data[this.CH_PROMO];
            if (typeof ch !== "undefined" && ch != null && ch !== "") {
                this.generateQrCode();
            }else{
                this.initForm();
                $('#area-personale-form').show();
            }

            $('#overlayer').hide();
        }, () => {
            window.location.href = '/promozioni/lucky-draw';
        });


    }

    initForm(){
        let form = document.getElementById("form-armonie-contest");

        let formConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'input-container',
            errorClass: 'error',
            successClass: 'has-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'input-container',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'error-field'
        };

        this.pristine = new Pristine(form, formConfig);

        $('#form-armonie-contest').submit((event) => {
            event.preventDefault();

            let valid = this.pristine.validate();

            if (valid) {
                GigyaManager.addCH(this.CH_PROMO).then(() => {
                    $('#area-personale-form').hide();
                    this.generateQrCode();
                });
            }
        });
    }
    areaPersonale(){
        return ajax(this.collectionPromoApi, {method: 'GET', auth: true});
    }

    generateQrCode(){
        $('#overlayer').show();
        this.areaPersonale().then((resp) => {
            const reservation = resp.body.response.data.reservation;
            let token = reservation.qr_token;
            $('.area-personale-qr-code__qr-image').html('<img src="' + reservation.qr_code +'">');
            $('.area-personale-qr-code__qr-token').html(token.replace("mulinobianco-", ""));

            $('#area-personale-qr-code').show();

            $('#overlayer').hide();
        });
    }
}

export default ArmonieContestAreaPersonaleCtrl;
