/**
 *
 */

import Controller from '../model/Controller';
import {configureStore, createSlice} from '@reduxjs/toolkit'
import HttpClient from "../business/HttpClient";
import GigyaManager from "../business/GigyaManager";
import IntroStepComponent from "../components/contactForm/IntroStepComponent";
import ThreeElementsListComponent from "../components/contactForm/ThreeElementsListComponent";
import BoxStep2Component from "../components/contactForm/BoxStep2Component";
import InputStep3Component from "../components/contactForm/InputStep3Component";
import ThankyouStepComponent from "../components/contactForm/ThankyouStepComponent";
import InputStep4Component from "../components/contactForm/InputStep4Component";
import $ from "jquery";


class ContactFormCtrl extends Controller {
    constructor(element) {
        super(element);
        this.init();
    }

    init() {
        console.log(' API_ENDPOINT', API_ENDPOINT)
        let pConfig = HttpClient.doCall({
            url: `${API_ENDPOINT}/api/mulinobianco/2.0/web/customercare/configuration`,
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false);

        let pLoggedUser = new Promise((resolve, reject) => {
            GigyaManager.getLoggedUser().then(
                (data) => resolve(data),
                () => resolve(false));
        })

        let urlInitialStep = 0;
        let previousStep = 0;

        let initialState = {};
        Promise.all([pConfig, pLoggedUser]).then(values => {
            const config = values[0].body.body;
            let initialUserChoices = {formData: {}};

            const urlParams = new URLSearchParams(window.location.search);
            const userFlow = urlParams.get('promoCode') || null;
            const userFlowPrefill = {};

            if (userFlow) {
                urlInitialStep = 3;
                previousStep = urlInitialStep - 1;
                const firstChild = config[1];
                const secondChild = firstChild.children.find((el) => el.type === "PROMO");
                initialUserChoices = Object.assign(initialUserChoices, {
                    step1: firstChild,
                    step2: secondChild
                });

                userFlowPrefill.contest = userFlow;
            }

            initialState = {
                config: config,
                initialStep: urlInitialStep,
                previousStep: previousStep,
                currentStep: urlInitialStep,
                totalStep: 5,
                isLoggedUser: !!values[1],
                prefillData: Object.assign({}, userFlowPrefill, this.getPrefillData(values[1])),
                userChoices: initialUserChoices
            }

            const cfSlice = createSlice({
                name: 'contactForm',
                initialState: initialState,
                reducers: {
                    updateFormdata: (state, params) => {
                        $.extend(state.userChoices, params.payload);
                    },
                    deleteFormdata: (state, params) => {
                        delete state.userChoices.formData
                    },
                    goToNextStep: (state, params) => {
                        this.moveOut(state.currentStep);

                        state.previousStep = state.currentStep;
                        state.currentStep = state.currentStep + 1;

                        if (params.payload) {
                            const newFileList = Object.assign({}, state.userChoices.formData.fileList, params.payload.formData.fileList);
                            $.extend(state.userChoices, params.payload);
                            $.extend(state.userChoices.formData.fileList, newFileList);
                        }

                        this.updateProgressLabel(state);
                        this.moveIn(state.currentStep);
                        this.updateStack();
                    },
                    back: (state, params) => {
                        this.moveIn(state.currentStep - 1);
                        this.pushBack(state.currentStep);

                        state.previousStep = state.currentStep;
                        state.currentStep = state.currentStep - 1;

                        if (params && params.payload) {
                            $.extend(state.userChoices, params.payload);
                        }
                        delete state.userChoices.formData[`step${state.previousStep}`]
                        this.updateProgressLabel(state);
                        this.updateStack();
                    }
                }
            });

            this.store = configureStore({
                reducer: cfSlice.reducer
            })

            this.store.subscribe(() => {
                let state = this.store.getState();

                if (!this.stepComponents[state.currentStep]) {
                    this.loadStep(state.currentStep);
                }

            })

            this.stepComponents = []
            this.loadStep(this.store.getState().initialStep)
            this.initStep(this.store.getState())
        });

    }

    loadStep(stepIdx) {
        switch (stepIdx) {
            case 0:
                this.stepComponents[stepIdx] = new IntroStepComponent($(this.element).find(`[data-stepid="0"]`)[0], this, this.store)
                break
            case 1:
                this.stepComponents[stepIdx] = new ThreeElementsListComponent($(this.element).find('[data-stepid="1"]')[0], this, this.store);
                break;
            case 2:
                this.stepComponents[stepIdx] = new BoxStep2Component($(this.element).find('[data-stepid="2"]')[0], this, this.store)
                break
            case 3:
                this.stepComponents[stepIdx] = new InputStep3Component($(this.element).find('[data-stepid="3"]')[0], this, this.store)
                break
            case 4:
                this.stepComponents[stepIdx] = new InputStep4Component($(this.element).find('[data-stepid="4"]')[0], this, this.store)
                break
            case 5:
                this.stepComponents[stepIdx] = new ThankyouStepComponent($(this.element).find('[data-stepid="5"]')[0], this, this.store)
                break
            default:

        }

    }

    getPrefillData(profile) {
        if (!profile) {
            return false
        }
        return {
            ContactidGigya: profile.UID,
            ContactName: profile.profile.firstName,
            ContactSurname: profile.profile.lastName,
            ContactEmail: profile.profile.email,
            ContactPhone: profile.phoneNumber
        }
    }

    initStep(state) {
        this.setProgressLabel()
        const context = this
        $('.step-container').each(function (index, item) {
            const $step = $(item);
            const currentStep = parseInt($step.data('stepid'));

            if (Number.isNaN(currentStep)) {
                console.error('Invalid step id');
                return;
            }
            if (currentStep < state.initialStep) {
                $step.css("display", "none")
                $step.addClass('move-out')
                setTimeout(() => {
                    $step.removeAttr('style')
                }, 2000)

            } else if (currentStep === state.initialStep) {
                $step.addClass('active-step');
            } else {
                $step.addClass('background-step');
                context.setStackElement($step, currentStep)
            }
        });
        this.updateProgressLabel(state)
        this.updateStack()
    }

    getStepById(id) {
        return $(this.element).find(`[data-stepid="${id}"]`)
    }

    setProgressLabel(val = '') {
        $('.progress-counter-label').html(val)
    }

    updateProgressLabel(state) {
        if (state.currentStep === 0) {
            this.setProgressLabel()
            return
        }
        const label = state.currentStep + '/' + state.totalStep
        this.setProgressLabel(label)
    }

    setStackElement($step, current, total = 5, speed = 1000) {
        const zindex = total - current;
        const topPosition = -(2 * current);
        const scale = (100 - (2 * current)) / 100;

        $step.css({
            'z-index': zindex,
            'top': topPosition + '%',
            'transform': 'scale(' + scale + ')',
            'transition-duration': speed + 'ms',
        })
    }

    updateStack() {
        let total = $('.background-step').length;
        $('.background-step').each((index, item) => {
            const $step = $(item);
            this.setStackElement($step, index + 1, total)
        })
    }

    pushBack(stepId) {
        const $step = this.getStepById(stepId)
        $step.addClass('background-step').removeClass('active-step')
    }

    moveOut(stepId) {
        const $step = this.getStepById(stepId)
        $step.addClass('move-out').removeClass('active-step');
    };

    moveIn(stepId) {
        const $step = this.getStepById(stepId)
        $step.removeClass('background-step').removeAttr('style').addClass('active-step')
        if ($step.hasClass('move-out')) {
            $step.hide().removeClass('move-out').fadeIn()
        }
    }

}

export default ContactFormCtrl;
