/**
 *
 */

import Controller from '../model/Controller';
import $ from "jquery";
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";

class LandingPanbaulettoCtrl extends Controller {
    constructor(element) {
        super(element);

        this.init();
    }

    init() {
        this.productSlider = $('#products-slider');

        this.productsError = $('#prods-error');
        this.serviceError = $('#service-error');
        this.name = $('#name');
        this.privacy1 = $('#privacy1');
        this.formWrapper = $('.landing-panbauletto .form');
        this.landingUrl = $('#form-lead').data('landingUrl');
        this.submitObj = {
            "profile": {
                "email": "",
                "firstName": ""
            },
            "data": {
                "CH_250_MLB_WEB_LITEPANI18_CRS_CRS_20181215_20190110": ""
            },
            "subscriptions": {
                "NL_pani": {
                    "email": {
                        "isSubscribed": true
                    }
                }
            }
        };

        $("#freccina").click(function() {
            $('html, body').animate({
                scrollTop: $("#targetDiv").offset().top
            }, 2000);
        });
    }


    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    submit() {
        var valid = true;

        var nome = $('.landing-panbauletto input[name="nome"]').val();
        var email = $('.landing-panbauletto input[name="email"]').val();

        if (nome == "") {
            valid = false;
            $('input[name="nome"]').addClass("error");
        } else {
            $('input[name="nome"]').removeClass("error");
            this.submitObj.profile.firstName = nome;
        }

        if (email == "") {
            valid = false;
            $('.landing-panbauletto input[name="email"] ~ .hint.error').text("Il campo è obbligatorio");
            $('input[name="email"]').addClass("error");
        } else {
            $('input[name="email"]').removeClass("error");
            if (!this.validateEmail(email)) {
                valid = false;
                $('.landing-panbauletto input[name="email"] ~ .hint.error').html("Email non valida");
                $('input[name="email"]').addClass("error");
            } else {
                $('input[name="email"]').removeClass("error");
                this.submitObj.profile.email = email;
            }
        }

        if (valid) {
            this.serviceError.hide();
            this.submitObj.data.CH_250_MLB_WEB_LITEPANI18_CRS_CRS_20181215_20190110 = Utility.formatDate();
            this.formWrapper.addClass('loading');
            /*GigyaManager.liteRegistration(this.submitObj).then((data) => {
                document.location.href = this.landingUrl;
            }, () => {
                this.serviceError.show();
                this.serviceError.addClass('error');
                this.formWrapper.removeClass('loading');
            });*/
        }
    }
}

export default LandingPanbaulettoCtrl;
