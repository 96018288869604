/**
 *
 */
import Controller from '../model/Controller';
import $ from 'jquery';
import Constants from "../business/Constants";
import HttpClient from "../business/HttpClient";

class LeadGeneratorFormCtrl extends Controller {

    constructor(element) {
        super(element);
        this.$el = $(element);
        this.files = [];
    }

    validateEmail(email){
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    submit(){
       var valid = true;

       var nome = $('.leadGeneratorForm input[name="nome"]').val();
       var email = $('.leadGeneratorForm input[name="email"]').val();

       var nsPasta = $('input[name="ns-pasta"]')[0].checked;
       var nsMB= $('input[name="ns-mulinobianco"]')[0].checked;
       var nsBio= $('input[name="ns-bio"]')[0].checked;


       if(nome == ""){
           valid = false;
           $('input[name="nome"]').addClass("error");
       }else{
           $('input[name="nome"]').removeClass("error");
       }

       if(email == ""){
            valid = false;
           $('.leadGeneratorForm input[name="email"] ~ .hint.error').text("Il campo è obbligatorio");
            $('input[name="email"]').addClass("error");
       }else{
           $('input[name="email"]').removeClass("error");
           if(!this.validateEmail(email)){
               valid = false;
               $('.leadGeneratorForm input[name="email"] ~ .hint.error').html("Email non valida");
               $('input[name="email"]').addClass("error");
           }else{
               $('input[name="email"]').removeClass("error");
           }
       }


       if( !(nsPasta || nsMB || nsBio)){
           valid = false;
           $('.checkbox-list-container').addClass("error");
       }else{
           $('.checkbox-list-container').removeClass("error");
       }

       if(valid){
           $("#form-lead").submit();
       }

    }




}

export default LeadGeneratorFormCtrl;
