import Component from '../model/Component';
import $ from 'jquery';

class ToTopComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.init();
    }

    init() {
        this.hidden = true;
        this.$element.click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 800);
        });

        window.onscroll = () => {
            this.updateTopAppearance();
        }

        this.updateTopAppearance();
    }

    updateTopAppearance() {
        if ($(window).scrollTop() > 350) {
            if (this.hidden) {
                this.$element.css('bottom', '17px');
                this.hidden = false;
            }
        } else if (!this.hidden) {
            this.$element.css('bottom', '-50px');
            this.hidden = true;
        }
    }
}

export default ToTopComponent;