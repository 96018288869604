import Controller from '../model/Controller';
import {configureStore, createSlice} from '@reduxjs/toolkit'
import PromoApi from "mbpromo/src/api/PromoApi";
import FirstStepComponent from "../components/armonieForm/FirstStepComponent";
import SecondStepComponent from "../components/armonieForm/SecondStepComponent";
import $ from "jquery";
import UserManager from "../business/UserManager";
import Constants from "../business/Constants";

class ArmonieContestCtrl extends Controller {
    constructor(element) {
        super(element);
        this.init();
    }

    init() {

        let urlInitialStep = 0;
        let previousStep = 0;

        let initialState = {}

        let initialUserChoices = {formData: {}}

        window.document.addEventListener(Constants.events.USER_LOGGGED_IN, event => {
            $('#overlayer').show();
           window.location.href = '/promozioni/lucky-draw/area-personale';
           return false;
        });

        window.document.addEventListener('screensetOnAfterScreenLoad', (event) => {
            // open modal
            if (event.detail.currentScreen === 'gigya-progressive-profiling-screen') {
                document.getElementById('login-modal').dispatchEvent(new CustomEvent('MB_MODAL_OPEN', () => this.open()));
            }
        });

        window.document.addEventListener("LOGIN_DONE", event => {
            $('body').removeClass('no-scroll');
            $(document.getElementById('login-modal')).hide();
            $('#overlayer').show();
            console.log("LOGIN_DONE");
            UserManager.init();
        });

        initialState = {
            // config: config,
            initialStep: urlInitialStep,
            previousStep: previousStep,
            currentStep: urlInitialStep,
            totalStep: 2,
            //isLoggedUser: !!values[1],
            //prefillData: Object.assign({}, userFlowPrefill, this.getPrefillData(values[1])),
            userChoices: initialUserChoices
        }

        const cfSlice = createSlice({
            name: 'armonieForm',
            initialState: initialState,
            reducers: {
                updateFormdata: (state, params) => {
                    $.extend(state.userChoices, params.payload);
                },
                deleteFormdata: (state, params) => {
                    delete state.userChoices.formData
                },
                goToNextStep: (state, params) => {

                    this.moveOut(state.currentStep);

                    state.previousStep = state.currentStep;
                    state.currentStep = state.currentStep + 1;

                    if (params.payload) {
                        const newFileList = Object.assign({}, state.userChoices.formData.fileList, params.payload.formData.fileList);
                        $.extend(state.userChoices, params.payload);
                        $.extend(state.userChoices.formData.fileList, newFileList);
                    }

                    this.updateProgressLabel(state)

                    this.moveIn(state.currentStep)
                    this.updateStack();
                },
                back: (state, params) => {
                    this.moveIn(state.currentStep - 1);
                    this.pushBack(state.currentStep)

                    state.previousStep = state.currentStep
                    state.currentStep = state.currentStep - 1;

                    if (params && params.payload) {
                        $.extend(state.userChoices, params.payload);
                    }
                    delete state.userChoices.formData[`step${state.previousStep}`]
                    this.updateProgressLabel(state)
                    this.updateStack();
                },
                goToStep: (state, params) => {

                    for (let i = 0; i < params.step; i++) {
                        this.moveOut(i);
                    }

                    state.previousStep = params.step - 1;
                    state.currentStep = params.step;

                    this.updateProgressLabel(state)

                    this.moveIn(state.currentStep)
                    this.updateStack();
                },
            }
        });

        this.store = configureStore({
            reducer: cfSlice.reducer
        })

        this.store.subscribe(() => {
            let state = this.store.getState();

            if (!this.stepComponents[state.currentStep]) {
                this.loadStep(state.currentStep);
            }

        })

        this.stepComponents = []
        this.loadStep(this.store.getState().initialStep)
        this.initStep(this.store.getState())
    }

    loadStep(stepIdx) {
        switch (stepIdx) {
            case 0:
                this.stepComponents[stepIdx] = new FirstStepComponent($(this.element).find(`[data-stepid="0"]`)[0], this, this.store)
                break
            case 1:
                this.stepComponents[stepIdx] = new SecondStepComponent($(this.element).find('[data-stepid="1"]')[0], this, this.store)
                break;
        }

    }

    getPrefillData(profile) {
        if (!profile) {
            return false
        }
        return {
            ContactidGigya: profile.UID,
            ContactName: profile.profile.firstName,
            ContactSurname: profile.profile.lastName,
            ContactEmail: profile.profile.email,
            ContactPhone: profile.phoneNumber
        }
    }

    initStep(state) {
        this.setProgressLabel()
        const context = this
        $('.step-container').each(function (index, item) {
            const $step = $(item);
            const currentStep = parseInt($step.data('stepid'));

            if (Number.isNaN(currentStep)) {
                console.error('Invalid step id');
                return;
            }
            if (currentStep < state.initialStep) {
                $step.css("display", "none")
                $step.addClass('move-out')
                setTimeout(() => {
                    $step.removeAttr('style')
                }, 2000)

            } else if (currentStep === state.initialStep) {
                $step.addClass('active-step');
            } else {
                $step.addClass('background-step');
                context.setStackElement($step, currentStep)
            }
        });
        this.updateProgressLabel(state)
        this.updateStack()
    }

    getStepById(id) {
        return $(this.element).find(`[data-stepid="${id}"]`)
    }

    setProgressLabel(val = '') {
        $('.progress-counter-label').html(val)
    }

    updateProgressLabel(state) {
        /*if (state.currentStep === 0) {
            this.setProgressLabel()
            return
        }*/
        const label = (state.currentStep + 1) + '/' + state.totalStep
        this.setProgressLabel(label)
    }

    setStackElement($step, current, total = 5, speed = 1000) {
        const zindex = total - current;
        const topPosition = -(2 * current);
        const scale = (100 - (2 * current)) / 100;

        $step.css({
            'z-index': zindex,
            'top': topPosition + '%',
            'transform': 'scale(' + scale + ')',
            'transition-duration': speed + 'ms',
        })
    }

    updateStack() {
        let total = $('.background-step').length;
        $('.background-step').each((index, item) => {
            const $step = $(item);
            this.setStackElement($step, index + 1, total)
        })
    }

    pushBack(stepId) {
        const $step = this.getStepById(stepId)
        $step.addClass('background-step').removeClass('active-step')
    }

    moveOut(stepId) {
        const $step = this.getStepById(stepId)
        $step.addClass('move-out').removeClass('active-step');
    };

    moveIn(stepId) {
        const $step = this.getStepById(stepId)
        $step.removeClass('background-step').removeAttr('style').addClass('active-step')
        if ($step.hasClass('move-out')) {
            $step.hide().removeClass('move-out').fadeIn()
        }
    }


}

export default ArmonieContestCtrl;
