import GigyaManager from "../../business/GigyaManager";
import {Utils} from "../unMorsoDiFelicita/Utils";
import HttpClient from "../../business/HttpClient";

class ContactStepFormComponent {
    constructor(dataToSave) {
        this.data = dataToSave;
        this.element = document.querySelector('#form .contact-form-step');
        this.loader = document.querySelector('.faq-page .loader');
        this.attachments = [];

        this.init();
    }

    checkInputValidity() {
        const inputs = this.element.querySelectorAll('input, textarea');
        inputs.forEach((input) => {
            input.validity.valid ?
                this.removeInputError(input.parentElement) :
                this.addInputError(input.parentElement, input.validity, input.validationMessage);
        });
    }


    removeInputError(wrapper) {
        wrapper.classList.remove('error');
        wrapper.querySelector('.error-label')?.remove();
    }

    addInputError(wrapper, validity, message) {
        this.removeInputError(wrapper);
        const fieldName = wrapper.querySelector('label').innerText.replace(' *', '');
        let value = fieldName + ' necessario';
        if (validity.typeMismatch) value = 'Formato email non valido';
        if (validity.rangeOverflow) value = fieldName +' non valida';
        if (validity.patternMismatch) value = `Formato ${fieldName.toLowerCase()} non valido`;
        if (validity.customError) value = message;
        wrapper.classList.add('error');
        wrapper.insertAdjacentHTML('beforeend', `<span class="error-label">${value}</span>`);
    }

    isFormValid() {
        this.checkInputValidity();

        this.element.querySelector('.input-wrapper.error')?.scrollIntoView({behavior: 'smooth'});
        return this.element.checkValidity();
    }


    addSubmitListener() {
        this.element.querySelector('.step-footer button').addEventListener('click', () => {
            this.isFormValid() && this.saveTicket();
        });
    }


    saveTicket() {
        const json = {};
        const data = new FormData(this.element);
        json.productionLot = data.get('lotCode');
        json.expirationDate = this.formatDate(data.get('expirationDate'));
        json.purchaseDate = this.formatDate(data.get('purchaseDate'));
        json.description = data.get('message');
        json.name = data.get('name');
        json.surname = data.get('surname');
        json.phone = data.get('phone');
        json.email = data.get('email');
        json.addressStreet = data.get('addressStreet');
        json.addressStreetPostalCode = data.get('addressStreetPostalCode');
        json.addressHouseNumber = data.get('addressHouseNumber');
        json.city = data.get('city');
        json.province = data.get('province');
        json.addressCountry = data.get('country');
        json.requestTypeFirstStep = this.data.step1;
        json.requestTypeSecondStep = this.data.step2;
        json.country = this.data.country;
        json.attachments = this.attachments;
        json.triageCategory = this.data.triageCategory;
        json.productId = this.data.productId ? parseInt(this.data.productId) : null;
        json.productPackagingId = this.data.productPackagingId ? parseInt(this.data.productPackagingId) : null;
        json.promoCode = this.data.step2 === "STEP2_PROMO" ? this.data.promoCode : null;
        json.jekoClassificationCode = data.get('type');
        const jekoType = this.types.find((el) => el.value === data.get('type'));
        json.jekoClassificationTxt = jekoType?.label;

        this.loader.classList.remove('hidden');
        HttpClient.doCall({
            url: `${API_ENDPOINT}/api/mulinobianco/3.0/web/customercare/open-ticket`,
            type: "POST",
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(json)
        }, false).then((data) => {
            this.loader.classList.add('hidden');
            this.appendThankYouPage();
        }, () => {
            this.loader.classList.add('hidden');
        });
    }


    formatDate(date) {
        if (!date) return;
        const token = date.split('-');
        return `${token[2]}-${token[1]}-${token[0]}`;
    }


    addInputFileListener() {
        const wrapper = this.element.querySelector('.input-wrapper.files');
        const fileInput = this.element.querySelector('input[type=file]');

        fileInput.onchange = () => {
            wrapper.querySelector('.error-label')?.remove();
            const [file] = fileInput.files;
            if (!file) return;

            const sizeInMB = file.size / (1024 * 1024).toFixed(2);
            if (sizeInMB > 3) {
                wrapper.insertAdjacentHTML('beforeend', `<span class="error-label">Dimensione massima consentita 3 MB</span>`);
                return;
            }

            this.loader.classList.remove('hidden');
            Utils.getBase64(file).then((base64) => {
                const attachment = {label: file.name, value: base64};
                this.attachments.push(attachment);
                this.loader.classList.add('hidden');
                this.appendFile(attachment, this.attachments.length - 1);
                fileInput.value = null;
                if (this.attachments.length === 3) fileInput.setAttribute('disabled', 'disabled');
            });
        }
    }

    appendFile(attachment, index) {
        const previewWrapper = this.element.querySelector('.files-container');
        previewWrapper.insertAdjacentHTML('beforeend',
            `<div class="file-chips" data-index="${index}">
                <span>${attachment.label}</span>
                <button type="button" class="delete-files"></button>
            </div>`);

        previewWrapper.querySelector(`div[data-index="${index}"] .delete-files`).addEventListener('click', (e) => {
            this.resetInputFile(e.target.parentElement);
        });
    }

    resetInputFile(element) {
        const index = parseInt(element.dataset.index);
        this.attachments.splice(index, 1);
        if (this.attachments.length < 3) this.element.querySelector('input[type=file]').removeAttribute('disabled');
        element.remove();
    }


    appendThankYouPage() {
        document.querySelector('.form-stepper > span:last-child')?.classList.add('active');
        document.querySelector('.faq-page-title').innerHTML = 'Richiesta presa in carico! <br> Riceverai a breve conferma per e-mail dell’apertura del ticket con il rispettivo ID.';
        document.querySelector('.faq-page-body').innerHTML =
            '<div class="form-page-description">Se, dopo aver controllato in spam, non ricevi conferma entro 24 ore, significa che la tua richiesta non è andata a buon fine e ti chiediamo gentilmente di riprovare.</div> <a class="button special" href="/">Torna alla Homepage</a>';

    }

    prefillField(fieldName, value, editable = false) {
        const field = this.element.querySelector(fieldName);
        field.value = value || '';
        if(!editable) field.setAttribute('readonly', 'readonly');
    }


    addGigyaButtonListener() {
        const emailButton = this.element.querySelector('#edit-email');
        const phoneButton = this.element.querySelector('#edit-phone');
        emailButton.classList.remove('hidden');
        emailButton.addEventListener('click', (e) => {
            e.preventDefault();
            const value = this.element.querySelector('#email').value;
            gigya.accounts.showScreenSet({
                screenSet: "Barilla-ProfileUpdate",
                startScreen: "gigya-confirm-email-screen",
                context: {
                    emailToVerify: value
                }
            });
        });

        phoneButton.classList.remove('hidden');
        phoneButton.addEventListener('click', (e) => {
            e.preventDefault();
            const value = this.element.querySelector('#phone').value;
            gigya.accounts.showScreenSet({
                screenSet: "Barilla-ProfileUpdate",
                startScreen: "gigya-mobile-confirm-screen",
                context: {
                    emailToVerify: value
                }
            });
        });
    }


    initForm() {
        GigyaManager.getLoggedUser().then((user) => {
            this.prefillField('#name', user.profile.firstName);
            this.prefillField('#surname', user.profile.lastName);
            this.prefillField('#phone', user.phoneNumber);
            this.prefillField('#email', user.profile.email);

            this.prefillField('#country', user.profile.country, true);
            this.prefillField('#city', user.profile.city, true);
            this.prefillField('#addressStreetPostalCode', user.profile.zip, true);
            this.prefillField('#province', user.data.COD_PROVINCE, true);

            this.addGigyaButtonListener();
            this.element.querySelector('.personal-details-disclaimer').classList.remove('hidden');
        });

        const lotCode = this.element.querySelector('#lotCode');
        this.element.querySelector('#noLotCode')?.addEventListener('change',
            (event) => this.toggleCheckbox(lotCode, event.target.checked));

        const expirationDate = this.element.querySelector('#expirationDate');
        this.element.querySelector('#noExpirationDate')?.addEventListener('change',
            (event) => this.toggleCheckbox(expirationDate, event.target.checked));


        const today = new Date();
        const month = `0${ today.getMonth()+1 }`.slice(-2);
        const day = `0${ today.getDate() }`.slice(-2);
        this.element.querySelector('#purchaseDate')?.setAttribute('max', `${ today.getFullYear() }-${ month }-${ day }`);
        this.addInputFileListener();
    }


    appendCountries(countries) {
        let html = '';
        const input = this.element.querySelector('#country');
        countries.forEach((country) => html += `<option value="${country.value}">${country.label}</option>`);
        input.insertAdjacentHTML('beforeend', html);
        input.value = 'IT';
    }



    getQueryParams() {
        let params = `step1=${this.data.step1}&step2=${this.data.step2}`;
        if(this.data.promoId) params += `&promoId=${this.data.promoId}`;

        return params;
    }


    getTypes() {
        this.loader.classList.remove('hidden');
        HttpClient.doCall({
            url: `${API_ENDPOINT}/api/mulinobianco/3.0/web/customercare/request-type?${ this.getQueryParams() }`,
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.loader.classList.add('hidden');
            this.appendTypes(data.body);
        }, () => {
            this.loader.classList.add('hidden');
        });
    }

    appendTypes(list) {
        this.types = list || [];
        const wrapper = this.element.querySelector('.input-wrapper.types');
        if(!list.length) return;
        let html = '<option value></option>';
        list.forEach((el) => html += `<option value="${el.value}">${el.label}</option>`);
        wrapper.querySelector('select').innerHTML = html;
        wrapper.classList.remove('hidden');
    }


    toggleCheckbox(input, checked) {
        if (checked) {
            input.setAttribute('disabled', '');
            this.removeInputError(input.parentElement);
        } else {
            input.removeAttribute('disabled');
        }
    }


    initDynamicLabels() {
        if(this.data.step1 === 3) return;  // caso problema

        const dynamicTitleElement = this.element.querySelector('.dynamic-section-title');
        const dynamicLabelElement = this.element.querySelector('.dynamic-section-label');
        if(this.data.step1 === 2) dynamicTitleElement.innerHTML = 'Richiedi informazioni *';

        if(this.data.step1 === 1) {
            dynamicTitleElement.innerHTML = 'Scrivi il tuo suggerimento *';
            dynamicLabelElement.innerHTML = 'Non vediamo l’ora di leggere i tuoi suggerimenti *';
        }
    }


    checkProductsVisibility() {
        const productSection = this.element.querySelector('.form-input-wrapper.products');
        this.showProductSection ? productSection.classList.remove('hidden') : productSection.classList.add('hidden');
        productSection.querySelectorAll('input').forEach((el) => {
            this.showProductSection ? el.removeAttribute('disabled') : el.setAttribute('disabled', 'disabled');
        });
    }

    initContactStepForm(data) {
        this.data = data;
        this.showProductSection = (this.data.triageCategory === 'PC');
        this.checkProductsVisibility();
        this.initDynamicLabels();
    }


    init() {
        this.initForm();
        this.addSubmitListener();
    }
}

export default ContactStepFormComponent;
