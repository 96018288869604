/**
 *
 */

import Controller from '../model/Controller';

class CarbonNeutralityCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.init();
    }

    init() {
        this.$el.find('.toggle-aspari').click(() => {
            this.$el.find('.toggle-aspari').toggleClass('open')
            this.$el.find('.aspari-content').fadeToggle()
        })
    }

}

export default CarbonNeutralityCtrl;
