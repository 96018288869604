import Component from '../model/Component';
import Pristine from "pristinejs/dist/pristine";
import $ from "jquery";
import GigyaManager from "../business/GigyaManager";
import CollectionPromoApi from "mbpromo/src/collection-api/CollectionPromoApi";
import Utility from "../business/Utility";

class PersonalCollectorFormEditComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.collectionPromoApi = new CollectionPromoApi({promoCode: 'raccolta-punti'});
        this.userBadgeCode = '';
        this.emailLoggedUser = '';
        this.emailVerified = false;
        this.phoneNumber = '';
        this.loadGigya();
        this.toggleFieldCpc();
        this.init();
    }


    loadGigya(){
        // load user info
        GigyaManager.getLoggedUser().then((u) => {
            this.emailLoggedUser = u.profile.email;

            this.userCap = u.profile.zip;
            this.emailVerified = u.emails.verified.map(e => e.toLowerCase()).includes(u.profile.email.toLowerCase());
            this.collectionPromoApi.areaPersonale().then((resp) => {
                let body = resp.body;
                this.phoneNumber = body.telefono;
                if (body.codici_tessera[0]) {
                    this.userBadgeCode = body.codici_tessera[0];
                    $('#cpc-code').html(this.userBadgeCode);
                    $('#cpc').html(this.userBadgeCode);

                    gigya.accounts.setAccountInfo({
                        data: {"CPC": this.userBadgeCode}, callback: function (d) {
                            console.log(d)
                        }
                    })
                }

                $('#email').html(this.emailLoggedUser);

                // reset fields before precompilation
                $('#personal-collector input[type="text"]').val('');
                $('#personal-collector input[type="text"]').attr("readonly", false);
                $('#personal-collector #cpc-den-urb').val('');
                $('#personal-collector #cpc-city').empty();
                $('#personal-collector #cpc-phone').attr('readonly', true);

                Utility.preCompileOnBoardingForm(body);
                Utility.prepareCap(false);
            });
        });
    }

    init(){
        let form = document.getElementById("personal-collector");

        let formConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'input-container',
            errorClass: 'error',
            successClass: 'has-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'input-container',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'error-field'
        };

        this.trimRequired();

        // init form validator
        this.pristine = new Pristine(form, formConfig);

        $('#cpc-province').attr('readonly', true);
        $('#cpc-info').change(() => {
            this.validatorCheckbox();
        })

        $('#personal-collector').submit((event) => {
            event.preventDefault();

            let valid = this.pristine.validate();

            if(valid){
                this.createAnagrafica();
            } else{
                this.scrollToError();
            }
        });
    }

    createAnagrafica() {
        $('#overlayer').show();
        this.collectionPromoApi.modificaDati(this.prepareData()).then(() => {
            $('.alert-success').css('display','flex');
            setTimeout(() => {
                $('.alert-success').hide();
            }, 5000);
            $('#overlayer').hide();
        }, (resp) => {
            let html = `<div class="input-container"><div class="error-field">${resp.body.error_message}</div></div>`;
            $('.personal-collector-form__error').html(html);
            $('#overlayer').hide();
        });
    }

    prepareData() {
        let nome = $("[name='cpc-firstname']").val();
        nome = nome.replace(/[\u2018\u2019]/g, "'");

        let cognome = $("[name='cpc-lastname']").val();
        cognome = cognome.replace(/[\u2018\u2019]/g, "'");

        let data = {
            "nome": nome,
            "cognome": cognome,
            "email": this.emailVerified ? this.emailLoggedUser : '',
            "ind_denurb": $("[name='cpc-den-urb']").val(),
            "ind_toponimo": $("[name='cpc-address']").val(),
            "ind_civico": $("[name='cpc-civic-number']").val(),
            "citofono": $("[name='cpc-notes']").val(),
            "cap": $("[name='cpc-zip']").val(),
            "citta": $("[name='cpc-city']").val(),
            "provincia": $("[name='cpc-province']").val(),
            "telefono": this.phoneNumber
        }

        if (this.userBadgeCode !== "") {
            data.codice_tessera = this.userBadgeCode;
        }

        return data;
    }

    toggleFieldCpc(){
        $('.toggle-cpc').click((e) => {
            e.preventDefault();
            $('.personal-collector-form__field').toggle();
        })
    }

    /**
     * Scroll to error
     */
    scrollToError(){
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".error").offset().top - 25
        }, 1500);
    }

    /**
     * Trim all required fields
     */
    trimRequired(){
        Pristine.addValidator('required', (value) => {
            return ( value.trim() !== '' );
        }, "", 5, false);
    }

    validatorCheckbox(){
        Pristine.addValidator(document.getElementById('cpc-info'), function(value) {
            console.log(value);
            // here `this` refers to the respective input element
            if (value.length && value[0] === value[0].toUpperCase()){
                return true;
            }
            return false;
        }, "The first character must be capitalized", 2, false);
    }

}

export default PersonalCollectorFormEditComponent;
