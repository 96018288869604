import ImageUpload from "../common/imageUpload";
import InputField from "../common/inputField";
import Button from "../common/button";
import StepHeader from "../common/stepHeader";

export default function TextTemplate(config) {
    let html = `
        ${InputField({type: 'hidden', name: "Classification", value: config.config.classificationId})}
        ${StepHeader({title: config.title, subtitle: config.subtitle})}
        <div class="telephone-info">${config.config.telephoneInfo}</div>  
        <div class="items-container">                
           ${ImageUpload({id: 1, next: 2})}
            ${ImageUpload({id: 2, next: 3, isDisabled: true})}
            ${ImageUpload({id: 3, isDisabled: true})}    
        </div>  
         <div class="items-container">       
        ${InputField({type: 'textarea', name: 'MessageText', placeholder: 'Cosa ci vuoi suggerire?*'})}
        </div>
        <div class="step-disclaimer">*I campi contrassegnati con l’asterisco sono obbligatori</div>
        <div class="item-centered">
           ${Button({
        id: 3,
        type: 'goNext',
        nextStep: 4,
    })}
        </div>`;
    return html
}