/**
 * Registra tutti i controller e componenti disponibili nel lifecycle della applicazione
 *
 * E' un singleton
 */


let instance = null;

class AppRegistry {

    constructor() {
        if (!instance) {
            instance = this;
        }
        this.components = [];
        this.controllers = [];


        return instance;
    }

    controller(name, clazz) {
        this.controllers.push({
            name: name,
            clazz: clazz
        });
    }

    component(name, clazz) {
        this.components.push({
            name: name,
            clazz: clazz
        });

    }

    getController(name) {
        var ctrl = this.controllers.filter(c => c.name == name).map(c => c.clazz)[0];
        return ctrl;
    }

    getComponent(name) {
        var comp = this.components.filter(c => c.name == name).map(c => c.clazz)[0];
        return comp;
    }
}

export default AppRegistry;
