/**
 *
 */

import Controller from '../model/Controller';

import UserManager from "../business/UserManager";
import $ from "jquery";
import Tracker from "../business/Tracker";
import Utilility from "../business/Utility";
import GigyaManager from "../business/GigyaManager";
import Constants from "../business/Constants";

class CioccogranoCtrl extends Controller {
    		constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.init()
    }

    init() {
//       console.log('init');
       
       UserManager.getLoggedUser().then((d) => {
           var ch = d.data["CH_186_MLB_WEB_CIOCCGRANO_BKR_CRS_20180516_20181231"];
           if (typeof ch === "undefined" || !ch || ch == "" || ch == null) {
               var d = new Date();
               var m = d.getMonth() + 1;
               var month = (m + "").length > 1 ? m : "0" + m;
               var fullstr = d.getFullYear() + "-" + month + "-" + d.getDate();
               window.gigya.accounts.setAccountInfo({
                   data: {"CH_186_MLB_WEB_CIOCCGRANO_BKR_CRS_20180516_20181231": fullstr}, callback: function (d) {
                       console.log(d)
                   }
               });
           }
       	});
    }

}

export default CioccogranoCtrl;
