import 'intersection-observer';
import $ from "jquery";
import "./business/DeferredImageLoaderManager";
import AppRegistry from "./business/AppRegistry";

import DefaultCtrl from "./controllers/DefaultCtrl";
import StabilimentiCtrl from "./controllers/StabilimentiCtrl";
import HomeCtrl from "./controllers/HomeCtrl";
import ProductDetailCtrl from "./controllers/ProductDetailCtrl";
import ProductCategoryListlCtrl from "./controllers/ProductCategoryListlCtrl";
import StyleGuideCtrl from "./controllers/StyleGuideCtrl";
import UnMorsoDiFelicitaFormCtrl from "./controllers/UnMorsoDiFelicitaFormCtrl";
import LoginCtrl from './controllers/LoginCtrl';
import FaqCtrl from './controllers/FaqCtrl';
import SendReviewCtrl from "./controllers/SendReviewCtrl";
import PromoCtrl from './controllers/PromoCtrl';
import ProductHubCtrl from "./controllers/ProductHubCtrl";
import ProductFinderCtrl from "./controllers/ProductFinderCtrl";
import EmmaAndGiovanniCtrl from "./controllers/EmmaAndGiovanniCtrl";
import ImpegnoCtrl from "./controllers/ImpegnoCtrl";
import ReservedAreaCtrl from "./controllers/ReservedAreaCtrl";
import LandingIntegraliCtrl from "./controllers/LandingIntegraliCtrl";
import SenzaGlutineCtrl from "./controllers/SenzaGlutineCtrl";
import IngredientsCtrl from "./controllers/IngredientsCtrl";
import PromoLocatorCtrl from "./controllers/PromoLocatorCtrl";
import CommitmentIngredientsCtrl from "./controllers/CommitmentIngredientsCtrl";
import HistoricalProductDetailCtrl from "./controllers/HistoricalProductDetailCtrl";
import HeroIngredientsCtrl from "./controllers/HeroIngredientsCtrl";
import FilieraCtrl from "./controllers/FilieraCtrl";
import LoginScreensetsCtrl from './controllers/LoginScreensetsCtrl';
import Tour2018Ctrl from "./controllers/Tour2018Ctrl";
import ReservedAreaAppCtrl from "./controllers/ReservedAreaAppCtrl";
import BowlCtrl from "./controllers/BowlCtrl";
import BowlViewCtrl from "./controllers/BowlViewCtrl";
import QuizCioccogranoCtrl from "./controllers/QuizCioccogranoCtrl";
import CioccogranoCtrl from "./controllers/CioccogranoCtrl";
import BioCtrl from "./controllers/BioCtrl";
import SenzaZuccheriCtrl from "./controllers/SenzaZuccheriCtrl";
import LeadGeneratorFormCtrl from "./controllers/LeadGeneratorFormCtrl";
import LeadGenIntegrali2018Ctrl from "./controllers/LeadGenIntegrali2018Ctrl";
import LandingMerendeCtrl from "./controllers/LandingMerendeCtrl";
import LandingPanbaulettoCtrl from "./controllers/LandingPanbaulettoCtrl";
import RecipeDetailCtrl from "./controllers/RecipeDetailCtrl";
import RecipeListCtrl from "./controllers/RecipeListCtrl";
import Tour2019PlayCtrl from "./controllers/Tour2019PlayCtrl";
import Tour2019DettaglioCtrl from "./controllers/Tour2019DettaglioCtrl";
import RecyclingPackCtrl from "./controllers/RecyclingPackCtrl";
import CartattackUpload from "./controllers/CartattackUpload";
import CartattackDetailCtrl from "./controllers/CartattackDetailCtrl";
import ContactFormCtrl from "./controllers/ContactFormCtrl";
import CarbonNeutralityCtrl from "./controllers/CarbonNeutralityCtrl";
import GazzettaCtrl from "./controllers/GazzettaCtrl";
import NewsletterGoodMorningCtrl from "./controllers/NewsletterGoodMorningCtrl";
import EmojiCtrl from "./controllers/EmojiCtrl";
import OnBoardingCartaceaCtrl from "./controllers/OnBoardingCartaceaCtrl";
import PersonalAreaCartaceaCtrl from "./controllers/PersonalAreaCartaceaCtrl";
import CaricamentoTesseraCtrl from "./controllers/CaricamentoTesseraCtrl";
import LandingCartaceaCtrl from "./controllers/LandingCartaceaCtrl";
import MillieCtrl from "./controllers/MillieCtrl";
import LandingSurveyCtrl from "./controllers/LandingSurveyCtrl";
import NewsletterAnni80Ctrl from "./controllers/NewsletterAnni80Ctrl";
import NewsletterNmcvCtrl from "./controllers/NewsletterNmcvCtrl";
import NewsletterTreNACCtrl from "./controllers/NewsletterTreNACCtrl";
import NewsletterTreNACConfermaCtrl from "./controllers/NewsletterTreNACConfermaCtrl";
import SorpresineCtrl from "./controllers/SorpresineCtrl";
import AryelHubCtrl from "./controllers/AryelHubCtrl";
import ArmonieHubCtrl from "./controllers/ArmonieHubCtrl";
import ArmonieLandingGlutenFreeCtrl from "./controllers/ArmonieLandinGlutenFreeCtrl";
import ArmonieLandingMandalaCtrl from "./controllers/ArmonieLandingMandalaCtrl";
import ArmonieCatalogoCtrl from "./controllers/ArmonieCatalogoCtrl";
import ArmonieGlutenFreeInsightsCtrl from "./controllers/ArmonieGlutenFreeInsightsCtrl";
import ArmonieContestCtrl from "./controllers/ArmonieContestCtrl";
import ArmonieContestAreaPersonaleCtrl from "./controllers/ArmonieContestAreaPersonaleCtrl";
import LandingCalendarioCtrl from "./controllers/LandingCalendarioCtrl";
import LandingCalendario2025Ctrl from "./controllers/LandingCalendario2025Ctrl";
import VideoLectureCtrl from "./controllers/VideoLectureCtrl";
import VideoCourseCtrl from "./controllers/VideoCourseCtrl";
import PiccoloMugnaioBiancoCtrl from "./controllers/PiccoloMugnaioBiancoCtrl";
import SpecialitaCasaCtrl from "./controllers/SpecialitaCasaCtrl";




import MenuComponent from "./components/MenuComponent";
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import SelectBoxComponent from "./components/SelectBoxComponent";
import ProductListComponent from "./components/ProductListComponent";
import ProductBarSliderComponent from "./components/ProductBarSliderComponent";
import StickyElementComponent from "./components/StickyElementComponent";
import YoutubeVideoComponent from "./components/YoutubeVideoComponent";
import LoginComponent from "./components/LoginComponent";
import ProductSliderComponent from "./components/ProductSliderComponent";
import RatingComponent from "./components/RatingComponent";
import IconRatingComponent from "./components/IconRatingComponent";
import ReviewListComponent from "./components/ReviewListComponent";
import NewsletterComponent from "./components/NewsletterComponentOld";
import AccordionComponent from "./components/AccordionComponent";
import ModalComponent from "./components/ModalComponent";
import VisualParallaxComponent from "./components/VisualParallaxComponent";
import TrackingComponent from "./components/TrackingComponent";
import AppearanceAnimationComponent from "./components/AppearanceAnimationComponent";
import SliderEcommerceComponent from "./components/SliderEcommerceComponent";
import PromoCustomizationComponent from "./components/PromoCustomizationComponent";
import VisualCarouselComponent from "./components/VisualCarouselComponent";
import NutritionCarouselComponent from "./components/NutritionCarouselComponent";
import LaunchBoxComponent from "./components/LaunchBoxComponent";
import FileUploadComponent from "./components/FileUploadComponent";
import ProductFinderCtaComponent from "./components/ProductFinderCtaComponent";
import CouponComponent from "./components/CouponComponent";
import CookieBarComponent from "./components/CookieBarComponent";
import IngredientsAutocompleteComponent from "./components/IngredientsAutocompleteComponent";
import PromoLocatorComponent from "./components/promoLocator/PromoLocatorComponent";
import PromoLocatorEventFilterComponent from "./components/promoLocator/PromoLocatorEventFilterComponent";
import PromoLocatorInformationWindow from "./components/promoLocator/PromoLocatorInformationWindow";
import PromoLocatorLightComponent from "./components/promoLocator/PromoLocatorLightComponent";
import ToTopComponent from "./components/ToTopComponent";
import SearchComponent from "./components/SearchComponent";
import VideoGalleryComponent from "./components/VideoGalleryComponent";
import SwiperComponent from "./components/SwiperComponent";
import CommentComponent from "./components/CommentComponent";
import SocialRecipeWallComponent from "./components/SocialRecipeWallComponent";
import FavouriteRecipeComponent from "./components/FavouriteRecipeComponent";
import StepsEventsSwiperComponent from "./components/StepsEventsSwiperComponent";
import InvitationTour2019Component from "./components/InvitationTour2019Component";
import LiteRegistrationComponent from "./components/LiteRegistrationComponent";
import PrivacyConsenseComponent from "./components/PrivacyConsenseComponent";
import CartaDelMulinoProductComponent from "./components/CartaDelMulinoProductComponent";
import RecipeLinkMenuComponent from "./components/RecipeLinkMenuComponent";
import GiocaApiComponent from "./components/GiocaApiComponent";
import QrCodeComponent from "./components/QrCodeComponent";
import OliComponent from "./components/OliComponent";
import GazzetteRelatedSliderComponent from "./components/GazzetteRelatedSliderComponent";
import SliderPromoCartaceaComponent from "./components/SliderPromoCartaceaComponent";
import GenericModalComponent from "./components/GenericModalComponent";
import DetailInfoComponent from "./components/DetailInfoComponent";
import TrackingPageViewComponent from "./components/TrackingPageViewComponent";
import PersonalCollectorFormComponent from "./components/PersonalCollectorFormComponent";
import PersonalCollectorFormEditComponent from "./components/PersonalCollectorFormEditComponent";
import SurveyComponent from "./components/SurveyComponent";
import FeedInstagramComponent from "./components/FeedInstagramComponent";
import ChatbotComponent from "./components/ChatbotComponent";
import SorpresineFilterMobileComponent from "./components/SorpresineFilterMobileComponent";
import SorpresineFilterDesktopComponent from "./components/SorpresineFilterDesktopComponent";
import SorpresineSliderComponent from "./components/SorpresineSliderComponent";
import LandingStepFormComponent from "./components/unMorsoDiFelicita/LandingStepFormComponent";
import VideoPageComponent from "./components/unMorsoDiFelicita/VideoPageComponent";
import AryelComponent from "./components/AryelComponent";
import VideoLectureLoginComponent from "./components/VideoLectureLoginComponent";
import GiocoSorpresine2024Component from "./components/GiocoSorpresine2024Component";
import LandingMooncakeComponent from "./components/LandingMooncakeComponent";
import LandingSnodoSorpresineComponent from "./components/LandingSnodoSorpresineComponent";
import LandingRssComponent from "./components/landingRss/LandingRssComponent";
import LandingRssModalComponent from "./components/landingRss/LandingRssModalComponent";
import EvidenceSliderComponent from "./components/EvidenceSliderComponent";

import FaqTriageComponent from "./components/contact-form/FaqTriageComponent";
import ContactFormComponent from "./components/contact-form/ContactFormComponent";
import HomepageCinquantesimoComponent from "./components/HomepageCinquantesimoComponent";
import PiccoloMugnaioBiancoFormComponent from "./components/PiccoloMugnaioBiancoFormComponent";
import SpecialitaCasaFormComponent from "./components/SpecialitaCasaFormComponent";


class App {
    constructor() {
        var registry = new AppRegistry();

        this.registerController(registry);
        this.registerComponent(registry);
        this.initController(registry);
    }

    initController(registry) {
        $(document).ready(() => {
            var ctrlName = window.document.body.getAttribute("mb-controller");
            if (ctrlName) {
                var clazz = registry.getController(ctrlName);
                if (!clazz) {
                    console.error("Ctrl " + ctrlName + " not found in registry!");
                } else {
                    new clazz(window.document.body); //Start Up Ctrl
                }
            } else {
                console.error("Ctrl is not defined!  ");
            }
        })
    }

    registerController(registry) {
        registry.controller("StabilimentiCtrl", StabilimentiCtrl);
        registry.controller("DefaultCtrl", DefaultCtrl);
        registry.controller("HomeCtrl", HomeCtrl);
        registry.controller("ProductDetailCtrl", ProductDetailCtrl);
        registry.controller("ProductCategoryListlCtrl", ProductCategoryListlCtrl);
        registry.controller("StyleGuideCtrl", StyleGuideCtrl);
        registry.controller("UnMorsoDiFelicitaFormCtrl", UnMorsoDiFelicitaFormCtrl);
        registry.controller("LoginCtrl", LoginCtrl);
        registry.controller("SendReviewCtrl", SendReviewCtrl);
        registry.controller("FaqCtrl", FaqCtrl);
        registry.controller("PromoCtrl", PromoCtrl);
        registry.controller("ProductFinderCtrl", ProductFinderCtrl);
        registry.controller("ProductHubCtrl", ProductHubCtrl);
        registry.controller("EmmaAndGiovanniCtrl", EmmaAndGiovanniCtrl);
        registry.controller("ImpegnoCtrl", ImpegnoCtrl);
        registry.controller("ReservedAreaCtrl", ReservedAreaCtrl);
        registry.controller("LandingIntegraliCtrl", LandingIntegraliCtrl);
        registry.controller("SenzaGlutineCtrl", SenzaGlutineCtrl);
        registry.controller("IngredientsCtrl", IngredientsCtrl);
        registry.controller("PromoLocatorCtrl", PromoLocatorCtrl);
        registry.controller("CommitmentIngredientsCtrl", CommitmentIngredientsCtrl);
        registry.controller("HistoricalProductDetailCtrl", HistoricalProductDetailCtrl);
        registry.controller("HeroIngredientsCtrl", HeroIngredientsCtrl);
        registry.controller("Tour2018Ctrl", Tour2018Ctrl);
        registry.controller("FilieraCtrl", FilieraCtrl);
        registry.controller("LoginScreensetsCtrl", LoginScreensetsCtrl);
        registry.controller("ReservedAreaAppCtrl", ReservedAreaAppCtrl);
        registry.controller("BowlCtrl", BowlCtrl);
        registry.controller("BowlViewCtrl", BowlViewCtrl);
        registry.controller("LeadGeneratorFormCtrl", LeadGeneratorFormCtrl);
        registry.controller("QuizCioccogranoCtrl", QuizCioccogranoCtrl);
        registry.controller("CioccogranoCtrl", CioccogranoCtrl);
        registry.controller("BioCtrl", BioCtrl);
        registry.controller("SenzaZuccheriCtrl", SenzaZuccheriCtrl);
        registry.controller("LeadGenIntegrali2018Ctrl", LeadGenIntegrali2018Ctrl);
        registry.controller("LandingMerendeCtrl", LandingMerendeCtrl);
        registry.controller("LandingPanbaulettoCtrl", LandingPanbaulettoCtrl);
        registry.controller("RecipeDetailCtrl", RecipeDetailCtrl);
        registry.controller("RecipeListCtrl", RecipeListCtrl);
        registry.controller("Tour2019PlayCtrl", Tour2019PlayCtrl);
        registry.controller("Tour2019DettaglioCtrl", Tour2019DettaglioCtrl);
        registry.controller("RecyclingPackCtrl", RecyclingPackCtrl);
        registry.controller("CartattackUpload", CartattackUpload);
        registry.controller("CartattackDetailCtrl", CartattackDetailCtrl);
        registry.controller("ContactFormCtrl", ContactFormCtrl);
        registry.controller("CarbonNeutralityCtrl", CarbonNeutralityCtrl);
        registry.controller("GazzettaCtrl", GazzettaCtrl);
        registry.controller("NewsletterGoodMorningCtrl", NewsletterGoodMorningCtrl);
        registry.controller("EmojiCtrl", EmojiCtrl);
        registry.controller("OnBoardingCartaceaCtrl", OnBoardingCartaceaCtrl);
        registry.controller("PersonalAreaCartaceaCtrl", PersonalAreaCartaceaCtrl);
        registry.controller("CaricamentoTesseraCtrl", CaricamentoTesseraCtrl);
        registry.controller("LandingCartaceaCtrl", LandingCartaceaCtrl);
        registry.controller("MillieCtrl", MillieCtrl);
        registry.controller("LandingSurveyCtrl", LandingSurveyCtrl);
        registry.controller("NewsletterAnni80Ctrl", NewsletterAnni80Ctrl);
        registry.controller("NewsletterTreNACCtrl", NewsletterTreNACCtrl);
        registry.controller("NewsletterTreNACConfermaCtrl", NewsletterTreNACConfermaCtrl);
        registry.controller("NewsletterNmcvCtrl", NewsletterNmcvCtrl);
        registry.controller("SorpresineCtrl", SorpresineCtrl);
        registry.controller("AryelHubCtrl", AryelHubCtrl);
        registry.controller("ArmonieHubCtrl", ArmonieHubCtrl);
        registry.controller("ArmonieLandingGlutenFreeCtrl", ArmonieLandingGlutenFreeCtrl);
        registry.controller("ArmonieLandingMandalaCtrl", ArmonieLandingMandalaCtrl);
        registry.controller("ArmonieCatalogoCtrl", ArmonieCatalogoCtrl);
        registry.controller("ArmonieGlutenFreeInsightsCtrl", ArmonieGlutenFreeInsightsCtrl);
        registry.controller("ArmonieContestCtrl", ArmonieContestCtrl);
        registry.controller("ArmonieContestAreaPersonaleCtrl", ArmonieContestAreaPersonaleCtrl);
        registry.controller("AryelHubCtrl", AryelHubCtrl);
        registry.controller("LandingCalendarioCtrl", LandingCalendarioCtrl);
        registry.controller("LandingCalendario2025Ctrl", LandingCalendario2025Ctrl);
        registry.controller("VideoLectureCtrl", VideoLectureCtrl);
        registry.controller("VideoCourseCtrl", VideoCourseCtrl);
        registry.controller("PiccoloMugnaioBiancoCtrl", PiccoloMugnaioBiancoCtrl);
        registry.controller("PiccoloMugnaioBiancoCtrl", PiccoloMugnaioBiancoCtrl);
        registry.controller("SpecialitaCasaCtrl", SpecialitaCasaCtrl);
    }

    registerComponent(registry) {
        registry.component("MenuComponent", MenuComponent);
        registry.component("HeaderComponent", HeaderComponent);
        registry.component("FooterComponent", FooterComponent);
        registry.component("ProductListComponent", ProductListComponent);
        registry.component("ProductBarSliderComponent", ProductBarSliderComponent);
        registry.component("SelectBoxComponent", SelectBoxComponent);
        registry.component("YoutubeVideoComponent", YoutubeVideoComponent);
        registry.component("StickyElementComponent", StickyElementComponent);
        registry.component("YoutubeVideoComponent", YoutubeVideoComponent);
        registry.component("ProductSliderComponent", ProductSliderComponent);
        registry.component("LoginComponent", LoginComponent);
        registry.component("RatingComponent", RatingComponent);
        registry.component("IconRatingComponent", IconRatingComponent);
        registry.component("ReviewListComponent", ReviewListComponent);
        registry.component("NewsletterComponent", NewsletterComponent);
        registry.component("AccordionComponent", AccordionComponent);
        registry.component("ModalComponent", ModalComponent);
        registry.component("VisualParallaxComponent", VisualParallaxComponent);
        registry.component("TrackingComponent", TrackingComponent);
        registry.component("AppearanceAnimationComponent", AppearanceAnimationComponent);
        registry.component("SliderEcommerceComponent", SliderEcommerceComponent);
        registry.component("PromoCustomizationComponent", PromoCustomizationComponent);
        registry.component("VisualCarouselComponent", VisualCarouselComponent);
        registry.component("NutritionCarouselComponent", NutritionCarouselComponent);
        registry.component("LaunchBoxComponent", LaunchBoxComponent);
        registry.component("FileUploadComponent", FileUploadComponent);
        registry.component("ProductFinderCtaComponent", ProductFinderCtaComponent);
        registry.component("CouponComponent", CouponComponent);
        registry.component("CookieBarComponent", CookieBarComponent);
        registry.component("IngredientsAutocompleteComponent", IngredientsAutocompleteComponent);
        registry.component("PromoLocatorComponent", PromoLocatorComponent);
        registry.component("PromoLocatorLightComponent", PromoLocatorLightComponent);
        registry.component("PromoLocatorEventFilterComponent", PromoLocatorEventFilterComponent);
        registry.component("PromoLocatorInformationWindow", PromoLocatorInformationWindow);
        registry.component("ToTopComponent", ToTopComponent);
        registry.component("SearchComponent", SearchComponent);
        registry.component("VideoGalleryComponent", VideoGalleryComponent);
        registry.component("SwiperComponent", SwiperComponent);
        registry.component("CommentComponent", CommentComponent);
        registry.component("SocialRecipeWallComponent", SocialRecipeWallComponent);
        registry.component("FavouriteRecipeComponent", FavouriteRecipeComponent);
        registry.component("StepsEventsSwiperComponent", StepsEventsSwiperComponent);
        registry.component("InvitationTour2019Component", InvitationTour2019Component);
        registry.component("LiteRegistrationComponent", LiteRegistrationComponent);
        registry.component("PrivacyConsenseComponent", PrivacyConsenseComponent);
        registry.component("CartaDelMulinoProductComponent", CartaDelMulinoProductComponent);
        registry.component("RecipeLinkMenuComponent", RecipeLinkMenuComponent);
        registry.component("GiocaApiComponent", GiocaApiComponent);
        registry.component("QrCodeComponent", QrCodeComponent);
        registry.component("OliComponent", OliComponent);
        registry.component("SurveyComponent", SurveyComponent);
        registry.component("GazzetteRelatedSliderComponent", GazzetteRelatedSliderComponent);
        registry.component("SliderPromoCartaceaComponent", SliderPromoCartaceaComponent);
        registry.component("GenericModalComponent", GenericModalComponent);
        registry.component("DetailInfoComponent", DetailInfoComponent);
        registry.component("TrackingPageViewComponent", TrackingPageViewComponent);
        registry.component("PersonalCollectorFormComponent", PersonalCollectorFormComponent);
        registry.component("PersonalCollectorFormEditComponent", PersonalCollectorFormEditComponent);
        registry.component("FeedInstagramComponent", FeedInstagramComponent);
        registry.component("ChatbotComponent", ChatbotComponent);
        registry.component("SorpresineFilterMobileComponent", SorpresineFilterMobileComponent);
        registry.component("SorpresineFilterDesktopComponent", SorpresineFilterDesktopComponent);
        registry.component("SorpresineSliderComponent", SorpresineSliderComponent);
        registry.component("AryelComponent", AryelComponent);
        registry.component("LandingStepFormComponent", LandingStepFormComponent);
        registry.component("VideoPageComponent", VideoPageComponent);
        registry.component("VideoLectureLoginComponent", VideoLectureLoginComponent);
        registry.component("LandingMooncakeComponent", LandingMooncakeComponent);
        registry.component("GiocoSorpresine2024Component", GiocoSorpresine2024Component);
        registry.component("LandingSnodoSorpresineComponent", LandingSnodoSorpresineComponent);
        registry.component("LandingRssComponent", LandingRssComponent);
        registry.component("LandingRssModalComponent", LandingRssModalComponent);
        registry.component("FaqTriageComponent", FaqTriageComponent);
        registry.component("ContactFormComponent", ContactFormComponent);
        registry.component("FaqTriageComponent", FaqTriageComponent);
        registry.component("ContactFormComponent", ContactFormComponent);
        registry.component("LandingRssComponent", LandingRssComponent);
        registry.component("LandingRssModalComponent", LandingRssModalComponent);
        registry.component("HomepageCinquantesimoComponent", HomepageCinquantesimoComponent);
        registry.component("PiccoloMugnaioBiancoFormComponent", PiccoloMugnaioBiancoFormComponent);
        registry.component("SpecialitaCasaFormComponent", SpecialitaCasaFormComponent);
        registry.component("EvidenceSliderComponent", EvidenceSliderComponent);
    }
}

new App();
