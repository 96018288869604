


class DeferredImageLoaderManager{
    constructor(){
        this.observer = new IntersectionObserver(this.onIntersection, {
            rootMargin: "0px 0px -200px 0px"
        });

        window.onload = ()=>{
            this.observeAllElements();
        }

    }

    onIntersection(entries, observer){
        entries.forEach(entry => {
            const ratio = entry.intersectionRatio;
            const element = entry.target;
            // se l'elemento è visibile
            if(ratio > 0){
                if(element.dataset.defloadSrc){
                    element.src = element.dataset.defloadSrc;
                } else if(element.dataset.defloadStyle){
                    element.style = element.dataset.defloadStyle;
                }
                observer.unobserve(entry.target);
            }
        });
    }

    observeAllElements(){
       let $elements = $('[data-defload-src], [data-defload-style]');
       if($elements.length > 0) {
           for (var i = 0; i < $elements.length; i++) {
               this.observer.observe($elements[i]);
           }
       }
    }
}

export default new DeferredImageLoaderManager();