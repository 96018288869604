import Component from '../model/Component';
import $ from 'jquery';
import GigyaManager from "../business/GigyaManager";
import CryptoJS from "crypto-js";
import Utilility from "../business/Utility";
import Cookies from "js-cookie";
import Constants from "../business/Constants";
import {ajax} from "mbpromo/src/core/ajax";

class GiocoSorpresine2024Component extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.init();
        this.userCodes = 0;
        this.requestInProgress = false;
    }

    areaPersonale() {
        let endpoint = window.API_ENDPOINT + "/api/mulinobianco/2.0/web/promo-engine/kids-2024/area-personale";
        return ajax(endpoint, {method: 'GET', auth: true});
    }

    init() {
        this.acceptRegulation();

        $('#overlayer').addClass('visible');
        this.areaPersonale().then((resp) => {
            this.userCodes = resp.body.response.data.codes.length;
        });

        GigyaManager.getLoggedUser().then((user) => {
            if (!user.data["CH_1017_MLB_WEB_GIOCOSORPRES_NAV_NAV_20240820_20241202"]) {
                $('.landing-sorpresine__modal').show();
            } else {
                this.prepareGame();
            }
        }, () => {
            this.prepareCover();
        });
    }

    prepareCover() {
        let urlBack = "/login?urlBack=" + Utilility.getCurrentUri() + '&regSource=1017';
        this.$element.html(`<a href="${urlBack}" class="iframe-cover"></a>`);
        $('#overlayer').removeClass('visible');
    }

    prepareGame() {
        this.$element.html('<iframe id="game" allow="fullscreen; autoplay; encrypted-media" src="https://mulino-animabili.gamindo.com/"></iframe>');

        $('#game').one('load', () => {
            console.log('game')
            $('#overlayer').removeClass('visible');
            window.addEventListener('message', (evt) => {
                this.onMessageRecieved(evt);
            });

        });
    }

    acceptRegulation() {
        $(document).on('click','.landing-sorpresine__modal .button', (e)=> {
            if (!this.requestInProgress) {
                this.requestInProgress = true;

                GigyaManager.addCH('CH_1017_MLB_WEB_GIOCOSORPRES_NAV_NAV_20240820_20241202').then(() => {
                    $('.landing-sorpresine__modal').hide();
                    $('#overlayer').removeClass('visible');
                    this.prepareGame();
                }, (err) => {
                    console.log(err)
                    this.requestInProgress = false;
                    $('#overlayer').removeClass('visible');
                })
            }
        })
    }


    sendMessageToIFrame(obj) {
        console.log("Send Message");
        console.log(obj);
        $('#game')[0].contentWindow.postMessage(JSON.stringify(obj), '*')
    }

    onMessageRecieved(message) {
        if (message.origin.includes('gamindo')) {
            let obj = JSON.parse(message.data);
            console.log(obj);

            if (obj.eventName == "GET_USER") {
                GigyaManager.getLoggedUser().then((d) => {
                    let event = {
                        eventName: "GET_USER",
                        payload: {
                            id_user: d.UID,
                            username: d.profile.firstName + '. ' + d.profile.lastName.substring(0, 1),
                            code_count: this.userCodes
                        }
                    }
                    this.sendMessageToIFrame(event);
                })
            } else if (obj.eventName == "FULLSCREEN") {
                $('#game').addClass("fullscreen");
            } else if (obj.eventName == "MINIMIZE") {
                $('#game').removeClass("fullscreen");
            } else if (obj.eventName == "ANALYTICS") {
                analyticsEvent(obj.param1, obj.param2, obj.param3); //Tracking to analytics
            } else if (obj.eventName == "PLAY") {
                dataLayer.push({
                    'event': 'button_start',
                    'data-click-text': 'gioca-animabili'
                });
            } else if (obj.eventName == "CTA") {
                if (obj.payload.link == "regolamento") {
                    window.location.href = '/promozioni/sorpresine-collection-animabili/regolamento';
                } else if (obj.payload.link == "codici") {
                    window.location.href = '/promozioni/sorpresine-collection-animabili/gioca-codice';
                }
            }
        }
    }

    hash(message) {
        return CryptoJS.SHA256(message + "odnimag").toString(CryptoJS.enc.Hex);
    }
}

export default GiocoSorpresine2024Component;

/*
 PUT_USER_STATS
 GET_USER_STATS
 {level: 1, score: 123}
 */