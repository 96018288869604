import Component from '../../model/Component';

class ThankyouStepComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;

        this.store.subscribe(() => {
            let state = this.store.getState();
            if (state.currentStep == 5) {
                $(this.element).html(this.createHtml(store.getState().userChoices.step1));
            }
        });

        $(this.element).html(this.createHtml(store.getState().userChoices.step1));
    }

    goToNextStep(id) {
        let userChoice = this.store.getState().step1.children.filter(o => o.id == id)[0];
        this.store.dispatch({
            ...this.store.getState(),
            type: 'contactForm/goToNextStep',
            payload: {step2: userChoice, formData: {}}
        });
    }

    back() {
        this.store.dispatch({...this.store.getState(), type: 'contactForm/back'});
    }

    createHtml(config) {
        return `<div class="thankyou-content">
                <img src="/fe-web/img/contact-form/thankyou.png">
                <div class="generic-text large-text">Grazie, il tuo messaggio è stato inviato</div>
                <div class="generic-text small-text">I nostri operatori analizzeranno la tua richiesta e ti ricontatteranno al più presto all'indirizzo email indicato.
                </div>
                <div class="hr-separator"></div>
                <a href="/home" class="button dark small-size go-home">
                    Torna alla Home
                </a>
            </div>`


    }


}


export default ThankyouStepComponent;
