import Component from '../model/Component';
import Constants from "../business/Constants";

class ModalComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        $(this.element).hide();

        this.data = {
            enableClose: !!$(this.element).data("enable-close"),
            title: $(this.element).data("title"),
            desc: $(this.element).data("desc"),
            href: $(this.element).data("href"),
            linkLabel: $(this.element).data("link-label"),
            smallType: $(this.element).data("small-type"),
            ctaClose: $(this.element).data("cta-close"),
            ctaClass: $(this.element).data('class-btn'),
            pathImage: $(this.element).data("path-image"),
            destroy: $(this.element).data("destroy")
        };

        if (!$(this.element).data("custom-html")) {
            this.createDOM();
        }

        this.element.addEventListener(Constants.events.MODAL_OPEN, () => this.open());

    }

    createDOM() {

        let close = this.data.enableClose ? '<a mb-component-click="close()" class="close-ico"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg_circle_close"></use> </svg></a>' : '';
        let title = this.data.title ? `<h4>${this.data.title}</h4>` : '';
        let desc = this.data.desc ? `<p>${this.data.desc}</p>` : '';
        let button = this.data.href ? `<a class="button ${this.data.ctaClass}" href="${this.data.href}">${this.data.linkLabel}</a>` : '';
        let buttonClose = this.data.ctaClose ? `<a mb-component-click="close()" class="button button--close">${this.data.linkLabel}</a>` : '';
        let image = this.data.pathImage ? `<img src="${this.data.pathImage}" alt="Welcome image" class="welcome-image">` : '';

        var html = `<div class='content-wrapper'>
           ${close}
           <div class="content">
               ${image}
               ${title}
               ${desc}           
               ${button}
               ${buttonClose}
            </div>
        </div>`;

        $(this.element).append(html);
    }

    close() {
        $('body').removeClass('no-scroll');
        $(this.element).hide();
        if(this.data.destroy){
            $(this.element).find('.modal-content').html('');
        }
    }

    open() {
        $('body').addClass('no-scroll');
        $(this.element).show();
    }
}


export default ModalComponent;