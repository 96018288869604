import Component from '../model/Component';
import Swiper from "swiper";

class SorpresineSliderComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.swiperElement = this.element.querySelector('.swiper');
        this.slidesPerView = this.element.dataset.slidePerView;
        this.init();
    }


    init() {
        new Swiper(this.swiperElement, {
            slidesPerView: this.slidesPerView || 6,
            spaceBetween: 40,
            prevButton: this.swiperElement.querySelector('.swiper-button-prev'),
            nextButton: this.swiperElement.querySelector('.swiper-button-next'),
            autoHeight: true,
            breakpoints: {
                767: {
                    slidesPerView: this.slidesPerView || 'auto',
                    spaceBetween: 16
                }
            }
        })
    }

}

export default SorpresineSliderComponent;