"use strict";

const ImageUpload = (props) => {
    return `<div class="upload-input small-size upload-image ${props && props.isDisabled ? 'disabled' : ''}" data-next="${props.next ? 'imageFilename' + props.next : ''}">
                    <a href="#" class="image-icon-close"></a>                 
                    <span class="image-loaded-text"></span>    
                    <span class="error-message"></span>    
                    <div class="slider loading-bar">
                        <div class="line"></div>
                        <div class="subline inc"></div>
                        <div class="subline dec"></div>
                    </div>
                    <button class="upload-input-button"><img src="/fe-web/img/contact-form/cloud.svg">
                        <div class="upload-button-text">Upload image<br>(max 3mb, JPG, PNG, PDF)</div>
                    </button>
                    <input class="input-image" type="file" id="uploadImage${props.id}" name="imageFilename${props.id}"
                           accept="image/x-png,image/jpg,image/jpeg,application/pdf"/>
                </div>
    `
}
export default ImageUpload;
