import Component from '../../model/Component';
import HttpClient from "../../business/HttpClient";
import VideoPreviewComponent from "./VideoPreviewComponent";
import {Utils} from "./Utils";

class VideoPageComponent extends Component {
    constructor(element, parentCtrl, params) {
        super(element, parentCtrl);
        this.videoWrapper = this.element.querySelector('.un-morso-di-felicita-video__wrapper');
        this.init();
    }


    getSandwich(id) {
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'GET',
                url: API_ENDPOINT + `/api/mulinobianco/1.0/web/mdf/sandwich/${id}`,
                dataType: "json",
                contentType: "application/json; charset=utf-8"
            }).then(
                (data) => resolve(data.body),
                (error) => {
                    Utils.hideVideoLoader();
                    console.log(error);
                    reject(error);
                });
        });
    }

    showExpiredVideoError() {
        Utils.hideVideoLoader();
        this.videoWrapper.innerHTML = `
            <div class="un-morso-di-felicita-video__error">
                <div class="umdf-subtitle">Troppo tardi...</div>
                <div class="un-morso-di-felicita-video__description umdf-text">Il video creato non è più disponibile</div>
                <a href="/promozioni/dedica-un-morso-di-felicita/prepara-il-tuo-morso" class="button" target="_blank">Crea un nuovo video</a>
            </div>
        `;
    }

    showGenericError(error) {
        let errorMessage = (error.status === 404) ? "Questa pagina non esiste" : "Qualcosa è andato storto";
        this.videoWrapper.innerHTML = `
            <div class="un-morso-di-felicita-video__error">
                <div class="umdf-subtitle">Oops!</div>
                <div class="un-morso-di-felicita-video__description umdf-text">${ errorMessage }</div>
            </div>
        `;
    }


    init() {
        Utils.showVideoLoader();
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        this.getSandwich(id).then((data) => {
            data ? new VideoPreviewComponent(data) : this.showExpiredVideoError();
        }).catch(error => this.showGenericError(error));
    }
}


export default VideoPageComponent;
