import Component from '../model/Component';
import $ from 'jquery';

class SocialRecipeWallComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);

        let touchDevice = this.is_touch_device();
        this.$element.addClass(touchDevice ? 'touch' : 'no-touch');

        if(touchDevice){
            this.$element.find('.srw-item').click((evt)=>{
                let $target = $(evt.target);
                this.$element.find('.srw-item').removeClass('tap');
                $target.addClass('tap');
            });
        }

    }

    is_touch_device() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function(query) {
            return window.matchMedia(query).matches;
        }

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }


}

export default SocialRecipeWallComponent;