import $ from 'jquery';
import Swiper from 'swiper';
import Component from '../model/Component';

class NutritionCarouselComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.$element = $(element);

        this.initSwiper();
    }

    initSwiper() {
        this.swiper = new Swiper(this.$element[0], {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 14,
            loop: false,
            nextButton: this.$element.parent().find('.swiper-button-next')[0],
            prevButton: this.$element.parent().find('.swiper-button-prev')[0],
            breakpoints: {
                479: {
                    slidesPerView: 1,
                },
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                }
            }
        })
    }
}

export default NutritionCarouselComponent;
