/**
 *
 */
import Controller from '../model/Controller';


import $ from 'jquery';
import Mustache from "mustache";
import Utility from '../business/Utility';
import HttpClient from "../business/HttpClient";
import UserManager from "../business/UserManager";
import range from 'rangeslider.js';
import TweenMax from "gsap";
import gReveal from "../vendor/gReveal";
import Swiper from "swiper";
import GigyaManager from '../business/GigyaManager';


class Tour2018Ctrl extends Controller {
	constructor(element) {
		super(element);
		this.init();
	}

	init() {
		this.reveal = new gReveal(
				{
					initialDelay: 0,
					offset: 120
				}
		);

		//console.log($('#showLive'));
		if($('#showLive').html()!=null && $('#showLive').html()!=undefined){
			this.showLive($('#showLive').val());
		}
		
		if($('#showLiveTours').html()!=null && $('#showLiveTours').html()!=undefined){
			this.showRanking();
		}
		
		if($('#showSlider').html()!=null && $('#showSlider').html()!=undefined){
			this.showSlider();
		}
		
		
		
		

	}

	showTourFact(tourselector){
		//console.log('|' + tourselector + '|' + tourselector.length);
		var newtourselector = tourselector.substring(1, (tourselector.length-1));

		//console.log('|' + newtourselector + '|');
		$('.tourTag').removeClass('selected');
		$('.tag_' + newtourselector).addClass('selected');

		$('.hoHide').fadeOut(300, 
				function(){
			$('#social_' + newtourselector).fadeIn(300);
			$('#after_social_' + newtourselector).fadeIn(300); 
		});


	}

	seguiTappa(tappa){

		this.user = null;
		UserManager.getLoggedUser()
		.then((d) => {
			this.user = d;
			//console.log('chiamo ' + API_ENDPOINT + '/api/mulinobianco/1.0/web/ratings/FAVOURITES/tourstage_' +tappa  + '/1');
			HttpClient.doCall({
				url: API_ENDPOINT + '/api/mulinobianco/1.0/web/ratings/FAVOURITES/tourstage_' +tappa  + '/1',
				type: "POST",
				dataType: "json",
				contentType: "application/json",
				data: JSON.stringify(this.form)
			}, false).then((data) => {
					//success
					$('.tour_follow_'+tappa ).html('Tappa seguita');
			}, () => {
				
				if (this.user == null) {
					//console.log(Utility.getCurrentUri());
					window.location.href = "/login?urlBack=" + Utility.getCurrentUri();
				} 

			});
		}, () => {
			if (this.user == null) {
				//console.log(Utility.getCurrentUri());
				window.location.href = "/login?urlBack=" + Utility.getCurrentUri();
			} 

		});
		//this.parentCtrl.loadComponents();
		


	}

	showLive(district){
		//console.log(district);
		$.when(
				$.ajax({
					type: "get",
					url: API_ENDPOINT + "/api/mulinobianco/1.0/web/tour/" + district + "/dashboard/",
					success: (data) => {
						//console.log('when success');
						//console.log(data);
					},
					error: (data) =>{
						//console.log('when error');
						//console.log(data);
					}
				})
				.then(( data, textStatus, jqXHR ) => {
					//console.log('then success');
					//console.log(data);
					$('#live-partecipanti').html(data.body.visitors);

					var i = 1;
					$.each(data.body.productRankingList, ( key, value ) => {
						if(value.image!=null && i<=3){
							$('#position-' + (i)).find('.roundend-blue').html(value.count);
							$('#position-' + (i)).find('.prod').attr("src",value.image);
							$('#position-' + (i)).find('h3').html(value.name);
							$('#position-' + (i)).removeClass('nope');
							i++;
						}
					});

					if(data.body.showCompletedQuiz==false){
						$('#esperienze-completate').addClass('two-col');
						$('#chicchi-guadagnati').addClass('two-col');
						$('#quiz-completati').remove();
						$('#esperienze-completate .roundend-blue').html(data.body.completedQuiz);
						$('#chicchi-guadagnati .roundend-blue').html(data.body.totalRewardPoint);
					}
					else{
						$('#esperienze-completate .roundend-blue').html(data.body.completedTotem);
						$('#quiz-completati .roundend-blue').html(data.body.completedQuiz);
						$('#chicchi-guadagnati .roundend-blue').html(data.body.totalRewardPoint);
					}



					$('#esperienze-completate').removeClass('nope');
					$('#quiz-completati').removeClass('nope');
					$('#chicchi-guadagnati').removeClass('nope');



					this.swiper = new Swiper('#swiper-container', {
						 autoHeight: true, //enable auto height
						pagination: '.swiper-pagination',
						paginationClickable: true,
						spaceBetween: 30,
						slidesPerView: 1
					})


				}));
	}
	
	
	
	
	showSlider(district){
		this.swiper = new Swiper('#swiper-container', {
			 autoHeight: true, //enable auto height
			pagination: '.swiper-pagination',
			paginationClickable: true,
			spaceBetween: 30,
			slidesPerView: 1
		});
		
		$(window).scroll(function() {
			if($(window).scrollTop() + $(window).height() > $(document).height() - $('section.last').height() - $('footer').height()) {
				var thumb = $('#social_wall .thumbnail.nope');
				$(thumb[0]).removeClass('nope');
				$(thumb[1]).removeClass('nope');
				$(thumb[2]).removeClass('nope');
		   }
		});
	}
	
	
	showRanking(){
		$.when(
				$.ajax({
					type: "get",
					url: API_ENDPOINT + "/api/mulinobianco/1.0/web/tour/TO/dashboard/",
//					url: "http://mb-api.stage.barilla.com/api/mulinobianco/1.0/web/tour/TO/dashboard/",
					success: (data) => {
						//console.log('when success');
						//console.log(data);
					},
					error: (data) =>{
						//console.log('when error');
						//console.log(data);
					}
				})
				.then(( data, textStatus, jqXHR ) => {
					//console.log('then success');
					//console.log(data);
					$('#live-partecipanti').html(data.body.visitors);

					var i = 1;
					$.each(data.body.productRankingList, ( key, value ) => {
						if(value.image!=null && i<=3){
							$('#position-' + (i)).find('.roundend-blue').html(value.count);
							$('#position-' + (i)).find('.prod').attr("src",value.image);
							$('#position-' + (i)).find('h3').html(value.name);
							$('#position-' + (i)).removeClass('nope');
							i++;
						}
					});

				}));
	}
}

export default Tour2018Ctrl;
