import $ from 'jquery';
import Component from '../model/Component';
import CouponManager from "../business/CouponManager";
import Constants from "../business/Constants";

class CouponComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        window.document.addEventListener(Constants.events.COUPON_FOUND, (evt) => {
            if (evt.detail.idProduct == this.id) {
                $(this.element).show();
            }
        });

        window.document.addEventListener(Constants.events.COUPON_NOTFOUND, (evt) => {
            if (evt.detail == this.id) {
                $(this.element).hide();
            }
        });

        this.id = $(element).data("id");
        CouponManager.register(this.id);


    }

}


export default CouponComponent;
