/**
 *
 */
import $ from "jquery";
import Controller from '../model/Controller';
import Constants from "../business/Constants";
import HttpClient from "../business/HttpClient";
import Swiper from 'swiper';
import recipeBlockTemplate from "../template/recipe-block";

class RecipeListCtrl extends Controller {
    constructor(element) {
        super(element);
        this.$el = $(this.element);
        this.elementPerPage = 6;
        this.productFilters = window.preselectedProductFilters || [];
        this.tasteFilter = null;
        this.order = "totalTime";
        this.init();

        $(window).resize(() => {
            this.onResize();
        })
        this.onResize();
    }

    init() {
        this.buildFilters();
        this.loadComponents();
        this.showMoreResults();
        this.updateProductNumberSelected();
        //this.resizeTimeOut();
        //this.rebuildRecipes();

        // filter if exists pid param in url
        if (this.getUrlParameter('pid') !== null &&
            this.getUrlParameter('pid') !== "" &&
            isNaN(this.getUrlParameter('pid')) === false) {
            this.selectFilter(parseInt(this.getUrlParameter('pid')));
        }

        this.buildRecipesBoxes();
    }

    updateProductNumberSelected() {
        $("#product-number-selected-value").html(this.productFilters.length);
    }

    buildFilters() {
        if ($(window).width() >= 768) {
            this.swiper = new Swiper($(this.element).find('#desktop-product-filter'), {
                slidesPerView: "auto",
                spaceBetween: 15,
                nextButton: $(this.element).find('.recipe-filter-button-next'),
                prevButton: $(this.element).find('.recipe-filter-button-prev')
            });
        }
        this.tagFilter = window.preselectedTagId || null;
        this.tasteFilter = window.preselectedTaste || null;
    }

    buildRecipesBoxes() {
        this.swiperBoxes = new Swiper($(this.element).find('#desktop-recipe-filter'), {
            slidesPerView: 3,
            spaceBetween: 15,
            nextButton: $(this.element).find('.recipe-button-next'),
            prevButton: $(this.element).find('.recipe-button-prev'),
            // setWrapperSize: true,
            breakpoints: {
                480: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 15
                },
                768: {
                    slidesPerView: 2,
                    spaceBetweenSlides: 15
                }
            },
            pagination:  $(this.element).find('.recipe-pagination'),
            paginationClickable: true,
        });
    }

    onResize() {
        this.destroyRecipesBoxes();
        this.buildRecipesBoxes();
    }

    destroyRecipesBoxes() {
        if (!!this.swiperBoxes) {
            this.swiperBoxes.destroy(true, true);
            delete this.swiperBoxes;
        }
    }

    showMoreResults() {
        this.$el.find(".recipe-block-container .recipe-block:hidden").slice(0, this.elementPerPage).css("display", "flex");
        this.togggleMoreButton();
    }


    togggleMoreButton() {
        $(".more-results-button-container").css("display", this.$el.find(".recipe-block-container .recipe-block:hidden").length == 0 ? "none" : "flex");
    }

    buildRecipeBlockHtml(recipe) {
        return recipeBlockTemplate(recipe);
    }


    selectFilter(filterCode) {

        let addFilter = this.productFilters.indexOf(filterCode) < 0;

        if (addFilter) {
            this.productFilters.push(filterCode);
        } else {
            this.productFilters.splice(this.productFilters.indexOf(filterCode), 1);
        }
        //Seleziono il filtro
        $("[data-filter-id='" + filterCode + "']").toggleClass("selected", addFilter);

        //Inserisco/rimuovo il filtro nei selezionati
        if (addFilter) {
            $(".selected-filter-container").prepend(`<div class="selected-filter" selected-filter-id="${filterCode}">
                                            <svg class="remove-icon" mb-click="removeFilter(${filterCode});">
                                                <use xlink:href="#svg_recipe_filter_remove"/>
                                            </svg>
                                           <div class="filter-text">${window.filterElements.filter(el => el.id == filterCode)[0].name}</div>
                                        </div>`);
        } else {
            $(".selected-filter-container [selected-filter-id='" + filterCode + "']").remove();
        }


        this.toogleDeleteAllSelectedFilter();
        this.updateProductNumberSelected();
        this.rebuildRecipes();
    }

    selectTasteFilter(filterCode) {
        let changeFilter = ((Number(filterCode)) != this.tasteFilter);
        $("[data-filter-taste-id]").removeClass("selected");
        if (changeFilter) {
            $("[data-filter-taste-id='" + filterCode + "']").addClass("selected");
            this.tasteFilter = Number(filterCode);
        } else {
            this.tasteFilter = null;
        }

        this.rebuildRecipes();
    }


    toogleDeleteAllSelectedFilter() {
        $(".remove-all-selected-filter").css("display", $(".selected-filter-container .selected-filter").length > 0 ? "flex" : "none");
    }

    removeFilter(filterCode) {
        this.productFilters.splice(this.productFilters.indexOf(filterCode), 1);
        this.changeFilterMarkupBeforeRemove(filterCode);
        this.toogleDeleteAllSelectedFilter();
        this.rebuildRecipes();
        this.updateProductNumberSelected();

    }

    changeFilterMarkupBeforeRemove(filterCode) {
        $(".selected-filter-container [selected-filter-id='" + filterCode + "']").remove();
        $("[data-filter-id='" + filterCode + "']").toggleClass("selected", false);
    }


    removeAllFilter() {
        this.productFilters.forEach((filterCode) => {
            this.changeFilterMarkupBeforeRemove(filterCode);
        });
        this.productFilters = [];
        this.toogleDeleteAllSelectedFilter();
        this.rebuildRecipes();
        this.updateProductNumberSelected();
    }

    selectOrderCriteria(orderCodeNumber) {
        orderCodeNumber = parseInt(orderCodeNumber);

        let orderCode;


        switch (orderCodeNumber) {
            case 0:
                orderCode = "totalTime";
                break;
            case 1:
                orderCode = "difficultyLevel";
                break;
            default:
                orderCode = "recent";
                break;
        }

        this.order = orderCode;
        $("[data-order-id]").removeClass("selected");
        $("[data-order-id='" + orderCode + "']").addClass("selected");
        this.rebuildRecipes();
    }

    showMobileProductFilter() {
        $('#modal-filter')[0].dispatchEvent(new CustomEvent(Constants.events.MODAL_OPEN, {detail: {}}));
    }


    rebuildRecipes() {

        var data = {idProduct: this.productFilters, order: this.order};
        if (this.tasteFilter) {
            data.idTaste = this.tasteFilter;
        }

        var params = "?order=" + this.order;
        if (this.tasteFilter) {
            params += "&idTaste=" + this.tasteFilter;
        }

        if (this.tagFilter) {
            params += "&idTag=" + this.tagFilter;
        }

        if (this.productFilters.length > 0) {
            for (var i = 0; i < this.productFilters.length; i++) {
                params += "&idProduct=" + this.productFilters[i];
            }
        }

        this.toggleLoader(true);
        HttpClient.doCall({
            method: 'GET',
            url: API_ENDPOINT +
                `/api/mulinobianco/1.0/web/recipe/search${params}`,
            dataType: "json",
            contentType: "application/json; charset=utf-8"
        }).then((data) => {
            $(".recipe-block-container").html("");
            data.body.forEach((recipe) => {
                $(".recipe-block-container").append(this.buildRecipeBlockHtml(recipe));
            });
            if (data.body.length == 0) {
                $(".recipe-block-container").append(`<div class="no-results">Non ci sono risultati per questa ricerca.</div>`);
            }
            this.showMoreResults();
            this.toggleLoader(false);
        });
    }

    toggleLoader(display) {
        if (display) {
            if (this.$el.find("[data-tool-loader]").length == 0) {
                this.$el.find(".recipe-block-container").addClass('loading');
                this.$el.find(".recipe-block-container").append(`
                    <div data-tool-loader class="tool_loader"><svg>
                        <use  xlink:href="#svg_loader"></use>
                        </svg>
                     </div>
                `);
            }
        } else {
            this.$el.find(".recipe-block-container").removeClass('loading');
            this.$el.find("[data-tool-loader]").remove();
        }
    }


    getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

}

export default RecipeListCtrl;
