import Component from '../model/Component';
import $ from 'jquery';
import GigyaManager from "../business/GigyaManager";
import CryptoJS from "crypto-js";
import Utility from "../business/Utility";
import Pristine from "pristinejs/dist/pristine";

class AryelComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);
        this.urlEndpoint = this.$element.data('url');
        this.code = Utility.getUrlParameter('code');
        this.chCode = 'CH_922_MLB_WEB_GIOCOARYELS23_NAV_NAV_20230822_20230822';
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        this.init();
    }

    /**
     * Init iframe and wait event message
     */
    init() {
        // check if user is logged
        GigyaManager.getLoggedUser().then((u) => {
            const ch = u.data[this.chCode];
            if (typeof ch !== "undefined" && ch != null && ch !== "") {
                $('#form-acceptance').remove();
                this.loadGame();
            }else{
                let form = document.getElementById("template-form");

                let formConfig = {
                    // class of the parent element where the error/success class is added
                    classTo: 'col-form',
                    errorClass: 'error',
                    successClass: 'has-success',
                    // class of the parent element where error text element is appended
                    errorTextParent: 'col-form',
                    // type of element to create for the error text
                    errorTextTag: 'span',
                    // class of the error text element
                    errorTextClass: 'error-field'
                };

                // init form validator
                this.pristine = new Pristine(form, formConfig);

                this.handleSubmitForm();

                $('#form-acceptance').show();
            }

        }, () => {
            window.location.href = '/login?urlBack=' + Utility.getCurrentUri() + '&regSource=922';
        })
    }

    /**
     * Load game
     */
    loadGame(){
        const url = this.code ? this.urlEndpoint + '?code=' + this.code : this.urlEndpoint;
        const host = window.location.host;
        const hostAryel = 'https://ar.mulinobianco.it';


        this.$element.html('<iframe id="game" allow="fullscreen; autoplay; encrypted-media; camera ' + hostAryel + '; gyroscope ' + hostAryel + '; accelerometer ' + hostAryel + '; magnetometer ' + hostAryel + '; web-share ' + hostAryel + ';" src="' + url + '"></iframe>');
        //$('#game').on('load', () => {
            window.addEventListener('message', (evt) => {
                this.onMessageReceived(evt);
            }, "*");

            $('#aryel').focus();

            // disable scroll page during gaming
            window.addEventListener("keydown", (e) => {
                if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
                    e.preventDefault();
                }
            }, false);
            document.onkeydown = function(evt) {
                evt = evt || window.event;
                var keyCode = evt.keyCode;
                if (keyCode >= 37 && keyCode <= 40) {
                    return false;
                }
            };
        //});
    }

    /**
     * Send message to iframe
     * @param obj
     */
    sendMessageToIFrame(obj) {
        $('#game')[0].contentWindow.postMessage(JSON.stringify(obj), '*')
    }

    /**
     * Handling event from iframe
     * @param message
     */
    onMessageReceived(message) {
            let obj = JSON.parse(message.data);

            if (obj.eventName === "INIT_GAME_REQUEST") {
                GigyaManager.getLoggedUser().then((data) => {
                    const userId = data.UID;

                    const url = window.location.href;
                    let dataUrl = {
                        eventName: "LINKS",
                        payload: {
                            main: url
                        }
                    }

                    this.sendMessageToIFrame(dataUrl);

                    const isMobile = this.isMobile;
                    let dataMobile = {
                        eventName: "IS_MOBILE",
                        payload: {isMobile}
                    };

                    this.sendMessageToIFrame(dataMobile);

                    let dataLogged = {
                        eventName: "USER_LOGGED",
                        payload: {
                            uid: userId
                        }
                    };

                    this.sendMessageToIFrame(dataLogged);
                });

            } else if(obj.eventName == "GAME_RELOAD"){
                window.history.pushState({}, document.title, window.location.pathname);
                window.location.href = window.location.pathname;
            } else if (obj.eventName == "LOGIN_REQUEST") {
                window.location.href = "/login?urlBack=" + window.location.pathname + "&regSource=922";
            } else if (obj.eventName == "ENTER_FULLSCREEN") {
                $('#game').addClass("fullscreen");
            } else if (obj.eventName == "EXIT_FULLSCREEN") {
                $('#game').removeClass("fullscreen");
            }
    }

    hash(message) {
        return CryptoJS.SHA256(message + "odnimag").toString(CryptoJS.enc.Hex);
    }

    handleSubmitForm(){
        $('#conferma').on('click', (e) => {
            e.preventDefault();

            let valid = this.pristine.validate();

            if(valid){
                if($('#acceptance').length){
                    GigyaManager.addCH(this.chCode).then(() => {
                        $('#template-form').hide();
                        this.loadGame();
                    }, () => {

                    });
                }
            }
        });
    }
}

export default AryelComponent;