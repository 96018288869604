/**
 *
 */
import Controller from '../model/Controller';
import Tracker from "../business/Tracker";


class LandingMerendeCtrl extends Controller {

    constructor(element) {
        super(element);
        this.init();
    }

    init() {
        $("[scroll-button-ref]").click(function () {
            let buttonAttr = $(this).attr("scroll-button-ref");
            var top = $("[scroll-target='" + buttonAttr + "']").offset().top;
            $('html, body').animate({
                scrollTop: top
            }, 1000);
        })

    }

}

export default LandingMerendeCtrl;
