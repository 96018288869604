import $ from 'jquery';
import Component from '../model/Component';

class RecipeLinkMenuComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);

        this.$element = $(element);

        let aHrefs = this.$element.find(".recipe-link-item-container .recipe-link-item");

        for (var i = 0; i < aHrefs.length; i++) {
            let url = $(aHrefs[i]).attr("href");
            if (url == window.location.pathname) {
                $(aHrefs[i]).addClass("selected");
            }
        }

    }

}

export default RecipeLinkMenuComponent;
