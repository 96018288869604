import Component from '../model/Component';
import $ from 'jquery';

class AccordionComponent extends Component
{
    constructor(element, parentCtrl)
    {
        super(element, parentCtrl);

        this.items = $(this.element).find('> .accordion-item');
        this.animation = false;
        this.easing = 'swing';
        this.time = 500;

        this.init();
    }

    openContent(content)
    {
        content.slideDown(this.time, this.easing, () =>
        {
            let item = content.parent();
            if (item.offset().top < $(document).scrollTop()) $('html,body').animate({scrollTop: item.offset().top - 5}, this.time, this.easing, () => this.animation = false);
            else this.animation = false;
        });
    }

    closeContent(content)
    {
        content.slideUp(this.time, this.easing, () => this.animation = false);
    }

    init()
    {
        this.items.each((index, item) =>
        {
            item = $(item);
            let header = item.find('> .accordion-header'),
                content = item.find('> .accordion-content');

            header.on('click', (e) =>
            {
                e.preventDefault();
                e.stopPropagation();
                if (!this.animation)
                {
                    this.animation = true;
                    let active = this.items.filter('.active').not(item).removeClass('active').find('> .accordion-content');
                    if (active.length) this.closeContent(active);
                    item.toggleClass('active');
                    if (item.hasClass('active')) this.openContent(content);
                    else this.closeContent(content);
                }
            });
        });
    }
}

export default AccordionComponent;
