/**
 *
 */

import Controller from '../model/Controller';
import $ from "jquery";
import Swiper from "swiper";
import GigyaManager from "../business/GigyaManager";
import Utility from "../business/Utility";

class LeadGenIntegrali2018Ctrl extends Controller {
    constructor(element) {
        super(element);

        this.init();
    }

    init() {
        this.productSlider = $('#products-slider');
        this.initProductSlider();

        this.productsError = $('#prods-error');
        this.serviceError = $('#service-error');
        this.name = $('#name');
        this.privacy1 = $('#privacy1');
        this.formWrapper = $('.lead-gen-integrali-2018 .form');
        this.landingUrl = $('#form-lead').data('landingUrl');
        this.submitObj = {
            "profile": {
                "email": "",
                "firstName": ""
            },
            "data": {
                "CH_239_MLB_WEB_LITEINTGR18_CRS_CRS_20181015_20181231": "",
                "preferredProductCategory": ""
            },
            "subscriptions": {
                "NL_integrali": {
                    "email": {
                        "isSubscribed": true
                    }
                }
            }
        };

        $("#freccina").click(function() {
            $('html, body').animate({
                scrollTop: $("#targetDiv").offset().top
            }, 2000);
        });
    }

    initProductSlider() {
        new Swiper(this.productSlider[0], {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 20,
            nextButton: this.productSlider.parent().find('.swiper-button-next')[0],
            prevButton: this.productSlider.parent().find('.swiper-button-prev')[0],
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                767: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                1023: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                }
            }
        });
    }

    selectProduct(hybrisCode) {
        $('.swiper-box').removeClass("active");
        $('.cbox').prop('checked', false);

        let id = '#' + hybrisCode;
        $(id).addClass("active");

        let checkboxid = '#checkbox_' + hybrisCode;
        $(checkboxid).prop('checked', true);

        this.submitObj.data.preferredProductCategory = hybrisCode;
        this.productsError.removeClass("error");
        this.name.removeClass("on-error-name");
        this.privacy1.removeClass("on-error");
    }


    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    submit() {
        var valid = true;

        if (!$(".swiper-box.active")[0]) {
            valid = false;
            this.productsError.addClass("error");
            this.name.addClass("on-error-name");
            this.privacy1.addClass("on-error");
        } else {
            this.productsError.removeClass("error");
            this.name.removeClass("on-error-name");
            this.privacy1.removeClass("on-error");
        }

        var nome = $('.lead-gen-integrali-2018 input[name="nome"]').val();
        var email = $('.lead-gen-integrali-2018 input[name="email"]').val();

        if (nome == "") {
            valid = false;
            $('input[name="nome"]').addClass("error");
        } else {
            $('input[name="nome"]').removeClass("error");
            this.submitObj.profile.firstName = nome;
        }

        if (email == "") {
            valid = false;
            $('.lead-gen-integrali-2018 input[name="email"] ~ .hint.error').text("Il campo è obbligatorio");
            $('input[name="email"]').addClass("error");
        } else {
            $('input[name="email"]').removeClass("error");
            if (!this.validateEmail(email)) {
                valid = false;
                $('.lead-gen-integrali-2018 input[name="email"] ~ .hint.error').html("Email non valida");
                $('input[name="email"]').addClass("error");
            } else {
                $('input[name="email"]').removeClass("error");
                this.submitObj.profile.email = email;
            }
        }

        if (valid) {
            this.serviceError.hide();
            this.submitObj.data.CH_239_MLB_WEB_LITEINTGR18_CRS_CRS_20181015_20181231 = Utility.formatDate();
            this.formWrapper.addClass('loading');
            GigyaManager.liteRegistration(this.submitObj).then((data) => {
                document.location.href = this.landingUrl;
            }, () => {
                this.serviceError.show();
                this.serviceError.addClass('error');
                this.formWrapper.removeClass('loading');
            });
        }
    }
}

export default LeadGenIntegrali2018Ctrl;
