import Component from '../../model/Component';
import {Utils} from "./Utils";
import HttpClient from "../../business/HttpClient";
import $ from "jquery";

class TopicStepComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);
        this.store = store;
        this.saveButton = this.element.querySelector('.topic-step-form__save');
        this.init();
    }

    goToNextStep() {
        const selectedTopic = this.topics.find((t) => t.id === this.selectedTopicId);
        const payload = {topic: selectedTopic};
        this.store.dispatch({...this.store.getState(), type: 'unMorsoDiFelicitaForm/goToNextStep', payload});
    }

    getTopics() {
        return new Promise((resolve, reject) => {
            HttpClient.doCall({
                method: 'GET',
                url: API_ENDPOINT + `/api/mulinobianco/1.0/web/mdf/topics-config`,
                dataType: "json",
                contentType: "application/json; charset=utf-8"
            }).then((data) => resolve(data.body), (error) => reject(error));
        });
    }


    appendTopics(topics) {
        const wrapper = this.element.querySelector('.topic-step-form__wrapper');
        let topicHtml = '';
        let modalHtml = '';

        topics.forEach((topic) => {
            topicHtml += `
            <div class="topic-step-form__topic" data-topic-id="${topic.id}">
                <img alt="${topic.name}" src="${topic.image}">
                <div class="topic-step-form__topic-name umdf-subtitle">${topic.name}</div>
            </div>`;

            modalHtml += `
            <div id="${topic.id}" class="umdf-modal">
                <div class="umdf-modal__overlay"></div>
                <div class="umdf-modal__wrap">
                    <img class="umdf-modal__close" alt=Close" src="/fe-web/img/icons/remove-circle.svg">
                    <img class="umdf-modal__image" alt="${topic.name}" src="${topic.image}">
                    <h2 class="umdf-subtitle">${topic.name}</h2>
                    <p class="umdf-text">${topic.message}</p>
                    <div class="umdf-modal__button">
                        <a class=" button ok-button">Ok, per questo video</a>
                        <a href="/promozioni/dedica-un-morso-di-felicita" class="umdf-link">Torna alla Home page</a>
                    </div>
                </div>
            </div>`;
        });

        this.element.insertAdjacentHTML('beforeend', modalHtml);
        wrapper.insertAdjacentHTML('beforeend', topicHtml);
    }



    addListeners() {
        this.saveButton.addEventListener('click', () => this.goToNextStep());
        const topics = this.element.querySelectorAll('.topic-step-form__topic');
        topics.forEach((topic) => {
            topic.addEventListener('click', () => this.openTopicModal(topic));
        });
    }

    openTopicModal(topic) {
        const modal = document.getElementById(topic.dataset.topicId);
        Utils.openModal(modal);

        const selectButton = modal.querySelector('.ok-button');
        selectButton.addEventListener('click', () => {
            const selected = this.element.querySelector('.topic-step-form__topic.selected');
            selected && selected.classList.remove('selected');
            this.selectedTopicId = parseInt(topic.dataset.topicId);
            topic.classList.add('selected');
            this.saveButton.removeAttribute('disabled');
            Utils.closeModal();
        });
    }

    init() {
        $('#overlayer').show();
        this.getTopics().then((topics) => {
            this.topics = topics;
            this.appendTopics(topics);
            this.addListeners();
            $('#overlayer').hide();
        })
    }
}


export default TopicStepComponent;
