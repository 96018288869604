import Component from '../model/Component';
import Pristine from "pristinejs/dist/pristine";
import $ from "jquery";
import GigyaManager from "../business/GigyaManager";
import CollectionPromoApi from "mbpromo/src/collection-api/CollectionPromoApi";
import Utility from "../business/Utility";

class PersonalCollectorFormComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.collectionPromoApi = new CollectionPromoApi({promoCode: 'raccolta-punti'});
        this.userBadgeCode = '';
        this.emailLoggedUser = '';
        this.phoneNumber = '';
        this.loadGigya();
        this.toggleFieldCpc();
        this.retrieveAnagrafica();
        this.init();

        this.createAnagraficaInProgress = false;
    }


    loadGigya() {
        // load user info

        GigyaManager.getLoggedUser().then((u) => {
            Utility.trackEvent(u.UID, "STEP", "CH_HUBMONDOCARTACEO", "4");
            $('#overlayer').show();
            this.emailLoggedUser = u.profile.email;
            this.userCap = u.profile.zip;
            this.phoneNumber = u.phoneNumber;

            this.emailVerified = u.emails.verified.map(e => e.toLowerCase()).includes(u.profile.email.toLowerCase());

            let obj = {};
            if(this.emailVerified){
                obj.email = u.profile.email;
            }
            obj.cellulare = this.phoneNumber;

            $('#emailReadOnly span').html(this.emailLoggedUser);
            $('#telReadOnly span').html(this.phoneNumber);

            this.collectionPromoApi.precompilazione(obj).then((resp) => {
                let body = resp.body;
                if (body.codice_tessera) {
                    this.userBadgeCode = body.codice_tessera;
                }

                // reset fields before precompilation
                $('#personal-collector input[type="text"]').val('');
                $('#personal-collector input[type="text"]').attr("readonly", false);
                $('#personal-collector #cpc-den-urb').val('')
                $('#personal-collector #cpc-city').empty();

                if (!body.precompilazione) {
                    window.gigyaIntegration.autocompleteForm('#personal-collector', () => {
                        Utility.fillCapProvCityForm(this.userCap);
                        Utility.prepareCap(true);
                        $('#overlayer').hide();
                    });
                } else {
                    Utility.preCompileOnBoardingForm(body.precompilazione);
                    Utility.prepareCap(false);
                    $('#overlayer').hide();
                    this.pristine.validate(document.getElementById('cpc-city'));
                    this.pristine.validate(document.getElementById('cpc-province'));

                }
            }, (err)=>{
                console.log("Errore precompilazione WS");
                window.gigyaIntegration.autocompleteForm('#personal-collector', () => {
                    Utility.fillCapProvCityForm(this.userCap);
                    Utility.prepareCap(true);
                    $('#overlayer').hide();
                });
            });
        });
    }

    init() {
        let form = document.getElementById("personal-collector");

        let formConfig = {
            // class of the parent element where the error/success class is added
            classTo: 'input-container',
            errorClass: 'error',
            successClass: 'has-success',
            // class of the parent element where error text element is appended
            errorTextParent: 'input-container',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'error-field'
        };

        this.trimRequired();

        // init form validator
        this.pristine = new Pristine(form, formConfig);
        $('#cpc-province').attr('readonly', true);
        $('#cpc-info').change(() => {
            this.validatorCheckbox();
        })

        $('#personal-collector').submit((event) => {
            event.preventDefault();

            let valid = this.pristine.validate();

            if (valid) {
                this.createAnagrafica();
            } else {
                this.scrollToError();
            }
        });
    }

    createAnagrafica() {
        if (!this.createAnagraficaInProgress) {
            this.createAnagraficaInProgress = true;
            $('#overlayer').show();
            GigyaManager.addCH('CH_992_MLB_WEB_RACPUNTI24_BIS_CRS_20240115_20240115').then(() => {
                this.collectionPromoApi.createAnagrafica(this.prepareData()).then(() => {
                    window.location.href = '/raccolte-punti/area-personale';
                }, (resp) => {
                    this.createAnagraficaInProgress = false;
                    let html = `<div class="input-container"><div class="error-field">${resp.body.error_message}</div></div>`;
                    $('.personal-collector-form__error').html(html);
                    $('#overlayer').hide();
                });
            }, () => {
                this.createAnagraficaInProgress = false;
                let html = `<div class="input-container"><div class="error-field">Errore durante il salvataggio</div></div>`;
                $('.personal-collector-form__error').html(html);
                $('#overlayer').hide();
            })
        }

    }

    prepareData() {
        let nome = $("[name='cpc-firstname']").val();
        nome = nome.replace(/[\u2018\u2019]/g, "'");

        let cognome = $("[name='cpc-lastname']").val();
        cognome = cognome.replace(/[\u2018\u2019]/g, "'");

        let data = {
            "nome": nome,
            "cognome": cognome,
            "email": this.emailVerified ? this.emailLoggedUser : '',
            "ind_denurb": $("[name='cpc-den-urb']").val(),
            "ind_toponimo": $("[name='cpc-address']").val(),
            "ind_civico": $("[name='cpc-civic-number']").val(),
            "citofono": $("[name='cpc-notes']").val(),
            "cap": $("[name='cpc-zip']").val(),
            "citta": $("[name='cpc-city']").val(),
            "provincia": $("[name='cpc-province']").val(),
            "telefono": this.phoneNumber
        }

        if (this.userBadgeCode !== "") {
            data["codice_tessera"] = this.userBadgeCode;
        }

        return data;
    }

    toggleFieldCpc() {
        $('.toggle-cpc').click((e) => {
            e.preventDefault();
            $('.toggle-cpc').removeClass("selected");
            $(e.target).addClass("selected");

            if ($(e.target).hasClass('accept-cpc')) {
                $('.personal-collector-form__field').show();
            } else {
                $('.personal-collector-form__field').hide();
            }
        })
    }

    retrieveAnagrafica() {
        $('#button-cpc').click((e) => {
            e.preventDefault();

            $('.personal-collector-form__field .personal-collector-form__row .error').remove();



            let cpcCode = $('input[name="cpc-code"]').val();

            if (cpcCode) {
                let obj = {};
                if(this.emailVerified){
                    obj.email = this.emailLoggedUser;
                }
                obj.cellulare = this.phoneNumber;
                obj.tessera = cpcCode
                $('#overlayer').show();
                this.collectionPromoApi.precompilazione(obj).then((resp) => {
                    let body = resp.body;
                    if (body.codice_tessera) {
                        this.userBadgeCode = body.codice_tessera;
                    }

                    // reset fields before precompilation
                    $('#personal-collector input[type="text"]').val('');
                    $('#personal-collector input[type="text"]').attr("readonly", false);
                    $('#personal-collector #cpc-den-urb').val('');
                    $('#personal-collector #cpc-city').empty();

                    if (body.precompilazione === false) {
                        window.gigyaIntegration.autocompleteForm('#personal-collector', () => {
                            Utility.fillCapProvCityForm(this.userCap);
                            Utility.prepareCap(true);

                            // force revalidate

                            $('html, body').animate({
                                scrollTop: $('.personal-collector-form__form-wrapper').offset().top
                            }, 1000);
                        });
                    } else {
                        Utility.preCompileOnBoardingForm(body.precompilazione);
                        Utility.prepareCap(false);
                        this.pristine.validate(document.getElementById('cpc-city'));
                        this.pristine.validate(document.getElementById('cpc-province'));
                        $('html, body').animate({
                            scrollTop: $('.personal-collector-form__form-wrapper').offset().top
                        }, 1000);
                    }
                    $('#overlayer').hide();
                }, (error) => {
                    $('#overlayer').hide();
                    let html = `<div class="input-container error"><div class="error-field">${error.message}</div></div>`;
                    $('.personal-collector-form__field .personal-collector-form__row').append(html);
                });
            } else {
                let html = `<div class="input-container error"><div class="error-field">Campo obbligatorio</div></div>`;
                $('.personal-collector-form__field .personal-collector-form__row').append(html);
            }
        })
    }

    /**
     * Scroll to error
     */
    scrollToError() {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".error").offset().top - 25
        }, 1500);
    }

    /**
     * Trim all required fields
     */
    trimRequired() {
        Pristine.addValidator('required', (value) => {
            return (value.trim() !== '');
        }, "", 5, false);
    }

    validatorCheckbox() {
        Pristine.addValidator(document.getElementById('cpc-info'), function (value) {
            console.log(value);
            // here `this` refers to the respective input element
            if (value.length && value[0] === value[0].toUpperCase()) {
                return true;
            }
            return false;
        }, "The first character must be capitalized", 2, false);
    }

}

export default PersonalCollectorFormComponent;
