import Component from '../../model/Component';
import GigyaManager from "../../business/GigyaManager";

class LandingRssComponent extends Component {
    constructor(element, parentCtrl) {
        super(element, parentCtrl);
        this.audio = this.element.querySelector('.landing-rss__audio');
        this.modal = this.element.querySelector('#rssModal');
        this.loader = this.element.querySelector('#loader');
        this.init();
    }

    startAudio() {
        this.audio.load();
        this.audio.play().then(
            () => console.log('audio started'),
            (error) => console.error(error)
        );
    }

    checkUserStatus() {
        GigyaManager.getLoggedUser().then(account => {
                this.element.querySelector('.landing-rss__item-list').classList.remove('hidden');
                this.loader.classList.add('hidden');
            },
            () => {
                this.element.querySelector('.landing-rss__login-button').classList.remove('hidden');
                this.loader.classList.add('hidden');
            }
        );
    }


    close() {

    }


    openModalListener() {
        const listenButtons = this.element.querySelectorAll('.landing-rss__item-list .button');
        listenButtons.forEach((el) => el.addEventListener('click', () => {
            this.audio.pause();
        }));
    }


    addCloseModalListener() {
        const closeButtons = this.modal.querySelectorAll('a');
        closeButtons.forEach((el) => el.addEventListener('click', () => {
            this.modal.querySelector('audio').pause();
        }));
    }


    init() {
        this.loader.classList.remove('hidden');
        this.addCloseModalListener();
        this.openModalListener();
        this.checkUserStatus();
        this.startAudio();
    }
}

export default LandingRssComponent;
