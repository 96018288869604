import Component from '../../model/Component';
import Utility from "../../business/Utility";
import GigyaManager from "../../business/GigyaManager";

class ThirdStepComponent extends Component {
    constructor(element, parentCtrl, store) {
        super(element, parentCtrl);

        this.store = store;



        this.loadScreenset();

        this.afterPhoneValidation();

        GigyaManager.getLoggedUser().then((u)=>{
            Utility.trackEvent(u.UID, "STEP", "CH_792_MLB_WEB_RACPUNTI22_BIS_CRS_20230109_20230731", "3");
        });
    }

    goToNextStep() {
        console.log(this.store.getState());

        this.store.dispatch({...this.store.getState(), type: 'onBoardingForm/goToNextStep'});
    }

    loadScreenset(){
        gigya.accounts.showScreenSet({
            screenSet: "Barilla-ProfileUpdate",
            startScreen: "gigya-mobile-edit-screen",
            containerID: "phoneVerificationContainer"
        });
    }

    afterPhoneValidation(){
        document.addEventListener("screensetPhoneVerified", () => {
            this.goToNextStep();
        });
    }

}


export default ThirdStepComponent;
