/**
 *
 */

import Controller from '../model/Controller';
import UserManager from "../business/UserManager";
import $ from 'jquery';

class ProductHubCtrl extends Controller {
    constructor(element) {
        super(element);

        UserManager.getLoggedUser().then(() => {
            $('#box-review-boost').hide();
        }, () => {
        })
    }
}

export default ProductHubCtrl;
