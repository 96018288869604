/**
 *
 */
import Controller from '../model/Controller';
import $ from 'jquery';
import Mustache from "mustache";
import Utility from '../business/Utility';
import HttpClient from "../business/HttpClient";
import UserManager from "../business/UserManager";
import range from 'rangeslider.js';
import {gsap, Linear} from "gsap";

class HomeCtrl extends Controller {
    constructor(element) {
        super(element);
        this.init();
    }

    init() {
        this.$toolBox = $('.tool-box');
        this.$range = this.$toolBox.find('input[type="range"]');
        this.currentValue = this.$range.attr('value');
        this.$timeWall = $('#time-wall');

        this.onSlide = Utility.debounce(this.updateTool, 300);
        var r = range;
        this.$range.rangeslider({
            polyfill: false,
            onSlide: (position, value) => {
                this.updateTime(value);
                this.toggleLoader(true);
                this.onSlide();
            }
        });

        const messageObj = Utility.welcomeMessage();
        const welcomeMessage = messageObj.welcomeMessage;
        const welcomeDescription = messageObj.welcomeDescription;
        $('.main-cta h2').html(welcomeMessage);
        $('.main-cta .subtitle').html(welcomeDescription);

        UserManager.getLoggedUser().then((d) => {
            // hidden a CTA if user has NL_3NAC subscription
            if(d.subscriptions.NL_3NAC !== 'undefined' && d.subscriptions.NL_3NAC.email.isSubscribed === true){
                $('.main-cta .button').hide();
            }

            const messageObj = Utility.welcomeMessage();
            const welcomeMessage = messageObj.welcomeMessage;
            const welcomeDescription = messageObj.welcomeDescription;
            $('.main-cta h2').html(welcomeMessage + " " + d.profile.firstName);
            $('.main-cta .subtitle').html(welcomeDescription);
            this.updateTool();
        }, () => {
            const messageObj = Utility.welcomeMessage();
            const welcomeMessage = messageObj.welcomeMessage;
            const welcomeDescription = messageObj.welcomeDescription;
            $('.main-cta h2').html(welcomeMessage);
            $('.main-cta .subtitle').html(welcomeDescription);
        });

        $(document).on('click', '.video-wrapper', (e) => {
            let tit = $(e.currentTarget).find('.title');

            if (!!dataLayer) {
                dataLayer.push({
                    "event": " video_play",
                    "category_name": tit.text() || 'no_title'
                });
            }
        });
    }

    updateTime(value) {
        this.currentValue = value;
        var orario = parseInt(value / 2);
        var minuti = value % 2 == 0 ? "00" : "30";

        $('#time-container').html(orario + ':' + minuti);
    }

    updateTool() {
        HttpClient.doCall({
            url: API_ENDPOINT + '/api/mulinobianco/1.0/web/consumptionmomenttimeslot/wall/' + (this.currentValue / 2),
            type: "GET",
            dataType: "json",
            contentType: "application/json"
        }, false).then((data) => {
            this.printTool(data.body);
        }, () => {
            this.toggleLoader(false);
        });
    }

    printTool(data) {
        this.$timeWall.find('.time_wall_image').attr('src', data.image);
        this.$timeWall.find('.time_wall_image').toggle(!!data.image && data.image.length > 0);

        this.$timeWall.find('.time_wall_title').html(data.title);
        this.$timeWall.find('.time_wall_subtitle').html(data.subtitle);

        let $tmpContainer = $("<div/>");
        $tmpContainer.load('/fe-web/include/home/box.jsp', () => {
            let body = '';

            for (let i = 0; i < data.recommendProducts.length; i++) {
                data.recommendProducts[i].title = data.recommendProducts[i].name;
                data.recommendProducts[i].text = data.recommendProducts[i].nutritionalTipBoxTitle;

                if (data.recommendProducts[i].tag == 'I tuoi preferiti') {
                    data.recommendProducts[i].isFavourite = true;
                }
                if (data.recommendProducts[i].tag == 'Preferiti dalla community') {
                    data.recommendProducts[i].isCommunity = true;
                }

                body += Mustache.render($tmpContainer[0].innerHTML, data.recommendProducts[i]);
            }

            var tip = {
                title: data.ingredientTitle,
                text: data.ingredientText,
                image: data.ingredientImage,
                type_class: 'tip'
            };

            body += Mustache.render($tmpContainer[0].innerHTML, tip);

            for (let i = 0; i < data.socialBoxes.length; i++) {
                data.socialBoxes[i].social_icon = true;
                data.socialBoxes[i].type_class = 'social';
                data.socialBoxes[i].title = 'Mulino Bianco';
                data.socialBoxes[i].seoUrl = data.socialBoxes[i].link.url;
                data.socialBoxes[i].target = data.socialBoxes[i].link.target == 'BLANK' ? '_blank' : '';

                if (data.socialBoxes[i].socialNetwork.value == 'FACEBOOK') {
                    data.socialBoxes[i].isFacebook = true;
                }
                if (data.socialBoxes[i].socialNetwork.value == 'INSTAGRAM') {
                    data.socialBoxes[i].title = 'mulinobianco';
                    data.socialBoxes[i].isInstagram = true;
                }
                if (data.socialBoxes[i].socialNetwork.value == 'TWITTER') {
                    data.socialBoxes[i].isTwitter = true;
                }
                if (data.socialBoxes[i].socialNetwork.value == 'VIDEO') {
                    data.socialBoxes[i].isVideo = true;
                }

                body += Mustache.render($tmpContainer[0].innerHTML, data.socialBoxes[i]);
            }

            this.updateDom(body);
        });
    }

    updateDom(html) {
        let $body = this.$timeWall.find('.time_wall_body');

        gsap.to(
            $body.find('.box'),
            {
                x: 0,
                scale: 0.7,
                alpha: 0,
                ease: Linear.easeOut
            }
        );

        setTimeout(() => {
            this.displayItems($body, html);
        }, 200)
    }

    displayItems($body, html) {
        $body.css('visibility', 'hidden');
        $body.html(html);
        $body.find('.box').css('transform', 'scale(0.7)').css('opacity', '0');
        $body.css('visibility', 'visible');

        let $boxes = $body.find('.box');

        let i = 0;
        for (; i < $boxes.length; i += 2) {
            this.animateBox($boxes.get(i), i + i);
        }

        i--;
        for (let j = 1; j < $boxes.length; j += 2) {
            this.animateBox($boxes.get(j), i + j)
        }

        this.toggleLoader(false);
    }

    animateBox(box, delay) {
        if (!!box) {
            gsap.to(
                box,
                {
                    x: 0,
                    scale: 1,
                    alpha: 1,
                    ease: Linear.easeOut,
                    delay: 0.1 * delay
                }
            );

            // TweenMax.to(
            //     b,
            //     0.3,
            //     {
            //         x: 0,
            //         scale: 1,
            //         ease: Linear.easeOut,
            //         delay: 0.1 * delay
            //     }
            // );
            // TweenMax.to(
            //     b,
            //     0.2,
            //     {
            //         x: 0,
            //         alpha: 1,
            //         ease: Linear.easeOut,
            //         delay: 0.1 * delay
            //     }
            // );
        }
    }

    toggleLoader(display) {
        if (display) {
            if (this.$timeWall.find("[data-tool-loader]").length == 0) {
                this.$timeWall.parent().addClass('loading');
                this.$timeWall.append(`
                    <div data-tool-loader class="tool_loader"><svg>
                        <use  xlink:href="#svg_loader"></use>
                        </svg>
                     </div>
                `);
            }
        } else {
            this.$timeWall.parent().removeClass('loading');
            this.$timeWall.find("[data-tool-loader]").remove();
        }
    }
}

export default HomeCtrl;
